import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IconInCircle, InfoIcon } from "../../Widgets/Icons";
import { RiShieldUserLine } from "react-icons/ri";
import { TableComponent, Searchbar } from "../../Widgets/CommonWidgets";
import { WebApimanager } from "../../WebApiManager";
import { BsThreeDotsVertical, BsPlusCircle } from "react-icons/bs";
import AddVendor from "./addVendor";
import { userData, vendorId } from "../../atoms";
import style from "./index.module.css";
import { useRecoilValue, useRecoilState } from "recoil";
import swal from "sweetalert";
import { InputSelectField } from "../../Widgets/Forms/InputFields";
import { ButtonBlueOutline } from "../../Widgets/Buttons";
import { PhoneNumFormat } from "../../Widgets/Forms/FieldDependants";
import { SearchTableComponent } from "../../Widgets/Table/searchTableComponent";

const VendorDashboard = () => {
  let webApi = new WebApimanager();
  let navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [allVendors, setAllVendors] = useState([]);
  const [addVendorForm, setAddVendorForm] = useState(false);
  const currentUserData = useRecoilValue(userData);
  const [vendorID, setVendorID] = useRecoilState(vendorId);
  const [allVendorTypes, setAllVendorTypes] = useState([]);
  const [vendorType, setVendorType] = useState("");

  useEffect(() => {
    getVendorTableData();
    getVendorTypes();
  }, []);

  const getVendorTableData = () => {
    webApi
      .get("/vendor/company/" + currentUserData.companyID)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setTableData(res.data);
          setAllVendors(res.data);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage)
          swal("Error", error.customErrorMessage, "error");
        else
          swal(
            "Error",
            `Oops! Unable to get Vendors Data, Please try again later.`,
            "error"
          );
      });
  };

  const getVendorTypes = () => {
    webApi
      .get("/vendor/get/vendorTypes")
      .then(async (res) => {
        if (res.data.errorCode) {
          console.log("vendortypes api erros : ", res.data.errorMessage);
          swal("Error", res.data.errorMessage, "error");
        } else {
          setAllVendorTypes(["All", ...res.data.type]);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage)
          swal("Error", error.customErrorMessage, "error");
        else
          swal(
            "Error",
            `Oops! Unable to get Vendor Types, Please try again later.`,
            "error"
          );
      });
  };

  const GotoVendorDetails = (rows) => {
    setVendorID(rows.ID);
    navigate("/vendor/vendorDetails");
  };

  const handleVendorTypeFilter = (e) => {
    const vendorType = e.target.value;
    if (vendorType === "All") {
      setTableData(allVendors);
    } else {
      const filteredData = allVendors.filter(
        (item) => item.vendorType === vendorType
      );
      setTableData(filteredData);
    }
    setVendorType(e.target.value);
  };

  return (
    <>
      {addVendorForm ? (
        <AddVendor
          openAddVendor={addVendorForm}
          setOpenVendor={setAddVendorForm}
          getVendorData={() => getVendorTableData()}
        />
      ) : null}

      <div className="w-5/6 m-auto">
        <span className="flex justify-center items-center mb-12 bg-blue-52 text-white font-semibold text-center rounded h-10">
          Vendors Directory
          <InfoIcon message=" Use the Vendors Database to track all vendor activity and much more!" />
        </span>

        <section
          name="Header"
          className="flex justify-start items-center mb-8 mx-6 gap-4"
        >
          <IconInCircle
            icon={<RiShieldUserLine className="w-9 h-9 text-[#2C88AE]" />}
            radius="4rem"
            className="shadow-xl"
          />

          <span className="font-semibold">
            Explore Vendor Database!
            <p className="text-gray-400/100 font-normal">
              Use the Vendors Database to track all vendor activity and much
              more!
            </p>
          </span>
        </section>

        <section name="Table and options" className="">
          <div
            name="title and table Options"
            className="flex justify-between items-end gap-4 mb-4"
          >
            <span className="font-semibold">
              Vendors Directory ({tableData.length})
            </span>
            <div className="flex gap-4 items-end">
              <InputSelectField
                label="Vendor Category"
                name="vendorType"
                id="vendorType"
                dataCy="vendorType"
                placeholder="Select a Vendor Type"
                options={allVendorTypes}
                value={vendorType}
                onChange={handleVendorTypeFilter}
                style="!w-auto"
                notImp={true}
              />

              <SearchTableComponent
                style="!w-auto"
                id="vendorDashboardSearch"
                placeholder="Search Vendor..."
                data={allVendors}
                setTableData={setTableData}
                columns={[
                  { key: "companyName", title: "Vendor Company Name" },
                  {
                    key: "firstName",
                    title: "Vendor Contact",
                    applyMethod: (row) => {
                      return row.firstName + " " + row.lastName;
                    },
                  },
                  {
                    key: "contactNo",
                    title: "Contact No",
                    applyMethod: (row) => PhoneNumFormat(row.contactNo, true),
                  },
                  { key: "email", title: "Email" },
                  { key: "vendorType", title: "Type" },
                  { key: "areaCovered", title: "Area Covered" },
                ]}
              />

              {/* <BsPlusCircle
                title="Add Vendor"
                className="w-5 h-5 mb-3 cursor-pointer hover:text-blue-53"
                onClick={() => {
                  setAddVendorForm(true);
                }}
              /> */}

              <ButtonBlueOutline
                id="addNewVendor"
                btnName="addNewVendor"
                dataCy="addNewVendor"
                title="Add Vendor"
                name={
                  <>
                    <BsPlusCircle className="w-5 h-5 mr-2" /> Add New
                  </>
                }
                onClick={() => {
                  setAddVendorForm(true);
                }}
                className={`flex justify-center items-center px-2 w-fit h-10 rounded-md whitespace-nowrap`}
              />
            </div>
          </div>

          <TableComponent
            tableId="VendorsTable"
            data={tableData}
            columns={[
              { key: "companyName", title: "Vendor Company Name" },
              {
                key: "firstName",
                title: "Vendor Contact",
                applyMethod: (row) => {
                  return row.firstName + " " + row.lastName;
                },
              },
              {
                key: "contactNo",
                title: "Contact No",
                applyMethod: (row) => PhoneNumFormat(row.contactNo, true),
              },
              { key: "email", title: "Email" },
              { key: "vendorType", title: "Type" },
              { key: "areaCovered", title: "Area Covered" },
            ]}
            initialSort={{ key: "CreatedAt", direction: "descending" }}
            rowOnClick={GotoVendorDetails}
          />
        </section>
      </div>
    </>
  );
};

export default VendorDashboard;
