import { WebApimanager } from "../../WebApiManager";
import swal from "sweetalert";

let webApi = new WebApimanager();

// API to Get Listed Properties by Client ID
export const GetListedPropertiesByClientIdAPI = (clientId) => {
  return webApi
    .get(`/pmCore/listedProperties/${clientId}`)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage);
      }
      return res;
    })
    .catch((error) => {
      swal(
        "",
        "Oops! Listed Properties couldn't be loaded at the moment. Please try again later.",
        "error"
      );
      throw error;
    });
};

// API to Get Propspects By Client Id
export const GetProspectsByClientIdAPI = (clientId) => {
  return webApi
    .get(`/prospect/client/${clientId}`)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage);
      }
      return res;
    })
    .catch((error) => {
      swal(
        "",
        "Oops! Prospects couldn't be loaded at the moment. Please try again later.",
        "error"
      );
      throw error;
    });
};

// API to Get Propspects By Property Id
export const GetProspectsByPropertyIdAPI = (propertyId) => {
  return webApi
    .get(`/prospects/properties/${propertyId}`)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage);
      }
      return res;
    })
    .catch((error) => {
      swal(
        "",
        "Oops! Prospects couldn't be loaded at the moment. Please try again later.",
        "error"
      );
      throw error;
    });
};
