import React from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import { useRecoilValue } from "recoil";
import { isAuthenticated, userData } from "../../atoms";
import { useLocation } from "react-router-dom";

import Landing from "../Landing";
import Login from "../Login";
import SignUp from "../SignUp";
import ResetPass from "../ResetPassword";
import ForgotPass from "../ForgotPassword";
import Survey from "../Survey";
import TempUser from "../TempUser";
import BankSuccessPage from "../BankInfo/successPage";

import SubscriptionPlans from "../Subscription/subscriptionPlans";
import SubscriptionSuccess from "../Subscription/subscriptionSuccess";
import SubscriptionFailed from "../Subscription/subscriptionFailed";
import SubscriptionDetails from "../Subscription/subscriptionDetails";

import Navbar from "../Navbar";
import VendorDashboard from "../Vendor/vendorDashboard";
import PortfolioTable from "../Portfolios/portfolioTable";
import PropertyTable from "../Properties/propertyTable";
import Dashboard from "../Dashboard";
import PropertyDetails from "../Properties/propertyDetails";
import UnitDetails from "../UnitDetails";
import MaintenanceRequestDetails from "../MaintenanceRequest/MaintenanceRequestDetails";
import UserTable from "../UserTable";
import VendorDetails from "../Vendor/vendorDetails";
import MaintenancaDashboard from "../MaintenanceRequest/MaintenanceDashboard";
import ImportCsv from "../ImportFiles/ImportFiles";
import MyProfile from "../Profile/myProfile";
import Documents from "../Documents/documents";
import TenantDashboard from "../Tenants/tenantDashboard";
import TenantDetails from "../Tenants/tenantDetails";
import BankInfo from "../BankInfo";
import BankDashboard from "../BankInfo/bankDashboard";
import Footer from "../../Widgets/Footer";
import Notifications from "../Notifications";
import Accounting from "../Accounting";
import ChartofAccounts from "../Accounting/Chart/chartofAccounts";
import AccountDetails from "../Accounting/Chart/accountDetails";
import TransactionDetails from "../Accounting/Chart/transactionDetails";
import OwnerDashboard from "../Owners/ownerDashboard";
import OwnerDetails from "../Owners/ownerDetails";
import LeaseDetails from "../Tenants/leaseDetails";
import ManageNotification from "../Notifications/manageNotification";
import EvictionDetails from "../Tenants/Eviction/evictionDetails";

//Reports
import ReportBuilder from "../Reports/reportBuilder";

//Vacate Notice
import VacateNoticeDetails from "../Tenants/VacateNotice/vacateNoticeDetails";

//Prospects Navigation
import ProspectDashboard from "../Prospects/prospectsDashboard";

export default function AppRoutes() {
  const userAuthenticated = useRecoilValue(isAuthenticated);
  let navigate = useNavigate()
  const userInfo = useRecoilValue(userData)
  const location = useLocation();

  const hideNavbarPaths = ["/survey", "/subscription", "/subscription/success", "/subscription/retry"]; // Add all paths where you want to hide the navbar
  const showNavbar = !hideNavbarPaths.includes(location.pathname);



  return (
    <div className="flex flex-col min-h-screen">
      {userAuthenticated && showNavbar && <Navbar />}
      
      <main className={`flex-1 overflow-y-auto ${userAuthenticated && showNavbar && 'pt-[8%]'}`}>
        <Routes>
          {/* Primary Pages */}
          <Route path="/" exact  element={<PublicRoute child={<Landing />} />} />
          <Route 
            path="/login" 
            exact 
            element={userAuthenticated ? <Navigate to="/dashboard" /> : <Login />}
          />

          <Route path="/signup" exact element={<PublicRoute child={<SignUp />} />} />
          <Route path="/resetpassword" exact  element={<PublicRoute child={<ResetPass />} />} />
          
          <Route path="/forgotpassword" exact  element={<PublicRoute child={<ForgotPass />} />} />
          <Route path="/survey" exact  element={userInfo.surveyStatus === false ? <PrivateRoute child={<Survey />} /> : <Navigate to="/dashboard" />} />
          
           {/* Subscription */}
          {userInfo.userRole === "Client Admin" && <Route path="/subscription">
            <Route index element={<PrivateRoute child={<SubscriptionPlans />} />} />
            <Route path="success" element={<PrivateRoute child={<SubscriptionSuccess />} />} />
            <Route path="retry" element={<PrivateRoute child={<SubscriptionFailed />} />} />
            <Route path="Details" element={<PrivateRoute child={<SubscriptionDetails />} />} />
          </Route>}

          <Route path="/tempuser" exact  element={<PublicRoute child={<TempUser />} />} />
          <Route path="/bankSuccess" exact element={<PublicRoute child={<BankSuccessPage />} />} />

          <Route path="/dashboard" element={<PrivateRoute child={<Dashboard />} />} />

          {/* Portfolio */}
          <Route path="/portfolio">
            <Route index element={<PrivateRoute child={<PortfolioTable />} />} />
            <Route path="properties" element={<PrivateRoute child={<PropertyTable />} />} />
            <Route path="propertyDetails" element={<PrivateRoute child={<PropertyDetails />} />} />
            <Route path="unitDetails" element={<PrivateRoute child={<UnitDetails />} />} />
          </Route>

          {/* Tenants */}
          <Route path="/tenants">
            <Route index element={<PrivateRoute child={<TenantDashboard />} />} />
            <Route path="tenantDetails" element={<PrivateRoute child={<TenantDetails />} />} />
            <Route path="leaseDetails" element={<PrivateRoute child={<LeaseDetails />}/>} />
            <Route path="evictionDetails" element={<PrivateRoute child={<EvictionDetails />} /> } />
            <Route path="vacateNoticeDetails" element={<PrivateRoute child={<VacateNoticeDetails />} /> } />

          </Route>

          {/* Maintenance */}
          <Route path="/maintenance">
            <Route index element={<PrivateRoute child={<MaintenancaDashboard />} />} />
            <Route path="maintenanceDetails" element={<PrivateRoute child={<MaintenanceRequestDetails />} />} />
          </Route>

          {/* Vendor */}
          <Route path="/vendor">
            <Route index element={<PrivateRoute child={<VendorDashboard />} />} />
            <Route path="vendorDetails" element={<PrivateRoute child={<VendorDetails />} />} />
          </Route>


            {/* Reports */}
          <Route path="/report">
            <Route index element={<PrivateRoute child={<ReportBuilder />} />} />
          </Route>

          {/* Documents */}
          <Route path="/documents" element={<PrivateRoute child={<Documents />} />} />

          {/* Import */}
          <Route path="/importCSV" element={<PrivateRoute child={<ImportCsv />} />} />

          {/* Directories */}
          <Route path="/directories" >
            {/* Users */}
           <Route path="users" element={<PrivateRoute child={<UserTable />} />} />
            {/* Owners */}
            <Route path="owners">
            <Route index element={<PrivateRoute child={<OwnerDashboard />} />} />
            <Route path="ownerDetails" element={<PrivateRoute child={<OwnerDetails />} />} />
           </Route>
            {/* Tenants */}
           <Route path="tenants">
            <Route index element={<PrivateRoute child={<TenantDashboard />} />} />
            <Route path="tenantDetails" element={<PrivateRoute child={<TenantDetails />} />} />
           </Route>
            {/* Vendor */}
           <Route path="vendor">
            <Route index element={<PrivateRoute child={<VendorDashboard />} />} />
           <Route path="vendorDetails" element={<PrivateRoute child={<VendorDetails />} />} />
           </Route>
          </Route>


          {/* Notifications */}
          
          <Route path="notifications">
            <Route index element={<PrivateRoute child={<Notifications />} />} />
            <Route path="manageNotification" element={<PrivateRoute child={<ManageNotification />} />} />
           </Route>

          {/* Accounting */}
          <Route path="/accounting" >
            {/* <Route index element={<PrivateRoute child={<Accounting />} />} /> */}
            <Route path="chartOfAccounts" >
              <Route index element={<PrivateRoute child={<ChartofAccounts />} />} />
              <Route path="details" element={<PrivateRoute child={<AccountDetails />} />} />
              <Route path="transactionDetails" element={<PrivateRoute child={<TransactionDetails />} />} />
            </Route>
            <Route path="bankInfo" element={<PrivateRoute child={<BankInfo />} />} />
            <Route path="banking" element={<PrivateRoute child={<Accounting />} />} />
          </Route>

          {/* Bankinfo */}
          <Route path="/bankDashboard" element={<PrivateRoute child={<BankDashboard />} />} />

          {/* Prospects */}
          <Route path="/prospects" >
            <Route index element={<PrivateRoute child={<ProspectDashboard />} />} />
          </Route>

          {/* Routines */}
          <Route path="/myProfile" element={<PrivateRoute child={<MyProfile />} />} />

          {/* Default */}
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </main>

      {userAuthenticated && showNavbar && <Footer />}
    </div>
  );
}
