import React from "react";
import { TbFileArrowRight } from "react-icons/tb";
import { ButtonIconSquare } from "./Buttons";
import swal from "sweetalert";

const ExportCSV = ({ data, keys, fileName, className }) => {
  const downloadCSV = () => {
    if (!data.length || !keys.length) {
      swal({
        icon: "warning",
        title: "No Data to Export",
        text: "There is no data available to export. Please ensure there is data before attempting to export.",
        confirmButtonText: "OK",
      });
      return;
    }

    // Extract headers from keys
    const headers = keys.map((keyObj) => keyObj.title).join(",");

    // Map over data to extract values based on keys, applying methods if present
    const rows = data
      .map((row) =>
        keys
          .map((keyObj) => {
            let value;
            if (keyObj.applyMethod) {
              value = keyObj.applyMethod(row);
            } else {
              value = keyObj.key
                .split(".")
                .reduce((acc, part) => acc && acc[part], row);
            }
            return value !== undefined ? value : "-";
          })
          .join(",")
      )
      .join("\n");

    const csvString = `${headers}\n${rows}`;
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;

    // Generate the timestamp in YYYYMMDDThh:mm format
    const now = new Date();
    const timestamp = now
      .toISOString()
      .replace(/[-:]/g, "")
      .split(".")[0]
      .replace("T", "T")
      .slice(0, 13)
      .replace("T", "T")
      .replace(/(\d{2})(\d{2})$/, "$1:$2");
    const fullFileName = `${fileName.split(".csv")[0]}_${timestamp}.csv`;

    a.download = fullFileName;
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <ButtonIconSquare
      id="export"
      btnName="export"
      dataCy="export"
      title="Export CSV"
      onClick={downloadCSV}
      icon={<TbFileArrowRight className="w-6 h-6" />}
      className={`hover:!bg-blue-50 hover:!text-white shadow-md ${className}`}
    />
  );
};

export default ExportCSV;
