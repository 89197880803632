import React, { useEffect, useRef, useState } from "react";
import { Searchbar, TableComponent } from "../../Widgets/CommonWidgets";
import {
  GetCompleteAddress,
  GetFullAddressinTwoLines,
} from "../../Widgets/Forms/FieldDependants";
import { ButtonIconCircle } from "../../Widgets/Buttons";
import { CiLink, CiShare1 } from "react-icons/ci";
import { GenerateLinkForPropertyAPI } from "../Properties/propertiesAPI";
import { SearchTableComponent } from "../../Widgets/Table/searchTableComponent";

export const PropertiesListing = (props) => {
  const searchBarRef = useRef(null);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    clearSearchField();
    setTableData(props.data);
  }, [props.data]);

  const copyOrSharePropertyListedLink = (row, action) => {
    GenerateLinkForPropertyAPI(row.propertyId).then((res) => {
      const propertyLink = res.data;

      if (action === "share") {
        // Check if the browser supports the Share API
        if (navigator.share) {
          navigator
            .share({
              title: "Property Listing",
              url: propertyLink,
            })
            .then(() => {
              console.log("Link shared successfully!");
            })
            .catch((err) => {
              console.error("Error sharing the link:", err);
            });
        } else {
          alert("Sharing is not supported on this device/browser.");
        }
      } else if (action === "copy") {
        // Copy the link to clipboard
        navigator.clipboard
          .writeText(propertyLink)
          .then(() => {
            alert("Link copied to clipboard!");
          })
          .catch((err) => {
            console.error("Failed to copy link: ", err);
            alert("Failed to copy link.");
          });
      }
    });
  };

  const clearSearchField = () => {
    if (searchBarRef.current) {
      searchBarRef.current.clearSearch();
    }
  };

  return (
    <>
      <div className="flex items-center justify-between gap-4 mb-2 mx-4">
        <span className="font-semibold">
          {tableData.length} Properties Available For Rent
        </span>
        <SearchTableComponent
          ref={searchBarRef}
          id="propertiesListingSearch"
          placeholder="Search..."
          data={props.data}
          setTableData={setTableData}
          columns={[
            {
              key: "address1",
              title: "Property Address",
              applyMethod: (row) => GetCompleteAddress(row),
            },
            {
              key: "propertyType",
              title: "Property Type",
            },
            {
              key: "status",
              title: "Status",
            },
            {
              key: "portfolio",
              title: "Portfolio",
            },
          ]}
        />
      </div>
      <TableComponent
        tableId="propertiesListingTable"
        className={"bg-white"}
        data={tableData}
        rowOnClick={(row) => {}}
        columns={[
          {
            key: "address1",
            title: "Property Address",
            applyMethod: (row) => GetCompleteAddress(row),
            getCellStyles: (row) => {
              return { color: "blue", textDecoration: "underline" };
            },
            dataOnClick: (row) => {
              props.handleCallBack(row);
            },
          },
          {
            key: "propertyType",
            title: "Property Type",
            //width: "120px",
          },
          {
            key: "status",
            title: "Status",
            //width: "120px",
          },
          {
            key: "portfolio",
            title: "Portfolio",
            // width: "120px",
          },
        ]}
        actionHeader={"Action Items"}
        actions={{
          customActions: (row) => (
            <div className="flex space-x-2">
              <ButtonIconCircle
                title={"Copy link"}
                onClick={(e) => copyOrSharePropertyListedLink(row, "copy")}
                icon={<CiLink className="w-5 h-5" />}
                className="hover:!text-white hover:!bg-blue-53 border text-blue-52 border-blue-30 font-semibold"
              />
              <ButtonIconCircle
                title={"Share Link"}
                onClick={(e) => copyOrSharePropertyListedLink(row, "share")}
                icon={<CiShare1 className="w-5 h-5" />}
                className="hover:!text-white hover:!bg-blue-53 border text-blue-52 border-blue-30 font-semibold"
              />
            </div>
          ),
        }}
      />
    </>
  );
};
