import React, { useState, useEffect } from "react";
import { clientId, accessToken } from "../../atoms";
import { useRecoilValue } from "recoil";

import { WebApimanager } from "../../WebApiManager";
import { IconInCircle, InfoIcon } from "../../Widgets/Icons";
import { ButtonCustom } from "../../Widgets/Buttons";
import {
  InputObjectSelectField,
  InputFileField,
} from "../../Widgets/Forms/InputFields";
import { TableComponent, GetDateInMMDDYYYY } from "../../Widgets/CommonWidgets";
import swal from "sweetalert";

import CreatePortfolio from "../Portfolios/createPortfolio";
import CreateProperty from "../Properties/createProperty";
import CreateUnit from "../CreateUnit";
import CreateTenant from "../CreateTenant";
import AddUtilityCharges from "../Properties/propertyServices/addUtilityCharges";
import { Loader } from "../../Widgets/notificationFeedbacks";

import { TbFileDownload, TbDownload, TbUpload } from "react-icons/tb";
import { PhoneNumFormat } from "../../Widgets/Forms/FieldDependants";

const ImportCsv = () => {
  let webApi = new WebApimanager();
  const clientIdVal = useRecoilValue(clientId);
  const accessTokenVal = useRecoilValue(accessToken);

  const [allPortfolios, setAllPortfolios] = useState([]);
  const [propertiesOptions, setPropertiesOptions] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedPortfolioId, setSelectedPortfolioId] = useState("");
  const [selectedPortfolio, setSelectedPortfolio] = useState({});
  const [selectedPropertyId, setSelectedPropertyId] = useState("");
  const [importFile, setImportFile] = useState("");

  const [importedTableData, setImportedTableData] = useState([]);

  const [currentImportedValues, setCurrentImportedValues] = useState({
    category: "",
    portfolioId: "",
    propertyId: "",
  });

  const [openPortfolioForm, setOpenPortfolioForm] = useState(false);
  const [editFolioData, setEditFolioData] = useState("");
  const [openPropertyForm, setOpenPropertyForm] = useState(false);
  const [editPropertyData, setEditPropertyData] = useState("");
  const [openUnitForm, setOpenUnitForm] = useState(false);
  const [editUnitData, setEditUnitData] = useState("");
  const [openTenantForm, setOpenTenantForm] = useState(false);
  const [editTenantData, setEditTenantData] = useState("");
  const [openUtilityChargesForm, setOpenUtilityChargesForm] = useState(false);
  const [editUtilityChargesData, setEditUtilityChargesData] = useState("");

  const CategoryOptions = [
    { name: "Portfolio" },
    { name: "Property Single Family" },
    { name: "Property Multi Family" },
    { name: "Unit" },
    { name: "Single Family Tenant" },
    { name: "Multi-Family Tenant" },
    { name: "Utility Charges" },
    { name: "Rental Transactions" },
  ];

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  useEffect(() => {
    setApiProcessing({
      loader: true,
      message: "Gathering Portfolios...",
    });
    getPortfolios();
  }, []);

  const getPortfolios = () => {
    setApiProcessing({
      loader: true,
      message: "Gathering Portfolios...",
    });
    webApi
      .get("/portfolios/client/" + clientIdVal)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setAllPortfolios(
            res.data.length > 1
              ? res.data.sort((a, b) =>
                  a.portfolioName > b.portfolioName ? 1 : -1
                )
              : res.data
          );
        }
        setApiProcessing({
          loader: false,
          message: "Loading...",
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: "Loading...",
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to get Portfolios, Please try again later.`,
            "error"
          );
        }
      });
  };

  const getProperties = (portfolioId) => {
    setApiProcessing({
      loader: true,
      message: "Gathering Properties...",
    });
    setSelectedPropertyId("");
    setPropertiesOptions([]);
    webApi
      .get("/properties/portfolio/" + portfolioId)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          let sortedData =
            res.data.length > 1
              ? res.data.sort((a, b) =>
                  a.propertyName > b.propertyName ? 1 : -1
                )
              : res.data;
          if (
            selectedCategory === "Unit" ||
            selectedCategory === "Multi-Family Tenant"
          ) {
            const MultiProps = sortedData.filter(
              (item) => item.propertyType === "Multi-Family"
            );
            sortedData = MultiProps;
          }
          setPropertiesOptions(sortedData);
        }
        setApiProcessing({
          loader: false,
          message: "Loading...",
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: "Loading...",
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to get Properties, Please try again later.`,
            "error"
          );
        }
      });
  };

  const handleDownloadSamples = (e) => {
    e.preventDefault();

    if (!selectedCategory) {
      swal(
        "Warning",
        "Please, Select a category to download respective sample file.",
        "warning"
      );
    } else {
      if (selectedCategory === "Portfolio") {
        window.location.href =
          "https://ai-models-test-yvoaa.s3.amazonaws.com/importCSV/PortfolioCSV.csv";
      } else if (selectedCategory === "Property Single Family") {
        window.location.href =
          "https://ai-models-test-yvoaa.s3.amazonaws.com/importCSV/PropertySingleFamilyCSV.csv";
      } else if (selectedCategory === "Property Multi Family") {
        window.location.href =
          "https://ai-models-test-yvoaa.s3.amazonaws.com/importCSV/PropertyMultiFamilyCSV.csv";
      } else if (selectedCategory === "Unit") {
        window.location.href =
          "https://ai-models-test-yvoaa.s3.amazonaws.com/importCSV/UnitCSV.csv";
      } else if (
        selectedCategory === "Single Family Tenant" ||
        selectedCategory === "Multi-Family Tenant"
      ) {
        window.location.href =
          "https://ai-models-test-yvoaa.s3.amazonaws.com/importCSV/ImportTenantTemplate.csv";
      } else if (selectedCategory === "Utility Charges") {
        window.location.href =
          "https://ai-models-test-yvoaa.s3.amazonaws.com/importCSV/UtilityBills.csv";
      } else if (selectedCategory === "Rental Transactions") {
        window.location.href =
          "https://ai-models-test-yvoaa.s3.amazonaws.com/importCSV/RentalLedgerImport.csv";
      }
    }
  };

  const validations = () => {
    const errors = [];
    if (!selectedCategory) {
      errors.push("Category is required!");
    }
    if (
      selectedCategory &&
      selectedCategory !== "Portfolio" &&
      !selectedPortfolioId
    ) {
      errors.push("Portfolio is Required!");
    }
    if (
      selectedCategory &&
      selectedCategory !== "Portfolio" &&
      selectedCategory !== "Property Single Family" &&
      selectedCategory !== "Property Multi Family" &&
      selectedCategory !== "Single Family Tenant" &&
      !selectedPropertyId
    ) {
      errors.push("Property is required!");
    }
    if (!importFile) {
      errors.push("Import File is required!");
    }

    return errors;
  };

  const handleUpload = (e) => {
    e.preventDefault();
    const temp = validations();
    if (temp.length) {
      swal({
        title: "Error",
        text: temp.join("\n"),
        icon: "error",
        button: "ok",
      });
    } else {
      setImportedTableData([]);
      setEditFolioData("");
      setEditPropertyData("");
      setEditUnitData("");
      setEditTenantData("");
      setEditUtilityChargesData("");
      if (selectedCategory === "Portfolio") {
        setCurrentImportedValues({
          ...currentImportedValues,
          category: "Portfolio",
        });
        handlePortfolioImport();
      } else if (selectedCategory === "Property Single Family") {
        setCurrentImportedValues({
          ...currentImportedValues,
          category: "PropertySingleFamily",
          portfolioId: selectedPortfolioId,
        });
        handleSingleFamilyImport();
      } else if (selectedCategory === "Property Multi Family") {
        setCurrentImportedValues({
          ...currentImportedValues,
          category: "PropertyMultiFamily",
          portfolioId: selectedPortfolioId,
        });
        handleMultiFamilyImport();
      } else if (selectedCategory === "Unit") {
        setCurrentImportedValues({
          ...currentImportedValues,
          category: "Unit",
          portfolioId: selectedPortfolioId,
          propertyId: selectedPropertyId,
        });
        handleUnitImport();
      } else if (selectedCategory === "Single Family Tenant") {
        setCurrentImportedValues({
          ...currentImportedValues,
          category: "Tenant",
          portfolioId: selectedPortfolioId,
          propertyId: selectedPropertyId,
        });
        handleTenantImport("Single Family", selectedPortfolioId);
      } else if (selectedCategory === "Multi-Family Tenant") {
        setCurrentImportedValues({
          ...currentImportedValues,
          category: "Tenant",
          portfolioId: selectedPortfolioId,
          propertyId: selectedPropertyId,
        });
        handleTenantImport("Multi-Family", selectedPropertyId);
      } else if (selectedCategory === "Utility Charges") {
        setCurrentImportedValues({
          ...currentImportedValues,
          category: "UtilityCharges",
          portfolioId: selectedPortfolioId,
          propertyId: selectedPropertyId,
        });
        handleUtilityChargesImport();
      } else if (selectedCategory === "Rental Transactions") {
        setCurrentImportedValues({
          ...currentImportedValues,
          category: "RentalTransactions",
          portfolioId: selectedPortfolioId,
          propertyId: selectedPropertyId,
        });
        handleRentalTransactionsImport();
      }
    }
  };

  const handlePortfolioImport = async () => {
    setApiProcessing({
      loader: true,
      message: "Importing Portfolios...",
    });
    const formData = new FormData();
    formData.append("myFile", importFile);

    webApi
      .post(`/portfolio/import/${clientIdVal}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + accessTokenVal,
        },
      })
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          let uploadedData = [];
          res.data.Success.map((item, index) => {
            uploadedData.push({
              status: "Success",
              reason: "",
              ...item,
            });
          });

          res.data.Failed.map((item, index) => {
            uploadedData.push({
              failed: true,
              status: "Failed",
              reason: item.Error,
              ...item.Portfolio,
            });
          });
          setImportedTableData(uploadedData);
          if (res.data.Success.length) {
            getPortfolios();
          }
        }
        setApiProcessing({
          loader: false,
          message: "loading...",
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: "Loading...",
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to get Extracted Portfolios Data, Please try again later.`,
            "error"
          );
        }
      });
  };

  const handleSingleFamilyImport = async () => {
    setApiProcessing({
      loader: true,
      message: "Importing Single Family Properties...",
    });
    const formData = new FormData();
    formData.append("myFile", importFile);

    webApi
      .post(`/property/import/singleFamily/${selectedPortfolioId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + accessTokenVal,
        },
      })
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          let uploadedData = [];
          res.data.Success.map((item) => {
            uploadedData.push({
              importStatus: "Success",
              reason: "",
              ...item.Property,
              unit: { ...item.Unit },
            });
          });
          res.data.Failed.map((item) => {
            uploadedData.push({
              failed: true,
              importStatus: "Failed",
              reason: item.Error,
              unitFailed: item.Property.ID === 0 ? false : true,
              ...item.Property,
              unit: { ...item.Unit },
            });
          });
          setImportedTableData(uploadedData);
        }
        setApiProcessing({
          loader: false,
          message: "loading...",
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: "loading...",
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to get Extracted Single Family Properties Data, Please try again later.`,
            "error"
          );
        }
      });
  };

  const handleMultiFamilyImport = async () => {
    setApiProcessing({
      loader: true,
      message: "Importing Multi Family Properties...",
    });
    const formData = new FormData();
    formData.append("myFile", importFile);

    webApi
      .post(`/property/import/${selectedPortfolioId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + accessTokenVal,
        },
      })
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          let uploadedData = [];
          res.data.Success.map((item, index) => {
            uploadedData.push({
              importStatus: "Success",
              reason: "",
              ...item,
            });
          });
          res.data.Failed.map((item, index) => {
            uploadedData.push({
              failed: true,
              importStatus: "Failed",
              reason: item.Error,
              ...item.Property,
            });
          });
          setImportedTableData(uploadedData);
        }
        setApiProcessing({
          loader: false,
          message: "loading...",
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: "loading...",
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to get Extracted Multi Family Properties Data, Please try again later.`,
            "error"
          );
        }
      });
  };

  const handleUnitImport = async () => {
    setApiProcessing({
      loader: true,
      message: "Importing Units...",
    });
    const formData = new FormData();
    formData.append("myFile", importFile);

    webApi
      .post(`/unit/import/${selectedPropertyId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + accessTokenVal,
        },
      })
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          let uploadedData = [];
          res.data.Success.map((item, index) => {
            uploadedData.push({
              status: "Success",
              reason: "",
              ...item,
            });
          });

          res.data.Failed.map((item, index) => {
            uploadedData.push({
              failed: true,
              status: "Failed",
              reason: item.Error,
              ...item.Unit,
            });
          });
          setImportedTableData(uploadedData);
        }
        setApiProcessing({
          loader: false,
          message: "loading...",
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: "loading...",
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to get Extracted Units Data, Please try again later.`,
            "error"
          );
        }
      });
  };

  const handleTenantImport = async (propType, Id) => {
    setApiProcessing({
      loader: true,
      message: "Importing Tenants...",
    });
    const formData = new FormData();
    formData.append("myFile", importFile);

    webApi
      .post(`/tenant/import/${propType}&${Id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + accessTokenVal,
        },
      })
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          let uploadedData = [];
          res.data.Success.map((item, index) => {
            uploadedData.push({
              status: "Success",
              reason: "",
              firstTenant: item.Tenant[0],
              ...item,
            });
          });

          res.data.Failed.map((item, index) => {
            uploadedData.push({
              failed: true,
              status: "Failed",
              reason: item.Error,
              firstTenant: item.Tenant[0],
              ...item,
            });
          });
          setImportedTableData(uploadedData);
        }
        setApiProcessing({
          loader: false,
          message: "loading...",
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: "loading...",
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to get Extracted Tenants Data, Please try again later.`,
            "error"
          );
        }
      });
  };

  const handleUtilityChargesImport = async () => {
    setApiProcessing({
      loader: true,
      message: "Extarcting Utility Charges...",
    });
    const formData = new FormData();
    formData.append("myFile", importFile);

    webApi
      .post(`/utilities/import/property/${selectedPropertyId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + accessTokenVal,
        },
      })
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          let uploadedData = [];
          res.data.Success.map((item, index) => {
            uploadedData.push({
              status: "Success",
              reason: "",
              ...item,
            });
          });

          res.data.Failed.map((item, index) => {
            uploadedData.push({
              failed: true,
              status: "Failed",
              reason: item.Error,
              ...item.UnitUtilityBill,
            });
          });
          setImportedTableData(uploadedData);
        }
        setApiProcessing({
          loader: false,
          message: "loading...",
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: "loading...",
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to get Extracted Utility Charges Data, Please try again later.`,
            "error"
          );
        }
      });
  };

  const handleRentalTransactionsImport = async () => {
    setApiProcessing({
      loader: true,
      message: "Extarcting Rental Transactions...",
    });
    const formData = new FormData();
    formData.append("myFile", importFile);

    webApi
      .post(`/ledger/import/property/${selectedPropertyId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + accessTokenVal,
        },
      })
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          let uploadedData = [];
          res.data.Success.map((item, index) => {
            uploadedData.push({
              status: "Success",
              reason: "",
              // formatedDate: GetDateInMMDDYYYY(item.dateOccured),
              ...item,
            });
          });

          res.data.Failed.map((item, index) => {
            uploadedData.push({
              failed: true,
              status: "Failed",
              reason: item.Error,
              //formatedDate: GetDateInMMDDYYYY(item.Ledger.dateOccured),
              ...item.Ledger,
            });
          });
          setImportedTableData(uploadedData);
        }
        setApiProcessing({
          loader: false,
          message: "loading...",
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: "loading...",
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to Extract Rental Transactions, Please try again later.`,
            "error"
          );
        }
      });
  };

  //Call Backs
  const handleEditPortfolioImportCallback = (row) => {
    const temp = importedTableData.filter((item) => item !== editFolioData);
    temp.push({ ...row, status: "Success" });
    setImportedTableData(temp);
    getPortfolios();
  };

  const handleEditPropertyImportCallback = (prop) => {
    const temp = importedTableData.filter((item) => item !== editPropertyData);
    temp.push({ ...prop, status: "Success" });
    setImportedTableData(temp);
  };

  const handleEditUnitImportCallback = (row, editData) => {
    const temp = importedTableData.filter((item) => item !== editUnitData);
    temp.push({ ...row, status: "Success" });
    setImportedTableData(temp);
  };

  const handleEditTenantImportCallback = (row, editData) => {
    const temp = importedTableData.filter((item) => item !== editTenantData);
    if (row.tenants)
      temp.push({ ...row, status: "Success", firstTenant: row.tenants[0] });
    else temp.push({ ...row, status: "Success", firstTenant: row.Tenant[0] });
    setImportedTableData(temp);
  };

  const handleEditUtiltyChargesImportCallback = (row, editData) => {
    const temp = importedTableData.filter(
      (item) => item !== editUtilityChargesData
    );
    temp.push({ ...row, status: "Success" });
    setImportedTableData(temp);
  };

  const commonPropertyTableProps = {
    data: importedTableData,
    columns: [
      { key: "propertyName", title: "Property Name" },
      {
        key: "propertyType",
        title: "Property Type",
      },
      {
        key: "status",
        title: "Property Status",
        // applyMethod: (row) => {
        //   if (row.isActive) {
        //     return "Active";
        //   } else return "In-Active";
        // },
      },
      {
        key: "purchaseDate",
        title: "Purchase Date",
        applyMethod: (row) => GetDateInMMDDYYYY(row.purchaseDate),
      },
      {
        key: "onboardDate",
        title: "Onboard Date",
        applyMethod: (row) => GetDateInMMDDYYYY(row.onboardDate),
      },

      {
        key: "importStatus",
        title: "Import Status",
        dataClassName: "font-medium",
        // applyMethod: (row) => {
        //   return row.failed ? "Failed" : "Success";
        // },
        getCellStyles: (row) => {
          if (row.failed) {
            return { color: "red" };
          } else {
            return { color: "green" };
          }
        },
      },
      {
        key: "reason",
        title: "Reason",
      },
    ],
    actionHeader: "Edit",
    actions: {
      editTitle: "Edit Property",
      editOnClick: (row) => {
        if (row.failed) {
          setEditPropertyData(row);
          setOpenPropertyForm(true);
        }
      },
      hideEdit: (row) => {
        if (row.failed) {
          return false;
        } else {
          return true;
        }
      },
    },
    rowOnClick: (row) => {},
    //initialSort: { key: "status", direction: "descending" },
  };

  const tableProps = {
    Portfolio: {
      tableId: "portfolio",
      data: importedTableData,
      columns: [
        { key: "portfolioName", title: "Portfolio Name" },
        {
          key: "ownership",
          title: "Ownership",
        },
        {
          key: "businessAddress.address1",
          title: "Address",
          applyMethod: (row) => {
            const { address1, address2, city, state, country, zipCode } =
              row.businessAddress;
            return `${address1}${address2 ? `, ${address2}` : ""}${
              city ? `, ${city}` : ""
            }${state ? `, ${state}` : ""}${country ? `, ${country}` : ""}${
              zipCode ? `, ${zipCode}` : ""
            }`;
          },
        },
        {
          key: "status",
          title: "Import Status",
          dataClassName: "font-medium",
          // applyMethod: (row) => {
          //   return row.failed ? "Failed" : "Success";
          // },
          getCellStyles: (row) => {
            if (row.failed) {
              return { color: "red" };
            } else {
              return { color: "green" };
            }
          },
        },
        {
          key: "reason",
          title: "Reason",
        },
      ],
      actionHeader: "Edit",
      actions: {
        editTitle: "Edit Portfolio",
        editOnClick: (row) => {
          if (row.failed) {
            setEditFolioData(row);
            setOpenPortfolioForm(true);
          }
        },
        hideEdit: (row) => {
          if (row.failed) {
            return false;
          } else {
            return true;
          }
        },
      },
      rowOnClick: (row) => {},
      //initialSort: { key: "status", direction: "descending" },
    },

    PropertyMultiFamily: {
      tableId: "PropertyMultiFamily",
      ...commonPropertyTableProps,
    },

    PropertySingleFamily: {
      tableId: "PropertySingleFamily",
      ...commonPropertyTableProps,
    },

    Unit: {
      tableId: "unit",
      data: importedTableData,
      columns: [
        { key: "unitNumber", title: "Unit Number" },
        { key: "unitStatus", title: "Unit Status" },
        { key: "sqFt", title: "Area (Sq.ft)" },
        // { key: "numberOfRooms", title: "No. Of Rooms" },
        // { key: "numberOfBeds", title: "No. of Beds" },
        // { key: "numberOfBaths", title: "No. of Bathrooms" },

        {
          key: "status",
          title: "Import Status",
          dataClassName: "font-medium",
          // applyMethod: (row) => {
          //   return row.failed ? "Failed" : "Success";
          // },
          getCellStyles: (row) => {
            if (row.failed) {
              return { color: "red" };
            } else {
              return { color: "green" };
            }
          },
        },
        {
          key: "reason",
          title: "Reason",
        },
      ],
      actionHeader: "Edit",
      actions: {
        editTitle: "Edit Unit",
        editOnClick: (row) => {
          if (row.failed) {
            const propData = propertiesOptions.filter(
              (prop) => prop.ID === currentImportedValues.propertyId
            );

            setEditPropertyData(propData[0]);
            setEditUnitData(row);
            setOpenUnitForm(true);
          }
        },
        hideEdit: (row) => {
          if (row.failed) {
            return false;
          } else {
            return true;
          }
        },
      },
      rowOnClick: (row) => {},
      //initialSort: { key: "status", direction: "descending" },
    },

    Tenant: {
      tableId: "Tenant",
      data: importedTableData,
      columns: [
        {
          key: "firstTenant.firstName",
          title: "Name",
          applyMethod: (row) => {
            return row.firstTenant.firstName + " " + row.firstTenant.lastName;
          },
        },
        {
          key: "firstTenant.email",
          title: "Email",
        },
        {
          key: "firstTenant.phoneNumber",
          title: "Contact No.",
          applyMethod: (row) =>
            PhoneNumFormat(row.firstTenant.phoneNumber, true),
        },
        { key: "firstTenant.tenantStatus", title: "Tenant Status" },
        {
          key: "status",
          title: "Import Status",
          dataClassName: "font-medium",
          getCellStyles: (row) => {
            if (row.failed) {
              return { color: "red" };
            } else {
              return { color: "green" };
            }
          },
        },
        {
          key: "reason",
          title: "Reason",
        },
      ],
      actionHeader: "Edit",
      actions: {
        editTitle: "Edit Tenant",
        editOnClick: (row) => {
          if (row.failed) {
            const propData = propertiesOptions.filter(
              (prop) => prop.ID === currentImportedValues.propertyId
            );
            setEditPropertyData(propData[0]);
            setEditTenantData(row);
            setOpenTenantForm(true);
          }
        },
        hideEdit: (row) => {
          if (row.failed) {
            return false;
          } else {
            return true;
          }
        },
      },
      rowOnClick: (row) => {},
      //initialSort: { key: "status", direction: "descending" },
    },

    UtilityCharges: {
      tableId: "UtilityCharges",
      data: importedTableData,
      columns: [
        { key: "unitNumber", title: "Unit Number", width: "120px" },
        { key: "ID", title: "Bill ID", width: "80px" },
        { key: "billType", title: "Bill Type", width: "120px" },
        {
          key: "serviceFrom",
          title: "Service from",
          applyMethod: (row) => GetDateInMMDDYYYY(row.serviceFrom),
          width: "120px",
        },
        {
          key: "serviceTo",
          title: "Service To",
          applyMethod: (row) => GetDateInMMDDYYYY(row.serviceTo),
          width: "120px",
        },
        // { key: "totalConsumption", title: "Total Consumption", width: "160px" },
        {
          key: "totalCharge",
          title: "Total Charges",
          applyMethod: (row) => "$" + row.totalCharge.toLocaleString(),
          width: "130px",
        },

        {
          key: "status",
          title: "Import Status",
          width: "120px",
          dataClassName: "font-medium",
          // applyMethod: (row) => {
          //   return row.failed ? "Failed" : "Success";
          // },
          getCellStyles: (row) => {
            if (row.failed) {
              return { color: "red" };
            } else {
              return { color: "green" };
            }
          },
        },
        {
          key: "reason",
          title: "Reason",
          width: "260px",
        },
      ],
      actionHeader: "Edit",
      actions: {
        editTitle: "Edit Utility Charges",
        editOnClick: (row) => {
          if (row.failed) {
            setEditUtilityChargesData(row);
            setOpenUtilityChargesForm(true);
          }
        },
        hideEdit: (row) => {
          if (row.failed) {
            return false;
          } else {
            return true;
          }
        },
      },
      rowOnClick: (row) => {},
      //initialSort: { key: "status", direction: "descending" },
    },

    RentalTransactions: {
      tableId: "RentalTransactions",
      data: importedTableData,
      columns: [
        { key: "unitNumber", title: "Unit Number", width: "160px" },
        { key: "residentCode", title: "Resident Code", width: "210px" },
        {
          key: "dateOccured",
          title: "Date",
          width: "120px",
          applyMethod: (row) => GetDateInMMDDYYYY(row.dateOccured),
        },
        { key: "description", title: "Description", width: "250px" },
        {
          key: "paymentInfo",
          title: "Payment Type",
          width: "150px",
          // getCellStyles: (row) => {
          //   if (row.paymentInfo === "Charge") {
          //     return { color: "red" };
          //   }
          // },
        },
        {
          key: "paymentAmount",
          title: "Amount",
          width: "100px",
          applyMethod: (row) => {
            if (row.paymentInfo === "Payment" && row.paymentAmount) {
              return "-$" + row.paymentAmount.toLocaleString();
            } else {
              return "$" + row.paymentAmount.toLocaleString();
            }
          },
          // getCellStyles: (row) => {
          //   if (row.paymentInfo === "Charge") {
          //     return { color: "red" };
          //   }
          // },
        },
        // {
        //   key: "balance",
        //   title: "Balance",
        //   width: "100px",
        //   applyMethod: (row) => {
        //     if (row.balance.toString().startsWith("-")) {
        //       return "-$" + row.balance.toLocaleString().substring(1);
        //     } else {
        //       return "$" + row.balance.toLocaleString();
        //     }
        //   },
        // },
        {
          key: "status",
          title: "Import Status",
          width: "120px",
          dataClassName: "font-medium",
          // applyMethod: (row) => {
          //   return row.failed ? "Failed" : "Success";
          // },
          getCellStyles: (row) => {
            if (row.failed) {
              return { color: "red" };
            } else {
              return { color: "green" };
            }
          },
        },
        {
          key: "reason",
          title: "Reason",
          width: "260px",
        },
      ],
      // actionHeader: "Edit",
      // actions: {
      //   editTitle: "Edit Rental Transactions",
      //   editOnClick: (row) => {
      //     if (row.failed) {
      //       setEditUtilityChargesData(row);
      //       setOpenUtilityChargesForm(true);
      //     }
      //   },
      //   hideEdit: (row) => {
      //     if (row.failed) {
      //       return false;
      //     } else {
      //       return true;
      //     }
      //   },
      // },
      rowOnClick: (row) => {},
      //initialSort: { key: "dateOccured", direction: "descending" },
    },
  };

  const handleChangeCategory = (e) => {
    const category = e.target.value;

    if (importedTableData.length) {
      swal({
        title: "Changing Selected Category?",
        text: "Any data in table and select fields may Reset!",
        icon: "warning",
        buttons: ["Cancel", "Change Category"],
        dangerMode: false,
      }).then((confirmed) => {
        console.log("confirmed ", confirmed);
        if (confirmed) {
          handleClearAll(category);
          setCurrentImportedValues({
            category: "",
            portfolioId: "",
            propertyId: "",
          });
          setSelectedPortfolio({});
        } else return;
      });
    } else handleClearAll(category);
  };

  const handleChangeSelectedValues = (portId, propId, type) => {
    if (importedTableData.length) {
      swal({
        title: `Changing Selected ${type}?`,
        text: "Any data in table and select fields may Reset!",
        icon: "warning",
        buttons: ["Cancel", `Change ${type}`],
        dangerMode: false,
      }).then((confirmed) => {
        if (confirmed) {
          if (type === "Portfolio") {
            getProperties(portId);
            setSelectedPortfolioId(portId);
          } else if (type === "Property") {
            setSelectedPropertyId(propId);
          }
          setCurrentImportedValues({
            ...currentImportedValues,
            portfolioId: portId,
            propertyId: propId,
          });
          setImportedTableData([]);
        } else return;
      });
    } else {
      if (type === "Portfolio") {
        getProperties(portId);
        setSelectedPortfolioId(portId);
      } else if (type === "Property") {
        setSelectedPropertyId(propId);
      }
    }
  };

  const handleSelectedPortfolio = (portID) => {
    const selectedPortfolio = allPortfolios.filter(
      (item) => item.ID === portID
    );
    if (selectedPortfolio) {
      setSelectedPortfolio(selectedPortfolio[0]);
    }
  };

  const handleClearAll = (category) => {
    //setImportFile("");
    setImportedTableData([]);
    setSelectedPortfolioId("");
    setSelectedPropertyId("");
    setPropertiesOptions([]);
    setSelectedCategory(category);
  };

  return (
    <>
      <Loader open={apiProcessing.loader} message={apiProcessing.message} />

      {openPortfolioForm && (
        <CreatePortfolio
          trigger={openPortfolioForm}
          setTrigger={setOpenPortfolioForm}
          operation={"Import"}
          portfolio={editFolioData}
          getAllPortfolios={(row) => handleEditPortfolioImportCallback(row)}
        />
      )}

      {openPropertyForm && (
        <CreateProperty
          trigger={openPropertyForm}
          setTrigger={setOpenPropertyForm}
          portfolioId={currentImportedValues.portfolioId}
          portfolio={selectedPortfolio}
          operation={"Import"}
          property={editPropertyData}
          getAllProperties={(prop) => handleEditPropertyImportCallback(prop)}
        />
      )}

      {openUnitForm && (
        <CreateUnit
          openUnitFormPage={openUnitForm}
          setUnitFormPage={setOpenUnitForm}
          Property={editPropertyData}
          //allFloorPlans={floorPlans}
          unitData={editUnitData}
          operation={"Import"}
          getUnits={(row) => handleEditUnitImportCallback(row)}
        />
      )}

      {openTenantForm && (
        <CreateTenant
          openTenantFormPage={openTenantForm}
          setTenantFormPage={setOpenTenantForm}
          allUnits={[]}
          isSingleFamily={
            editPropertyData.propertyType === "Single Family" ? true : false
          }
          getTenants={(row) => handleEditTenantImportCallback(row)}
          Property={editPropertyData}
          operation={"Import"}
          tenantData={editTenantData}
        />
      )}

      {openUtilityChargesForm && (
        <AddUtilityCharges
          trigger={openUtilityChargesForm}
          setTrigger={setOpenUtilityChargesForm}
          propertyId={currentImportedValues.propertyId}
          allUnits={[]}
          getBills={(row) => handleEditUtiltyChargesImportCallback(row)}
          operation={"Import"}
          editData={editUtilityChargesData}
        />
      )}

      <div className="w-5/6 m-auto">
        <span className="flex justify-center items-center mb-12 bg-blue-52 text-white font-semibold text-center rounded h-10">
          IMPORT
          <InfoIcon message="Use the Import Data Module to import all of your Portfolios, Properties, & Units with ease!" />
        </span>

        <section
          name="Header and SearchBar"
          className="flex justify-between items-center mb-8 mx-6"
        >
          <span className="flex items-center gap-4">
            <IconInCircle
              icon={<TbFileDownload className="w-9 h-9 text-[#2C88AE]" />}
              radius="4rem"
              className="shadow-xl"
            />
            <span className="font-semibold">
              Import All Your Portfolios With Ease!
              <p className="text-gray-400/100 font-normal">
                Use the Import Data Module to import all your Portfolios,
                Properties & Units with Ease!
              </p>
            </span>
          </span>
          <ButtonCustom
            id="downloadSample"
            btnName="downloadSample"
            dataCy="downloadSample"
            title="Download Sample File"
            name={
              <span className="flex items-center gap-1">
                <TbDownload className="w-5 h-5" /> Sample File
              </span>
            }
            onClick={handleDownloadSamples}
            className="px-2 h-10 bg-[#FF6522] text-white rounded-md whitespace-nowrap"
          />
        </section>

        <section name="Category and Select Fields" className="mb-8 mx-6">
          <div className="flex items-end gap-4">
            <InputObjectSelectField
              label="Select Category"
              name="category"
              id="category"
              dataCy="category"
              placeholder="Select a Category"
              value={selectedCategory}
              onChange={handleChangeCategory}
              options={CategoryOptions}
              optionValue="name"
              optionName="name"
              style=""
            />

            {selectedCategory !== "" && selectedCategory !== "Portfolio" && (
              <InputObjectSelectField
                label="Portfolio Name"
                name="portfolio"
                id="portfolio"
                dataCy="portfolio"
                //notImp
                placeholder="Select a Portfolio"
                value={selectedPortfolioId}
                onChange={(e) => {
                  handleChangeSelectedValues(
                    parseInt(e.target.value),
                    "",
                    "Portfolio"
                  );
                  handleSelectedPortfolio(parseInt(e.target.value));
                }}
                options={allPortfolios}
                optionValue="ID"
                optionName="portfolioName"
                style=""
              />
            )}

            {selectedCategory !== "" &&
              selectedCategory !== "Portfolio" &&
              selectedCategory !== "Property Single Family" &&
              selectedCategory !== "Property Multi Family" &&
              selectedCategory !== "Single Family Tenant" && (
                <InputObjectSelectField
                  label="Property Name"
                  name="property"
                  id="property"
                  data-cy="property"
                  //notImp
                  placeholder="Select a property"
                  value={selectedPropertyId}
                  onChange={(e) => {
                    handleChangeSelectedValues(
                      selectedPortfolioId,
                      parseInt(e.target.value),
                      "Property"
                    );
                  }}
                  options={propertiesOptions}
                  optionValue="ID"
                  optionName="propertyName"
                  style=""
                />
              )}

            <button
              id="clearAll"
              name="clearAll"
              data-cy="clearAll"
              onClick={(e) => handleClearAll("")}
              className="underline text-blue-52 cursor-pointer items-end active:scale-95 transform transition duration-100"
            >
              Clear All
            </button>
          </div>
          <div className="flex items-end gap-4 mt-4">
            <InputFileField
              label="Select File"
              name="uploadFile"
              id="uploadFile"
              dataCy="uploadFile"
              accept=".csv"
              placeholder="Select a CSV File"
              value={importFile}
              setValue={setImportFile}
              style=" w-80"
              //hintText="*accepts CSV"
            />

            <ButtonCustom
              id="uploadFinalFile"
              btnName="uploadFinalFile"
              dataCy="uploadFinalFile"
              title="Upload File"
              name={
                <span className="flex items-center gap-1">
                  <TbUpload className="w-5 h-5" /> Upload
                </span>
              }
              onClick={handleUpload}
              className="px-2 h-10 text-blue-52 rounded-md border-blue-52 hover:bg-blue-52 hover:text-white"
            />
          </div>
        </section>

        {currentImportedValues.category && (
          <section className={`mb-4`}>
            <TableComponent {...tableProps[currentImportedValues.category]} />
          </section>
        )}
      </div>
    </>
  );
};

export default ImportCsv;
