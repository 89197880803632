import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { clientId } from "../../../atoms";
import { WebApimanager } from "../../../WebApiManager";

//Reusable Components
import ProfitandLossSummary from "./p&LSummary";
import {
  clickEffect,
  DateFormatForAPI,
  GetColorByDenomination,
  GetDenominationWithDollar,
  Searchbar,
  TableComponent,
} from "../../../Widgets/CommonWidgets";
import {
  InputDateField,
  InputObjectSelectField,
  InputSelectField,
} from "../../../Widgets/Forms/InputFields";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import { GetCompleteAddress } from "../../../Widgets/Forms/FieldDependants";
import { ButtonBlueOutline } from "../../../Widgets/Buttons";
import { showLoader, hideLoader } from "../../../Utility/loaderProvider";

//External Imports
import swal from "sweetalert";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import dayjs from "dayjs";
import { SearchTableComponent } from "../../../Widgets/Table/searchTableComponent";

const ProfitandLossReport = () => {
  let webApi = new WebApimanager();
  let navigate = useNavigate();
  const clientIdVal = useRecoilValue(clientId);
  const [view, setView] = useState("portfolio");

  //Portfoio Table
  const [allPortfolios, setAllPortfolios] = useState([]);
  const [filteredPortfolioId, setFilteredPortfolioId] = useState(0);
  const [portfolioReport, setPortfolioReport] = useState([]);
  const [portfolioTableData, setPortfolioTableData] = useState([]);
  const [drillPortfolio, setDrillPortfolio] = useState({});

  //Property Table
  const [propertyReport, setPropertyReport] = useState([]);
  const [propertyTableData, setPropertyTableData] = useState([]);
  const [drillProperty, setDrillProperty] = useState({});
  const [propertyType, setPropertyType] = useState("Single Family");
  const [propTotals, setPropTotals] = useState({
    totalIncome: 0,
    totalExpense: 0,
    netOperatingIncome: 0,
  });
  const searchBarRef = useRef(null);

  //Unit Table
  const [unitReport, setUnitReport] = useState([]);
  const [unitTableData, setUnitTableData] = useState([]);
  const [drillUnit, setDrillUnit] = useState({});
  const [unitTotals, setUnitTotals] = useState({
    totalIncome: 0,
    totalExpense: 0,
    netOperatingIncome: 0,
  });

  //Summary Page
  const [showSummaryPage, setShowSummaryPage] = useState(false);
  const [parentSummary, setParentSummary] = useState({});
  const [childrenSummary, setChildrenSummary] = useState({});
  const [summaryType, setSummaryType] = useState("");

  useEffect(() => {
    getPortfolioProfitandLossReport(clientIdVal, 0);
    getAllPortfolios();
  }, []);

  //Portfolio View Api's
  const getAllPortfolios = () => {
    webApi
      .get("/portfolios/client/" + clientIdVal, {
        loader: true,
      })
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setAllPortfolios(
            res.data.length > 1
              ? res.data.sort((a, b) =>
                  a.portfolioName > b.portfolioName ? 1 : -1
                )
              : res.data
          );
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops, Failed to load Portfolios, Please try again later.`,
            "error"
          );
        }
      });
  };

  const getPortfolioProfitandLossReport = (clientId, portfolioId) => {
    webApi
      .get(`/PL/client/${clientId}/portfolio/${portfolioId}`, {
        loader: true,
      })
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setPortfolioReport(res.data);
          setPortfolioTableData(res.data);
        }
      })
      .catch((error) => {
        swal(
          "Error",
          `Oops, Failed to load Portfolios Report, Please try again later.`,
          "error"
        );
      });
  };

  //Property View Api's & Functions
  const getPropertyProfitandLossReport = (
    portfolioId,
    startDate = "",
    endDate = ""
  ) => {
    const request = {
      portfolioId: portfolioId,
      startDate: startDate,
      endDate: endDate,
    };

    webApi
      .post(`/PL/property`, request, {
        loader: true,
      })
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          if (res.data.properties) {
            setPropertyReport(res.data.properties);
            setPropertyTableData(res.data.properties);
            setPropTotals({
              totalIncome: res.data.totalIncome,
              totalExpense: res.data.totalExpense,
              netOperatingIncome: res.data.netOperatingIncome,
            });
            // handlePropertyTypeChange("Single Family", res.data.properties);
          }
        }
      })
      .catch((error) => {
        swal(
          "Error",
          `Oops, Failed to load Properties Report, Please try again later.`,
          "error"
        );
      });
  };

  // const handlePropertyTypeChange = (
  //   propType,
  //   propertyReportParam = propertyReport
  // ) => {
  //   clearSearchField();

  //   const propTypeData = propertyReportParam.filter(
  //     (item) => item.propertyType === propType
  //   );
  //   setPropertyTableData(propTypeData);

  //   setPropertyType(propType);
  // };

  const handlePropSearchFieldData = (propType) => {
    if (propType === "All") {
      return propertyReport;
    } else {
      const propTypeData = propertyReport.filter(
        (item) => item.propertyType === propType
      );
      return propTypeData;
    }
  };

  const clearPropertyView = () => {
    setPropertyReport([]);
    setPropertyTableData([]);
    setDrillProperty({});
    setDrillPortfolio({});
    setPropertyType("All");
    clearSearchField();
    setPropTotals({
      totalIncome: 0,
      totalExpense: 0,
      netOperatingIncome: 0,
    });
  };

  //Unit View Api's
  const getUnitProfitandLossReport = (
    propertyId,
    startDate = "",
    endDate = ""
  ) => {
    const request = {
      propertyId: propertyId,
      startDate: startDate,
      endDate: endDate,
    };

    webApi
      .post(`/PL/Unit`, request, {
        loader: true,
      })
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          if (res.data.units) {
            setUnitReport(res.data.units);
            setUnitTableData(res.data.units);
          }
          setUnitTotals({
            totalIncome: res.data.totalIncome,
            totalExpense: res.data.totalExpense,
            netOperatingIncome: res.data.netOperatingIncome,
          });
        }
      })
      .catch((error) => {
        swal(
          "Error",
          `Oops, Failed to load Units Report, Please try again later.`,
          "error"
        );
      });
  };

  const clearUnitView = () => {
    setUnitReport([]);
    setUnitTableData([]);
    setDrillUnit({});
    clearSearchField();
    setUnitTotals({
      totalIncome: 0,
      totalExpense: 0,
      netOperatingIncome: 0,
    });
  };

  //Search Bar
  const clearSearchField = () => {
    if (searchBarRef.current) {
      searchBarRef.current.clearSearch();
    }
  };

  //Summary API's
  const summaryApiEndpoints = {
    Portfolio: "/PLsummary/portfolio",
    SingleProperty: "/PLsummary/singleProperty",
    SingleUnit: "/PLsummary/singleUnit",
    Properties: "/PLsummary/property",
    Units: "/PLsummary/units",
  };

  const fetchSummaryData = (endpoint, request) => {
    return webApi
      .post(endpoint, request)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
          throw new Error(res.data.errorMessage); // To handle the error in Promise.all
        } else {
          return res.data;
        }
      })
      .catch((error) => {
        throw error; // To handle the error in Promise.all
      });
  };

  const handleSummaryAPIs = (type, startDate = "", endDate = "", ID) => {
    showLoader("Generating...");

    let parentDataPromise = null;
    let childrenDataPromise = null;

    switch (type) {
      case "Properties":
        parentDataPromise = fetchSummaryData(summaryApiEndpoints.Portfolio, {
          portfolioID: drillPortfolio.portfolioId || 0,
          startDate,
          endDate,
        });

        childrenDataPromise = fetchSummaryData(summaryApiEndpoints.Properties, {
          portfolioID: drillPortfolio.portfolioId || 0,
          startDate,
          endDate,
        });
        break;

      case "Units":
        parentDataPromise = fetchSummaryData(
          summaryApiEndpoints.SingleProperty,
          {
            propertyId: drillProperty.propertyId || 0,
            startDate,
            endDate,
          }
        );

        childrenDataPromise = fetchSummaryData(summaryApiEndpoints.Units, {
          propertyID: drillProperty.propertyId || 0,
          startDate,
          endDate,
        });
        break;

      case "SingleProperty":
        parentDataPromise = fetchSummaryData(summaryApiEndpoints.Portfolio, {
          portfolioID: drillPortfolio.portfolioId || 0,
          startDate,
          endDate,
        });

        childrenDataPromise = fetchSummaryData(
          summaryApiEndpoints.SingleProperty,
          {
            propertyId: ID || drillProperty.propertyId || 0,
            startDate,
            endDate,
          }
        );
        break;

      case "SingleUnit":
        parentDataPromise = fetchSummaryData(
          summaryApiEndpoints.SingleProperty,
          {
            propertyId: drillProperty.propertyId || 0,
            startDate,
            endDate,
          }
        );

        childrenDataPromise = fetchSummaryData(summaryApiEndpoints.SingleUnit, {
          unitId: ID || drillUnit.unitId || 0,
          startDate,
          endDate,
        });
        break;

      default:
        console.error("Invalid type provided");
        hideLoader();
        return;
    }

    // Use Promise.all if both promises are defined
    const promises = [];
    if (parentDataPromise) promises.push(parentDataPromise);
    if (childrenDataPromise) promises.push(childrenDataPromise);

    Promise.all(promises)
      .then((results) => {
        const [parentData, childrenData] = results;

        if (parentData) setParentSummary(parentData);

        if (childrenData) {
          if (type === "SingleProperty" || type === "SingleUnit") {
            let modifiedData = {
              //Mocking Code as Properties, Units Summary for Code Reusability
              _links: {
                next: "",
                prev: "",
              },
              results: [childrenData],
            };
            setChildrenSummary(modifiedData);
          } else {
            setChildrenSummary(childrenData);
          }
        }

        setShowSummaryPage(true);
        hideLoader();
      })
      .catch((error) => {
        hideLoader();
        swal(
          "Error",
          "Unable to Load Complete Data. Please try again later.",
          "error"
        );
      });
  };

  //Summary Pagination API's
  const getPropertiesSummary = (
    apiPath,
    portfolioId,
    startDate = "",
    endDate = ""
  ) => {
    const request = {
      portfolioID: portfolioId || 0,
      startDate: startDate,
      endDate: endDate,
    };

    webApi
      .post(`${apiPath}`, request, {
        loader: true,
      })
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setChildrenSummary(res.data);
        }
      })
      .catch((error) => {
        swal(
          "Error",
          `Oops, Failed to Load Properties Income and Expenses, Please try again later.`,
          "error"
        );
      });
  };

  const getUnitsSummary = (
    apiPath,
    propertyId,
    startDate = "",
    endDate = ""
  ) => {
    const request = {
      propertyID: propertyId || 0,
      startDate: startDate,
      endDate: endDate,
    };

    webApi
      .post(`${apiPath}`, request, {
        loader: true,
      })
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setChildrenSummary(res.data);
        }
      })
      .catch((error) => {
        swal(
          "Error",
          `Oops, Failed to Load Units Income and Expenses, Please try again later.`,
          "error"
        );
      });
  };

  const handleCSVReportDownload = (type, startDate = "", endDate = "") => {
    let requestData = {
      startDate: DateFormatForAPI(startDate),
      endDate: DateFormatForAPI(endDate),
    };

    if (type === "Properties") {
      requestData.level = "property";
      requestData.portfolioId = drillPortfolio.portfolioId;
    } else if (type === "Units") {
      requestData.level = "unit";
      requestData.propertyID = drillProperty.propertyId;
    } else if (type === "SingleProperty") {
      requestData.level = "singleproperty";
      requestData.propertyID = drillProperty.propertyId;
    } else if (type === "SingleUnit") {
      requestData.level = "singleunit";
      requestData.unitId = drillUnit.unitId;
    }
    webApi
      .post("/PANDL/CSV", requestData, { loader: true })
      .then((res) => {
        if (res.data.errorCode) {
          swal("", res.data.errorMessage, "error");
        } else {
          window.location.href = res.data;
        }
      })
      .catch((error) => {
        swal("", "Report generation was unsuccessful.", "error");
      });
  };

  const handlePDFReportDownload = (type, startDate = "", endDate = "") => {
    let requestData = {
      startDate: DateFormatForAPI(startDate),
      endDate: DateFormatForAPI(endDate),
    };

    if (type === "Properties") {
      requestData.level = "property";
      requestData.portfolioId = drillPortfolio.portfolioId;
    } else if (type === "Units") {
      requestData.level = "unit";
      requestData.propertyID = drillProperty.propertyId;
    } else if (type === "SingleProperty") {
      requestData.level = "singleproperty";
      requestData.propertyID = drillProperty.propertyId;
    } else if (type === "SingleUnit") {
      requestData.level = "singleunit";
      requestData.unitId = drillUnit.unitId;
    }
    webApi
      .post("/report/pAndL/pdf", requestData, { loader: true })
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Apologies, Failed to download P&L Summary Report, Please try again later.`,
            "error"
          );
        }
      });
  };

  //Table Props
  const tableProps = {
    portfolio: {
      tableId: "portfolioTable",
      data: portfolioTableData,
      rowOnClick: (row) => {
        getPropertyProfitandLossReport(row.portfolioId, "", "");
        setDrillPortfolio(row);
        setView("property");
      },
      columns: [
        { key: "portfolioName", title: "Portfolio Name" },
        {
          key: "totalIncome",
          title: "Income",
          applyMethod: (row) => GetDenominationWithDollar(row.totalIncome),
        },
        {
          key: "totalExpense",
          title: "Expenses",
          applyMethod: (row) => GetDenominationWithDollar(row.totalExpense),
        },
        {
          key: "netOperatingIncome",
          title: "Net Operating Income",
          applyMethod: (row) =>
            GetDenominationWithDollar(row.netOperatingIncome),
          getCellStyles: (row) =>
            GetColorByDenomination(row.netOperatingIncome),
        },
      ],
    },
    property: {
      tableId: "propertyTable",
      data: propertyTableData,
      rowOnClick: (row) => {
        if (row.propertyType === "Multi-Family") {
          getUnitProfitandLossReport(row.propertyId);
          setDrillProperty(row);
          setView("unit");
        } else {
          setDrillProperty(row);
          setSummaryType("SingleProperty");
          handleSummaryAPIs("SingleProperty", "", "", row.propertyId);
        }
      },
      columns: [
        { key: "propertyName", title: "Property Name" },
        {
          key: "address",
          title: "Property Address",
          applyMethod: (row) => GetCompleteAddress(row.address),
        },
        {
          key: "propertyType",
          title: "Property Type",
        },
        ...(propertyType !== "Single Family"
          ? [{ key: "noOfUnits", title: "No. Of Units" }]
          : []),
        {
          key: "totalIncome",
          title: "Income",
          applyMethod: (row) => GetDenominationWithDollar(row.totalIncome),
        },
        {
          key: "totalExpense",
          title: "Expenses",
          applyMethod: (row) => GetDenominationWithDollar(row.totalExpense),
        },
        {
          key: "netOperatingIncome",
          title: "Net Operating Income",
          applyMethod: (row) =>
            GetDenominationWithDollar(row.netOperatingIncome),
          getCellStyles: (row) =>
            GetColorByDenomination(row.netOperatingIncome),
        },
      ],
    },
    unit: {
      tableId: "unitTable",
      data: unitTableData,
      rowOnClick: (row) => {
        setDrillUnit(row);
        setSummaryType("SingleUnit");
        handleSummaryAPIs("SingleUnit", "", "", row.unitId);
      },
      columns: [
        { key: "unitNo", title: "Unit No." },
        {
          key: "income",
          title: "Income",
          applyMethod: (row) => GetDenominationWithDollar(row.income),
        },
        {
          key: "expenses",
          title: "Expenses",
          applyMethod: (row) => GetDenominationWithDollar(row.expenses),
        },
        {
          key: "netOperatingIncome",
          title: "Net Operating Income",
          applyMethod: (row) =>
            GetDenominationWithDollar(row.netOperatingIncome),
          getCellStyles: (row) =>
            GetColorByDenomination(row.netOperatingIncome),
        },
      ],
    },
  };

  const searchBarProps = {
    portfolio: {
      style: "",
      id: "PortfolioSearch",
      placeholder: "Search...",
      data: portfolioReport,
      setTableData: setPortfolioTableData,
      columns: [
        { key: "portfolioName", title: "Portfolio Name" },
        {
          key: "totalIncome",
          title: "Income",
          applyMethod: (row) => GetDenominationWithDollar(row.totalIncome),
        },
        {
          key: "totalExpense",
          title: "Expenses",
          applyMethod: (row) => GetDenominationWithDollar(row.totalExpense),
        },
        {
          key: "netOperatingIncome",
          title: "Net Operating Income",
          applyMethod: (row) =>
            GetDenominationWithDollar(row.netOperatingIncome),
        },
      ],
    },

    property: {
      style: "",
      id: "PropertySearch",
      placeholder: "Search...",
      data: propertyReport,
      setTableData: setPropertyTableData,
      columns: [
        { key: "propertyName", title: "Property Name" },
        {
          key: "address",
          title: "Property Address",
          applyMethod: (row) => GetCompleteAddress(row.address),
        },
        {
          key: "propertyType",
          title: "Property Type",
        },
        ...(propertyType !== "Single Family"
          ? [{ key: "noOfUnits", title: "No. Of Units" }]
          : []),
        {
          key: "totalIncome",
          title: "Income",
          applyMethod: (row) => GetDenominationWithDollar(row.totalIncome),
        },
        {
          key: "totalExpense",
          title: "Expenses",
          applyMethod: (row) => GetDenominationWithDollar(row.totalExpense),
        },
        {
          key: "netOperatingIncome",
          title: "Net Operating Income",
          applyMethod: (row) =>
            GetDenominationWithDollar(row.netOperatingIncome),
        },
      ],
    },

    unit: {
      style: "",
      id: "UnitSearch",
      placeholder: "Search...",
      data: unitReport,
      setTableData: setUnitTableData,
      columns: [
        { key: "unitNo", title: "Unit No." },
        {
          key: "income",
          title: "Income",
          applyMethod: (row) => GetDenominationWithDollar(row.income),
        },
        {
          key: "expenses",
          title: "Expenses",
          applyMethod: (row) => GetDenominationWithDollar(row.expenses),
        },
        {
          key: "netOperatingIncome",
          title: "Net Operating Income",
          applyMethod: (row) =>
            GetDenominationWithDollar(row.netOperatingIncome),
        },
      ],
    },
  };

  return (
    <>
      {!showSummaryPage && (
        <div className="bg-white">
          <span
            title="ReportTitle"
            className={`flex justify-start pl-2 gap-2 items-center bg-blue-52 text-white font-semibold text-center rounded h-10`}
          >
            {view !== "portfolio" && (
              <BsFillArrowLeftCircleFill
                title="Back"
                onClick={(e) => {
                  if (view === "unit") {
                    clearUnitView();
                    setView("property");
                  } else if (view === "property") {
                    clearPropertyView();
                    setView("portfolio");
                  }
                }}
                className={`justify-self-start text-white w-6 h-6 ${clickEffect}`}
              />
            )}

            {view === "portfolio" && (
              <div id="Profit&LossTitle" className="flex-grow">
                Profit & Loss Report
              </div>
            )}
            {view === "property" &&
              `Portfolio : ${drillPortfolio.portfolioName}`}
            {view === "unit" && `Property : ${drillProperty.propertyName}`}
          </span>

          {view !== "portfolio" && (
            <section
              name="profit&LossInfo"
              id="profit&LossInfo"
              className="flex justify-around p-4 shadow-lg"
            >
              <div className="bg-blue-100 p-3 rounded-md w-1/4">
                <h3 id="IncomeTitle" className="text-blue-600 font-semibold">
                  Income
                </h3>
                <p id="totalIncomeValue" className="text-2xl text-blue-700">
                  {GetDenominationWithDollar(
                    view === "property"
                      ? propTotals.totalIncome
                      : unitTotals.totalIncome
                  )}
                </p>
              </div>
              <div className="bg-yellow-100 p-3 rounded w-1/4">
                <h3
                  id="ExpensesTitle"
                  className="text-yellow-600 font-semibold"
                >
                  Expenses
                </h3>
                <p id="totalExpensesValue" className="text-2xl text-yellow-700">
                  {GetDenominationWithDollar(
                    view === "property"
                      ? propTotals.totalExpense
                      : unitTotals.totalExpense
                  )}
                </p>
              </div>
              <div className="bg-green-100 p-3 rounded w-1/4">
                <h3
                  id="netOperatingIncomeTitle"
                  className="text-green-600 font-semibold"
                >
                  Net Operating Income
                </h3>
                <p
                  id="netOperatingIncomeValue"
                  className="text-2xl text-green-700"
                >
                  {GetDenominationWithDollar(
                    view === "property"
                      ? propTotals.netOperatingIncome
                      : unitTotals.netOperatingIncome
                  )}
                </p>
              </div>
            </section>
          )}

          <section
            name="Searchbar and Options"
            className={"flex justify-between items-end my-4 mx-4"}
          >
            <div className="flex items-end gap-1">
              {/* {view === "portfolio" && (
                <InputObjectSelectField
                  label="Select Portfolio: "
                  name="selectPortfolio"
                  id="selectPortfolio"
                  notImp
                  placeholder="Select Portfolio"
                  value={filteredPortfolioId}
                  onChange={(e) => {
                    if (parseInt(e.target.value)) {
                      getPortfolioProfitandLossReport(
                        0,
                        parseInt(e.target.value)
                      );
                    } else {
                      getPortfolioProfitandLossReport(clientIdVal, 0);
                    }
                    setFilteredPortfolioId(parseInt(e.target.value));
                  }}
                  options={[{ ID: 0, portfolioName: "All" }, ...allPortfolios]}
                  optionValue="ID"
                  optionName="portfolioName"
                  defaultPlaceholderValue={0}
                  className="!w-fit"
                  style="flex items-center gap-1 !w-fit"
                />
              )} */}

              {view === "portfolio" && (
                <p id="portfoliosTitle" className="font-semibold">
                  Portfolios ({allPortfolios.length})
                </p>
              )}

              {view === "property" && (
                <p id="PropertiesTitle" className="font-semibold">
                  Properties ({propertyReport.length})
                </p>
              )}

              {view === "unit" && (
                <p id="UnitsTitle" className="font-semibold">
                  Units ({unitReport.length})
                </p>
              )}
            </div>

            <div className="flex items-center gap-4">
              {/* {view === "property" && (
                <InputSelectField
                  label="Property Type: "
                  name="propertyType"
                  id="propertyType"
                  dataCy="propertyType"
                  notImp
                  placeholder="Choose a Property Type"
                  value={propertyType}
                  onChange={(e) => handlePropertyTypeChange(e.target.value)}
                  options={["Single Family", "Multi-Family"]}
                  className="!w-fit"
                  style=""
                />
              )} */}

              {(view === "unit" ||
                (view === "property" &&
                  drillPortfolio.portfolioType === "Single Family")) && (
                <ButtonBlueOutline
                  id="p&lSummary"
                  dataCy="addNewFolder"
                  title="Summary"
                  name="P&L Summary"
                  onClick={(e) => {
                    if (view === "unit") {
                      setSummaryType("Units");
                      handleSummaryAPIs("Units");
                    } else if (view === "property") {
                      setSummaryType("Properties");
                      handleSummaryAPIs("Properties");
                    }
                  }}
                  className="flex justify-center items-center px-2 w-fit h-10 rounded-md"
                />
              )}

              <SearchTableComponent
                ref={searchBarRef}
                {...searchBarProps[view]}
              />
            </div>
          </section>

          <TableComponent className="bg-white" {...tableProps[view]} />
        </div>
      )}

      {showSummaryPage && (
        <ProfitandLossSummary
          trigger={showSummaryPage}
          setTrigger={setShowSummaryPage}
          parent={parentSummary}
          children={childrenSummary}
          summaryType={summaryType}
          // parent={initialPortfolio}
          // children={initialProperties}
          handleSummaryFilter={(type, startDate, endDate) => {
            handleSummaryAPIs(type, startDate, endDate);
          }}
          handlePagination={(apiPath, type, startDate, endDate) => {
            if (type === "Properties") {
              getPropertiesSummary(
                apiPath,
                drillPortfolio.portfolioId,
                startDate,
                endDate
              );
            } else if ((type = "Units")) {
              getUnitsSummary(
                apiPath,
                drillProperty.propertyId,
                startDate,
                endDate
              );
            }
          }}
          handleReportDownload={(type, startDate, endDate) =>
            handleCSVReportDownload(type, startDate, endDate)
          }
          handlePdfReportDownload={(type, startDate, endDate) =>
            handlePDFReportDownload(type, startDate, endDate)
          }
          handleSummaryPageClose={() => {
            setShowSummaryPage(false);
            setParentSummary({});
            setChildrenSummary([]);
            setSummaryType("");
          }}
        />
      )}
    </>
  );
};

export default ProfitandLossReport;
