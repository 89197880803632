import React from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../Widgets/Footer";

const Landing = () => {
  let navigate = useNavigate();

  const directToLogin = () => {
    navigate("/login");
  };

  const directToSignup = () => {
    navigate("/SignUP");
  };

  return (
    <>
      <section className="flex flex-col justify-center items-center bg-blue-52 min-h-screen">
        <img
          id="landingPageLogo"
          className="w-72 xs:w-72 mb-2"
          src="logo/NEWLOGO.png"
          alt="Yvooa Logo"
          data-cy="landingPageYvooaLogo"
        />

        <span className="text-white text-xl text-center mb-32">
          <p>Your Residential Solutions</p>
          <p>under one roof.</p>
        </span>

        <button
          onClick={directToLogin}
          className="bg-blue-52 mb-4 w-80 h-10 xs:w-64 sm:w-64 text-white font-semibold border rounded shadow"
          id="loginButton"
          name="loginButton"
          data-cy="loginButton"
        >
          LOGIN
        </button>

        <u
          onClick={directToSignup}
          className="text-white text-sm text-center cursor-pointer mb-32"
          id="signupButton"
          name="signupButton"
          data-cy="signupButton"
        >
          SIGN-UP HERE
        </u>
        <span className="flex flex-row text-white text-center">
          Questions? Contact Customer Support at{" "}
          <p className=" pl-1" data-cy="supportEmail">
            support@rapidviewtech.com
          </p>
        </span>

        <Footer
          className={"!text-white !mt-8"}
          classNamePrivacyPolicy="!text-white"
        />
      </section>
    </>
  );
};

export default Landing;
