import React, { useState, useCallback } from "react";
import { WebApimanager } from "../../WebApiManager";
import AddressComponent from "../../Widgets/Forms/AddressComponent";
import { TextReviewTile } from "../../Widgets/Forms/FormReviewTiles";
import {
  ButtonBlue,
  ButtonGrayOutline,
  ButtonIconSquare,
} from "../../Widgets/Buttons";
import SelectCountryCode, {
  PhoneNumFormat,
  IsSpecialCharFree,
} from "../../Widgets/Forms/FieldDependants";
import InputField, {
  InputPhoneNumField,
} from "../../Widgets/Forms/InputFields";
import SnackAlert from "../../Widgets/SnackbarAlert";
import { clientId, companyId, userData } from "../../atoms";
import { useRecoilValue } from "recoil";
import {
  Dialog,
  Stepper,
  StepLabel,
  Step,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import swal from "sweetalert";
import { useEffect } from "react";
import {
  InputSelectField,
  InputObjectSelectField,
} from "../../Widgets/Forms/InputFields";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { einFormat } from "../../Widgets/Forms/FieldDependants";

const CreatePortfolio = (props) => {
  let webApi = new WebApimanager();
  const clientIdVal = useRecoilValue(clientId);
  const companyIdVal = useRecoilValue(companyId);
  const userInfo = useRecoilValue(userData);
  const isClientAdmin = userInfo.userRole === "Client Admin" ? true : false;
  const editForm = props.operation === "Edit" ? true : false;
  const editImport = props.operation === "Import" ? true : false;

  const [portfolioName, setPortfolioName] = useState(
    editForm || editImport ? props.portfolio.portfolioName : ""
  );
  const [companyName, setCompanyName] = useState(
    editForm || editImport ? props.portfolio.companyName : ""
  );
  const [einSsn, setEinSsn] = useState(
    editForm || editImport ? einFormat(props.portfolio.ein) : ""
  );
  const [ownershipName, setOwnershipName] = useState("");
  const [email, setEmail] = useState(
    editForm || editImport ? props.portfolio.email : ""
  );
  const [phoneNum, setPhoneNum] = useState(
    editForm || editImport ? props.portfolio.phoneNumber : ""
  );

  const [portfolioType, setPortfolioType] = useState(
    editForm || editImport ? props.portfolio.portfolioType : ""
  );

  const [assignedPropManagerId, setAssignedPropManagerId] = useState(
    editForm || editImport
      ? props.portfolio.assignedPropertyManager.propertyManagerID
      : 0
  );

  const [completeAddress, setCompleteAddress] = useState({
    address1:
      editForm || editImport ? props.portfolio.businessAddress.address1 : "",
    address2:
      editForm || editImport ? props.portfolio.businessAddress.address2 : "",
    city: editForm || editImport ? props.portfolio.businessAddress.city : "",
    state: editForm || editImport ? props.portfolio.businessAddress.state : "",
    country:
      editForm || editImport ? props.portfolio.businessAddress.country : "",
    zipCode:
      editForm || editImport ? props.portfolio.businessAddress.zipCode : "",
  });

  const [allPropertManagers, setAllPropertyManagers] = useState([]);
  const [assignedPropertyManagerName, setAssignedPropManagerName] =
    useState("");

  const [formErrors, setFormErrors] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [companyOptions, setCompanyOptions] = useState([]);
  const [selectedOwnerID, setSelectedOwnerID] = useState(
    editForm || editImport ? props.portfolio.landlordId : 0
  );
  const [OwnerOptions, setOwnerOptions] = useState([]);
  const [allOwnerOptions, setAllOwnerOptions] = useState([]);

  useEffect(() => {
    if (isClientAdmin) {
      webApi
        .get(`/clients/company/${companyIdVal}/propertyManager`, {
          loader: true,
        })
        .then((res) => {
          if (res.data.errorCode) {
            swal("Failure", res.data.errorMessage, "error");
          } else {
            // setAllPropertyManagers(res.data);
            setAllPropertyManagers(
              res.data.length > 1
                ? res.data.sort((a, b) => (a.name > b.name ? 1 : -1))
                : res.data
            );
          }
        })
        .catch((error) => {
          swal(
            "Error",
            `Oops! Failed to Load Property Managers List, Please try again later.`,
            "error"
          );
        });
    }
    getCompaniesList();
    getOwnersListByCompany();
  }, [isClientAdmin]);

  const getCompaniesList = () => {
    webApi
      .get(`/landlord/company/${companyIdVal}/companyName`, { loader: true })
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setCompanyOptions(res.data);
        }
      })
      .catch((error) => {
        swal(
          "Error",
          "Oops! Failed to get Companies options, Please try again later.",
          "error"
        );
      });
  };

  const getOwnersListByCompany = () => {
    webApi
      .get(`/landlord/company/${companyIdVal}`, { loader: true })
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setOwnerOptions(res.data);
          setAllOwnerOptions(res.data);
        }
      })
      .catch((error) => {
        swal(
          "Error",
          "Oops! Failed to get Owner options, Please try again later.",
          "error"
        );
      });
  };

  const validate = () => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (activeStep === 0) {
      if (!portfolioName) {
        errors.portfolioName = "Portfolio Name is required!";
      }
      if (!portfolioType) {
        errors.portfolioType = "Portfolio Type is required!";
      }
      if (!einSsn) {
        errors.einSsn = "EIN is required!";
      } else if (einSsn.replace(/-/g, "").length !== 9) {
        errors.einSsn = "EIN format is invalid!";
      }

      // else if (!/^\d{2}-\d{7}$/.test(einSsn) && !/^\d{9}$/.test(einSsn)) {
      //   errors.einSsn = "EIN format is invalid!";
      // }

      // if (!ownership) {
      //   errors.ownership = "Owner Name is required!";
      // }
      if (!email) {
        errors.email = "Email is required!";
      } else if (!emailRegex.test(email)) {
        errors.email = "Email is not valid!";
      }
      if (!phoneNum || phoneNum.length !== 12) {
        errors.phoneNum = "Contact number is not Valid!";
      }
    } else if (activeStep === 1) {
      if (!completeAddress.address1) {
        errors.address1 = "Address 1 is required!";
      }
      if (!completeAddress.city) {
        errors.city = "City is required!";
      }
      if (!completeAddress.state) {
        errors.state = "State is required!";
      }
      if (!completeAddress.country) {
        errors.country = "Country is required!";
      }
      if (!completeAddress.zipCode) {
        errors.zipCode = "Zipcode is required!";
      } else if (completeAddress.zipCode.toString().length !== 5) {
        errors.zipCode = "Zipcode is not valid!";
      }
    }

    return errors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const numericValue = parseInt(einSsn.replace(/-/g, ""), 10);

    let data = {
      portfolioName: portfolioName,
      ownership: ownershipName,
      email: email,
      phoneNumber: phoneNum,
      clientId: clientIdVal,
      businessAddress: {
        address1: completeAddress.address1,
        address2: completeAddress.address2,
        city: completeAddress.city,
        state: completeAddress.state,
        country: completeAddress.country,
        zipCode: completeAddress.zipCode,
      },
      landlordId: selectedOwnerID,
      ein: numericValue,
      companyName: companyName,
      portfolioType: portfolioType,
    };

    if (editForm) {
      handlePropertyManagerAPICall(props.portfolio.ID).then(() => {
        webApi
          .put("/portfolios/" + props.portfolio.ID, data, { loader: true })
          .then((res) => handleApiResponse(res))
          .catch((error) => {
            swal(
              "Error",
              `Oops! Failed to Update Portfolio ${data.portfolioName}, Please try again later.`,
              "error"
            );
          });
      });
    } else {
      webApi
        .post("/portfolios", data, { loader: true })
        .then((res) => handleApiResponse(res))
        .catch((error) => {
          swal(
            "Error",
            `Oops! Failed to Create Portfolio ${data.portfolioName}, Please try again later.`,
            "error"
          );
        });
    }
  };

  const handleApiResponse = (res) => {
    if (res.data.errorCode) {
      swal("error", res.data.errorMessage, "error");
    } else {
      let propertyManagerPromise;

      if (!editForm) {
        propertyManagerPromise = handlePropertyManagerAPICall(res.data.ID);
      } else {
        propertyManagerPromise = Promise.resolve();
      }

      propertyManagerPromise.then(() => {
        props.getAllPortfolios(res.data);
        swal(
          "Success",
          editForm ? "Portfolio Updated" : "New Portfolio Created",
          "success"
        );
        props.setTrigger(false);
      });
    }
  };

  const handlePropertyManagerAPICall = (portfolioID) => {
    if (assignedPropManagerId && isClientAdmin) {
      const assignData = {
        clientAdminID: clientIdVal,
        propertyManagerID: assignedPropManagerId,
      };

      if (editForm) {
        assignData.portfolioID = portfolioID;
        return webApi
          .put("/propertyManager/updateAssignPortfolios", assignData, {
            loader: true,
          })
          .then((propManagerRes) =>
            handlePropertyManagerResponse(propManagerRes)
          )
          .catch((error) => {
            // Handle API call failure
            swal(
              "Error",
              "Oops! Failed to Update Property Manager, Please try again later.",
              "error"
            );
          });
      } else {
        assignData.portfolioIDS = [portfolioID];
        return webApi
          .post("/propertyManager/assignPortfolios", assignData, {
            loader: true,
          })
          .then((propManagerRes) =>
            handlePropertyManagerResponse(propManagerRes)
          )
          .catch((error) => {
            // Handle API call failure
            swal(
              "Error",
              "Oops! Failed to Assign Property Manager to Portfolio, Please try again later.",
              "error"
            );
          });
      }
    } else {
      return Promise.resolve(); // Return a resolved promise if assignedPropManagerId is falsy
    }
  };

  const handlePropertyManagerResponse = (res) => {
    if (res.data.errorCode) {
      swal("Failure", res.data.errorMessage, "error");
    } else {
      console.log("Assigning property Manager is success");
    }
  };

  const handleSteps = (event) => {
    event.preventDefault();
    const temp = validate();
    setFormErrors(temp);
    if (Object.keys(temp).length) {
      return;
    } else {
      if (activeStep === 1 && assignedPropManagerId !== 0 && isClientAdmin) {
        const assignedPropManager = allPropertManagers.filter(
          (propManager) => propManager.ID === assignedPropManagerId
        );
        setAssignedPropManagerName(assignedPropManager[0].name);
      }
      if (activeStep === 1 && selectedOwnerID !== 0) {
        const selectedOwnership = OwnerOptions.filter(
          (item) => item.landlordId === selectedOwnerID
        );
        setOwnershipName(selectedOwnership[0].ownerName);
      }
      setActiveStep((currentStep) => currentStep + 1);
    }
  };

  const handleBack = (event) => {
    event.preventDefault();
    setActiveStep((currentStep) => currentStep - 1);
  };

  const setNewAddress = useCallback(
    (newAddress) => {
      setCompleteAddress(newAddress);
    },
    [completeAddress]
  );

  const handleSnackbar = (type, message) => {
    setOpenSnackbar(true);
    setSnackbarType(type);
    setSnackbarMessage(message);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleEinSsnChange = (e) => {
    let value = e.target.value.replace(/\D/g, ""); // Remove all non-numeric characters
    if (value.length > 9) {
      value = value.slice(0, 9); // Limit to 9 characters
    }

    if (value.length > 2) {
      value = value.slice(0, 2) + "-" + value.slice(2); // Add the hyphen after the second digit
    }

    setEinSsn(value);
  };

  const handleAutoFillEmailAndPhonenum = (val) => {
    const selectedOwner = OwnerOptions.find(
      (item) => item.landlordId === parseInt(val)
    );

    if (selectedOwner) {
      setEmail(selectedOwner.email);
      setPhoneNum(selectedOwner.phoneNumber);
    } else {
      console.log("Owner not found");
    }
  };

  return props.trigger ? (
    <>
      <Dialog
        open={true}
        aria-labelledby="Create Portfolio Form"
        fullWidth
        maxWidth="lg"
        //PaperProps={{ sx: { height: "80%" } }}
        //scroll="body"
        className="flex flex-col justify-center w-5/6 mx-auto"
      >
        <SnackAlert
          open={openSnackbar}
          close={handleSnackbarClose}
          type={snackbarType}
          message={snackbarMessage}
        />

        <div className="flex justify-center items-center bg-blue-52 text-white font-semibold rounded-sm h-10 pr-2">
          <span id="portfolioForm" className="flex-grow text-center">
            {editForm ? "Edit" : "Add"} Portfolio
          </span>
          <ButtonIconSquare
            title="Close Portfolio Form"
            id="closePortfolioFrom"
            btnName="closePortfolioFrom"
            dataCy="closePortfolioFrom"
            onClick={(e) => props.setTrigger(false)}
            icon={<AiOutlineCloseCircle className="w-5 h-5" />}
            className="hover:!text-red-600"
          />
        </div>

        <Stepper activeStep={activeStep} alternativeLabel className="my-4">
          <Step>
            <StepLabel>Portfolio Details</StepLabel>
          </Step>
          <Step>
            <StepLabel>Business Address</StepLabel>
          </Step>
          <Step>
            <StepLabel>Confirm Details</StepLabel>
          </Step>
        </Stepper>

        <DialogContent dividers>
          <form className="flex flex-col justify-center items-center">
            {activeStep === 0 ? (
              <>
                <span
                  id="portfolioDetailsHeader"
                  className="border-b-2 border-blue-52 pb-1 mb-4 text-blue-52 w-5/6 md:gap-x-8 md:w-11/12"
                >
                  Porfolio Details
                </span>
                <section className="grid grid-cols-2 gap-x-16 gap-y-6 w-5/6 mb-4 md:gap-x-8 md:w-11/12">
                  <InputField
                    label="Portfolio Name"
                    name="portfolioName"
                    id="portfolioName"
                    dataCy="portfolioName"
                    type="text"
                    placeholder="Enter Portfolio Name"
                    value={portfolioName}
                    onChange={(e) =>
                      setPortfolioName(e.target.value.replace(/[^\w\s]/gi, ""))
                    }
                    errorText={formErrors.portfolioName}
                    style="!w-full"
                  />

                  <InputSelectField
                    label="Portfolio Type"
                    name="portfolioType"
                    id="portfolioType"
                    dataCy="portfolioType"
                    placeholder="Choose a Portfolio Type"
                    disabled={
                      editForm && props.portfolio.numberOfProperties !== 0
                    }
                    value={portfolioType}
                    onChange={(e) => setPortfolioType(e.target.value)}
                    options={["Single Family", "Multi-Family"]}
                    errorText={formErrors.portfolioType}
                    style="!w-full"
                    hintText={
                      editForm &&
                      props.portfolio.numberOfProperties !== 0 &&
                      "*Non Editable"
                    }
                  />

                  <InputSelectField
                    label="Company Name"
                    name="companyName"
                    id="companyName"
                    dataCy="companyName"
                    placeholder="Select a Company"
                    notImp
                    value={companyName}
                    options={companyOptions}
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                      if (e.target.value) {
                        const newOwnerOptions = allOwnerOptions.filter(
                          (item) => item.companyName === e.target.value
                        );
                        setSelectedOwnerID(0);
                        setOwnerOptions(newOwnerOptions);
                      }
                    }}
                    style="!w-full"
                  />

                  <InputField
                    label="EIN No"
                    name="einSsn"
                    id="einSsn"
                    dataCy="einSsn"
                    type="text"
                    placeholder="Enter EIN No"
                    value={einSsn}
                    onChange={(e) => handleEinSsnChange(e)}
                    errorText={formErrors.einSsn}
                    style="!w-full"
                  />

                  <InputObjectSelectField
                    label="Ownership"
                    name="OwnerShip"
                    id="OwnerShip"
                    dataCy="OwnerShip"
                    placeholder="Select a Owner"
                    value={selectedOwnerID}
                    onChange={(e) => {
                      if (companyName) {
                        console.log("company name ", companyName);
                        setSelectedOwnerID(parseInt(e.target.value));
                        handleAutoFillEmailAndPhonenum(e.target.value);
                      } else
                        swal(
                          "Info",
                          `Please choose a company to select an owner!`,
                          "info"
                        );
                    }}
                    options={OwnerOptions}
                    optionValue="landlordId"
                    optionName="ownerName"
                    defaultPlaceholderValue={0}
                    style="!w-full"
                    notImp
                  />

                  <InputField
                    label="Portfolio Owner Email ID"
                    name="email"
                    id="email"
                    dataCy="email"
                    type="text"
                    placeholder="Enter Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    errorText={formErrors.email}
                    style="!w-full"
                  />

                  <InputPhoneNumField
                    label="Portfoilo Owner Contact Number"
                    name="phoneNumber"
                    id="phoneNumber"
                    dataCy="phoneNumber"
                    placeholder="Enter Contact Number"
                    value={phoneNum}
                    setValue={setPhoneNum}
                    errorText={formErrors.phoneNum}
                    style="!w-full"
                  />

                  {isClientAdmin ? (
                    <InputObjectSelectField
                      label="Property Manager"
                      name="propManager"
                      id="propManager"
                      notImp
                      placeholder="Assign to Property Manager"
                      value={assignedPropManagerId}
                      onChange={(e) => {
                        if (e.target.value === "") {
                          setAssignedPropManagerId(0);
                        } else {
                          setAssignedPropManagerId(parseInt(e.target.value));
                        }
                      }}
                      options={allPropertManagers}
                      defaultPlaceholderValue={0}
                      optionValue="ID"
                      optionName="name"
                      style="!w-full"
                    />
                  ) : (
                    <span></span>
                  )}

                  <ButtonGrayOutline
                    name="Cancel"
                    id="cancel"
                    btnName="cancel"
                    dataCy="cancel"
                    onClick={() => props.setTrigger(false)}
                    className="mt-6 justify-self-end"
                  />
                  <ButtonBlue
                    name="Next"
                    id="next"
                    btnName="next"
                    dataCy="next"
                    onClick={(e) => handleSteps(e)}
                    className="mt-6"
                  />
                </section>
              </>
            ) : null}

            {activeStep === 1 ? (
              <>
                <span
                  id="businessAdress"
                  className="border-b-2 border-blue-52 pb-1 mb-4 text-blue-52 w-5/6 md:gap-x-8 md:w-11/12"
                >
                  Business Address
                </span>
                <section className="grid grid-cols-2 gap-x-16 gap-y-6 w-5/6 mb-4 md:gap-x-8 md:w-11/12">
                  <AddressComponent
                    address={completeAddress}
                    setNewAddress={setNewAddress}
                    formErrors={formErrors}
                    style="!w-full"
                  />
                </section>
                <div className="flex justify-center items-center gap-16 mt-6 mb-4 w-3/4">
                  <ButtonGrayOutline
                    name="Back"
                    id="back"
                    btnName="back"
                    onClick={(e) => handleBack(e)}
                    className="mt-6 justify-self-end"
                  />
                  <ButtonBlue
                    name="Next"
                    id="next"
                    btnName="next"
                    onClick={(e) => handleSteps(e)}
                    className="mt-6"
                  />
                </div>
              </>
            ) : null}

            {activeStep === 2 ? (
              <>
                <span
                  id="reviewPortfolioDetails"
                  className="border-b-2 border-blue-52 pb-1 mb-4 text-blue-52 w-5/6 md:gap-x-8 md:w-11/12"
                >
                  Review Portfolio Details
                </span>
                <section className="grid grid-cols-3 gap-x-16 gap-y-6 w-5/6 justify-around items-center md:gap-x-8 md:w-11/12">
                  <TextReviewTile
                    id="portfolioNameReview"
                    title="Portfolio Name"
                    body={portfolioName}
                  />
                  <TextReviewTile
                    id="companyNameReview"
                    title="Company Name"
                    body={companyName}
                  />
                  <TextReviewTile
                    id="ssnNoReview"
                    title="SSN No"
                    body={einSsn}
                  />
                  <TextReviewTile
                    id="ownershipReview"
                    title="Ownership"
                    body={ownershipName}
                  />
                  <TextReviewTile
                    id="portfolioOwnerEmailReview"
                    title="Portfolio Owner Email"
                    body={email}
                  />
                  <TextReviewTile
                    id="portfolioOwnerContactNumberReview"
                    title="Portfolio Owner Contact Number"
                    body={PhoneNumFormat(phoneNum, true)}
                  />
                  <TextReviewTile
                    id="assignedToPropertyManagerReview"
                    title="Assigned to Property Manager"
                    body={assignedPropertyManagerName}
                    hidden={!isClientAdmin}
                  />
                  <TextReviewTile
                    id="address1Review"
                    title="Address 1"
                    body={completeAddress.address1}
                  />
                  <TextReviewTile
                    id="address2Review"
                    title="Address 2"
                    body={completeAddress.address2}
                  />
                  <TextReviewTile
                    id="cityReview"
                    title="City"
                    body={completeAddress.city}
                  />
                  <TextReviewTile
                    id="stateReview"
                    title="State"
                    body={completeAddress.state}
                  />
                  <TextReviewTile
                    id="countryReview"
                    title="Country"
                    body={completeAddress.country}
                  />
                  <TextReviewTile
                    id="zipcodeReview"
                    title="Zipcode"
                    body={completeAddress.zipCode}
                  />
                </section>

                <div className="flex justify-center items-center gap-16 mt-6 mb-4 w-3/4">
                  <ButtonGrayOutline
                    name="Back"
                    id="back"
                    btnName="back"
                    dataCy="back"
                    onClick={(e) => handleBack(e)}
                  />
                  <ButtonBlue
                    id="confirm"
                    btnName="confirm"
                    name="Confirm"
                    onClick={handleSubmit}
                  />
                </div>
              </>
            ) : null}
          </form>
        </DialogContent>
      </Dialog>
    </>
  ) : (
    ""
  );
};

export default CreatePortfolio;
