import React, { useState } from "react";
import { Auth } from "aws-amplify";
import swal from "sweetalert";
import { useNavigate, Link } from "react-router-dom";
import {
  accessToken,
  idToken,
  refreshToken,
  clientId,
  userData,
  companyId,
  profileImageUrl,
} from "../../atoms.js";
import { useRecoilState, useRecoilValue } from "recoil";
import { WebApimanager } from "../../WebApiManager";
import { BsFillShieldLockFill } from "react-icons/bs";
import InputField, {
  InputPasswordField,
} from "../../Widgets/Forms/InputFields";
import config from "../../config.js";
import VerifyEmail from "../Profile/verifyEmail.js";
import { Loader } from "../../Widgets/notificationFeedbacks";
import Footer from "../../Widgets/Footer.js";

const Login = () => {
  let webApi = new WebApimanager();
  const [email, setEmail] = useState("");
  const [clientIdVal, setClientIdVal] = useRecoilState(clientId);
  const [userInfo, setUserInfo] = useRecoilState(userData);
  const [profileImage, setProfileImage] = useRecoilState(profileImageUrl);
  const [pass, setPass] = useState("");
  const [formErrors, setFormErrors] = useState("");
  const [confirmUser, setConfirmUser] = useState(false);
  const [code, setCode] = useState("");
  const [accessTokenVal, setAccessTokenVal] = useRecoilState(accessToken);
  const [idTokenVal, setIdTokenVal] = useRecoilState(idToken);
  const [refreshTokenVal, setRefreshTokenVal] = useRecoilState(refreshToken);
  const [companyIdVal, setCompanyIdVal] = useRecoilState(companyId);
  const [openEmailCodeForm, setOpenEmailCodeForm] = useState(false);
  const [fromLogin, setFromLogin] = useState(false);

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  let navigate = useNavigate();

  const validate = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    const errors = {};
    if (!email) {
      errors.email = "Email is required!";
    } else if (!emailRegex.test(email)) {
      errors.email = "Email is not valid!";
    }
    if (!pass) {
      errors.pass = "Password is required!";
    } else if (!passwordRegex.test(pass)) {
      errors.pass = "Password is not valid!";
    }
    return errors;
  };

  const handlesubmit = async (event) => {
    event.preventDefault();
    const temp = validate();
    setFormErrors(temp);

    if (Object.keys(temp).length) {
      return;
    } else {
      setApiProcessing({
        loader: true,
        message: "Logging In...",
      });
      await Auth.signIn(email, pass)
        .then((user) => {
          // console.log("user", user);
          // swal("Success", "Login Successful", "success");
          let cognitoAccessToken =
            (user &&
              user.signInUserSession &&
              user.signInUserSession.accessToken &&
              user.signInUserSession.accessToken.jwtToken) ||
            "";
          setAccessTokenVal(cognitoAccessToken);
          let idToken =
            (user &&
              user.signInUserSession &&
              user.signInUserSession.idToken &&
              user.signInUserSession.idToken.jwtToken) ||
            "";
          setIdTokenVal(idToken);
          let refreshToken =
            (user &&
              user.signInUserSession &&
              user.signInUserSession.refreshToken &&
              user.signInUserSession.refreshToken.token) ||
            "";
          setRefreshTokenVal(refreshToken);
          webApi
            .get("/clients/" + email)
            .then((res) => {
              setInterval(refreshTokens, 60 * 60 * 1000 + 80);

              if (res.data.errorCode) {
                swal("Failure", res.data.errorMessage, "error");
              } else {
                setUserInfo(res.data);
                setClientIdVal(res.data.ID);
                setCompanyIdVal(res.data.companyID);
                getUserProfilePhoto(res.data.image);
                if (res.data.isAddedInCognitoUserGroup === false) {
                  //adding user to usergroup API
                  let data = {
                    userPoolId: config.cognito.USER_POOL_ID,
                    groupName: res.data.userRole.split(" ").join(""),
                    userName: user.username,
                  };
                  //console.log(data)
                  webApi
                    .post("/cognito/user", data)
                    .then((res2) => {
                      if (res2.data.errorCode !== 200) {
                        console.log("addtogrp", res2.data.errorMessage);
                      } else {
                        console.log("Added to Group");
                        //updating backend isAddedInCognitoUserGroup value api
                        let data = {
                          ID: res.ID,
                          CreatedAt: res.data.CreatedAt,
                          UpdatedAt: res.data.UpdatedAt,
                          DeletedAt: res.data.DeletedAt,
                          email: res.data.email,
                          firstName: res.data.firstName,
                          lastName: res.data.lastName,
                          companyName: res.data.companyName,
                          phoneNumber: res.data.phoneNumber,
                          taxId: res.data.taxid,
                          userRole: res.data.userRole,
                          businessAddress: {
                            ID: res.data.businessAddress.ID,
                            CreatedAt: res.data.businessAddress.CreatedAt,
                            UpdatedAt: res.data.businessAddress.UpdatedAt,
                            DeletedAt: res.data.businessAddress.DeletedAt,
                            address1: res.data.businessAddress.address1,
                            address2: res.data.businessAddress.address2,
                            city: res.data.businessAddress.city,
                            state: res.data.businessAddress.state,
                            country: res.data.businessAddress.country,
                            zipCode: res.data.businessAddress.zipCode,
                            ClientId: res.data.businessAddress.ClientId,
                          },
                          surveyStatus: res.data.surveyStatus,
                          clientID: res.data.clientID,
                          isBlock: res.data.isBlock,
                          companyID: res.data.companyID,
                          isAddedInCognitoUserGroup: true,
                        };
                        webApi
                          .put("/clients/" + res.data.ID, data)
                          .then((res) => {
                            if (res.data.errorCode) {
                              console.log(
                                "updatebacknd",
                                res.data.errorMessage
                              );
                              swal("Error", res.data.errorMessage, "error");
                            } else {
                              console.log("updatebacknd success");
                            }
                          })
                          .catch((error) => {
                            if (error.customErrorMessage) {
                              swal("Error", error.customErrorMessage, "error");
                            } else {
                              swal(
                                "Error",
                                `Oops! Failed to Login, Please try again later.`,
                                "error"
                              );
                            }
                          });
                      }
                    })
                    .catch((error) => {
                      if (error.customErrorMessage) {
                        swal("Error", error.customErrorMessage, "error");
                      } else {
                        swal(
                          "Error",
                          `Oops! Failed to Login, Please try again later.`,
                          "error"
                        );
                      }
                    });
                }
                if (user.attributes.email_verified === false) {
                  console.log("email not verified");
                  //updateUserEmail(email)
                  setFromLogin(true);
                  sendVerificationCode();
                } else {
                  navigate("/dashboard"); //Checking Survey Condition in dashboard

                  // if (res.data.surveyStatus === false) {
                  //   navigate("/survey");
                  // } else {
                  //   navigate("/dashboard");
                  // }
                  // setApiProcessing({
                  //   loader: false,
                  //   message: "Loading...",
                  // });
                }
              }
            })
            .catch((error) => {
              if (error.customErrorMessage) {
                swal("Error", error.customErrorMessage, "error");
              } else {
                swal(
                  "Error",
                  `Oops! Failed to Login, Please try again later.`,
                  "error"
                );
              }
            });
          //
          setApiProcessing({
            loader: false,
            message: "Loading...",
          });
        })
        .catch((e) => {
          console.log("e", e);
          setApiProcessing({
            loader: false,
            message: ``,
          });
          if (e.code === "UserNotConfirmedException") {
            setConfirmUser(true);
            return;
          }
          swal("Error", e.message, "error");
        });
    }
  };

  const getUserProfilePhoto = (filePath) => {
    const data = {
      objectKey: `${filePath}`,
    };
    webApi
      .post(`/documents/download`, data)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setProfileImage(res.data.objectKey);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else
          swal(
            "Error",
            "Oops, Unable to get the Profile Image! Please try again later.",
            "error"
          );
      });
  };

  // //Function to update the user's email
  // async function updateUserEmail(newEmail) {
  //   console.log(newEmail)
  //   try {
  //     const user = await Auth.currentAuthenticatedUser();
  //     const attributes = {
  //       email: newEmail
  //     };
  //     await Auth.updateUserAttributes(user, attributes);
  //     console.log('Verification Code sent successfully');
  //     setOpenEmailCodeForm(true)
  //     //updateProfile()
  //   } catch (error) {
  //     swal('Error updating email', error.message);
  //   }
  // };

  // Function to send a verification code to the user's email
  const sendVerificationCode = async () => {
    try {
      await Auth.verifyCurrentUserAttribute("email");
      console.log("Verification code sent successfully");
      setOpenEmailCodeForm(true);
    } catch (error) {
      console.log("Error sending verification code:", error);
      swal(
        "Error",
        "Failed to send verification code. Please try again later.",
        "error"
      );
    }
  };

  const refreshTokens = () => {
    console.log("came to refreshingTokens");
    Auth.currentSession(refreshTokenVal)
      .then((user) => {
        //console.log("newSession", user);
        let cognitoAccessToken =
          (user && user.accessToken && user.accessToken.jwtToken) || "";
        //console.log("new access Token val", cognitoAccessToken);
        setAccessTokenVal(cognitoAccessToken);
        let idToken = (user && user.idToken && user.idToken.jwtToken) || "";
        setIdTokenVal(idToken);
        let refreshToken =
          (user && user.refreshToken && user.refreshToken.token) || "";
        setRefreshTokenVal(refreshToken);
      })
      .catch((e) => {
        console.log("newSession", e);
        console.log("newSession", e.message);
        swal(
          "Error",
          ` Apologies! An error occurred. Please log in again to continue.`,
          "error"
        );
      });
  };

  const handlesubmitVerificationCode = async (event) => {
    event.preventDefault();
    await Auth.confirmSignUp(email, code)
      .then((user) => {
        console.log("user", user);
        swal("Success", "Verification Successful", "success");
        setConfirmUser(false);
      })
      .catch((e) => {
        console.log("e", e.message);
        swal("Error", e.message, "error");
      });
  };

  const navigateDashboard = () => {
    navigate("/dashboard");
  };

  const resendCode = () => {
    Auth.resendSignUp(email)
      .then((user) => {
        console.log("user", user);
        swal("Success", "Verification code sent Successful", "success");
        //navigate("/login");
      })
      .catch((e) => {
        console.log("e", e);
        swal("Error", e.message, "error");
      });
  };

  return (
    <>
      <VerifyEmail
        trigger={openEmailCodeForm}
        setTrigger={setOpenEmailCodeForm}
        fromLogin={fromLogin}
        setFromLogin={setFromLogin}
        navigate={() => navigateDashboard()}
      />
      <Loader open={apiProcessing.loader} message={apiProcessing.message} />

      <div className="flex min-h-screen w-full">
        <section className="flex flex-col w-1/4  bg-blue-52 justify-center items-center">
          <img
            id="loginPageLogo"
            className="w-64 h-20 md:h-14 md:w-40"
            src="logo/NEWLOGO.png"
            alt="Yvooa logo"
          />
          <span className="text-white text-xl text-center">
            <p>Your Residential Solutions</p>
            <p>under one roof.</p>
          </span>
        </section>

        <div className="flex justify-center items-center max-w-md mx-auto w-3/4">
          {!confirmUser && (
            <div>
              <h2
                id="loginPageTitle"
                className="text-center font-bold text-gray-700 underline text-2xl"
              >
                YVOOA LOGIN
              </h2>

              <div className="mt-8">
                <form
                  onSubmit={handlesubmit}
                  className="bg-white px-8 pt-6 pb-8 mb-4"
                >
                  <InputField
                    label="Email"
                    name="email"
                    id="email"
                    data-cy="email-field"
                    type="text"
                    placeholder="Enter Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    errorText={formErrors.email}
                    style="!mb-4"
                  />

                  <InputPasswordField
                    label="Password"
                    name="password"
                    id="password"
                    data-cy="pw-field"
                    placeholder="Enter Password"
                    value={pass}
                    onChange={(e) => setPass(e.target.value)}
                    errorText={formErrors.pass}
                    style="mt-4"
                  />

                  <div className="flex items-center justify-center mt-12">
                    <button
                      id="loginButton"
                      data-cy="login-btn"
                      className="bg-blue-52 w-full h-10 text-white font-semibold border border-gray-400 rounded shadow"
                      name="loginButton"
                    >
                      LOGIN
                    </button>
                  </div>

                  <Link
                    to="/ForgotPassword"
                    data-cy="forgotPw-link"
                    className="text-blue-500 text-md float-right hover:underline mt-1"
                    id="forgotPassword"
                    name="forhotPassword"
                  >
                    Forgot Password?
                  </Link>
                </form>

                <p className="mt-6 text-sm text-center text-gray-400">
                  Don&#x27;t have an account yet?{" "}
                  <u
                    onClick={(e) => navigate("/SignUP")}
                    className="text-blue-500 cursor-pointer"
                    id="signupHere"
                    data-cy="signUpHere-link"
                    name="signupHere"
                  >
                    SIGN-UP HERE
                  </u>
                </p>
              </div>
              <div
                id="customerSupport"
                className="text-center text-black text-sm pt-12"
              >
                Questions? Contact Customer Support at{" "}
                <p
                  id="customerSupportEmail"
                  data-cy="supportEmail-link"
                  className=" text-blue-52"
                >
                  support@rapidviewtech.com
                </p>
              </div>
              <Footer />
            </div>
          )}

          {confirmUser && (
            <div className="flex flex-col justify-center items-center">
              <BsFillShieldLockFill className="w-8 h-8 text-blue-52" />
              <h2 className="text-2xl font-semibold text-center text-gray-700">
                ENTER CODE
              </h2>
              <p className="font-extralight">
                Enter the code sent to your email.
              </p>

              <form
                onSubmit={handlesubmitVerificationCode}
                className="bg-white mt-6 px-8 pt-6 pb-8 mb-4"
              >
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="code"
                  >
                    Verification Code
                  </label>
                  <input
                    className="shadow border rounded w-96 py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                    name="code"
                    id="code"
                    type="text"
                    placeholder="Verification Code"
                    defaultValue={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                  <div
                    className="text-blue-500 text-right mt-1 text-sm focus:outline-none focus:underline hover:underline cursor-pointer"
                    onClick={() => resendCode()}
                  >
                    Resend code?
                  </div>
                </div>

                <div className="flex items-center justify-center">
                  <button className="bg-blue-52 w-96 h-10 text-white font-semibold border border-gray-400 rounded shadow">
                    Submit
                  </button>
                </div>
              </form>
              <Footer />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Login;
