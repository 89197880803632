import React, { useEffect, useState } from "react";

import { WebApimanager } from "../../WebApiManager";
import { ButtonCustom, ButtonIconSquare } from "../../Widgets/Buttons";
import { TableComponent } from "../../Widgets/CommonWidgets";
import { ButtonGrayOutline, ButtonBlue } from "../../Widgets/Buttons";

import swal from "sweetalert";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogContent,
} from "@mui/material";
import {
  AiOutlineCloseCircle,
  AiOutlineMinusCircle,
  AiOutlinePlusCircle,
} from "react-icons/ai";
import InputField, {
  InputDollarsField,
  InputSelectField,
  InputTextArea,
} from "../../Widgets/Forms/InputFields";
import { IoIosArrowDown } from "react-icons/io";

const AddRentalCashTransactions = (props) => {
  let webApi = new WebApimanager();

  const [allPendingCharges, setAllPendingCharges] = useState([]);
  const [selectedChargeIds, setSelectedChargeIds] = useState([]);
  const [totalCharge, setTotalCharge] = useState(0);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [formErrors, setFormErrors] = useState("");

  const [paymentDetails, setPaymentDetails] = useState([
    {
      paymentMethod: "",
      amount: "",
      note: "",
    },
  ]);
  const [payMethodErrors, setPayMethodErrors] = useState([]);
  const [totalPayment, setTotalPayment] = useState(0);

  useEffect(() => {
    getPendingCharges();
    getPaymentsMethods();
  }, []);

  useEffect(() => {
    calculateTotalCharge();
  }, [paymentDetails]);

  const addNewPayment = () => {
    setPaymentDetails([
      ...paymentDetails,
      { paymentMethod: "", amount: "", note: "" },
    ]);
    setPayMethodErrors([
      ...payMethodErrors,
      { paymentMethod: "", amount: "", note: "" },
    ]);
  };

  const deletePayment = (index) => {
    setPaymentDetails((prevDetails) =>
      prevDetails.filter((_, i) => i !== index)
    );
  };

  const handlePayDetailsChange = (index, field, value) => {
    const updatedDetails = paymentDetails.map((detail, idx) =>
      idx === index ? { ...detail, [field]: value } : detail
    );
    setPaymentDetails(updatedDetails);
  };

  const calculateTotalCharge = () => {
    const total = paymentDetails.reduce(
      (sum, detail) => sum + (parseInt(detail.amount) || 0),
      0
    );
    setTotalPayment(total);
  };

  const getPendingCharges = () => {
    webApi
      .get(`/ledger/rentalUnpaid/tenant/${props.tennatCode}`)
      .then((res) => {
        if (res.data.errorCode) {
          swal("", res.data.errorMessage, "error");
        } else {
          setAllPendingCharges(res.data.ledgers);
        }
      })
      .catch((error) => {
        swal(
          "",
          `Oops!, Unable to load Pending Charges! Please try again later.`,
          "error"
        );
      });
  };

  const getPaymentsMethods = () => {
    webApi
      .get(`/ledger/paymentMethods`)
      .then((res) => {
        if (res.data.errorCode) {
          swal("", res.data.errorMessage, "error");
        } else {
          setPaymentMethods(res.data);
        }
      })
      .catch((error) => {
        swal(
          "",
          `Unable to load Payment Methods! Please try again later.`,
          "error"
        );
      });
  };

  const validate = () => {
    const formErrors = {};
    const payMethodErrors = paymentDetails.map(() => ({
      paymentMethod: "",
      amount: "",
      note: "",
    }));

    // Validate selectedChargeIds
    if (!selectedChargeIds.length) {
      formErrors.selectedChargeIds =
        "Please Select One or More Pending Charges!";
    }

    if (totalPayment > totalCharge) {
      formErrors.totalPayment =
        "Total payment does not exceed the total charge.";
    }

    // Validate each payment detail
    paymentDetails.forEach((detail, index) => {
      if (!detail.paymentMethod) {
        payMethodErrors[index].paymentMethod = "Please select a payment method";
      }
      if (
        !detail.amount ||
        isNaN(detail.amount) ||
        Number(detail.amount) <= 0
      ) {
        payMethodErrors[index].amount = "Please enter a valid amount";
      }
    });

    setFormErrors(formErrors);
    setPayMethodErrors(payMethodErrors);

    return { formErrors, payMethodErrors };
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { formErrors, payMethodErrors } = validate();

    // Check if there are no form errors or payment detail errors
    const isValidForm = !Object.keys(formErrors).length;
    const isValidPaymentDetails = !payMethodErrors.some(
      (error) => error.paymentMethod || error.amount
    );

    if (isValidForm && isValidPaymentDetails) {
      let requestData = {
        TenantCode: props.tennatCode,
        UnitId: props.unitID,
        chargeIds: selectedChargeIds,
        offlineFeeCharges: paymentDetails,
      };

      webApi
        .post("/ledger/payment", requestData)
        .then((res) => {
          if (res.data.errorCode) {
            swal("", res.data.errorMessage, "error");
          } else {
            props.getAllRentalTransactions(res.data);
            swal("", "Payment Successfully", "success");
            props.setTrigger(false);
          }
        })
        .catch((error) => {
          swal(
            "",
            `Oops! Failed to Add Payment, Please try again later.`,
            "error"
          );
        });
    }
  };

  const handleCheckboxChange = (isChecked, row) => {
    if (isChecked) {
      setSelectedChargeIds([...selectedChargeIds, row.ID]);
      setTotalCharge(totalCharge + row.pendingAmount);
    } else {
      setSelectedChargeIds(selectedChargeIds.filter((item) => item !== row.ID));
      setTotalCharge(totalCharge - row.pendingAmount);
    }
  };

  return (
    props.trigger && (
      <>
        <Dialog
          open={true}
          aria-labelledby="Add Payment"
          fullWidth
          maxWidth="md"
          className="flex flex-col justify-center w-5/6 mx-auto"
        >
          <div className="flex justify-center items-center bg-blue-52 text-white font-semibold rounded-sm h-10 pr-2">
            <span id="addPaymentHeader" className="flex-grow text-center">
              Add Payment
            </span>
            <ButtonIconSquare
              id="closeTransactionForm"
              dataCy="closeTransactionForm"
              title="Close Transaction Form"
              onClick={(e) => props.setTrigger(false)}
              icon={<AiOutlineCloseCircle className="w-5 h-5" />}
              className="hover:!text-red-600"
            />
          </div>

          <DialogContent dividers>
            <section className="flex flex-col justify-center items-center w-5/6 m-auto pt-6">
              <span className="text-red-10">
                {formErrors.selectedChargeIds}
              </span>
              <TableComponent
                tableId="Files table"
                data={allPendingCharges}
                const
                columns={[
                  {
                    key: "icon",
                    title: "",
                    applyMethod: (row) => (
                      <input
                        title="Select/UnSelect"
                        className="cursor-pointer w-5 h-5"
                        name={`Charges`}
                        type="checkbox"
                        //disabled={disableCheckbox}
                        checked={selectedChargeIds.includes(row.ID)}
                        onChange={(e) =>
                          handleCheckboxChange(e.target.checked, row)
                        }
                      />
                    ),
                    width: "80px",
                  },

                  {
                    key: "description",
                    title: "Payment Due For",
                    width: "60%",
                  },
                  {
                    key: "pendingAmount",
                    title: "Charge",
                    applyMethod: (row) => {
                      return "$" + row.pendingAmount;
                    },
                    //width: "20%"
                  },
                ]}
                rowOnClick={(row) => {}}
              />
              <table className="w-full mb-8">
                <tr className="bg-blue-28 text-gray-800 h-10 font-semibold">
                  <th className="w-[80px]"></th>
                  <th
                    id="TotalPendingAmountTitle"
                    style={{
                      minWidth: "150px",
                    }}
                    className="pl-4 text-right w-[60%]"
                  >
                    TOTAL
                  </th>
                  <th
                    id="TotalPendingAmount"
                    style={{
                      minWidth: "150px",
                    }}
                    className="text-left pl-4"
                  >
                    ${totalCharge}
                  </th>
                </tr>
              </table>

              <span
                id="subDetailsTitleText"
                className="font-semibold border-b-2 border-blue-52 pb-1 mb-4 text-blue-52 w-full mt-4"
              >
                Payment Details
              </span>

              {/* Scrollable Payment Details Section */}
              <section className="overflow-y-scroll max-h-96 mb-4 pr-2">
                {paymentDetails.map((detail, index) => (
                  <React.Fragment key={index}>
                    <div className="grid grid-cols-2 gap-x-16 w-full mb-4 gap-y-2">
                      <InputDollarsField
                        label={`Amount ${index + 1}`}
                        name="amount"
                        id={`amount-${index + 1}`}
                        dataCy="amount"
                        placeholder="Enter Amount"
                        value={detail.amount}
                        setValue={(value) =>
                          handlePayDetailsChange(index, "amount", value)
                        }
                        errorText={payMethodErrors[index]?.amount}
                        style="!w-full"
                      />

                      <InputSelectField
                        label={`Payment Method ${index + 1}`}
                        name="paymentMethod"
                        id={`paymentMethod-${index + 1}`}
                        dataCy="paymentMethod"
                        placeholder="Choose a Payment Method"
                        value={detail.paymentMethod}
                        onChange={(e) =>
                          handlePayDetailsChange(
                            index,
                            "paymentMethod",
                            e.target.value
                          )
                        }
                        options={paymentMethods}
                        errorText={payMethodErrors[index]?.paymentMethod}
                        style={`!w-full`}
                      />

                      <InputField
                        label={`Reference Note ${index + 1}`}
                        name="referenceNote"
                        id={`referenceNote-${index + 1}`}
                        dataCy="referenceNote"
                        type="text"
                        notImp
                        placeholder="Enter Note"
                        value={detail.note}
                        onChange={(e) =>
                          handlePayDetailsChange(index, "note", e.target.value)
                        }
                        errorText={payMethodErrors[index]?.note}
                        style="!w-full col-span-2"
                      />
                    </div>

                    <div className="flex justify-center">
                      {index > 0 && (
                        <ButtonCustom
                          id={`remove-${index}`}
                          btnName="removePayment"
                          dataCy="removePayment"
                          name={
                            <span
                              id="removePaymentTitle"
                              className="flex items-center gap-1"
                            >
                              <AiOutlineMinusCircle id="removePaymentIcon" />
                              Delete Payment {index}
                            </span>
                          }
                          onClick={() => deletePayment(index)}
                          className="!w-fit h-10 px-2 mb-2 text-red-600 font-medium border border-red-600 rounded shadow focus:shadow-lg hover:shadow-lg hover:text-white focus:text-white hover:bg-red-600 focus:bg-red-600"
                        />
                      )}
                    </div>
                  </React.Fragment>
                ))}

                <div className="flex justify-center items-center mt-6">
                  <ButtonCustom
                    id="addNewPayment"
                    btnName="addNewPayment"
                    dataCy="addNewPayment"
                    name={
                      <span
                        id="addNewPaymentTitle"
                        className="flex items-center gap-1"
                      >
                        <AiOutlinePlusCircle id="addNewPaymentIcon" /> Add New
                        Payment
                      </span>
                    }
                    onClick={addNewPayment}
                    className="!w-fit h-10 px-2 text-blue-53 font-medium border border-blue-53 rounded shadow focus:shadow-lg hover:shadow-lg hover:text-white focus:text-white hover:bg-blue-53 focus:bg-blue-53"
                  />
                </div>
              </section>

              {/* Static Total Amount Section */}
              <table className="w-full">
                <tr className="bg-blue-28 text-gray-800 h-10 font-semibold mt-2">
                  <th className="w-[80px]"></th>
                  <th
                    id="TotalPendingAmountTitle"
                    style={{
                      minWidth: "150px",
                    }}
                    className="pl-4 text-right w-[60%]"
                  >
                    TOTAL
                  </th>
                  <th
                    id="TotalPendingAmount"
                    style={{
                      minWidth: "150px",
                    }}
                    className="text-left pl-4"
                  >
                    ${totalPayment}
                  </th>
                </tr>
              </table>
              <span className="text-red-10">{formErrors.totalPayment}</span>
            </section>
            <div className="flex justify-center gap-16 mt-6 mb-4">
              <ButtonGrayOutline
                id="cancel"
                btnName="cancel"
                dataCy="cancel"
                name="Cancel"
                onClick={(e) => props.setTrigger(false)}
              />
              <ButtonBlue
                id="confirm"
                btnName="confirm"
                dataCy="confirm"
                name="Confirm"
                onClick={handleSubmit}
              />
            </div>
          </DialogContent>
        </Dialog>
      </>
    )
  );
};

export default AddRentalCashTransactions;
