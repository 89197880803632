import React, { useEffect, useState } from "react";
import { Dialog, DialogContent } from "@mui/material";
import swal from "sweetalert";
import { WebApimanager } from "../../WebApiManager";
import { ButtonBlue, ButtonGrayOutline } from "../../Widgets/Buttons";
import { Gallery, clickEffect } from "../../Widgets/CommonWidgets";
import { PDFViewer } from "../Documents/documents";
import { Loader } from "../../Widgets/notificationFeedbacks";
import { ButtonIconSquare } from "../../Widgets/Buttons";
import { AiOutlineCloseCircle } from "react-icons/ai";

const FloorPlanDetails = (props) => {
  let webApi = new WebApimanager();
  const [floorPlanData, setFloorPlanData] = useState("");
  const [currentLayout, setCurrentLayout] = useState([]);
  //const [objectKey, setObjectKey] = useState("");
  const [openPdf, setOpenPdf] = useState(false);
  const [fileLink, setFileLink] = useState("");
  const [openGallery, setOpenGallery] = useState(false);
  const [itsPDF, setItsPDF] = useState(false);
  const [pdfName, setPdfName] = useState("");

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  useEffect(() => {
    setApiProcessing({
      loader: true,
      message: "Gathering Floor Plan Details...",
    });
    getFloorPlanDetails();
  }, []);

  const getFloorPlanDetails = () => {
    webApi
      .get("/unitTypes/" + props.floorPlanDetails.ID)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setApiProcessing({ loader: false, message: `` });
          setFloorPlanData(res.data);
          if (res.data.layouts.length > 0) {
            getFileLink(res.data.layouts[0]);
          }
        }
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Oops! Failed to get Floor Plan Details, Please try again later.",
            "error"
          );
        }
      });
  };

  const getFileLink = (fileName) => {
    setApiProcessing({
      loader: true,
      message: `Gathering File...`,
    });
    const data = {
      objectKey: `${fileName}`,
    };
    webApi
      .post(`/documents/download`, data)
      .then((res) => {
        if (res.data.errorCode) {
          setApiProcessing({ loader: false, message: `` });
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setFileLink(res.data.objectKey);
          if (
            res.data.metadata.filename.split(".").pop().toLowerCase() === "pdf"
          ) {
            setPdfName(res.data.metadata.filename);

            setCurrentLayout(
              "https://png.pngtree.com/png-vector/20190330/ourlarge/pngtree-pdf-file-document-icon-png-image_892814.jpg"
            );
            setItsPDF(true);
          } else {
            setCurrentLayout(res.data.objectKey);
          }
        }
        setApiProcessing({ loader: false, message: `` });
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else
          swal(
            "Error",
            "Oops, Unable to get the File! Please try again later.",
            "error"
          );
      });
  };

  const openPdfViewer = () => {
    if (itsPDF) {
      setOpenPdf(true);
    } else {
      setOpenGallery(true);
    }
  };

  return props.trigger ? (
    <>
      <Dialog
        open={true}
        aria-labelledby="Create FloorPlan Form"
        fullWidth
        maxWidth="lg"
        //PaperProps={{ sx: { height: "80%" } }}
        //scroll="body"
        className="flex flex-col justify-center w-4/5 mx-auto"
      >
        <div className="flex justify-center items-center bg-blue-52 text-white font-semibold rounded-sm h-10 pr-2">
          <span className="flex-grow text-center"> Floor Plan</span>
          <ButtonIconSquare
            title="Close"
            onClick={(e) => props.setTrigger(false)}
            icon={<AiOutlineCloseCircle className="w-5 h-5" />}
            className="hover:!text-red-600"
          />
        </div>

        {openGallery && (
          <Gallery
            images={[fileLink]}
            openGallery={openGallery}
            setOpenGallery={setOpenGallery}
          />
        )}

        {openPdf && (
          <PDFViewer pdfUrl={fileLink} onClose={(e) => setOpenPdf(false)} />
        )}

        <Loader open={apiProcessing.loader} message={apiProcessing.message} />

        <DialogContent dividers>
          <section className="grid grid-cols-2 justify-center items-center w-full">
            <div className="border bg-gray-50 cursor-pointer rounded-xl flex flex-col items-center justify-center w-full h-80 overflow-hidden">
              {floorPlanData && floorPlanData.layouts.length > 0 ? (
                <>
                  {itsPDF ? (
                    <>
                      <span className="flex flex-col justify-center items-center gap-4">
                        <img
                          src={currentLayout}
                          alt="PDF"
                          className="w-32 h-auto"
                          onClick={() => openPdfViewer()}
                        />
                        <p className="text-gray-600 text-center">
                          {pdfName || "No File Available"}
                        </p>
                        <button
                          className={
                            "hover:bg-blue-500 hover:text-white px-4 py-2 rounded-md mt-2 border border-blue-500 text-blue-500 " +
                            clickEffect
                          }
                          onClick={() => openPdfViewer()}
                        >
                          View PDF
                        </button>
                      </span>
                    </>
                  ) : (
                    <img
                      src={currentLayout}
                      alt="Layout"
                      className="w-full h-full"
                      onClick={() => openPdfViewer()}
                    />
                  )}
                </>
              ) : (
                <p className="text-gray-600 text-center">No data available!</p>
              )}
            </div>

            <div className="flex flex-col justify-center gap-4 mx-auto">
              <FloorPlanDetailsTile
                label="Floor Plan Name"
                data={floorPlanData.unitTypeName || "-"}
                dataClassName="font-semibold"
              />
              <FloorPlanDetailsTile
                label="Floor Plan Code"
                data={floorPlanData.unitCode || "-"}
                dataClassName="font-semibold"
              />
              {/* <FloorPlanDetailsTile
                label="No. Of Rooms"
                data={floorPlanData.numberOfRooms || "-"}
                dataClassName="font-semibold"
              /> */}
              <FloorPlanDetailsTile
                label="No. Of Beds"
                data={floorPlanData.numberOfBeds || "-"}
                dataClassName="font-semibold"
              />
              <FloorPlanDetailsTile
                label="No. Of Bathrooms"
                data={floorPlanData.numberOfBaths || "-"}
                dataClassName="font-semibold"
              />
            </div>
          </section>

          <div className="flex justify-center items-center gap-16 mt-2 mb-2">
            <ButtonBlue
              name="OK"
              onClick={() => props.setTrigger(false)}
              className="mt-6"
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  ) : null;
};

export default FloorPlanDetails;

export const FloorPlanDetailsTile = (props) => {
  return (
    <>
      <div className={props.className + " grid grid-cols-2 items-center gap-8"}>
        <span className={props.labelClassName}>{props.label}</span>
        <span className={props.dataClassName}>{props.data}</span>
      </div>
    </>
  );
};
