import { useNavigate } from "react-router-dom";
import { ButtonCustom } from "../../Widgets/Buttons";
import { motion } from "framer-motion";
import { BsFillExclamationCircleFill } from "react-icons/bs";

import { userData } from "../../atoms";
import { useEffect } from "react";
import { GetProfileInfoByEmailAPI } from "../../CentralizedAPI/commonAPI";
import { useRecoilState } from "recoil";
import { useState } from "react";
import { ResubscribeAPI } from "./subscriptionAPI";

import swal from "sweetalert";

const SubscriptionFailed = (props) => {
  let navigate = useNavigate();
  const [userInfo, setUserInfo] = useRecoilState(userData);
  const [subscriptionExpired, setSubscriptionExpired] = useState(false);

  useEffect(() => {
    getUpdatedSubscriptionInfo();
  }, []);

  const getUpdatedSubscriptionInfo = () => {
    GetProfileInfoByEmailAPI(userInfo.email).then((res) => {
      setUserInfo(res.data);
      if (
        res.data.isSubscribed === true &&
        res.data?.subscriptionStatus !== "Cancelled" &&
        res.data.userRole === "Client Admin"
      ) {
        navigate("/subscription/Details");
      } else if (
        res.data.isSubscribed === false &&
        res.data?.subscriptionStatus === "Cancelled" &&
        res.data.userRole === "Client Admin"
      ) {
        setSubscriptionExpired(true);
      }
    });
  };

  const handleRetry = () => {
    navigate("/subscription");
  };

  const handleReSubscibe = () => {
    ResubscribeAPI({ clientId: userInfo.ID }).then((res) => {
      if (res.data.status === true) {
        swal("", res.data.message, "success");
        getUpdatedSubscriptionInfo();
      }
    });
  };

  return (
    <div className="bg-slate-200 h-screen">
      <section className="relative bg-blue-52 h-44 text-white overflow-hidden">
        <div className="absolute inset-0 w-full">
          <svg
            viewBox="0 0 1440 320"
            className="absolute w-full h-full"
            preserveAspectRatio="none"
          >
            <path
              fill="#E2E8F0"
              fillOpacity="1"
              d="M0,320L60,288C120,256,240,192,360,160C480,128,600,128,720,144C840,160,960,192,1080,218.7C1200,245,1320,256,1380,256L1440,256V320H0V320Z"
            ></path>
          </svg>
        </div>
        <div className="relative grid grid-cols-2 h-full items-center z-10">
          <div className="ml-8 -mt-8">
            <motion.img
              id="yvooaLogo"
              className="w-48 h-14"
              src="/logo/NEWLOGO.png"
              alt="Yvooa logo"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            />
          </div>
        </div>
      </section>

      <div className="flex bg-white w-fit h-64 px-4 py-6 rounded-xl mx-auto border-2">
        <div className="flex flex-col w-auto items-center justify-center">
          <BsFillExclamationCircleFill className="w-14 h-12 text-red-600 mb-4" />
          <p className="text-black font-semibold text-center mb-4">
            {subscriptionExpired
              ? "Your Subscription has Expired! All services have stopped. Please renew your subscription to continue using the service."
              : "You need to take a subscription to continue using the service. Please choose a plan and retry the subscription process."}
          </p>
          <ButtonCustom
            id="retrySubscriptionButton"
            name={subscriptionExpired ? "Resubscribe" : "Retry Subscription"}
            onClick={(e) => {
              if (subscriptionExpired === true) {
                handleReSubscibe();
              } else {
                handleRetry();
              }
            }}
            className="px-6 py-2 w-fit font-bold border-2 border-[#FF6522] text-gray-400 hover:bg-[#FF6522] hover:text-white focus:bg-[#FF6522] focus:text-white"
          />
        </div>
      </div>
    </div>
  );
};

export default SubscriptionFailed;
