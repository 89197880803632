import { forwardRef, useImperativeHandle, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { TiDelete } from "react-icons/ti";

export const SearchTableComponent = forwardRef((props, ref) => {
  const [searchString, setSearchString] = useState("");

  const handleSearch = (event) => {
    const searchString = event.target.value.toLowerCase();
    setSearchString(event.target.value);

    if (searchString === "") {
      props.setTableData(props.data);
    } else {
      const filteredData = props.data.filter((item) => {
        return searchComplexMatchProps(item, props.columns, searchString);
      });
      props.setTableData(filteredData);
    }
  };

  const searchComplexMatchProps = (item, columns, searchString) => {
    for (const column of columns) {
      // Check the main column
      const cellValue = getColumnValue(item, column);
      if (
        cellValue &&
        cellValue.toString().toLowerCase().includes(searchString)
      ) {
        return true;
      }

      // Check subColumns, if any
      if (column.subColumns) {
        for (const subColumn of column.subColumns) {
          const subCellValue = getColumnValue(item, subColumn);
          if (
            subCellValue &&
            subCellValue.toString().toLowerCase().includes(searchString)
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const getColumnValue = (row, column) => {
    if (column.applyMethod) {
      return column.applyMethod(row);
    }

    // If the key refers to nested properties, resolve them
    const keys = column.key.split(".");
    return keys.reduce((value, key) => (value ? value[key] : undefined), row);
  };

  const handleClear = () => {
    setSearchString("");
    props.setTableData(props.data);
  };

  // Expose the handleClear method to the parent component
  useImperativeHandle(ref, () => ({
    clearSearch: handleClear,
  }));

  return (
    <div
      className={`flex justify-end items-center w-auto max-w-sm relative ${props.style}`}
    >
      <input
        id={props.id || "searchBar"}
        className={`${props.className} border shadow rounded-lg w-full focus:outline-none pr-[30px] py-2 px-3`}
        placeholder={props.placeholder}
        value={searchString}
        onChange={handleSearch}
      />
      {searchString === "" ? (
        <AiOutlineSearch
          id={`${props.id}SearchIcon`}
          size={25}
          className="cursor-pointer absolute mr-[4px] text-blue-52"
        />
      ) : (
        <TiDelete
          id={`${props.id}ClearIcon`}
          size={25}
          className="cursor-pointer absolute mr-[4px] text-red-500"
          onClick={handleClear}
        />
      )}
    </div>
  );
});
