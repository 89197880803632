import { WebApimanager } from "../../WebApiManager";

import swal from "sweetalert";

let webApi = new WebApimanager();

// API to Get All Associated Users to Unit
export const GetUnitAssociatedUsers = (unitId) => {
  return webApi
    .get(`/users/unit/${unitId}`)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage); // Optional: You can throw an error to be caught later
      }
      return res; // Return the response on success
    })
    .catch((error) => {
      //   swal(
      //     "",
      //     "Oops! Subscriptions couldn't be loaded at the moment. Please try again later.",
      //     "error"
      //   );
      throw error; // Throw error so it can be handled in the calling function
    });
};

// API to Get All Associated Users to Unit
export const NotifyTaggedUserAPI = (requestData) => {
  return webApi
    .post(`/notification`, requestData)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage);
      }
      return res; // Return the response on success
    })
    .catch((error) => {
      //   swal(
      //     "",
      //     "Oops! Subscriptions couldn't be loaded at the moment. Please try again later.",
      //     "error"
      //   );
      throw error; // Throw error so it can be handled in the calling function
    });
};

// API to Get list of Buildings for the Property
export const GetBuildingsOfPropAPI = (propId) => {
  return webApi
    .get(`/buildingList/property/${propId}`)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage);
      }
      return res;
    })
    .catch((error) => {
      swal(
        "",
        "Oops! Buildings couldn't be loaded at the moment. Please try again later.",
        "error"
      );
      throw error; // Throw error so it can be handled in the calling function
    });
};
