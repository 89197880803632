import React, { useState, useEffect } from "react";
import { WebApimanager } from "../../WebApiManager";
import { Dialog, DialogContent } from "@mui/material";
import {
  ButtonBlue,
  ButtonGrayOutline,
  ButtonIconSquare,
} from "../../Widgets/Buttons";
import { AiOutlineCloseCircle } from "react-icons/ai";
import InputField, {
  InputTextArea,
  InputMultiSelectField,
  InputSelectField,
  InputObjectSelectField,
} from "../../Widgets/Forms/InputFields";
import { DetailsTile } from "../../Widgets/Forms/FormReviewTiles";
import swal from "sweetalert";
import { clientId } from "../../atoms";
import { useRecoilValue } from "recoil";

const CreateNotifications = (props) => {
  let webApi = new WebApimanager();
  const [formErrors, setFormErrors] = useState("");
  const clientIdVal = useRecoilValue(clientId);
  const [type, setType] = useState("");
  const [notifType, setNotifType] = useState("");
  const [notifMessage, setNotifMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [userGroupOptions, setUserGroupOptions] = useState([]);
  const [selectedUserGroup, setSelectedUserGroup] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedUserProfile, setSelectedUserProfile] = useState("");

  const optionsType = [
    { value: "Notification", label: "Notification" },
    { value: "Alert", label: "Alert" },
  ];
  const optionsNotifType = [
    { value: "Broadcast", label: "Broadcast" },
    { value: "UserGroup", label: "User Group" },
    { value: "SpecificUser", label: "Specific User" },
  ];

  useEffect(() => {
    getUserGroups();
    getUsersList();
  }, []);

  const getUserGroups = () => {
    webApi
      .get("/notification/fetch/userGroups")
      .then((res) => {
        if (res.data.errorCode) {
          console.log("Failure", res.data.errorMessage, "error");
        } else {
          setUserGroupOptions(res.data);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Unable to get User groups, Please try again later.`,
            "error"
          );
        }
      });
  };

  const getUsersList = () => {
    webApi
      .get("/notification/client/" + clientIdVal + "/fetch/allUsers")
      .then((res) => {
        if (res.data.errorCode) {
          console.log("Failure", res.data.errorMessage, "error");
        } else {
          setUsersList(res.data);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Unable to get Users list, Please try again later.`,
            "error"
          );
        }
      });
  };

  const handleTypeChange = (value) => {
    setType(value);
    // console.log(value);
  };

  const handleNotifTypeChange = (value) => {
    setNotifType(value);
    // console.log(value);
  };

  const validate = () => {
    const errors = {};
    if (!type) {
      errors.type = "Type is required!";
    }
    if (!notifType) {
      errors.notifType = "Notification Type is required!";
    }
    if (!subject) {
      errors.subject = "Subject Type is required!";
    }
    if (!notifMessage) {
      errors.message = "Message Type is required!";
    }
    if (notifType === "UserGroup" && selectedUserGroup.length === 0) {
      errors.userGroup = "Please select a User Group!";
    }
    if (
      notifType === "SpecificUser" &&
      !selectedUserId &&
      !selectedUserProfile
    ) {
      errors.speceficUser = "Please select a User!";
    }
    return errors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const temp = validate();
    setFormErrors(temp);
    if (Object.keys(temp).length) {
      return;
    } else {
      // if(notifType === "Broadcast"){
      let data = {
        clientId: clientIdVal,
        subject: subject,
        message: notifMessage,
        type: type,
        userGroups: selectedUserGroup,
        specificUserGroup: selectedUserProfile,
        specificUserID: parseInt(selectedUserId),
      };
      webApi
        .post("/notification/type/" + notifType + "/create", data)
        .then(async (res) => {
          if (res.data.errorCode) {
            swal("Failure", res.data.errorMessage, "error");
          } else {
            swal("Success", res.data.message, "success");
            props.setTrigger(false);
            props.getAllNotifs();
          }
        })
        .catch((error) => {
          if (error.customErrorMessage) {
            swal("Error", error.customErrorMessage, "error");
          } else {
            swal(
              "Error",
              `Oops! Failed to Create User ${data.firstName}, Please try again later.`,
              "error"
            );
          }
        });
      //}
    }
  };

  return props.trigger ? (
    <>
      <Dialog
        open={true}
        aria-labelledby="Create Notification Form"
        fullWidth
        maxWidth="lg"
        PaperProps={{ sx: { height: "70%" } }}
        //scroll="body"
        className="flex flex-col justify-center items-center"
      >
        <div className="flex justify-center items-center bg-blue-52 text-white font-semibold rounded-sm h-10 pr-2">
          <span className="flex-grow text-center">New Message</span>
          <ButtonIconSquare
            id="closeForm"
            btnname="closeForm"
            dataCy="closeForm"
            title="Close Create Notification Form"
            onClick={(e) => props.setTrigger(false)}
            icon={<AiOutlineCloseCircle className="w-5 h-5" />}
            className="hover:!text-red-600"
          />
        </div>

        <DialogContent dividers>
          <div className="flex flex-col gap-y-6">
            <div className="flex flex-row gap-32">
              <p className="w-20">
                Type<span className="text-red-600">*</span>
              </p>
              <div className="flex-row">
                <div className="flex place-content-center gap-1 sm:flex-col">
                  {optionsType.map((option) => (
                    <div
                      className="flex items-center mr-10 sm:mt-2"
                      key={option.value}
                    >
                      <input
                        id={`inline-radio1-${option.value}`}
                        data-cy={option.value}
                        type="radio"
                        value={option.value}
                        name="inline-radio-group1"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                        onChange={(e) => handleTypeChange(e.target.value)}
                      />
                      <label
                        htmlFor={`inline-radio1-${option.value}`}
                        className="ml-2 text-sm text-gray-900"
                      >
                        {option.label}
                      </label>
                    </div>
                  ))}
                </div>
                <div className="text-red-10 text-sm">
                  {formErrors.type || ""}
                </div>
              </div>
            </div>

            <div className="flex flex-row gap-32">
              <p className="w-20">
                Send To<span className="text-red-600">*</span>
              </p>
              <div className="flex-row w-96">
                <div className="flex place-content-center sm:flex-col">
                  {optionsNotifType.map((option) => (
                    <div
                      className="flex items-center w-full"
                      key={option.value}
                    >
                      <input
                        id={`inline-radio-${option.value}`}
                        type="radio"
                        data-cy={option.value}
                        value={option.value}
                        name="inline-radio-group2"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                        onChange={(e) => handleNotifTypeChange(e.target.value)}
                      />
                      <label
                        htmlFor={`inline-radio-${option.value}`}
                        className="ml-2 text-sm text-gray-900"
                      >
                        {option.label}
                      </label>
                    </div>
                  ))}
                </div>
                <div className="text-red-10 text-sm">
                  {formErrors.notifType || ""}
                </div>
              </div>
            </div>

            {notifType === "UserGroup" ? (
              <div className="w-64 ml-52">
                <InputMultiSelectField
                  label="User Group"
                  name="userGroup"
                  id="userGroup"
                  dataCy="userGroup"
                  placeholder="Select a User Group"
                  options={userGroupOptions}
                  value={selectedUserGroup}
                  setValue={setSelectedUserGroup}
                  errorText={formErrors.userGroup}
                  style="!w-12"
                />
              </div>
            ) : null}

            {notifType === "SpecificUser" ? (
              <div className="w-64 ml-52">
                <label
                  htmlFor="users"
                  className={"text-gray-700 w-full text-sm font-semibold"}
                >
                  Select a User
                  <span className="text-red-600">*</span>
                  <select
                    name="users"
                    id="users"
                    data-cy="users"
                    required
                    value={selectedUserId}
                    onChange={(e) => {
                      const selectedOption =
                        e.target.options[e.target.selectedIndex];
                      setSelectedUserId(e.target.value);
                      setSelectedUserProfile(
                        selectedOption.getAttribute("data-profile")
                      );
                      console.log(selectedUserId, selectedUserProfile);
                    }}
                    className={
                      " mt-1 p-2.5 w-full bg-gray-50 border rounded-md text-gray-900 text-sm focus:border-blue-52"
                    }
                  >
                    <option
                      value={
                        props.defaultPlaceholderValue
                          ? props.defaultPlaceholderValue
                          : ""
                      }
                      disabled
                    >
                      {"Select a User"}
                    </option>
                    {usersList && usersList.length > 0 ? (
                      <>
                        {usersList.map((option, index) => (
                          <option
                            key={index}
                            value={option.id}
                            data-cy={index}
                            data-profile={option.profile} // Store profile data as data attribute
                          >
                            {option.firstName +
                              " " +
                              option.lastName +
                              " " +
                              option.profile}
                          </option>
                        ))}
                      </>
                    ) : (
                      <option>No options</option>
                    )}
                  </select>
                  <span className="text-red-10 text-sm">
                    {formErrors.speceficUser}
                  </span>
                </label>
              </div>
            ) : null}

            <div className="flex flex-row gap-32">
              <p className="w-20">
                Subject<span className="text-red-600">*</span>
              </p>
              <InputField
                name="subject"
                id="subject"
                type="text"
                dataCy="subject"
                placeholder="Enter Subject here..."
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                //errorText={formErrors.firstName}
                style="!w-96 mb-4"
                notImp
                errorText={formErrors.subject}
              />
            </div>

            <div className="flex flex-row gap-32">
              <p className="w-20">
                Message<span className="text-red-600">*</span>
              </p>

              <InputTextArea
                name="notifMessage"
                id="notifMessage"
                type="text"
                dataCy="notifMessage"
                placeholder="Enter Message here..."
                value={notifMessage}
                onChange={(e) => setNotifMessage(e.target.value)}
                //errorText={formErrors.problem}
                style="!w-96"
                className="h-44 rounded-lg"
                notImp
                errorText={formErrors.message}
              />
            </div>
          </div>
          <div className="flex justify-center items-center gap-16 mt-4">
            <ButtonGrayOutline
              id="cancel"
              btnName="cancel"
              dataCy="cancel"
              name="Cancel"
              onClick={() => props.setTrigger(false)}
              className="mt-6 justify-self-end"
            />
            <ButtonBlue
              id="send"
              btnName="send"
              dataCy="send"
              name="Send"
              onClick={(e) => handleSubmit(e)}
              className="mt-6"
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  ) : (
    ""
  );
};

export default CreateNotifications;
