import React, { useEffect, useState } from "react";
import { ButtonIconSquare } from "../../Widgets/Buttons";
import { BsDownload } from "react-icons/bs";
import { DownloadWorkOrderReportPdfAPI } from "./reportsAPI";
import ExportCSV from "../../Widgets/ExportCSV";
import { GetDateInMMDDYYYY, TableComponent } from "../../Widgets/CommonWidgets";
import { SearchTableComponent } from "../../Widgets/Table/searchTableComponent";

export const WorkOrdersReport = (props) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(props.data || []);
  }, [props.data]);

  const downloadPDF = () => {
    let requestData = {
      vendorId: props.vendor.roleId,
    };

    DownloadWorkOrderReportPdfAPI(requestData).then((res) => {
      window.open(res.data.url, "_blank");
    });
  };

  return (
    <>
      <div className="flex justify-end items-center mb-4 mt-4 gap-4 mr-4">
        <ExportCSV
          data={tableData}
          keys={[
            { key: "woid", title: "Work Order Id" },
            {
              key: "unitDetails.unitNumber",
              title: "Unit Number",
            },
            {
              key: "status",
              title: "Status",
            },
            {
              key: "CreatedAt",
              title: "Call Date",
              applyMethod: (row) => GetDateInMMDDYYYY(row.CreatedAt),
            },
            {
              key: "vendor",
              title: "Vendor/Employee",
            },
            {
              key: "description",
              title: "Brief Description",
            },
          ]}
          fileName="Work Order Report.csv"
          className={"bg-white"}
        />

        <ButtonIconSquare
          id="Download"
          btnName="download"
          dataCy="download"
          title="Download PDF"
          onClick={downloadPDF}
          icon={<BsDownload className="w-5 h-5" />}
          className="hover:!bg-blue-50 hover:!text-white shadow-md bg-white"
        />

        <SearchTableComponent
          id="workOrderReportSearch"
          placeholder="Search..."
          data={props.data}
          setTableData={setTableData}
          columns={[
            { key: "woid", title: "Work Order Id", width: "150px" },
            {
              key: "unitDetails.unitNumber",
              title: "Unit Number",
            },
            {
              key: "status",
              title: "Status",
            },
            {
              key: "CreatedAt",
              title: "Call Date",
              applyMethod: (row) => GetDateInMMDDYYYY(row.CreatedAt),
            },
            {
              key: "vendor",
              title: "Vendor/Employee",
            },
            {
              key: "description",
              title: "Brief Description",
            },
          ]}
        />
      </div>

      <TableComponent
        tableId="workOrderTable"
        className="bg-white"
        data={tableData}
        columns={[
          { key: "woid", title: "Work Order Id", width: "150px" },
          {
            key: "unitDetails.unitNumber",
            title: "Unit Number",
            width: "150px",
          },
          {
            key: "status",
            title: "Status",
            dataClassName: "font-medium",
            getCellStyles: (row) => {
              return { color: row.statusColor };
            },
            width: "100px",
          },
          {
            key: "CreatedAt",
            title: "Call Date",
            applyMethod: (row) => GetDateInMMDDYYYY(row.CreatedAt),
            width: "100px",
          },
          {
            key: "vendor",
            title: "Vendor/Employee",
            width: "200px",
          },
          {
            key: "description",
            title: "Brief Description",
            width: "260px",
          },
        ]}
        rowOnClick={() => {}}
        initialSort={{ key: "CreatedAt", direction: "descending" }}
      />
    </>
  );
};
