import React, { useState } from "react";
import { WebApimanager } from "../../../WebApiManager";

//Reusable Components
import InputField, {
  InputDateField,
  InputSelectField,
  InputFileField,
} from "../../../Widgets/Forms/InputFields";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import {
  ButtonBlue,
  ButtonGrayOutline,
  ButtonIconSquare,
} from "../../../Widgets/Buttons";
import { DateFormatForAPI } from "../../../Widgets/CommonWidgets";

//External Imports
import swal from "sweetalert";
import { Dialog, DialogContent } from "@mui/material";
import { AiOutlineCloseCircle } from "react-icons/ai";

const CreateEviction = (props) => {
  const activityOptions = [
    "Eviction Filed",
    "Tenant Response",
    "Court Hearing",
    "Judgment",
    "Writ of Possession",
    "Sheriff Eviction",
  ];

  let webApi = new WebApimanager();

  //Input Variables
  const [courtFiledIn, setCourtFiledIn] = useState("");
  const [caseID, setCaseID] = useState("");
  const [dateFiled, setDateFiled] = useState("");
  const [activity, setActivity] = useState("Eviction Filed");
  const [activityUpdateDate, setActivityUpdateDate] = useState("");
  const [lastDaytoAnswer, setLastDayToAnswer] = useState("");
  const [description, setDescription] = useState("");
  const [document, setDocument] = useState("");

  const [formErrors, setFormErrors] = useState("");
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: ``,
  });

  const validate = () => {
    const errors = {};

    if (!courtFiledIn.trim()) {
      errors.courtFiledIn = "Court name is required!";
    }
    if (!caseID.trim()) {
      errors.caseID = "Case ID is required!";
    }
    if (!dateFiled) {
      errors.dateFiled = "Date Filed is required!";
    }
    if (!activity) {
      errors.activity = "Activity is required!";
    }
    if (!activityUpdateDate) {
      errors.activityUpdateDate = "Activity Update Date required!";
    }
    if (!lastDaytoAnswer) {
      errors.lastDaytoAnswer = "Last Day to Answer is required!";
    }

    return errors;
  };

  const handleConfirm = (e) => {
    e.preventDefault();
    const temp = validate();
    setFormErrors(temp);
    if (Object.keys(temp).length) {
      return;
    } else {
      handleCreateEviction();
    }
  };

  const handleCreateEviction = () => {
    setApiProcessing({
      loader: true,
      message: `Creating Eviction...`,
    });

    let data = {
      tenantCode: props.tenantCode,
      courtFiledIn: courtFiledIn,
      caseId: caseID,
      dateFiled: DateFormatForAPI(dateFiled),
      activity: activity,
      activityUpdateDate: DateFormatForAPI(activityUpdateDate),
      lastDateToAnswer: DateFormatForAPI(lastDaytoAnswer),
      description: description,
      documents: document ? [{ fileName: document.name }] : [],
    };

    webApi
      .post("/eviction", data)
      .then((res) => {
        if (res.data.errorCode) {
          setApiProcessing({ loader: false, message: `` });
          swal("Error", res.data.errorMessage, "error");
        } else {
          if (document) {
            handleDocUpload(res.data);
          } else {
            handleEvictionSuccess(res.data);
          }
        }
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: "" });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to Create ${data.activity} , Please try again later.`,
            "error"
          );
        }
      });
  };

  const handleDocUpload = (data) => {
    const metaData = {
      filename: document.name,
      mediatype: document.type,
    };
    if (data.documents.length) {
      setApiProcessing({
        loader: true,
        message: "Uploding Document...",
      });

      webApi
        .uploadFile(data.documents[0].prefix, metaData)
        .then((res) => {
          handleEvictionSuccess(data);
        })
        .catch((error) => {
          if (error.customErrorMessage) {
            swal("Error", error.customErrorMessage, "error");
          } else {
            setApiProcessing({ loader: false, message: "" });
            swal(
              "Error",
              `Failed to upload ${document.name}. Please try again later.`,
              "error"
            );
          }
          handleEvictionSuccess(data);
        });
    } else {
      handleEvictionSuccess(data);
    }
  };

  const handleEvictionSuccess = (data) => {
    swal("Success", `${activity} creation is successful!`, "success");
    props.handleEvictionCallback(data);
    props.setTrigger(false);
  };

  return (
    <>
      <Dialog
        open={true}
        aria-labelledby="Create Eviction Form"
        fullWidth
        maxWidth="lg"
        className="flex flex-col justify-center w-5/6 mx-auto"
      >
        <Loader open={apiProcessing.loader} message={apiProcessing.message} />

        <div className="flex justify-center items-center bg-blue-52 text-white font-semibold rounded-sm h-10 pr-2">
          <span className="flex-grow text-center">Create Eviction Notice</span>
          <ButtonIconSquare
            title="Close"
            onClick={(e) => props.setTrigger(false)}
            icon={<AiOutlineCloseCircle className="w-5 h-5" />}
            className="hover:!text-red-600"
          />
        </div>
        <DialogContent dividers>
          <section
            className={"flex flex-col justify-center items-center w-full my-4"}
          >
            <span className="border-b-2 border-blue-52 pb-1 mb-4 text-blue-52 w-5/6 md:gap-x-8 md:w-11/12">
              Eviction Details
            </span>
            <div className="grid grid-cols-2 items-start gap-x-16 gap-y-4 w-5/6 mb-4 md:gap-x-8 md:w-11/12">
              <InputField
                label="Tenant Name"
                name="tenantName"
                id="tenantName"
                dataCy="tenantName"
                notImp
                disabled={true}
                type="text"
                placeholder="Enter Tenant Name"
                value={props.tenantName || ""}
                onChange={(e) => {}}
                errorText={""}
                style="!w-full text-gray-400/100"
              />

              <InputField
                label="Property Address"
                name="propertyAddress"
                id="propertyAddress"
                dataCy="propertyAddress"
                notImp
                disabled={true}
                type="text"
                placeholder="Enter Property Address"
                value={props.propAddress}
                onChange={(e) => {}}
                errorText={""}
                style="!w-full !text-gray-400/100"
              />

              <InputField
                label="Court Filed In"
                name="courtFiledIn"
                id="courtFiledIn"
                dataCy="courtFiledIn"
                type="text"
                placeholder="Enter Court Filed In"
                value={courtFiledIn}
                onChange={(e) => setCourtFiledIn(e.target.value)}
                errorText={formErrors.courtFiledIn}
                style="!w-full"
              />

              <InputField
                label="Case ID"
                name="caseID"
                id="caseID"
                dataCy="caseID"
                type="text"
                placeholder="Enter Case ID"
                value={caseID}
                onChange={(e) => setCaseID(e.target.value)}
                errorText={formErrors.caseID}
                style="!w-full"
              />

              <InputDateField
                label="Date Filed"
                name="dateFiled"
                id="dateFiled"
                btnName="dateFiled"
                dataCy="dateFiled"
                value={dateFiled}
                onChange={(value) => setDateFiled(value)}
                style="!w-full"
                errorText={formErrors.dateFiled}
              />

              <InputSelectField
                label="Activity"
                name="activity"
                id="activityType"
                dataCy="activity"
                placeholder="Choose a Activity"
                value={activity}
                onChange={(e) => setActivity(e.target.value)}
                options={["Eviction Filed"]}
                errorText={formErrors.activity}
                style="!w-full"
              />

              <InputDateField
                label="Activity Update Date"
                name="activityUpdateDate"
                id="activityUpdateDate"
                btnName="activityUpdateDate"
                dataCy="activityUpdateDate"
                value={activityUpdateDate}
                onChange={(value) => setActivityUpdateDate(value)}
                style="!w-full"
                errorText={formErrors.activityUpdateDate}
              />

              <InputDateField
                label="Last Day to Answer"
                name="lastDayToAnswer"
                id="lastDayToAnswer"
                btnName="lastDayToAnswer"
                dataCy="lastDayToAnswer"
                value={lastDaytoAnswer}
                onChange={(value) => setLastDayToAnswer(value)}
                style="!w-full"
                errorText={formErrors.lastDaytoAnswer}
              />

              <InputField
                label="Description"
                name="description"
                id="description"
                dataCy="description"
                type="text"
                notImp
                placeholder="Enter Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                errorText={formErrors.description}
                style="!w-full"
              />

              <InputFileField
                label="Attach Document"
                name="document"
                id="document"
                dataCy="document"
                accept=".png, .jpg, .pdf"
                maxSizeinMB={10}
                notImp
                placeholder="Attach .pdf .png .jpg format only"
                value={document}
                setValue={setDocument}
                hintText="*File Size should be less than 10MB"
                style="!w-full"
                errorText={formErrors.document}
              />

              <ButtonGrayOutline
                name="Cancel"
                btnName="cancel"
                dataCy="cancel"
                onClick={() => props.setTrigger(false)}
                className="mt-6 justify-self-end"
              />
              <ButtonBlue
                name="Confirm"
                btnName="Confirm"
                dataCy="Confirm"
                onClick={(e) => handleConfirm(e)}
                className="mt-6"
              />
            </div>
          </section>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreateEviction;
