import { WebApimanager } from "../../WebApiManager";
import swal from "sweetalert";
let webApi = new WebApimanager();

// API to get offline paid rental ledger for tenant account
export const GetOfflinePaidPendingPaymentsAPI = (requestData) => {
  return webApi
    .post(`/ledger/unpaid/accounting/tenant`, requestData)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage);
      }
      return res;
    })
    .catch((error) => {
      swal(
        "",
        "Oops! Pending Payments couldn't be loaded at the moment. Please try again later.",
        "error"
      );
      throw error;
    });
};

// API to get Payment Method Options
export const GetPaymentMethodsAPI = (requestData) => {
  return webApi
    .get(`/ledger/paymentMethods`, requestData)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage);
      }
      return res;
    })
    .catch((error) => {
      //   swal(
      //     "",
      //     "Oops! Payment Methods couldn't be loaded at the moment. Please try again later.",
      //     "error"
      //   );
      throw error;
    });
};
