import React, { useState, useEffect, useRef } from "react";
import { portfolioTreeStore } from "../../atoms";
import { WebApimanager } from "../../WebApiManager";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import CreateUnit from "../CreateUnit";
import CreateFloorPlan from "../CreateFloorPlan";
import FloorPlanDetails from "../CreateFloorPlan/FloorPlanDetails";
import CreateMaintenanceRequest from "../MaintenanceRequest/CreateMaintenanceRequest";
import swal from "sweetalert";
import { GoogleChart } from "../../Widgets/charts";
import {
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import {
  BsThreeDotsVertical,
  BsFillArrowLeftCircleFill,
  BsPlusCircle,
} from "react-icons/bs";
import { TiEdit, TiDelete } from "react-icons/ti";
import {
  AiOutlinePlusSquare,
  AiOutlinePlus,
  AiOutlineSearch,
} from "react-icons/ai";
import { FiTool } from "react-icons/fi";
import CreateTenant from "../CreateTenant";
import {
  GetDateInMMDDYYYY,
  GetTimeYearToMin,
  TableComponent,
  Searchbar,
  Dropdown,
  clickEffect,
  Gallery,
} from "../../Widgets/CommonWidgets";
import {
  ButtonIconSquare,
  ButtonCustom,
  ButtonGrayOutline,
  ButtonYellowOutline,
  ButtonBlue,
  ButtonBlueOutline,
  ButtonIconCircle,
} from "../../Widgets/Buttons";
import AddUtility from "./propertyServices/addUtility";
import AddAmenities from "./propertyServices/addAmenities";
import CreateProperty from "./createProperty";
import { TbFileArrowRight } from "react-icons/tb";
import AddUtilityCharges from "./propertyServices/addUtilityCharges";
import {
  last12MonthsList,
  PhoneNumFormat,
} from "../../Widgets/Forms/FieldDependants";
import {
  TextReviewTile,
  AddressViewTile,
  DetailsTile,
  DetailsTileTwoCol,
} from "../../Widgets/Forms/FormReviewTiles";
import { BsDownload } from "react-icons/bs";
import { TbClipboardText, TbFileUpload } from "react-icons/tb";
import { IconInCircle } from "../../Widgets/Icons";
import { FcFolder } from "react-icons/fc";
import { CreateNewFolder, UploadNewFile } from "../Documents/documents";
import { ChartCard } from "../Dashboard";
import { PDFViewer } from "../Documents/documents";
import { Loader } from "../../Widgets/notificationFeedbacks";
import { PiDotsThreeCircleVerticalLight } from "react-icons/pi";
import ExportCSV from "../../Widgets/ExportCSV";
//Inspection
import AddInspection from "../Inspection/addInspection";
import InspectionDetails from "../Inspection/inspectionDetails";

//Multi-Property Status Change
import InputField, {
  InputSelectField,
  InputFileField,
  InputObjectSelectField,
  InputTextArea,
} from "../../Widgets/Forms/InputFields";
import { Dialog, DialogContent } from "@mui/material";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { MdDeleteForever } from "react-icons/md";
import {
  AddMultiFamilyToListingAPI,
  AddSingleFamilyToListingAPI,
  GenerateLinkForPropertyAPI,
  GetProspectsByPropertyIdAPI,
} from "./propertiesAPI";
import { InputSelectFieldMUI } from "../../Widgets/InputFieldsMUI";

import { LiaClipboardListSolid } from "react-icons/lia";
import { CiShare1 } from "react-icons/ci";
import { SearchTableComponent } from "../../Widgets/Table/searchTableComponent";

const PropertyDetails = (props) => {
  let webApi = new WebApimanager();
  let navigate = useNavigate();
  const searchBarRef = useRef(null);
  const [portfolioTree, setPortfolioTree] = useRecoilState(portfolioTreeStore);
  const [propertyInfo, setPropertyInfo] = useState("");
  const [isSingleFamily, setIsSingleFamily] = useState(
    portfolioTree.property.propertyType === "Single Family" ? true : false
  );
  const [createPropertypage, setcreatePropertypage] = useState(false);
  const [createTenantpage, setCreateTenantpage] = useState(false);
  const [createUnitpage, setCreateUnitpage] = useState(false);
  const [createFloorPlanPage, setCreateFloorPlanPage] = useState(false);
  const [viewFloorPlanDetails, setViewFloorPlanDetails] = useState();
  const [allFloorPlanDetails, setAllFloorPlanDetails] = useState("");
  const [units, setUnits] = useState([]);
  const [floorPlans, setFloorPlans] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [floorPlanTableData, setFloorPlanTableData] = useState([]);
  const [unitsTableData, setUnitsTableData] = useState([]);
  const [tenantsTableData, setTenantsTableData] = useState([]);
  const [maintenanceTickets, setMaintenanceTickets] = useState([]);
  const [maintenanceTicketsTableData, setMaintenanceTicketsTableData] =
    useState([]);

  const handleFileInput = useRef(null);
  const utilityInfoTabs = [
    { label: "UTILITIES", nickName: "utilities" },
    { label: "AMENITIES", nickName: "amenities" },
    //{ label: "PROMOTIONS", nickName: "promotions" },
  ];
  const [activeMenuItem, setActiveMenuItem] = useState("home");

  const tabs = [
    { label: "Home", nickName: "home" },
    { label: "Floor Plans", nickName: "floorPlans", type: "Multi-Family" },
    { label: "Units", nickName: "units", type: "Multi-Family" },
    { label: "Tenants", nickName: "tenants" },
    { label: "Maintenance", nickName: "maintenance" },
    { label: "Documents", nickName: "documents" },
    { label: "Utility Info", nickName: "utilityInfo" },
    { label: "Charges", nickName: "charges" },
    { label: "Inspection", nickName: "inspection", type: "Single Family" },
    { label: "Prospects", nickName: "prospects" },
    // { label: "Vendor", nickName: "vendor" },
    // { label: "Lease Renewal", nickName: "leaseRenewal" },
  ];

  const [openMaintenanceReqPage, setOpenMaintenanceReqPage] = useState(false);
  const [multiDeleteFloorData, setMultiDeleteFloorData] = useState([]);
  const [multiDeleteUnitData, setMultiDeleteUnitData] = useState([]);
  const [selectAllFloorPlans, setSelectAllFloorPlans] = useState(false);
  const [selectAllUnits, setSelectAllUnits] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [activeInnerMenuItem, setActiveInnerMenuItem] = useState("utilities");
  const [openUtilityForm, setOpenUtilityForm] = useState(false);
  const [openAmenitiesForm, setOpenAmenitiesForm] = useState(false);
  const [uitlitiesTableData, setUitlitiesTableData] = useState([]);
  const [amenitiesTableData, setAmenitiesTableData] = useState([]);

  const [openAddUtilityChargesForm, setOpenAddUtilityChargesForm] =
    useState(false);
  const [utilityBillTypes, setUtilityBillTypes] = useState([]);
  const [allUtilityBills, setAllUtilityBills] = useState([]);
  const [utilityBillsTableData, setUtilityBillsTableData] = useState([]);
  const [billFilter, setBillFilter] = useState("");
  const [vendors, setVendors] = useState([]);
  const [allVendors, setAllVendors] = useState([]);

  const [editableData, setEditableData] = useState("");
  const [formOperation, setFormOperation] = useState("");

  //Multi-Property Status Change
  const [openMFStatusChangeForm, setOpenMFStatusChangeForm] = useState(false);
  const [viewMFStatusChangeNotes, setViewMFStatusChangeNotes] = useState(false);

  //Inspection
  const [openInspectionForm, setOpenInspectionForm] = useState(false);
  const [inspectionTypes, setInspectionTypes] = useState([]);
  const [allInspectionRecords, setAllInspectionRecords] = useState([]);
  const [inspectionTableData, setInspectionTableData] = useState([]);
  const [inspectionFilterValue, setInspectionFilterValue] = useState("All");
  const [viewInspectionDetails, setViewInspectionDetails] = useState("");

  //Doucments
  const [currentPrefix, setCurrentPrefix] = useState("");
  const [allFolders, setAllFolders] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [documentsTableData, setDocumentsTableData] = useState([]);
  const [currentFolders, setCurrentFolders] = useState([]);
  const [docsSearchString, setDocsSearchString] = useState("");
  const [openFolderForm, setOpenFolderForm] = useState(false);
  const [openFileUploadForm, setOpenFileUploadForm] = useState(false);
  const [openPdf, setOpenPdf] = useState(false);
  const [openGallery, setOpenGallery] = useState(false);
  const [fileLink, setFileLink] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [OpenLeaseRenewalForm, setOpenLeaseRenewalForm] = useState("");
  const [leaseRenewalData, setLeaseRenewalData] = useState([]);
  const [bankDetails, setBankDetails] = useState("");
  const [isbankConnected, setIsBankConnected] = useState(false);

  //Prospects
  const [showAdvertise, setShowAdvertise] = useState(false);
  const [allProspects, setAllProspects] = useState([]);
  const [prospectsTableData, setProspectsTableData] = useState([]);
  const [selProsReportPeriod, setSelProsReportPeriod] = useState("All");

  //Home
  const [propertyRevenueMultiFamily, setPropertyRevenueMultiFamily] = useState(
    []
  );
  const [maintenanceOverview, setMaintenanceOverview] = useState([]);
  const [expiringLeases, setExpiringLeases] = useState([]);

  const [propertyRevenueSingleFamily, setPropertyRevenueSingleFamily] =
    useState([
      ["Annual Revenue", "Rent Collected", "Actual Rent"],
      ["Jan", 0, 0],
      ["Feb", 0, 0],
      ["Mar", 0, 0],
      ["Apr", 0, 0],
      ["May", 0, 0],
      ["Jun", 0, 0],
      ["Jul", 0, 0],
      ["Aug", 0, 0],
      ["Sep", 0, 0],
      ["Oct", 0, 0],
      ["Nov", 0, 0],
      ["Dec", 0, 0],
    ]);

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  const propertyRevenueMultiFamilyOptions = {
    //title: "Property Revenue",
    // titleTextStyle: {
    //   fontSize: 18,
    // },
    pieHole: 0.4,
    is3D: false,
    colors: ["#FF6522", "#00BDA5", "#FBB43B"],
    pieSliceText: "value",
    legend: {
      position: "bottom",
      alignment: "center",
      textStyle: { color: "black", fontSize: 11 },
    },
  };

  const maintenanceOverviewOptions = {
    //title: "Maintenance",
    // titleTextStyle: {
    //   fontSize: 18,
    // },
    pieHole: 0.4,
    is3D: false,
    colors: ["#FBB53C", "#D10D22", "#38B37E"],
    pieSliceText: "value",
    legend: {
      position: "bottom",
      alignment: "center",
      textStyle: { color: "black", fontSize: 11 },
    },
  };

  const expiringLeasesOptions = {
    //title: "Expiring Leases",
    // titleTextStyle: {
    //   fontSize: 18,
    // },
    pieHole: 0.4,
    //pieStartAngle: -90,
    is3D: false,
    colors: ["#D10D22", "#FF6522", "#3D72BF"],
    pieSliceText: "value",
    legend: {
      position: "bottom",
      alignment: "center",
      textStyle: { color: "black", fontSize: 12 },
    },
  };

  const propertyRevenueSingleFamilyOptions = {
    colors: ["#D96470", "#4049AC"],
    legend: {
      position: "right",
      alignment: "start",
    },
  };

  useEffect(() => {
    getPropertyData();
    getHomeWidgetsData(
      portfolioTree.property.ID,
      portfolioTree.property.propertyType
    );
    getAllUnits();
    getAllFloorPlans();
    getAllTenants();
    getAllMaintenanceTickets();
    getAllUtilities();
    getAllAmenities();
    getUtilityBillTypes();
    getAllUtilityBills();
    getVendorDetailsByProp();
    getDocumentsByPropertyId(portfolioTree.property.ID);
    //getActiveLeaseByUnit();

    //Inspection API's
    getAllInspectionRecords();
    getInspectionTypes();
  }, []);

  const handlePropertiesTabClick = (tabAccessor) => {
    if (tabAccessor === "prospects") {
      getProspects();
    }
    setActiveMenuItem(tabAccessor);
  };

  const getPropertyData = () => {
    webApi
      .get("/properties/" + portfolioTree.property.ID)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setPortfolioTree({
            ...portfolioTree,
            property: res.data,
          });
          setPropertyInfo(res.data);
          if (res.data.propertyType === "Single Family") {
            setIsSingleFamily(true);
          }
          if (
            res.data.propertyType === "Single Family" &&
            res.data.status !== "Occupied" &&
            res.data.status !== "Turnover"
          ) {
            setShowAdvertise(true);
          } else if (
            res.data.propertyType === "Multi-Family" &&
            res.data.status === "Active"
          ) {
            setShowAdvertise(true);
          }
          if (res.data.connectedAccountId === 0) {
            setIsBankConnected(true);
          } else {
            getConnectedBankAccountDetails(res.data.connectedAccountId);
          }
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops!, Unable to load Property Details! Please try again later.`,
            "error"
          );
        }
      });
  };

  const getConnectedBankAccountDetails = (id) => {
    webApi
      .get("/payment/stripe/connectAccount/" + id)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setBankDetails(res.data);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Oops! Failed to get Bank Details, Please try again later.",
            "error"
          );
        }
      });
  };

  const getHomeWidgetsData = (propertyId, propertyType) => {
    webApi
      .get(`/properties/${propertyId}/dashboard/${propertyType}`)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          if (propertyType === "Single Family") {
            const tempRev = res.data.revenue;

            const RevenueData = [
              ["Annual Revenue", "Rent Collected", "Actual Rent"],
              ...last12MonthsList.map((month) => {
                const collectedRent = tempRev[month]?.collectedRent ?? 0;
                const actualRent = tempRev[month]?.actualRent ?? 0;
                return [month, collectedRent, actualRent];
              }),
            ];

            setPropertyRevenueSingleFamily(RevenueData);
          } else {
            const tempPropertyRevenueMultiFamily = [
              ["Property Revenue", "Denomination"],
            ];
            for (const key in res.data.totalRevenue) {
              tempPropertyRevenueMultiFamily.push([
                key,
                res.data.totalRevenue[key],
              ]);
            }
            setPropertyRevenueMultiFamily(tempPropertyRevenueMultiFamily);

            const tempMaintenanceOverview = [
              ["Maintenance Status", "No. of WorkOrders"],
            ];
            for (const key in res.data.maintenanceOverview) {
              tempMaintenanceOverview.push([
                key,
                res.data.maintenanceOverview[key],
              ]);
            }
            setMaintenanceOverview(tempMaintenanceOverview);

            setExpiringLeases([
              ["Lease Period", "No. of Expiring Leases"],
              ["<30 Days", res.data.leaseOverview.zeroToThirty],
              ["30-60 Days", res.data.leaseOverview.thirtyToSixty],
              [">60 Days", res.data.leaseOverview.sixtyToNinety],
            ]);
          }
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to get Home Dashboard data, Please try again later.`,
            "error"
          );
        }
      });
  };

  const getAllFloorPlans = () => {
    webApi
      .get("/unitTypes/property/" + portfolioTree.property.ID)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setFloorPlans(res.data);
          setFloorPlanTableData(res.data);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops!, Unable to get Floor Plans! Please try again later.`,
            "error"
          );
        }
      });
  };

  const getAllUnits = () => {
    webApi
      .get("/units/property/" + portfolioTree.property.ID)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setUnits(res.data);
          setUnitsTableData(res.data);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops!, Unable to get Units! Please try again later.`,
            "error"
          );
        }
      });
  };

  const getAllTenants = () => {
    webApi
      .get("/tenant/property/" + portfolioTree.property.ID)
      .then((res) => {
        if (res.data !== null) {
          if (res.data.errorCode) {
            swal("Failure", res.data.errorMessage, "error");
          } else {
            setTenants(res.data);
            setTenantsTableData(res.data);
          }
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops!, Unable to get Tenants! Please try again later.`,
            "error"
          );
        }
      });
  };

  const getAllMaintenanceTickets = () => {
    webApi
      .get("/maintenance/tickets/property/" + portfolioTree.property.ID)
      .then((res) => {
        if (res.data !== null) {
          if (res.data.errorCode) {
            swal("Failure", res.data.errorMessage, "error");
          } else {
            setMaintenanceTickets(res.data.slice().reverse());
            setMaintenanceTicketsTableData(res.data.slice().reverse());
          }
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops!, Unable to get Maintenance Work Orders! Please try again later.`,
            "error"
          );
        }
      });
  };

  // const getActiveLeaseByUnit = () => {
  //   webApi
  //     .get("/lease/unit/" + units.ID)
  //     .then((res) => {
  //       setLeaseRenewalData(res.data);
  //     })
  //     .catch((error) => {
  //       if (error.customErrorMessage) {
  //         swal("Error", error.customErrorMessage, "error");
  //       } else {
  //         swal(
  //           "Error",
  //           `Oops! Unable to get Lease Renewal Data, Please try again later.`,
  //           "error"
  //         );
  //       }
  //     });
  // };

  const deleteFloorPlan = (floorPlan) => {
    swal({
      title: "Are you sure?",
      text: `You want to delete the Floor Plan ${floorPlan.unitTypeName}!`,
      icon: "warning",
      buttons: ["Cancel", "Yes, delete it!"],
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        webApi
          .delete("/unitTypes/" + floorPlan.ID)
          .then((res) => {
            if (res.data.errorCode !== 200) {
              swal("Failure", res.data.errorMessage, "error");
            } else {
              swal("Success", res.data.errorMessage, "success");
              getAllFloorPlans();
            }
          })
          .catch((error) => {
            if (error.customErrorMessage) {
              swal("Error", error.customErrorMessage, "error");
            } else {
              swal(
                "Error",
                `Failed to Delete the Floor Plan ${floorPlan.unitTypeName}, Please try again later.`,
                "error"
              );
            }
          });
      } else {
        return;
      }
    });
  };

  const deleteUnit = (unit) => {
    swal({
      title: "Are you sure?",
      text: `You want to delete the Unit ${unit.unitNumber}!`,
      icon: "warning",
      buttons: ["Cancel", "Yes, delete it!"],
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        webApi
          .delete("/units/" + unit.ID)
          .then((res) => {
            if (res.data.errorCode !== 200) {
              swal("Failure", res.data.errorMessage, "error");
            } else {
              getAllUnits();
              swal("Success", res.data.errorMessage, "success");
            }
          })
          .catch((error) => {
            if (error.customErrorMessage) {
              swal("Error", error.customErrorMessage, "error");
            } else {
              swal(
                "Error",
                `Failed to Delete Unit ${unit.unitNumber}, Please try again later.`,
                "error"
              );
            }
          });
      } else {
        return;
      }
    });
  };

  const multiDeleteFloorPlans = () => {
    swal({
      title: "Are you sure?",
      text: "You want to delete selected Floor Plans!",
      icon: "warning",
      buttons: ["Cancel", "Yes, delete it!"],
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        webApi
          .delete("/unitTypes", multiDeleteFloorData)
          .then((res) => {
            getAllFloorPlans();
            if (res.data.notFound) {
              swal(
                "Oops",
                "Unable to Delete all the Selected FloorPlans!",
                "waring"
              );
            } else {
              swal(
                "Success",
                "All the Selected FloorPlans are Deleted",
                "success"
              );
            }
          })
          .catch((error) => {
            if (error.customErrorMessage) {
              swal("Error", error.customErrorMessage, "error");
            } else {
              swal(
                "Error",
                "Failed to Delete Floor Plans, Please try again later.",
                "error"
              );
            }
          });
      } else {
        return;
      }
    });
  };

  const multiDeleteUnits = () => {
    swal({
      title: "Are you sure?",
      text: "You want to delete seltected Units!",
      icon: "warning",
      buttons: ["Cancel", "Yes, delete it!"],
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        webApi
          .delete("/units", multiDeleteUnitData)
          .then((res) => {
            getAllUnits();
            setMultiDeleteUnitData([]);
            if (res.data.notFound) {
              swal(
                "Oops",
                "Unable to Delete all the Selected Units!",
                "waring"
              );
            } else {
              swal("Success", "All the Selected Units are Deleted", "success");
            }
          })
          .catch((error) => {
            if (error.customErrorMessage) {
              swal("Error", error.customErrorMessage, "error");
            } else {
              swal(
                "Error",
                "Failed to Delete Units, Please try again later.",
                "error"
              );
            }
          });
      } else {
        return;
      }
    });
  };

  const validateFileTypes = (image) => {
    const allowedExtensions = ".png, .jpg, .jpeg"
      .split(",")
      .map((type) => type.trim());

    const fileExtension = image.name.split(".").pop().toLowerCase();

    if (allowedExtensions.includes("." + fileExtension)) {
      UploadFeaturedPhoto(image);
    } else {
      swal(
        "Warning",
        "Invalid File type, Please choose a file with one of the (.png, .jpg, .jpeg) file types",
        "warning"
      );
    }
  };

  const UploadFeaturedPhoto = (image) => {
    setSnackbarMessage("Uploading Featured Photo");
    setSnackbarType("info");
    setOpenSnackbar(true);
    webApi
      .put(
        "/properties/featuredPhoto/" +
          portfolioTree.property.ID +
          "&" +
          image.name
      )
      .then(async (res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          const metaData = {
            filename: image.name,
            mediatype: image.type,
          };
          webApi
            .uploadFile(res.data, metaData, image)
            .then((res) => {
              getPropertyData();
              swal("Success", "Successfully Updated Featured Photo", "success");
            })
            .catch((error) => {
              if (error.customErrorMessage) {
                swal("Error", error.customErrorMessage, "error");
              } else {
                swal(
                  "Error",
                  "Failed to Update Featured Photo. Please try again later.",
                  "error"
                );
              }
            });
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Failed to Update Featured Photo. Please try again later.",
            "error"
          );
        }
      });
  };

  //Propspect API's
  const getProspects = () => {
    GetProspectsByPropertyIdAPI(portfolioTree.property.ID).then((res) => {
      setAllProspects(res.data);
      setProspectsTableData(res.data);
    });
  };

  const GotoUnitDetails = (unit) => {
    setPortfolioTree({
      ...portfolioTree,
      unit: unit,
    });
    navigate("/portfolio/unitDetails", { state: floorPlans });
  };

  const handleFloorSelectOrUnSelectAll = (checked) => {
    if (checked) {
      setMultiDeleteFloorData(floorPlanTableData.map((item) => item.ID));
    } else {
      setMultiDeleteFloorData([]);
    }
    setSelectAllFloorPlans(!selectAllFloorPlans);
  };

  const handleUnitSelectOrUnSelectAll = (checked) => {
    if (checked) {
      setMultiDeleteUnitData(unitsTableData.map((item) => item.ID));
    } else {
      setMultiDeleteUnitData([]);
    }
    setSelectAllUnits(!selectAllUnits);
  };

  const handleMultiDeleteFloorData = (checked, floor) => {
    if (checked) {
      setMultiDeleteFloorData([...multiDeleteFloorData, floor.ID]);
    } else {
      setMultiDeleteFloorData(
        multiDeleteFloorData.filter((item) => item !== floor.ID)
      );
    }
  };

  const handleMultiDeleteUnitData = (checked, unit) => {
    if (checked) {
      setMultiDeleteUnitData([...multiDeleteUnitData, unit.ID]);
    } else {
      setMultiDeleteUnitData(
        multiDeleteUnitData.filter((item) => item !== unit.ID)
      );
    }
  };

  const handleUtilityInfoForms = () => {
    if (activeInnerMenuItem === "utilities") {
      setOpenUtilityForm(true);
      setFormOperation("New");
    } else if (activeInnerMenuItem === "amenities") {
      setOpenAmenitiesForm(true);
      setFormOperation("New");
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const unitTableActions = {
    editTitle: "Edit Unit",
    editOnClick: (row) => {
      setEditableData(row);
      setFormOperation("Edit");
      setCreateUnitpage(true);
    },
    deleteTitle: "Delete Unit",
    deleteOnClick: (row) => deleteUnit(row),
    checkboxTitle: "Select/UnSelect Units",
    checkboxOnClick: (checked, row) => handleMultiDeleteUnitData(checked, row),
    checkedData: multiDeleteUnitData,
    checkboxReadOnly: true,
  };

  const floorPlanTableActions = {
    // eyeTitle: "View Floor Plan",
    // eyeOnClick: (row) => viewFloorPlan(row),
    deleteTitle: "Delete Floor Plan",
    deleteOnClick: (row) => deleteFloorPlan(row),
    checkboxTitle: "Select/UnSelect Floor Plans",
    checkboxOnClick: (checked, row) => handleMultiDeleteFloorData(checked, row),
    checkedData: multiDeleteFloorData,
    checkboxReadOnly: true,
  };

  const tenantTableActions = {
    // deleteTitle: "Delete Tenant",
    // deleteOnClick: (row) => {},
    // eyeTitle: "View, Edit Tenant",
    // eyeOnClick: (row) => {
    //   setPortfolioTree({
    //     ...portfolioTree,
    //     tenant: row,
    //   });
    //   navigate("/tenants/tenantDetails");
    // },
    customActions: (row) => customActionsContentTenant(row),
  };

  const customActionsContentTenant = (row) => (
    <>
      <ButtonBlue
        id="resendTenantInvitation"
        name="Resend"
        title="Resend Invitation"
        onClick={() => resendTenantInvitation(row)}
        hidden={row.isVerified === true || row.tenantStatus === "Moved-out"}
        className="text-sm font-normal w-auto h-8 cursor-pointer bg-white border-blue-52 text-blue-52 hover:text-white focus:text-white"
      />
    </>
  );

  const maintenanceTableActions = {
    // deleteTitle: "Delete Maintenance",
    // deleteOnClick: (row) => {},
    editTitle: "Edit Maintenance",
    editOnClick: (row) =>
      navigate("/maintenance/maintenanceDetails", {
        state: { ticketId: row.ID },
      }),
  };

  const utilitiesTableActions = {
    deleteTitle: "Delete Utility",
    deleteOnClick: (row) => deleteUtility(row),
    editTitle: "Edit Utility",
    editOnClick: (row) => {
      setEditableData(row);
      setFormOperation("Edit");
      setOpenUtilityForm(true);
    },
  };

  const amenitiesTableActions = {
    deleteTitle: "Delete Amenity",
    deleteOnClick: (row) => {
      deleteAmenity(row);
    },
    editTitle: "Edit Amenity",
    editOnClick: (row) => {
      setEditableData(row);
      setFormOperation("Edit");
      setOpenAmenitiesForm(true);
    },
  };

  const getStatusCellColor = (row) => {
    return { color: row.statusColor };
  };

  const changetoUpperCase = (str) => {
    return str.toUpperCase();
  };

  const searchbarProps = {
    vendor: {
      id: "vendorSearchBar",
      placeholder: "Search Vendors..",
      data: allVendors,
      setTableData: setVendors,
      columns: [
        { key: "companyName", title: "Vendor Company Name" },
        {
          key: "firstName",
          title: "Vendor Contact",
          applyMethod: (row) => {
            return row.firstName + " " + row.lastName;
          },
        },
        {
          key: "contactNo",
          title: "Contact No",
          applyMethod: (row) => PhoneNumFormat(row.contactNo, true),
        },
        { key: "email", title: "Email" },
        { key: "vendorType", title: "Type" },
      ],
    },
  };

  const getAllUtilities = () => {
    webApi
      .get("/utility/property/" + portfolioTree.property.ID + "/utilities")
      .then((res) => {
        if (res.data.errorCode) {
          console.log("error in utilities", res.data.errorMessage, "error");
        } else {
          setUitlitiesTableData(res.data);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Oops! Unable to get Utilities, Please try again later.",
            "error"
          );
        }
      });
  };

  const deleteUtility = (utility) => {
    swal({
      title: "Are you sure?",
      text: `You want to delete the Utility ${utility.serviceName}!`,
      icon: "warning",
      buttons: ["Cancel", "Yes, delete it!"],
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        webApi
          .delete("/utility/" + utility.ID)
          .then((res) => {
            if (res.data.errorCode !== 200) {
              swal("Failure", res.data.errorMessage, "error");
            } else {
              swal("Success", res.data.errorMessage, "success");
              getAllUtilities();
            }
          })
          .catch((error) => {
            if (error.customErrorMessage) {
              swal("Error", error.customErrorMessage, "error");
            } else {
              swal(
                "Error",
                `Failed to Delete Utility ${utility.serviceName}. Please try again later.`,
                "error"
              );
            }
          });
      } else {
        return;
      }
    });
  };

  const getAllAmenities = () => {
    webApi
      .get("/amenity/property/" + portfolioTree.property.ID + "/amenities")
      .then((res) => {
        if (res.data.errorCode) {
          console.log("error in Amenities", res.data.errorMessage, "error");
        } else {
          setAmenitiesTableData(res.data);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Oops! Unable to get Amenities, Please try again later.",
            "error"
          );
        }
      });
  };

  const deleteAmenity = (amenity) => {
    swal({
      title: "Are you sure?",
      text: `You want to delte the Amenity ${amenity.amenityName}!`,
      icon: "warning",
      buttons: ["Cancel", "Yes, delete it!"],
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        webApi
          .delete("/amenity/" + amenity.ID)
          .then((res) => {
            if (res.data.errorCode !== 200) {
              swal("Failure", res.data.errorMessage, "error");
            } else {
              getAllAmenities();
              swal("Success", res.data.errorMessage, "success");
            }
          })
          .catch((error) => {
            if (error.customErrorMessage) {
              swal("Error", error.customErrorMessage, "error");
            } else {
              swal(
                "Error",
                `Failed to Delete Amenity ${amenity.amenityName}, Please try again later.`,
                "error"
              );
            }
          });
      } else {
        return;
      }
    });
  };

  const viewFloorPlan = (row) => {
    setViewFloorPlanDetails(true);
    setAllFloorPlanDetails(row);
  };

  const getUtilityBillTypes = () => {
    webApi
      .get("/unitUtilityBill/billTypes")
      .then((res) => {
        if (res.data.errorCode) {
          console.log(
            "error in uitlity bill types",
            res.data.errorMessage,
            "error"
          );
        } else {
          setUtilityBillTypes(["All", ...res.data.billType]);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Oops, Unable to get Utility Bill Types. Please try again later.",
            "error"
          );
        }
      });
  };

  const getAllUtilityBills = () => {
    webApi
      .get("/unitUtilityBill/property/" + portfolioTree.property.ID)
      .then((res) => {
        if (res.data.errorCode) {
          console.log(
            "error in get uitlity bills",
            res.data.errorMessage,
            "error"
          );
        } else {
          setAllUtilityBills(res.data);
          setUtilityBillsTableData(res.data);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Unable to get Utility Bills. Please try again later.",
            "error"
          );
        }
      });
  };

  const utilityBillsTableActions = {
    deleteTitle: "Delete Bill",
    deleteOnClick: (row) => {
      deleteUtilityBill(row);
    },
    editTitle: "Edit Bill",
    editOnClick: (row) => {
      setEditableData(row);
      setFormOperation("Edit");
      setOpenAddUtilityChargesForm(true);
    },
  };

  const deleteUtilityBill = (bill) => {
    swal({
      title: "Are you sure?",
      text: "You want to delete this Utility!",
      icon: "warning",
      buttons: ["Cancel", "Yes, delete it!"],
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        webApi
          .delete("/unitUtilityBill/" + bill.ID)
          .then((res) => {
            if (res.data.errorCode !== 200) {
              swal("Failure", res.data.errorMessage, "error");
            } else {
              getAllUtilityBills();
              swal("Success", res.data.errorMessage, "success");
            }
          })
          .catch((error) => {
            if (error.customErrorMessage) {
              swal("Error", error.customErrorMessage, "error");
            } else {
              swal(
                "Error",
                "Failed to Delete Utility Bill, Please try again later.",
                "error"
              );
            }
          });
      } else {
        return;
      }
    });
  };

  const handleUitilityBillFilter = (e) => {
    const currentBillType = e.target.value;
    if (currentBillType === "All") {
      setUtilityBillsTableData(allUtilityBills);
    } else {
      const filteredData = allUtilityBills.filter(
        (item) => item.billType === currentBillType
      );
      setUtilityBillsTableData(filteredData);
    }
    setBillFilter(e.target.value);
  };

  const getVendorDetailsByProp = () => {
    webApi
      .get("/vendor/vendorDetails/property/" + portfolioTree.property.ID)
      .then((res) => {
        setVendors(res.data);
        setAllVendors(res.data);
        // if (res.data.errorCode) {
        //   console.log(
        //     "error in get vendor by prop",
        //     res.data.errorMessage,
        //     "error"
        //   );
        // } else {
        //   setVendors(res.data);
        //   setAllVendors(res.data)
        // }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Unable to get Vendor Details, Please try again later.",
            "error"
          );
        }
      });
  };

  const GotoVendorDetails = (rows) => {
    //setVendorID(rows.ID);
    navigate("/vendor/vendorDetails");
  };

  //Documents API's
  const getDocumentsByPropertyId = (propertyId) => {
    setApiProcessing({
      loader: true,
      message: ``,
    });
    webApi
      .get(`/documents/property/${propertyId}`)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setCurrentPrefix(res.data.prefix);
          setAllFolders(res.data.folders);
          setCurrentFolders(res.data.folders);
          if (res.data.files.length) {
            const transformedFiles = res.data.files.map((fileName) => ({
              file: fileName,
            }));
            setDocumentsTableData(transformedFiles);
            setDocuments(transformedFiles);
          } else {
            setDocumentsTableData([]);
            setDocuments([]);
          }
        }
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Failed to Get the Documents. Please try again later.",
            "error"
          );
        }
      });
  };

  const createFolder = (folderName) => {
    setApiProcessing({
      loader: true,
      message: `Creating Folder...`,
    });
    const data = {
      prefix: currentPrefix,
    };
    webApi
      .post(`/documents/newFolder/${folderName}`, data)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setCurrentPrefix(res.data.prefix);
          setAllFolders(res.data.folders);
          setCurrentFolders(res.data.folders);
          if (res.data.files.length) {
            const transformedFiles = res.data.files.map((fileName) => ({
              file: fileName,
            }));
            setDocumentsTableData(transformedFiles);
            setDocuments(transformedFiles);
          } else {
            setDocuments([]);
            setDocumentsTableData([]);
          }
        }
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Failed to Create Folder ${folderName}. Please try again later.`,
            "error"
          );
        }
      });
  };

  const handleDocumentsFileUpload = (file) => {
    setApiProcessing({
      loader: true,
      message: `Uploading File...`,
    });
    const data = {
      objectKey: `${currentPrefix}${file.name}`,
      metadata: {
        filename: file.name,
        mediatype: file.type,
      },
    };

    webApi
      .uploadFile(data.objectKey, data.metadata, file)
      .then((res) => {
        getDocumentsByPrefix(`${currentPrefix}`);
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
        swal("Success", "File Upload is successful", "success");
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Failed to upload file ${file.name}. Please try again later.`,
            "error"
          );
        }
      });
  };

  const handlePrefixClick = (index) => {
    const clickedFolders = currentPrefix.split("/").slice(0, index + 1);
    const updatedPrefix = clickedFolders.join("/");
    getDocumentsByPrefix(`${updatedPrefix}/`);
  };

  const getDocumentsByPrefix = (newprefix) => {
    setApiProcessing({
      loader: true,
      message: `Gathering Docs and Folders...`,
    });
    const data = {
      prefix: newprefix,
    };
    webApi
      .post("/documents/prefix", data)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setCurrentPrefix(res.data.prefix);
          setAllFolders(res.data.folders);
          setCurrentFolders(res.data.folders);
          if (res.data.files.length) {
            const transformedFiles = res.data.files.map((fileName) => ({
              file: fileName,
            }));
            setDocumentsTableData(transformedFiles);
            setDocuments(transformedFiles);
          } else {
            setDocuments([]);
            setDocumentsTableData([]);
          }
        }
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Oops, something went wrong! Please try again later.",
            "error"
          );
        }
      });
  };

  const filesTableActions = {
    downloadTitle: "Download",
    downloadOnclick: (row) => {
      getFileLink(row.file, "download");
    },
    mailTitle: "Mail",
    mailOnclick: (row) => {
      swal(
        "Info",
        "Mail Option isn't available at the moment, Our Developers are working on it.",
        "info"
      );
    },
  };

  const handleDocumentsSearch = (event) => {
    const currentSearchString = event.target.value;
    setDocsSearchString(currentSearchString);

    if (currentSearchString === "") {
      setCurrentFolders(allFolders);
      setDocumentsTableData(documents);
    } else {
      const filteredFolders = allFolders.filter((folder) =>
        folder.toLowerCase().includes(currentSearchString.toLowerCase())
      );

      setCurrentFolders(filteredFolders);

      const filteredFiles = documents.filter((singleFile) =>
        singleFile.file
          .toLowerCase()
          .includes(currentSearchString.toLowerCase())
      );

      setDocumentsTableData(filteredFiles);
    }
  };

  const getFileLink = (fileName, action) => {
    setApiProcessing({
      loader: true,
      message: `Getting File...`,
    });
    setVideoLink("");
    setFileLink("");
    const data = {
      objectKey: `${currentPrefix}${fileName}`,
    };
    webApi
      .post(`/documents/download`, data)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          if (action === "view") {
            if (
              res.data.metadata.filename.split(".").pop().toLowerCase() ===
              "pdf"
            ) {
              setFileLink(res.data.objectKey);
              setOpenPdf(true);
            } else if (
              ["jpg", "jpeg", "png"].includes(
                res.data.metadata.filename.split(".").pop().toLowerCase()
              )
            ) {
              setFileLink(res.data.objectKey);
              setOpenGallery(true);
            } else {
              setVideoLink(res.data.objectKey);
              setOpenGallery(true);
            }
          } else if (action === "download") {
            window.location.href = res.data.objectKey;
          }
        }
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else
          swal(
            "Error",
            "Oops, Unable to get the File! Please try again later.",
            "error"
          );
      });
  };

  //Inspection API's

  const getInspectionTypes = () => {
    setApiProcessing({
      loader: true,
      message: `Gathering Inspection Types...`,
    });
    webApi
      .get("/inspection/types")
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setInspectionTypes(["All", ...res.data.inspectionType]);
        }
        setApiProcessing({ loader: false, message: `` });
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Oops, Unable to get Inspection Types. Please try again later.",
            "error"
          );
        }
      });
  };

  const getAllInspectionRecords = () => {
    setApiProcessing({
      loader: true,
      message: `Gathering Inspection Records...`,
    });
    webApi
      .get(`/inspection/property/${portfolioTree.property.ID}`)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setInspectionTableData(res.data);
          setAllInspectionRecords(res.data);
        }
        setApiProcessing({ loader: false, message: `` });
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Oops! Unable to get Inspection Records, Please try again later.",
            "error"
          );
        }
      });
  };

  const clearSearchField = () => {
    if (searchBarRef.current) {
      searchBarRef.current.clearSearch();
    }
  };

  const handleProspectReportPeriodFilter = (e) => {
    const currentReportPeriod = e.target.value;
    let prospectData = [];
    clearSearchField();

    const currentDate = new Date();
    const oneDay = 1000 * 60 * 60 * 24;
    const oneMonth = 30 * oneDay;
    const threeMonths = 3 * oneMonth;
    const oneYear = 365 * oneDay;

    if (currentReportPeriod === "All") {
      prospectData = allProspects;
    } else if (currentReportPeriod === "Weekly") {
      // Filter prospects created in the last 7 days
      prospectData = allProspects.filter((item) => {
        const createdAt = new Date(item.CreatedAt);
        return currentDate - createdAt <= 7 * oneDay;
      });
    } else if (currentReportPeriod === "Monthly") {
      // Filter prospects created in the last 30 days
      prospectData = allProspects.filter((item) => {
        const createdAt = new Date(item.CreatedAt);
        return currentDate - createdAt <= oneMonth;
      });
    } else if (currentReportPeriod === "Quarterly") {
      // Filter prospects created in the last 3 months
      prospectData = allProspects.filter((item) => {
        const createdAt = new Date(item.CreatedAt);
        return currentDate - createdAt <= threeMonths;
      });
    } else if (currentReportPeriod === "Annual") {
      // Filter prospects created in the last 12 months
      prospectData = allProspects.filter((item) => {
        const createdAt = new Date(item.CreatedAt);
        return currentDate - createdAt <= oneYear;
      });
    }

    setProspectsTableData(prospectData);
    setSelProsReportPeriod(currentReportPeriod);
  };

  const handleInspectionFilter = (e) => {
    const currentInspectionType = e.target.value;
    clearSearchField();
    if (currentInspectionType === "All") {
      setInspectionTableData(allInspectionRecords);
    } else {
      const filteredData = allInspectionRecords.filter(
        (item) => item.inspectionType === currentInspectionType
      );
      setInspectionTableData(filteredData);
    }
    setInspectionFilterValue(currentInspectionType);
  };

  const inspectionTableActions = {
    mailTitle: "Mail",
    mailOnclick: (row) => {
      swal(
        "Info",
        "Mail Option isn't available at the moment, Our Developers are working on it.",
        "info"
      );
    },
    editTitle: "Edit Inspection",
    editOnClick: (row) => {
      setEditableData(row);
      setFormOperation("Edit");
      setOpenInspectionForm(true);
    },
    downloadTitle: "Download",
    downloadOnclick: (row) => {
      swal(
        "Info",
        "Download Option isn't available at the moment, Our Developers are working on it.",
        "info"
      );
    },
  };

  //Change Multi-Family Status
  const handleChangeStatusOnClick = () => {
    setOpenMFStatusChangeForm(true);
  };

  const handleViewNotesOnClick = () => {
    setViewMFStatusChangeNotes(true);
  };

  //Resending the Tenant invitaion
  const resendTenantInvitation = (row) => {
    // console.log("resend", row.email)
    setApiProcessing({
      loader: true,
      message: `Resending Invitation...`,
    });
    webApi
      .post(`/resendTempPassword/${row.email}&Tenant`)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          swal("Success", res.data.message, "success");
          getAllTenants();
        }
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Failed to send invitation. Please try again later.`,
            "error"
          );
        }
      });
  };

  const getFullAddress = (data) => {
    const { address1, address2 } = data.address;
    return `${address1}${address2 ? `, ${address2}` : ""},`;
  };

  const getFullAddress2 = (data) => {
    const { city, state, country, zipCode } = data.address;
    return `${city ? `${city}` : ""} ${state ? `, ${state}` : ""} ${
      zipCode ? `, ${zipCode}` : ""
    }`;
  };

  const downloadPDF = () => {
    let data = {
      propertyId: portfolioTree.property.ID,
      type: "Single Family",
    };
    webApi
      .post("/report/inspectionList/pdf", data, { loader: true })
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          const link = document.createElement("a");
          link.href = res.data.url;
          link.setAttribute("download", "filename.pdf"); // You can set a default filename
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Apologies, Failed to download Inspection Report, Please try again later.`,
            "error"
          );
        }
      });
  };

  //Prospect API's
  const addPropertyToListing = (propertyType) => {
    if (propertyType === "Single Family") {
      addSingleFamilyToListing();
    } else {
      addMultiFamilyToListing();
    }
  };
  const addSingleFamilyToListing = () => {
    const requestData = {
      isListed: true,
    };
    AddSingleFamilyToListingAPI(portfolioTree.property.ID, requestData).then(
      (res) => {
        if (res.data.isListed === true) {
          swal("", "Property Listed Successfully!", "success");
        }
      }
    );
  };

  const addMultiFamilyToListing = () => {
    const requestData = {
      isListed: true,
    };
    AddMultiFamilyToListingAPI(portfolioTree.property.ID, requestData).then(
      (res) => {
        if (res.data.isListed === true) {
          swal("", "Property Listed Successfully!", "success");
        }
      }
    );
  };

  const copyOrSharePropertyListedLink = (action) => {
    GenerateLinkForPropertyAPI(portfolioTree.property.ID).then((res) => {
      const propertyLink = res.data;

      if (action === "share") {
        // Check if the browser supports the Share API
        if (navigator.share) {
          navigator
            .share({
              title: "Property Listing",
              url: propertyLink,
            })
            .then(() => {
              console.log("Link shared successfully!");
            })
            .catch((err) => {
              console.error("Error sharing the link:", err);
            });
        } else {
          alert("Sharing is not supported on this device/browser.");
        }
      } else if (action === "copy") {
        // Copy the link to clipboard
        navigator.clipboard
          .writeText(propertyLink)
          .then(() => {
            alert("Link copied to clipboard!");
          })
          .catch((err) => {
            console.error("Failed to copy link: ", err);
            alert("Failed to copy link.");
          });
      }
    });
  };

  return (
    <>
      {createPropertypage ? (
        <CreateProperty
          trigger={createPropertypage}
          setTrigger={setcreatePropertypage}
          portfolio={portfolioTree.portfolio}
          operation="Edit"
          property={portfolioTree.property}
          getAllProperties={(prop) => {
            if (prop.propertyType === "Single Family") {
              getAllUnits();
            }
            getPropertyData();
          }}
        />
      ) : null}

      {createUnitpage ? (
        <CreateUnit
          openUnitFormPage={createUnitpage}
          setUnitFormPage={setCreateUnitpage}
          Property={propertyInfo}
          allFloorPlans={floorPlans}
          unitData={editableData}
          operation={formOperation}
          getUnits={() => {
            setEditableData("");
            getAllUnits();
            getPropertyData();
          }}
        />
      ) : null}

      {createTenantpage ? (
        <CreateTenant
          openTenantFormPage={createTenantpage}
          allUnits={units}
          isSingleFamily={
            propertyInfo && propertyInfo.propertyType === "Single Family"
              ? true
              : false
          }
          getTenants={() => {
            getAllTenants();
            getAllUnits();
            if (isSingleFamily) {
              getPropertyData();
            }
          }}
          setTenantFormPage={setCreateTenantpage}
        />
      ) : null}

      {createFloorPlanPage ? (
        <CreateFloorPlan
          openFloorPlanForm={createFloorPlanPage}
          SetOpenFloorPlanForm={setCreateFloorPlanPage}
          PropertyId={portfolioTree.property.ID}
          getFloorPlans={getAllFloorPlans}
        />
      ) : null}

      {viewFloorPlanDetails ? (
        <FloorPlanDetails
          trigger={viewFloorPlanDetails}
          floorPlanDetails={allFloorPlanDetails}
          setTrigger={setViewFloorPlanDetails}
        />
      ) : null}

      {openMaintenanceReqPage && (
        <CreateMaintenanceRequest
          trigger={openMaintenanceReqPage}
          setTrigger={setOpenMaintenanceReqPage}
          propertyId={portfolioTree.property.ID}
          propertyType={portfolioTree.property.propertyType}
          //unitId={units[0].ID}
          allUnits={units}
          getMaintenanceTickets={(data) => {
            getAllMaintenanceTickets();
            if (data?.requestType === "Turnover") {
              getPropertyData();
            }
          }}
        />
      )}

      {openUtilityForm && (
        <AddUtility
          trigger={openUtilityForm}
          setTrigger={setOpenUtilityForm}
          getUtilitites={() => {
            getAllUtilities();
            setEditableData("");
          }}
          PropertyId={portfolioTree.property.ID}
          operation={formOperation}
          editData={editableData}
        />
      )}

      {openAmenitiesForm && (
        <AddAmenities
          trigger={openAmenitiesForm}
          setTrigger={setOpenAmenitiesForm}
          getAmenities={() => {
            getAllAmenities();
            setEditableData("");
          }}
          PropertyId={portfolioTree.property.ID}
          operation={formOperation}
          editData={editableData}
        />
      )}

      {openAddUtilityChargesForm && (
        <AddUtilityCharges
          trigger={openAddUtilityChargesForm}
          setTrigger={setOpenAddUtilityChargesForm}
          propertyId={portfolioTree.property.ID}
          allUnits={units}
          isSingleFamily={
            propertyInfo && propertyInfo.propertyType === "Single Family"
              ? true
              : false
          }
          getBills={() => {
            getAllUtilityBills();
            setEditableData("");
          }}
          operation={formOperation}
          editData={editableData}
        />
      )}

      {openInspectionForm && (
        <AddInspection
          trigger={openInspectionForm}
          setTrigger={setOpenInspectionForm}
          unitId={units[0].ID}
          tenants={tenants}
          inspectionTypes={inspectionTypes}
          operation={formOperation}
          editData={editableData}
          getAllInspections={(newRecord) => {
            getAllInspectionRecords();
            getPropertyData();
            getAllTenants();
            setEditableData("");
          }}
        />
      )}

      <CreateNewFolder
        trigger={openFolderForm}
        setTrigger={setOpenFolderForm}
        handleCreate={(folderName) => createFolder(folderName)}
      />

      <UploadNewFile
        trigger={openFileUploadForm}
        setTrigger={setOpenFileUploadForm}
        title="Upload New File"
        accept=".png, .jpg, .jpeg, .pdf"
        placeholder="Attach .pdf .png .jpg format only"
        handleUpload={(file) => handleDocumentsFileUpload(file)}
      />

      {openGallery && (
        <Gallery
          images={[fileLink]}
          videos={[videoLink]}
          openGallery={openGallery}
          setOpenGallery={setOpenGallery}
        />
      )}

      {openPdf && (
        <PDFViewer pdfUrl={fileLink} onClose={(e) => setOpenPdf(false)} />
      )}

      {openMFStatusChangeForm && (
        <ChangeMultiPropertyStatus
          trigger={openMFStatusChangeForm}
          setTrigger={setOpenMFStatusChangeForm}
          propertyId={portfolioTree.property.ID}
          getUpdatedMultiPropertyData={(data) => {
            getPropertyData();
          }}
        />
      )}

      {viewMFStatusChangeNotes && (
        <ViewMultiPropertyStatusNotes
          trigger={viewMFStatusChangeNotes}
          setTrigger={setViewMFStatusChangeNotes}
          propertyId={portfolioTree.property.ID}
        />
      )}

      <Snackbar
        open={openSnackbar}
        onClose={handleSnackbarClose}
        autoHideDuration={5000}
      >
        <Alert
          onClose={handleSnackbarClose}
          variant="filled"
          severity={snackbarType}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Loader open={apiProcessing.loader} message={apiProcessing.message} />

      {!viewInspectionDetails && (
        <div className={`w-11/12 m-auto border`}>
          <section
            name="Title and Options"
            className="flex justify-between items-center bg-blue-52 h-10"
          >
            <span className="flex gap-4">
              <BsFillArrowLeftCircleFill
                id="backToPreviousPage"
                title="Back to Previous Page"
                onClick={(e) => {
                  setPortfolioTree({
                    ...portfolioTree,
                    property: {},
                    unit: {},
                    tenant: {},
                  });
                  navigate("/portfolio/properties");
                }}
                className={`text-white w-6 h-6 ml-2 ${clickEffect}`}
              />
              <p
                id="propertyNameOverviewHeader"
                className="font-semibold text-white"
              >
                {propertyInfo && propertyInfo.propertyName} Overview
              </p>
            </span>

            <div
              name="Property Options"
              className="flex text-gray-700 items-center gap-4 mr-4"
            >
              {showAdvertise && (
                <Dropdown
                  selector={
                    <span className="flex items-center gap-2 p-1 rounded-md border-white text-white hover:bg-white hover:text-blue-53">
                      <CiShare1
                        title="Advertize options"
                        className="w-5 h-5 cursor-pointer hover:text-blue-53 focus:text-blue-53"
                      />
                      Advertise
                    </span>
                  }
                  options={[
                    {
                      name: "Copy Link",
                      onClick: (e) => copyOrSharePropertyListedLink("copy"),
                    },
                    {
                      name:
                        propertyInfo && propertyInfo.isListed
                          ? "Added To Listing"
                          : "Add To Listing",
                      onClick: () => {
                        if (!propertyInfo.isListed) {
                          addPropertyToListing(propertyInfo.propertyType);
                        } else {
                          swal("", "Already included in the listing!", "info");
                        }
                      },
                    },
                    {
                      name: "Share",
                      onClick: (e) => copyOrSharePropertyListedLink("share"),
                    },
                  ]}
                />
              )}

              <span
                title="Edit Property"
                id="editPropButton"
                name="editPropButton"
                data-cy="editPropButton"
                onClick={(e) => {
                  setcreatePropertypage(true);
                }}
                className={
                  "p-2 rounded flex justify-center items-center cursor-pointer text-white hover:bg-white hover:text-blue-52 hover:shadow-lg " +
                  clickEffect
                }
              >
                <TiEdit className="w-5 h-5" />
              </span>

              {/* <BsThreeDotsVertical className="w-5 h-5 mr-4" /> */}
            </div>
          </section>

          <section
            name="propertyDetails"
            className="flex justify-around items-start mx-4 my-6 gap-4 md:overflow-x-auto"
          >
            <div className="flex flex-col justify-start w-96">
              <div className="flex flex-col justify-center items-center bg-gray-50 border w-full h-52 overflow-hidden">
                {propertyInfo && propertyInfo.featuredPhotoURL !== "" ? (
                  <img
                    id="featuredPhoto"
                    src={propertyInfo.featuredPhotoURL}
                    alt="Featured Photo"
                    onClick={(e) => {
                      setVideoLink("");
                      setFileLink(propertyInfo.featuredPhotoURL);
                      setOpenGallery(true);
                    }}
                    className="w-full h-full object-cover cursor-pointer"
                  />
                ) : (
                  <>
                    <p
                      id="noImagetoDisplay"
                      className="text-gray-600 text-center"
                    >
                      No Featured Image to Display
                    </p>
                    <input
                      accept=".png, .jpg, .jpeg"
                      type="file"
                      className="hidden"
                      ref={handleFileInput}
                      onChange={(e) => validateFileTypes(e.target.files[0])}
                    />
                    <button
                      onClick={() => handleFileInput.current.click()}
                      id="uploadImage"
                      name="uploadImage"
                      data-cy="uploadImage"
                      className={`text-blue-54 mt-2 p-1 rounded-md border border-blue-54 shadow hover:bg-blue-54 hover:text-white ${clickEffect}`}
                    >
                      Upload Image
                    </button>
                  </>
                )}
              </div>

              <AddressViewTile
                id="Address"
                title="Address:"
                body={propertyInfo && getFullAddress(propertyInfo)}
                body2={propertyInfo && getFullAddress2(propertyInfo)}
                className="flex gap-2 break-words mb-1"
                labelClassName="!text-gray-500"
                dataClassName="!text-black !w-96"
                dataClassName2="!text-black"
              />

              <TextReviewTile
                id="latitude"
                title="Latitude:"
                body={propertyInfo && propertyInfo.latitude}
                labelClassName="!text-gray-500"
                dataClassName="!text-black"
                className="flex gap-2"
              />

              <TextReviewTile
                id="longitude"
                title="Longitude:"
                body={propertyInfo && propertyInfo.longitude}
                labelClassName="!text-gray-500"
                dataClassName="!text-black"
                className="flex gap-2"
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <span className="flex flex-col gap-2">
                <DetailsTile
                  id="propertyType"
                  label="Property Type"
                  data={propertyInfo && propertyInfo.propertyType}
                  labelClassName="text-gray-500"
                />

                <DetailsTile
                  id="propertyStatus"
                  label="Property Status"
                  data={
                    <div className="flex items-center gap-1">
                      <div
                        className={`${
                          propertyInfo && propertyInfo.isActive
                            ? "text-green-500"
                            : "text-red-500"
                        }`}
                      >
                        {propertyInfo && propertyInfo.status}
                      </div>
                      {propertyInfo && propertyInfo.isActive && (
                        <Dropdown
                          selector={
                            <PiDotsThreeCircleVerticalLight
                              title="Options"
                              className="w-5 h-5 cursor-pointer text-gray-500 hover:text-blue-53 focus:text-blue-53"
                            />
                          }
                          options={[
                            {
                              name: "Change Status",
                              onClick: handleChangeStatusOnClick,
                            },
                            {
                              name: "View Notes",
                              onClick: handleViewNotesOnClick,
                            },
                          ]}
                        />
                      )}
                    </div>
                  }
                  labelClassName="text-gray-500"
                />

                <DetailsTile
                  id="propertyNameDetail"
                  label="Property Name"
                  data={propertyInfo && propertyInfo.propertyName}
                  labelClassName="text-gray-500"
                />

                <DetailsTile
                  id="yearBuiltDetail"
                  label="Year Built"
                  data={propertyInfo && propertyInfo.yearBuilt}
                  labelClassName="text-gray-500"
                />

                <DetailsTile
                  id="accquisitionDateDetail"
                  label="Accquisition Date"
                  data={
                    propertyInfo && GetDateInMMDDYYYY(propertyInfo.purchaseDate)
                  }
                  labelClassName="text-gray-500"
                />

                {isSingleFamily ? (
                  <>
                    <DetailsTile
                      id="monthlyRentDetail"
                      label="Monthly Rent"
                      data={
                        units.length && "$" + units[0].rent.toLocaleString()
                      }
                      labelClassName="text-gray-500"
                    />

                    <DetailsTile
                      id="minSecurityDepositDetail"
                      label="Min Security Deposit"
                      data={
                        units.length && "$" + units[0].deposit.toLocaleString()
                      }
                      labelClassName="text-gray-500"
                    />
                    <DetailsTile
                      id="areaDetail"
                      label="Area (Sq.ft)"
                      data={units.length && units[0].sqFt}
                      labelClassName="text-gray-500"
                    />
                    {/* <DetailsTile
                      label="No. of Rooms"
                      data={units.length && units[0].numberOfRooms}
                      labelClassName="text-gray-500"
                    />

                    <DetailsTile
                      label="No. of Beds"
                      data={units.length && units[0].numberOfBeds}
                      labelClassName="text-gray-500"
                    /> */}
                  </>
                ) : (
                  <>
                    <DetailsTile
                      id="noOfBuildingsDetail"
                      label="No. of Buildings"
                      data={propertyInfo && propertyInfo.noOfBuildings}
                      labelClassName="text-gray-500"
                    />

                    <DetailsTile
                      id="totalUnitsDetail"
                      label="Total Units"
                      data={propertyInfo && propertyInfo.noOfUnits}
                      labelClassName="text-gray-500"
                    />

                    {/* <DetailsTile
                      label="Available Units"
                      data={propertyInfo && propertyInfo.availableUnits}
                      labelClassName="text-gray-500"
                    /> */}
                  </>
                )}
              </span>

              <span className="flex flex-col gap-2">
                {isSingleFamily ? (
                  <>
                    {/* <DetailsTile
                      label="No. of Rooms"
                      data={units.length && units[0].numberOfRooms}
                      labelClassName="text-gray-500"
                    /> */}

                    <DetailsTile
                      id="noOfBedsDetail"
                      label="No. of Beds"
                      data={units.length && units[0].numberOfBeds}
                      labelClassName="text-gray-500"
                    />

                    <DetailsTile
                      id="noOfBathroomsDetail"
                      label="No. of Bathrooms"
                      data={units.length && units[0].numberOfBaths}
                      labelClassName="text-gray-500"
                    />
                  </>
                ) : (
                  <>
                    <DetailsTile
                      id="occupiedUnitsDetail"
                      label="Occupied Units"
                      data={propertyInfo && propertyInfo.occupiedUnits}
                      labelClassName="text-gray-500"
                    />

                    <DetailsTile
                      id="availableUnitsDetail"
                      label="Available Units"
                      data={propertyInfo && propertyInfo.availableUnits}
                      labelClassName="text-gray-500"
                    />
                  </>
                )}

                <DetailsTile
                  id="contactNoDetail"
                  label="Contact No."
                  data={
                    propertyInfo &&
                    PhoneNumFormat(propertyInfo.phonenumber, true)
                  }
                  labelClassName="text-gray-500"
                />

                <DetailsTile
                  id="emailIdDetail"
                  label="Email ID."
                  data={propertyInfo && propertyInfo.email}
                  labelClassName="text-gray-500"
                />

                <DetailsTile
                  id="chargeDayDetail"
                  label="Charge Day"
                  data={propertyInfo && propertyInfo.chargeDay}
                  labelClassName="text-gray-500"
                />

                <DetailsTile
                  id="accountTypeDetail"
                  label={
                    bankDetails.accountType === "Card" ? (
                      <>Card Type</>
                    ) : (
                      <>Bank Name</>
                    )
                  }
                  data={bankDetails.name ? bankDetails.name : "-"}
                  labelClassName="text-gray-500"
                />
                <DetailsTile
                  id="accountNumberDetail"
                  label={
                    bankDetails.accountType === "Card" ? (
                      <>Card Number</>
                    ) : (
                      <>Account Number</>
                    )
                  }
                  data={
                    bankDetails.last4 ? `**** **** ${bankDetails.last4}` : "-"
                  }
                  labelClassName="text-gray-500"
                />
              </span>
            </div>
          </section>

          <section
            name="tabs"
            className="flex justify-around items-center h-10 font-semibold shadow-lg text-gray-600 cursor-pointer border-t-2 border-blue-52"
          >
            {tabs.map((tab, index) => (
              <p
                key={tab.nickName}
                id={tab.nickName}
                name={tab.nickName}
                data-cy={tab.nickName}
                hidden={
                  tab.type &&
                  propertyInfo &&
                  propertyInfo.propertyType !== tab.type
                    ? true
                    : false
                }
                className={`hover:text-[#003C9A] focus:text-[#003C9A] pb-2 w-fit text-center ${
                  activeMenuItem === tab.nickName
                    ? `text-[#003C9A] border-[#003C9A] font-semibold w-fit border-b-2`
                    : `text-gray-600 border-gray-600`
                } 
                `}
                onClick={(e) => handlePropertiesTabClick(tab.nickName)}
              >
                {tab.label}
              </p>
            ))}
          </section>

          {searchbarProps[activeMenuItem] && (
            <section className="mt-2 mb-2 flex justify-end">
              <SearchTableComponent
                style="mr-2"
                {...searchbarProps[activeMenuItem]}
              />
            </section>
          )}

          {activeMenuItem === "home" &&
            portfolioTree.property.propertyType === "Multi-Family" && (
              <section className="m-6 grid grid-cols-3 gap-x-4 gap-y-4 justify-between items-center text-center">
                <ChartCard
                  id="propertyRevenueInformationChart"
                  title="Property Revenue Information"
                  cardClassName="col-span-1"
                  data={propertyRevenueMultiFamily}
                  chart={
                    <GoogleChart
                      chartType="PieChart"
                      width="100%"
                      data={propertyRevenueMultiFamily}
                      options={propertyRevenueMultiFamilyOptions}
                    />
                  }
                />

                <ChartCard
                  id="maintenanceChart"
                  title="Maintenance"
                  cardClassName="col-span-1"
                  data={maintenanceOverview}
                  chart={
                    <GoogleChart
                      chartType="PieChart"
                      width="100%"
                      data={maintenanceOverview}
                      options={maintenanceOverviewOptions}
                    />
                  }
                />

                <ChartCard
                  id="expiringLeasesChart"
                  title="Expiring Leases"
                  cardClassName="col-span-1"
                  data={expiringLeases}
                  chart={
                    <GoogleChart
                      chartType="PieChart"
                      width="100%"
                      data={expiringLeases}
                      options={expiringLeasesOptions}
                    />
                  }
                />
              </section>
            )}

          {activeMenuItem === "home" &&
            portfolioTree.property.propertyType === "Single Family" && (
              <section className="m-6 grid grid-cols-3 gap-x-4 gap-y-4 justify-between items-center text-center">
                <ChartCard
                  title="Property Revenue Information"
                  cardClassName="col-span-3"
                  chart={
                    <>
                      <GoogleChart
                        chartType="Bar"
                        width="100%"
                        data={propertyRevenueSingleFamily}
                        options={propertyRevenueSingleFamilyOptions}
                      />
                    </>
                  }
                />
              </section>
            )}

          {activeMenuItem === "units" ? (
            <section name="Units Table">
              <div className="flex justify-between items-center gap-2 m-4">
                <p className="font-semibold text-gray-600">
                  Units ({units.length})
                </p>

                <div className="flex justify-between items-center gap-2">
                  <ButtonCustom
                    id="addNewUnit"
                    btnName="addNewUnit"
                    dataCy="addNewUnit"
                    disabled={!propertyInfo || !propertyInfo.isActive}
                    name={
                      <>
                        <BsPlusCircle className="w-5 h-5 mr-2" /> Add New
                      </>
                    }
                    onClick={() => {
                      setFormOperation("New");
                      setCreateUnitpage(true);
                    }}
                    className={`flex justify-center items-center px-2 w-36 h-10 rounded-md text-blue-52 border-blue-52 ${
                      !propertyInfo || !propertyInfo.isActive
                        ? "cursor-not-allowed"
                        : "hover:text-white hover:bg-blue-52"
                    }`}
                  />

                  <SearchTableComponent
                    id="unitTableSearch"
                    placeholder="Search.."
                    data={units}
                    setTableData={setUnitsTableData}
                    columns={[
                      { key: "unitNumber", title: "Unit Number" },
                      {
                        key: "sqFt",
                        title: "Area (Sq.ft)",
                      },
                      { key: "numberOfBeds", title: "No. of Beds" },
                      { key: "numberOfBaths", title: "No. of Bathrooms" },
                      { key: "unitStatus", title: "Unit Status" },
                    ]}
                  />
                </div>
              </div>
              <TableComponent
                tableId="unitTable"
                data={unitsTableData}
                columns={[
                  { key: "unitNumber", title: "Unit Number" },
                  {
                    key: "sqFt",
                    title: "Area (Sq.ft)",
                  },
                  { key: "numberOfBeds", title: "No. of Beds" },
                  { key: "numberOfBaths", title: "No. of Bathrooms" },
                  { key: "unitStatus", title: "Unit Status" },
                ]}
                actions={unitTableActions}
                rowOnClick={GotoUnitDetails}
                actionHeader={
                  <div className="flex justify-start items-center gap-3">
                    {/* <AiOutlinePlusSquare
                      title="Add Unit"
                      onClick={() => {
                        setFormOperation("New");
                        setCreateUnitpage(true);
                      }}
                      className="w-5 h-6 cursor-pointer focus:text-blue-52 hover:text-blue-52"
                    /> */}
                    <input
                      title="Select/UnSelect All"
                      className="cursor-pointer w-4 h-5"
                      name="Unit Select/UnSelect"
                      data-cy="unitSelect/UnSelect"
                      type="checkbox"
                      id="Select/DeSelect"
                      defaultChecked={selectAllUnits}
                      onClick={(e) =>
                        handleUnitSelectOrUnSelectAll(e.target.checked)
                      }
                    />
                    <Dropdown
                      selector={
                        <BsThreeDotsVertical
                          title="Additional options"
                          className="w-5 h-5 cursor-pointer hover:text-blue-53 focus:text-blue-53"
                        />
                      }
                      options={[{ name: "Delete", onClick: multiDeleteUnits }]}
                    />
                  </div>
                }
              />
            </section>
          ) : null}

          {activeMenuItem === "floorPlans" && (
            <section name="floorPlan Table">
              <div className="flex justify-between items-center gap-2 m-4">
                <p className="font-semibold text-gray-600">
                  Floor Plans ({floorPlans.length})
                </p>

                <div className="flex justify-between items-center gap-2">
                  <ButtonCustom
                    id="addNewFloorPlan"
                    btnName="addNewFloorPlan"
                    dataCy="addNewFloorPlan"
                    name={
                      <>
                        <BsPlusCircle className="w-5 h-5 mr-2" /> Add New
                      </>
                    }
                    disabled={!propertyInfo || !propertyInfo.isActive}
                    onClick={() => {
                      if (
                        (propertyInfo &&
                          propertyInfo.propertyType === "Single Family" &&
                          !floorPlans.length) ||
                        (propertyInfo &&
                          propertyInfo.propertyType === "Multi-Family")
                      ) {
                        setCreateFloorPlanPage(true);
                      } else {
                        setSnackbarMessage(
                          "Only One Floor Plan allowed for Single Family!"
                        );
                        setSnackbarType("warning");
                        setOpenSnackbar(true);
                      }
                    }}
                    className={`flex justify-center items-center px-2 w-36 h-10 rounded-md text-blue-52 border-blue-52 ${
                      !propertyInfo || !propertyInfo.isActive
                        ? "cursor-not-allowed"
                        : "hover:text-white hover:bg-blue-52"
                    }`}
                  />

                  <SearchTableComponent
                    style=""
                    id="floorPlanSearch"
                    placeholder="Search.."
                    data={floorPlans}
                    setTableData={setFloorPlanTableData}
                    columns={[
                      { key: "unitTypeName", title: "Floor Plan Name" },
                      {
                        key: "unitCode",
                        title: "Floor Plan Code",
                      },
                      { key: "numberOfBeds", title: "No. of Beds" },
                      { key: "numberOfBaths", title: "No. of Bathrooms" },
                    ]}
                  />
                </div>
              </div>

              <TableComponent
                tableId="floorPlanTable"
                data={floorPlanTableData}
                columns={[
                  { key: "unitTypeName", title: "Floor Plan Name" },
                  {
                    key: "unitCode",
                    title: "Floor Plan Code",
                  },
                  // { key: "numberOfRooms", title: "No. Of Rooms" },
                  { key: "numberOfBeds", title: "No. of Beds" },
                  { key: "numberOfBaths", title: "No. of Bathrooms" },
                ]}
                actions={floorPlanTableActions}
                rowOnClick={(row) => viewFloorPlan(row)}
                actionHeader={
                  <div className="flex justify-start items-center gap-3">
                    <input
                      title="Select/UnSelect All"
                      className="cursor-pointer w-4 h-5"
                      name="FloorPlans Select/UnSelect"
                      data-cy="FloorPlans Select/UnSelect"
                      type="checkbox"
                      id="Select/DeSelect"
                      defaultChecked={selectAllFloorPlans}
                      onClick={(e) =>
                        handleFloorSelectOrUnSelectAll(e.target.checked)
                      }
                    />
                    <Dropdown
                      selector={
                        <BsThreeDotsVertical
                          title="Additional options"
                          className="w-5 h-5 cursor-pointer hover:text-blue-53 focus:text-blue-53"
                        />
                      }
                      options={[
                        {
                          name: "Delete",
                          onClick: multiDeleteFloorPlans,
                        },
                      ]}
                    />
                  </div>
                }
              />
            </section>
          )}

          {activeMenuItem === "tenants" ? (
            <section name="tenants Table">
              <div className="flex justify-between items-center gap-2 m-4">
                <p id="tenantTitle" className="font-semibold text-gray-600">
                  Tenants ({tenants.length})
                </p>

                <div className="flex justify-between items-center gap-2">
                  <ButtonCustom
                    id="addTenant"
                    btnName="addTenant"
                    dataCy="addTenant"
                    name={
                      <>
                        <BsPlusCircle className="w-5 h-5 mr-2" /> Add New
                      </>
                    }
                    disabled={!propertyInfo || !propertyInfo.isActive}
                    onClick={() => {
                      if (!propertyInfo) return;
                      if (!propertyInfo.isActive) {
                        swal(
                          "Warning",
                          "Oops! Property is currently not Active. Please try again when the Property is Active.",
                          "warning"
                        );
                        return;
                      }
                      // if (propertyInfo.connectedAccountId === 0) {
                      //   swal(
                      //     "Warning",
                      //     "There is no connected bank account linked with this property, and you won't be able to receive any payments from the Tenant. Please link a connected bank account with this property to receive payments.",
                      //     "warning"
                      //   );
                      //   return;
                      // }
                      setCreateTenantpage(true);
                    }}
                    className={`flex justify-center items-center px-2 w-36 h-10 rounded-md text-blue-52 border-blue-52 ${
                      !propertyInfo || !propertyInfo.isActive
                        ? "cursor-not-allowed"
                        : "hover:text-white hover:bg-blue-52"
                    }`}
                  />

                  <SearchTableComponent
                    id="tenantsSearchBar"
                    placeholder="Search Tenants.."
                    data={tenants}
                    setTableData={setTenantsTableData}
                    columns={[
                      {
                        key: "name",
                        title: "Name",
                      },
                      {
                        key: "unitNumber",
                        title: "Unit Number",
                      },
                      {
                        key: "residentCode",
                        title: "Resident Code",
                      },
                      {
                        key: "email",
                        title: "Email",
                      },
                      {
                        key: "phoneNumber",
                        title: "Contact No.",
                        applyMethod: (row) =>
                          PhoneNumFormat(row.phoneNumber, true),
                      },
                      { key: "tenantStatus", title: "Status" },
                    ]}
                  />
                </div>
              </div>
              <TableComponent
                tableId="tenantsTable"
                data={tenantsTableData}
                columns={[
                  {
                    key: "name",
                    title: "Name",
                  },
                  {
                    key: "unitNumber",
                    title: "Unit Number",
                  },
                  {
                    key: "residentCode",
                    title: "Resident Code",
                  },
                  {
                    key: "email",
                    title: "Email",
                  },
                  {
                    key: "phoneNumber",
                    title: "Contact No.",
                    applyMethod: (row) => PhoneNumFormat(row.phoneNumber, true),
                  },
                  { key: "tenantStatus", title: "Status" },
                ]}
                actions={tenantTableActions}
                //rowOnClick={() => {}}
                rowOnClick={(row) => {
                  setPortfolioTree({
                    ...portfolioTree,
                    tenant: row,
                  });
                  navigate("/tenants/tenantDetails");
                }}
                initialSort={{ key: "CreatedAt", direction: "ascending" }}
              />
            </section>
          ) : null}

          {activeMenuItem === "documents" && (
            <section name="Documents and Folders" className="my-6">
              <div name="Prefix listing" className="overflow-x-auto">
                <div className="flex items-center mb-2 mt-2 ml-4">
                  <span className="text-gray-500">Documents</span>
                  {currentPrefix.split("/").map((folder, index, array) => (
                    <React.Fragment key={index}>
                      <span className="text-gray-500 ml-1 mr-1">
                        &nbsp;&gt;&nbsp;
                      </span>

                      <span
                        className={
                          "cursor-pointer " +
                          (index === 0
                            ? "text-gray-500"
                            : "text-blue-500 " + clickEffect)
                        }
                        onClick={() => {
                          if (!(index === 0)) {
                            handlePrefixClick(index);
                          }
                        }}
                        title={folder.charAt(0).toUpperCase() + folder.slice(1)}
                      >
                        {index >= 2 && index <= array.length - 4
                          ? "..."
                          : folder.charAt(0).toUpperCase() + folder.slice(1)}
                      </span>
                    </React.Fragment>
                  ))}
                </div>
                <div className="border-b-2 border-gray-400"></div>
              </div>

              <div className="flex justify-end items-center mb-4 mt-4 gap-4 mr-4">
                <div
                  name="Folders and Files Search"
                  className={"flex justify-end items-center w-80"}
                >
                  <input
                    className={
                      " border shadow rounded-2xl w-full focus:outline-none pr-[30px] py-2 px-3"
                    }
                    placeholder={"Search Files, Folders ..."}
                    value={docsSearchString}
                    onChange={handleDocumentsSearch}
                  />
                  {docsSearchString === "" && (
                    <AiOutlineSearch
                      size={25}
                      className="cursor-pointer absolute mr-[4px] text-blue-52"
                    />
                  )}
                  {docsSearchString !== "" && (
                    <TiDelete
                      size={25}
                      className="cursor-pointer absolute mr-[4px] text-red-500"
                      onClick={() => {
                        setDocsSearchString("");
                        setCurrentFolders(allFolders);
                        setDocumentsTableData(documents);
                      }}
                    />
                  )}
                </div>

                {/* <span
                  title="Create Folder"
                  className={
                    "p-2 shadow-lg rounded flex justify-center items-center cursor-pointer hover:bg-yellow-300 hover:text-white " +
                    clickEffect
                  }
                >
                  <AiOutlinePlus
                    className="w-5 h-5"
                    onClick={(e) => setOpenFolderForm(true)}
                  />
                </span> */}

                <ButtonBlueOutline
                  id="addNewFolder"
                  btnName="addNewFolder"
                  dataCy="addNewFolder"
                  title="Create Folder"
                  name={
                    <>
                      <BsPlusCircle className="w-5 h-5 mr-2" /> Add New
                    </>
                  }
                  onClick={(e) => setOpenFolderForm(true)}
                  className={`flex justify-center items-center px-2 w-fit h-10 rounded-md`}
                />
              </div>

              <div className="grid grid-cols-3 gap-4 mb-8 mx-6">
                {currentFolders.length > 0 &&
                  currentFolders.sort().map((folder, index) => (
                    <div
                      key={folder + index}
                      name={folder + index}
                      id={folder + index}
                      data-cy={folder + index}
                      className={
                        "flex items-center cursor-pointer h-10 p-2 border rounded-md w-auto max-w-[300px] overflow-x-scroll overflow-y-hidden focus:outline-none shadow-lg bg-gray-50 " +
                        clickEffect
                      }
                      onClick={(e) =>
                        getDocumentsByPrefix(`${currentPrefix}${folder}/`)
                      }
                      title={folder.charAt(0).toUpperCase() + folder.slice(1)}
                    >
                      <FcFolder className="w-5 h-5 mr-2" />
                      <span className="text-blue-500 overflow-hidden whitespace-nowrap overflow-ellipsis">
                        {folder.charAt(0).toUpperCase() + folder.slice(1)}
                      </span>
                    </div>
                  ))}
              </div>

              <TableComponent
                tableId="Files table"
                data={documentsTableData}
                const
                columns={[
                  {
                    key: "icon",
                    title: "",
                    applyMethod: (row) => (
                      <IconInCircle
                        icon={
                          <TbClipboardText
                            title={row.file}
                            className="w-5 h-5 text-blue-52"
                          />
                        }
                        radius="2rem"
                        className="shadow-xl"
                        color="#D8F3F7"
                      />
                    ),
                    width: "80px",
                  },

                  { key: "file", title: "Name", width: "80%" },
                ]}
                rowOnClick={(row) => {
                  getFileLink(row.file, "view");
                }}
                actionHeader={
                  <span
                    title="Upload File"
                    className={
                      "p-1 rounded flex justify-center items-center cursor-pointer hover:text-white hover:bg-[#228B22] hover:shadow" +
                      clickEffect
                    }
                  >
                    <TbFileUpload
                      id="uploadFile"
                      name="uploadFile"
                      data-cy="uploadFile"
                      className="w-6 h-6"
                      onClick={(e) => setOpenFileUploadForm(true)}
                    />
                  </span>
                }
                actions={filesTableActions}
                //initialSort={{ key: "file", direction: "descending" }}
              />
            </section>
          )}

          {activeMenuItem === "maintenance" && (
            <section name="maintenanceTable">
              <div className="flex justify-between items-center gap-2 m-4">
                <p className="font-semibold text-gray-600">
                  Work Orders ({maintenanceTickets.length})
                </p>

                <div className="flex justify-between items-center gap-2">
                  <ButtonCustom
                    id="addNewWorkOrderRequest"
                    btnName="addNewWorkOrderRequest"
                    dataCy="addNewWorkOrderRequest"
                    name={
                      <>
                        <BsPlusCircle className="w-5 h-5 mr-2" /> Add New
                      </>
                    }
                    disabled={
                      !(propertyInfo && propertyInfo.isActive && units.length)
                    }
                    onClick={() => setOpenMaintenanceReqPage(true)}
                    className={`flex justify-center items-center px-2 w-36 h-10 rounded-md text-blue-52 border-blue-52 
    ${
      propertyInfo && propertyInfo.isActive && units.length
        ? "hover:text-white hover:bg-blue-52"
        : "cursor-not-allowed"
    }`}
                  />

                  <SearchTableComponent
                    style=""
                    id="maintenanceTableSearch"
                    placeholder="Search Maintenance Requests.."
                    data={maintenanceTickets}
                    setTableData={setMaintenanceTicketsTableData}
                    columns={[
                      { key: "woid", title: "WorkOrder ID" },
                      { key: "unitNumber", title: "Unit Number" },
                      { key: "requestType", title: "Request Type" },
                      { key: "category", title: "Category" },
                      {
                        key: "CreatedAt",
                        title: "Date of Request",
                        applyMethod: (row) => GetDateInMMDDYYYY(row.CreatedAt),
                      },
                      {
                        key: "UpdatedAt",
                        title: "Last Updated",
                        applyMethod: (row) => GetDateInMMDDYYYY(row.UpdatedAt),
                      },
                      { key: "vendor", title: "Vendor" },
                      { key: "taskAssigned", title: "Assigned To" },
                      {
                        key: "status",
                        title: "Status",
                      },
                    ]}
                  />
                </div>
              </div>
              <TableComponent
                tableId="maintenanceTable"
                data={maintenanceTicketsTableData}
                columns={[
                  { key: "woid", title: "WorkOrder ID" },
                  propertyInfo && propertyInfo.propertyType === "Multi-Family"
                    ? { key: "unitNumber", title: "Unit Number" }
                    : null,
                  { key: "requestType", title: "Request Type" },
                  { key: "category", title: "Category" },
                  {
                    key: "CreatedAt",
                    title: "Date of Request",
                    applyMethod: (row) => GetDateInMMDDYYYY(row.CreatedAt),
                  },
                  {
                    key: "UpdatedAt",
                    title: "Last Updated",
                    applyMethod: (row) => GetDateInMMDDYYYY(row.UpdatedAt),
                  },
                  { key: "vendor", title: "Vendor" },
                  { key: "taskAssigned", title: "Assigned To" },
                  {
                    key: "status",
                    title: "Status",
                    dataClassName: "font-medium",
                    //getCellStyles: getStatusCellColor,
                    //applyMethod: (row) => changetoUpperCase(status),
                  },
                ].filter(Boolean)}
                initialSort={{ key: "CreatedAt", direction: "descending" }}
                actions={maintenanceTableActions}
                rowOnClick={(row) =>
                  navigate("/maintenance/maintenanceDetails", {
                    state: { ticketId: row.ID },
                  })
                }
                actionHeader={"Actions"}
              />
            </section>
          )}

          {activeMenuItem === "utilityInfo" ? (
            <>
              <section
                name="utilityInfoTabs"
                className="flex justify-between items-center h-10 font-medium text-gray-600 cursor-pointer mt-4 pr-8"
              >
                <div className="flex justify-start items-center w-full gap-8">
                  {utilityInfoTabs.map((tab, index) => (
                    <p
                      key={index}
                      id={tab.nickName}
                      name={tab.nickName}
                      data-cy={tab.nickName}
                      className={`hover:text-[#003C9A] focus:text-[#003C9A] pb-2 text-center w-60 ${
                        activeInnerMenuItem === tab.nickName
                          ? `text-[#003C9A] border-blue-52 font-semibold w-fit border-b-2`
                          : `text-gray-600 border-gray-600`
                      }`}
                      onClick={(e) => setActiveInnerMenuItem(tab.nickName)}
                    >
                      {tab.label}
                    </p>
                  ))}
                </div>
                <ButtonBlueOutline
                  id="addnewUtilityInfo"
                  btnName="addnewUtilityInfo"
                  dataCy="addnewUtilityInfo"
                  name={
                    <>
                      <BsPlusCircle className="w-5 h-5 mr-2" /> ADD NEW
                    </>
                  }
                  disabled={!propertyInfo || !propertyInfo.isActive}
                  onClick={() => {
                    //setOpenUtilityForm(true);
                    handleUtilityInfoForms();
                  }}
                  className={`flex justify-center items-center w-36 mb-2 
    font-semibold ${
      propertyInfo && !propertyInfo.isActive && "cursor-not-allowed"
    }
  `}
                />
              </section>

              {activeInnerMenuItem === "utilities" ? (
                <TableComponent
                  tableId="utiltiesTable"
                  data={uitlitiesTableData}
                  rowOnClick={() => {}}
                  columns={[
                    { key: "serviceName", title: "Service Name" },
                    { key: "serviceProvider", title: "Service Provider" },
                    {
                      key: "phoneNumber",
                      title: "Contact Number",
                      applyMethod: (row) =>
                        PhoneNumFormat(row.phoneNumber, true),
                    },
                    { key: "email", title: "Email Address" },
                  ]}
                  actions={utilitiesTableActions}
                />
              ) : null}

              {activeInnerMenuItem === "amenities" ? (
                <TableComponent
                  tableId="amenitiesTable"
                  data={amenitiesTableData}
                  rowOnClick={() => {}}
                  columns={[
                    {
                      key: "amenityName",
                      title: "Amenities Name",
                      width: "90%",
                    },
                  ]}
                  actionHeader="Actions"
                  actions={amenitiesTableActions}
                />
              ) : null}
            </>
          ) : null}

          {activeMenuItem === "charges" ? (
            <>
              <div className="w-full bg-white border border-gray-200 rounded-lg shadow-md mt-8">
                <div className="flex justify-between items-center gap-2 m-4">
                  <InputSelectField
                    //label="Bill Type"
                    name="billType"
                    id="billType"
                    dataCy="billType"
                    placeholder="Select a Bill Type"
                    options={utilityBillTypes}
                    value={billFilter}
                    onChange={(e) => {
                      handleUitilityBillFilter(e);
                    }}
                    notImp
                  />

                  <div className="flex justify-between items-center gap-2">
                    <SearchTableComponent
                      id="chargesSearchBar"
                      placeholder="Search Charges"
                      data={allUtilityBills}
                      setTableData={setUtilityBillsTableData}
                      columns={[
                        { key: "unitNumber", title: "Unit Number" },
                        { key: "ID", title: "Bill ID" },
                        { key: "billType", title: "Bill Type" },
                        {
                          key: "serviceFrom",
                          title: "Service from",
                          applyMethod: (row) =>
                            GetDateInMMDDYYYY(row.serviceFrom),
                        },
                        {
                          key: "serviceTo",
                          title: "Service To",
                          applyMethod: (row) =>
                            GetDateInMMDDYYYY(row.serviceTo),
                        },
                        { key: "totalConsumption", title: "Total Consumption" },
                        {
                          key: "totalCharge",
                          title: "Total Charges",
                          applyMethod: (row) =>
                            "$" + row.totalCharge.toLocaleString(),
                        },
                      ]}
                    />
                    <ButtonBlueOutline
                      id="addNewCharge"
                      name={
                        <>
                          <BsPlusCircle className="w-5 h-5 mr-2" /> Add New
                        </>
                      }
                      onClick={() => {
                        setOpenAddUtilityChargesForm(true);
                        setFormOperation("New");
                      }}
                      disabled={!propertyInfo || !propertyInfo.isActive}
                      className={`flex justify-center items-center w-36 rounded-md font-semibold ${
                        propertyInfo &&
                        !propertyInfo.isActive &&
                        "!cursor-not-allowed"
                      }`}
                    />
                  </div>
                </div>

                {utilityBillsTableData != null && (
                  <TableComponent
                    tableId="utilityBillsTable"
                    data={utilityBillsTableData}
                    columns={[
                      { key: "unitNumber", title: "Unit Number" },
                      { key: "ID", title: "Bill ID" },
                      { key: "billType", title: "Bill Type" },
                      {
                        key: "serviceFrom",
                        title: "Service from",
                        applyMethod: (row) =>
                          GetDateInMMDDYYYY(row.serviceFrom),
                      },
                      {
                        key: "serviceTo",
                        title: "Service To",
                        applyMethod: (row) => GetDateInMMDDYYYY(row.serviceTo),
                      },
                      { key: "totalConsumption", title: "Total Consumption" },
                      {
                        key: "totalCharge",
                        title: "Total Charges",
                        applyMethod: (row) =>
                          "$" + row.totalCharge.toLocaleString(),
                      },
                    ]}
                    actions={utilityBillsTableActions}
                    initialSort={{ key: "serviceTo", direction: "descending" }}
                  />
                )}
              </div>
            </>
          ) : null}

          {isSingleFamily && activeMenuItem === "inspection" && (
            <section className="mt-2">
              <div className="flex justify-between items-end m-4 gap-4">
                <InputSelectField
                  //label="Select"
                  name="inspectionType"
                  id="inspectionType"
                  dataCy="inspectionType"
                  placeholder="Select a Inspection Type"
                  notImp
                  options={inspectionTypes}
                  value={inspectionFilterValue}
                  onChange={handleInspectionFilter}
                />

                <div className="flex justify-between items-center gap-2">
                  <ButtonCustom
                    id="addNewInspection"
                    btnName="addNewInspection"
                    dataCy="addNewInspection"
                    disabled={
                      !propertyInfo ||
                      !propertyInfo.isActive ||
                      units.length === 0
                    }
                    name={
                      <>
                        <BsPlusCircle className="w-5 h-5 mr-2" /> Add New
                      </>
                    }
                    onClick={() => {
                      setFormOperation("New");
                      setOpenInspectionForm(true);
                    }}
                    className={`flex justify-center items-center px-2 w-36 h-10 rounded-md text-blue-52 border-blue-52 ${
                      !propertyInfo ||
                      !propertyInfo.isActive ||
                      units.length === 0
                        ? "cursor-not-allowed"
                        : "hover:bg-blue-52 hover:text-white"
                    }`}
                  />

                  <ExportCSV
                    data={inspectionTableData}
                    keys={[
                      { key: "inspectionCode", title: "Inspection ID" },
                      { key: "tenantName", title: "Tenant Name" },
                      { key: "inspectionType", title: "Inspection Type" },
                      {
                        key: "inspectionDate",
                        title: "Inspection Date",
                        applyMethod: (row) =>
                          GetDateInMMDDYYYY(row.inspectionDate),
                      },
                      {
                        key: "inspectionPerformedBy",
                        title: "Performed By",
                      },
                    ]}
                    fileName="Inspection.csv"
                  />

                  <ButtonIconSquare
                    id="Download"
                    btnName="download"
                    dataCy="download"
                    title="Download"
                    onClick={downloadPDF}
                    icon={<BsDownload className="w-5 h-5" />}
                    className="hover:!bg-blue-50 hover:!text-white shadow-md"
                  />
                  <SearchTableComponent
                    style=""
                    id="inspectionTableSearch"
                    placeholder="Search..."
                    ref={searchBarRef}
                    data={allInspectionRecords}
                    setTableData={setInspectionTableData}
                    columns={[
                      { key: "inspectionCode", title: "Inspection ID" },
                      { key: "tenantName", title: "Tenant Name" },
                      { key: "inspectionType", title: "Inspection Type" },
                      {
                        key: "inspectionDate",
                        title: "Inspection Date",
                        applyMethod: (row) =>
                          GetDateInMMDDYYYY(row.inspectionDate),
                      },
                      {
                        key: "inspectionPerformedBy",
                        title: "Performed By",
                      },
                    ]}
                  />
                </div>
              </div>

              <TableComponent
                tableId="inspectionTable"
                data={inspectionTableData}
                columns={[
                  { key: "inspectionCode", title: "Inspection ID" },
                  { key: "tenantName", title: "Tenant Name" },
                  { key: "inspectionType", title: "Inspection Type" },
                  {
                    key: "inspectionDate",
                    title: "Inspection Date",
                    applyMethod: (row) => GetDateInMMDDYYYY(row.inspectionDate),
                  },
                  {
                    key: "inspectionPerformedBy",
                    title: "Performed By",
                  },
                ]}
                actions={inspectionTableActions}
                rowOnClick={(row) => {
                  setEditableData(row);
                  setViewInspectionDetails(true);
                }}
                initialSort={{ key: "inspectionDate", direction: "descending" }}
              />
            </section>
          )}

          {activeMenuItem === "prospects" && (
            <section name="Prospect Table">
              <div className="flex justify-between items-center gap-2 m-4">
                {/* <InputSelectFieldMUI
                  id="reportPeriod"
                  label="Report Period:"
                  value={selProsReportPeriod}
                  onChange={handleProspectReportPeriodFilter}
                  options={["Weekly", "Monthly", "Quarterly", "Annual"]}
                  variant="variant"
                  className="w-44"
                  clearOptionLabel="Clear selection"
                /> */}

                <InputSelectField
                  label="Report Period: "
                  name="reportPeriod"
                  id="reportPeriod"
                  dataCy="reportPeriod"
                  placeholder="Select Period"
                  notImp
                  options={["All", "Weekly", "Monthly", "Quarterly", "Annual"]}
                  value={selProsReportPeriod}
                  onChange={handleProspectReportPeriodFilter}
                  style="flex items-center !w-64 whitespace-nowrap gap-2"
                />

                <div className="flex justify-between items-center gap-2">
                  <SearchTableComponent
                    style=""
                    id="prospectSearchBar"
                    placeholder="Search.."
                    ref={searchBarRef}
                    data={allProspects}
                    setTableData={setProspectsTableData}
                    columns={[
                      {
                        key: "prosId",
                        title: "Prospect Id",
                      },
                      {
                        key: "firstname",
                        title: "Applicant Name",
                        applyMethod: (row) => {
                          return row.firstname + row.lastname;
                        },
                      },
                      {
                        key: "CreatedAt",
                        title: "Enquiry Date",
                        applyMethod: (row) => GetDateInMMDDYYYY(row.CreatedAt),
                      },
                      {
                        key: "email",
                        title: "Email",
                      },
                      {
                        key: "phonenumber",
                        title: "Contact No.",
                        applyMethod: (row) => PhoneNumFormat(row.phonenumber),
                      },
                      {
                        key: "status",
                        title: "Status",
                      },
                    ]}
                  />
                </div>
              </div>

              <TableComponent
                tableId="prospectsTableData"
                dataOnClick={(row) => {}}
                data={prospectsTableData}
                columns={[
                  {
                    key: "prosId",
                    title: "Prospect Id",
                  },
                  {
                    key: "firstname",
                    title: "Applicant Name",
                    applyMethod: (row) => {
                      return row.firstname + row.lastname;
                    },
                  },
                  {
                    key: "CreatedAt",
                    title: "Enquiry Date",
                    applyMethod: (row) => GetDateInMMDDYYYY(row.CreatedAt),
                  },
                  {
                    key: "email",
                    title: "Email",
                  },
                  {
                    key: "phonenumber",
                    title: "Contact No.",
                    applyMethod: (row) => PhoneNumFormat(row.phonenumber),
                  },
                  {
                    key: "status",
                    title: "Status",
                  },
                ]}
                initialSort={{ key: "CreatedAt", direction: "descending" }}
                actionHeader={"Action Items"}
                actions={{
                  customActions: (row) => (
                    <ButtonIconCircle
                      title={"View Report"}
                      onClick={() => {
                        window.open(row.reportlink, "_blank");
                      }}
                      icon={<LiaClipboardListSolid className="w-5 h-5" />}
                      className="hover:!text-white hover:!bg-blue-53 border text-blue-52 border-blue-30 font-semibold"
                    />
                  ),
                }}
              />
            </section>
          )}

          {activeMenuItem === "vendor" ? (
            <>
              <section name="Table and options" className="">
                <TableComponent
                  tableId="VendorsTable"
                  data={vendors}
                  columns={[
                    { key: "companyName", title: "Vendor Company Name" },
                    {
                      key: "firstName",
                      title: "Vendor Contact",
                      applyMethod: (row) => {
                        return row.firstName + " " + row.lastName;
                      },
                    },
                    {
                      key: "contactNo",
                      title: "Contact No",
                      applyMethod: (row) => PhoneNumFormat(row.contactNo, true),
                    },
                    { key: "email", title: "Email" },
                    { key: "vendorType", title: "Type" },
                  ]}
                  rowOnClick={GotoVendorDetails}
                />
              </section>
            </>
          ) : null}
        </div>
      )}

      {viewInspectionDetails && (
        <InspectionDetails
          trigger={viewInspectionDetails}
          setTrigger={setViewInspectionDetails}
          data={editableData}
          setData={(updatedData) => {
            setEditableData(updatedData);
            getAllInspectionRecords();
          }}
          tenants={tenants}
          inspectionTypes={inspectionTypes}
        />
      )}
    </>
  );
};

export default PropertyDetails;

const ChangeMultiPropertyStatus = (props) => {
  let webApi = new WebApimanager();

  const [Isinactive, setisinactive] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState("");
  const [note, setNote] = useState("");
  const [formErrors, setFormErrors] = useState("");

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  useEffect(() => {}, []);

  const validate = () => {
    const errors = {};
    if (Isinactive === "") {
      errors.Isinactive = "Confirmation is required!";
    }
    if (!note.trim()) {
      errors.note = "Note is required!";
    }
    return errors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const temp = validate();
    setFormErrors(temp);
    if (Object.keys(temp).length) {
      return;
    } else {
      if (Isinactive) {
        swal({
          title: "Are you sure?",
          text: `Property Once Made In-Active, Can't be Reverted!!!`,
          icon: "warning",
          buttons: ["Cancel", "Yes, I Acknowledge!"],
          dangerMode: true,
        }).then((confirmed) => {
          if (confirmed) {
            setApiProcessing({
              loader: true,
              message: `Updating Property Status...`,
            });

            let data = {
              //fromStatus: "",
              toStatus: "Inactive",
              note: note,
              attachments: selectedDoc ? [selectedDoc.name] : [],
              propertyId: props.propertyId,
            };

            webApi
              .post("/properties/propertyHistoryStatus", data)
              .then(async (res) => handleApiResponse(res, data))
              .catch((error) => {
                setApiProcessing({ loader: false, message: `` });
                if (error.customErrorMessage) {
                  swal("Error", error.customErrorMessage, "error");
                } else {
                  swal(
                    "Error",
                    "Failed to Update Status! Please try again later.",
                    "error"
                  );
                }
              });
            setApiProcessing({ loader: false, message: `` });
          } else {
            return;
          }
        });
      } else {
        props.setTrigger(false);
      }
    }
  };

  const handleApiResponse = async (res, data) => {
    if (res.data.errorCode) {
      setApiProcessing({ loader: false, message: `` });
      swal("Failure", res.data.errorMessage, "error");
    } else {
      if (res.data.attachments.length) {
        setApiProcessing({ loader: true, message: `Uploading Attachment...` });
        const metaData = {
          filename: selectedDoc.name,
          mediatype: selectedDoc.type,
        };
        await webApi
          .uploadFile(res.data.attachments[0], metaData, selectedDoc)
          .then((res) => {})
          .catch((error) => {
            if (error.customErrorMessage) {
              swal("Error", error.customErrorMessage, "error");
            } else {
              swal("Error", "Oops! Failed to Upload Attachment!", "error");
            }
          });
      }

      props.getUpdatedMultiPropertyData(res.data);
      setApiProcessing({ loader: false, message: `` });
      swal("Success", `Property Status is Updated successfully`, "success");
      props.setTrigger(false);
    }
  };

  return (
    props.trigger && (
      <>
        <Dialog
          open={true}
          aria-labelledby="Change Multi-Family Status Form"
          fullWidth
          maxWidth="xs"
          //PaperProps={{ sx: { height: "100%" } }}
          className="flex flex-col justify-center w-full h-fit"
        >
          <Loader open={apiProcessing.loader} message={apiProcessing.message} />

          <div className="flex justify-center items-center bg-blue-52 text-white font-semibold rounded-sm h-10 pr-2">
            <span className="flex-grow text-center">Property Status</span>
            <ButtonIconSquare
              title="Close"
              onClick={(e) => props.setTrigger(false)}
              icon={<AiOutlineCloseCircle className="w-5 h-5" />}
              className="hover:!text-red-600"
            />
          </div>

          <DialogContent dividers>
            <div className="flex flex-col items-center justify-center gap-3 mx-auto">
              <InputObjectSelectField
                label="Do you want to make the Property In-Active?"
                name="propertyStatus"
                id="propertyStatus"
                dataCy="propertyStatus"
                placeholder="Choose a Property Status"
                value={Isinactive.toString()}
                onChange={(e) => {
                  const status = JSON.parse(e.target.value);
                  if (status) {
                    setisinactive(status);
                  } else {
                    setisinactive(status);
                  }
                }}
                options={[
                  { name: "Yes", value: "true" },
                  { name: "No", value: "false" },
                ]}
                optionValue="value"
                optionName="name"
                style="!w-full"
                errorText={formErrors.Isinactive}
              />

              <InputFileField
                label="Attach Document"
                name="attachDocs"
                id="attachDocs"
                dataCy="attachDocs"
                accept=".pdf"
                maxSizeinMB={10}
                notImp
                placeholder="Select Document"
                value={selectedDoc}
                setValue={setSelectedDoc}
                errorText={formErrors.selectedDoc}
                hintText="File should be less than 10 MB"
                style="!w-full"
              />

              <InputTextArea
                label="Note"
                name="note"
                id="note"
                dataCy="note"
                type="text"
                placeholder="Enter Reason for Status Change..."
                value={note}
                onChange={(e) => setNote(e.target.value)}
                errorText={formErrors.note}
                style="!w-full"
                className="h-20 rounded-lg"
              />
            </div>

            <div className="flex justify-center items-center gap-16 my-6">
              <ButtonGrayOutline
                name="Cancel"
                id="cancel"
                btnName="cancel"
                dataCy="cancel"
                title="Close the Form"
                onClick={(e) => props.setTrigger(false)}
                className="justify-self-end"
              />
              <ButtonBlue name="Confirm" onClick={handleSubmit} className="" />
            </div>
          </DialogContent>
        </Dialog>
      </>
    )
  );
};

const ViewMultiPropertyStatusNotes = (props) => {
  let webApi = new WebApimanager();

  const [notes, setNotes] = useState([]);

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  useEffect(() => {
    setApiProcessing({
      loader: true,
      message: `Gathering Property Status Change Notes...`,
    });
    webApi
      .get(`/properties/propertyHistoryStatus/${props.propertyId}`)
      .then(async (res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setNotes(res.data);
        }
        setApiProcessing({ loader: false, message: `` });
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Unable to load Property Status Change Notes, Please try again later.`,
            "error"
          );
        }
      });
  }, []);

  const handleDelete = (event, ID) => {
    event.preventDefault();
    //props.handleDelete(ID);
  };

  return (
    props.trigger && (
      <>
        <Dialog
          open={true}
          aria-labelledby="Change Multi-Family Status Form"
          fullWidth
          maxWidth="xs"
          className="flex flex-col justify-center w-full "
        >
          <Loader open={apiProcessing.loader} message={apiProcessing.message} />

          <div className="flex justify-center items-center bg-blue-52 text-white font-semibold rounded-sm h-10 pr-2">
            <span className="flex-grow text-center">Notes</span>
            <ButtonIconSquare
              title="Close"
              id="close"
              btnName="close"
              dataCy="close"
              onClick={(e) => props.setTrigger(false)}
              icon={<AiOutlineCloseCircle className="w-5 h-5" />}
              className="hover:!text-red-600"
            />
          </div>

          <DialogContent dividers style={{ maxHeight: "80vh" }}>
            <div
              name="notesView"
              className="overflow-y-auto overscroll-contain w-full pr-4"
              style={{ height: "400px" }}
            >
              {notes.length ? (
                <>
                  {notes.map((note, index) => (
                    <div
                      key={index}
                      className={
                        "flex justify-start items-center gap-4 mb-4 w-full"
                      }
                    >
                      <span className="flex flex-col text-base w-full">
                        <p className="text-blue-53 text-sm">
                          {GetTimeYearToMin(note.CreatedAt)}
                        </p>
                        <p
                          className="font-light bg-[#E8EAF6] rounded p-2 break-words w-full"
                          style={{ maxWidth: "100%" }}
                        >
                          {note.note}
                        </p>
                      </span>
                    </div>
                  ))}
                </>
              ) : (
                <div
                  className={
                    "flex justify-center items-center gap-4 mb-4 w-full"
                  }
                >
                  <p className="text-base font-light bg-[#E8EAF6] rounded p-2 break-words">
                    No Data Available
                  </p>
                </div>
              )}
            </div>
          </DialogContent>
        </Dialog>
      </>
    )
  );
};
