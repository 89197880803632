import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { WebApimanager } from "../../WebApiManager";
import { ButtonIconSquare } from "../../Widgets/Buttons";
import { Dialog, DialogContent } from "@mui/material";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { InputObjectSelectField } from "../../Widgets/Forms/InputFields";
import { InputSelectField } from "../../Widgets/Forms/InputFields";
import { TablePagination } from "@mui/material";
import { ButtonGrayOutline, ButtonBlue } from "../../Widgets/Buttons";
import style from "./index.module.css";
import swal from "sweetalert";
import { clientId } from "../../atoms";
import { useRecoilValue } from "recoil";

const AttachFolioOrProp = (props) => {
  let webApi = new WebApimanager();
  let navigate = useNavigate();
  const [type, setType] = useState("portfolio");
  const [allPortfolios, setAllPortfolios] = useState([]);
  const [allFolioNames, setAllFolioNames] = useState([]);
  const [propertyTypes, setPropertyTypes] = useState();
  const [tableData1, setTableData1] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const [allproperties, setAllProperties] = useState([]);
  //folis
  const [selectAllFolios, setSelectAllFolios] = useState(false);
  const [selectedFolios, setSelectedFolios] = useState([]);
  //properties
  const [selectedAllProperties, setSelectedAllProperties] = useState(false);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const clientIdVal = useRecoilValue(clientId);
  const [selectedFolio, setSelectedFolio] = useState("");
  const [selectedFolioId, setSelectedFolioId] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    // if (type === "portfolio") {
    //   getPortfolios();
    // } else {
    //   //getProperties();
    // }
    getPortfolios();
    getProperties();
  }, []);

  const handleRadioChange = (e) => {
    setType(e.target.value);

    if (e.target.value === "portfolio") {
      getPortfolios();
    } else if (e.target.value === "property") {
      //getProperties();
    }
  };

  const getPortfolios = () => {
    webApi
      .get("/portfolios/client/" + clientIdVal)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          //console.log("data", res.data)
          // setAllPortfolios(res.data)
          setTableData1(res.data);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Oops! Failed to get Portfolios, Please try again later.",
            "error"
          );
        }
      });
  };

  const getProperties = () => {
    webApi
      .get("/properties/clientId/" + clientIdVal)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          //console.log("data", res.data)
          setTableData2(res.data);
          setAllProperties(res.data);
          const uniquePortfolioNames = new Set(
            res.data.map((item) => item.portfolioName)
          );
          //setAllFolioNames(Array.from(uniquePortfolioNames));
          setAllFolioNames(["All", ...uniquePortfolioNames]);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Oops! Failed to get Portfolios, Please try again later.",
            "error"
          );
        }
      });
  };
  //Handling folios
  const handleAllSelectedFolios = (checked) => {
    if (checked) {
      setSelectedFolios(tableData1.map((item) => item.ID));
    } else {
      setSelectedFolios([]);
    }
    setSelectAllFolios(!selectAllFolios);
    //console.log("folios",selectedFolios)
  };

  const handleSelectedFolio = (checked, row) => {
    if (checked) {
      setSelectedFolios([...selectedFolios, row.ID]);
    } else {
      setSelectedFolios(selectedFolios.filter((item) => item !== row.ID));
    }
    //console.log("folios",selectedFolios)
  };

  //Handling propertis
  const handleAllSelectedProperties = (checked) => {
    if (checked) {
      setSelectedProperties(tableData2.map((item) => item.id));
    } else {
      setSelectedProperties([]);
    }
    setSelectedAllProperties(!selectedAllProperties);
    //console.log("props",selectedProperties)
  };

  const handleSelectedProperty = (checked, row) => {
    if (checked) {
      setSelectedProperties([...selectedProperties, row.id]);
    } else {
      setSelectedProperties(
        selectedProperties.filter((item) => item !== row.id)
      );
    }
    //console.log("props",selectedProperties)
  };

  const attachPortfolio = (event) => {
    event.preventDefault();
    console.log("folios", selectedFolios);
    if (selectedFolios.length) {
      const data = selectedFolios;
      webApi
        .post(
          `/payment/stripe/connectAccount/portfolio/` + props.bankAccId,
          data
        )
        .then((res) => {
          if (res.data.errorCode) {
            swal("Error", res.data.errorMessage, "error");
          } else {
            swal(
              "Success",
              selectedFolios.length + " Portfolios Added Successfully",
              "success"
            );
            props.getAllProps();
            props.setTrigger(false);
          }
        })
        .catch((error) => {
          if (error.customErrorMessage) {
            swal("Error", error.customErrorMessage, "error");
          } else
            swal(
              "Error",
              "Oops, Unable to add PortFolios! Please try again later.",
              "error"
            );
        });
    } else {
      swal("Warning", "Please select Portfolios to proceed!", "warning");
    }
  };

  const attachProperty = (event) => {
    event.preventDefault();
    console.log("props", selectedProperties);
    if (selectedProperties.length) {
      const data = selectedProperties;
      webApi
        .post(
          `/payment/stripe/connectAccount/property/` + props.bankAccId,
          data
        )
        .then((res) => {
          if (res.data.errorCode) {
            swal("Error", res.data.errorMessage, "error");
          } else {
            swal(
              "Success",
              selectedProperties.length + " Properties Added Successfully",
              "success"
            );
            props.getAllProps();
            props.setTrigger(false);
          }
        })
        .catch((error) => {
          if (error.customErrorMessage) {
            swal("Error", error.customErrorMessage, "error");
          } else
            swal(
              "Error",
              "Oops, Unable to add Properties! Please try again later.",
              "error"
            );
        });
    } else {
      swal("Warning", "Please select properties to proceed!", "warning");
    }
  };

  const handleFolioFilter = (e) => {
    const folioName = e.target.value;
    if (folioName === "All") {
      setTableData2(allproperties);
    } else {
      const filteredData = allproperties.filter(
        (item) => item.portfolioName === folioName
      );
      setTableData2(filteredData);
    }
    setSelectedFolio(e.target.value);
  };

  const handlePropTypeFilter = (e) => {
    const propType = e.target.value;
    if (propType === "All") {
      setTableData2(allproperties);
    } else {
      const filteredData = allproperties.filter(
        (item) => item.propertyType === propType
      );
      setTableData2(filteredData);
    }
    setPropertyTypes(e.target.value);
  };

  return props.trigger ? (
    <>
      <Dialog
        open={true}
        aria-labelledby="Add Protfolio/Property Form"
        fullWidth
        maxWidth="lg"
        PaperProps={{ sx: { height: "80%" } }}
        //scroll="body"
        className="flex flex-col justify-center w-full"
      >
        <div className="flex justify-center items-center bg-blue-52 text-white font-semibold rounded-sm h-10 pr-2">
          <span className="flex-grow text-center">ADD PORTFOLIO/PROPERTY</span>
          <ButtonIconSquare
            title="Close Portfolio Form"
            onClick={(e) => props.setTrigger(false)}
            icon={<AiOutlineCloseCircle className="w-5 h-5" />}
            className="hover:!text-red-600"
          />
        </div>

        <DialogContent dividers>
          <form className="flex flex-col justify-center items-center">
            {/* <span className="border-b-2 border-blue-52 pb-1 mb-4 text-blue-52 w-full">
              Porfolio/Property Details
            </span> */}

            <div className="flex flex-row w-full mb-2">
              <div className="justify-start">
                <p className="mt-t mr-2 mb-2 font-semibold">Select Profile :</p>
                <div className="flex flex-row sm:flex-col">
                  <div className="flex items-center mr-10">
                    <input
                      id="portfolio"
                      type="radio"
                      data-cy="portfolio"
                      value="portfolio"
                      name="profileType"
                      checked={type === "portfolio"}
                      className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                      onChange={handleRadioChange}
                    />
                    <label
                      htmlFor="portfolio"
                      className="ml-2 text-sm font-semibold text-gray-900"
                    >
                      Portfolio
                    </label>
                  </div>
                  <div className="flex items-center mr-10 sm:mt-2">
                    <input
                      id="property"
                      type="radio"
                      value="property"
                      data-cy="property"
                      name="profileType"
                      checked={type === "property"}
                      className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                      onChange={handleRadioChange}
                    />
                    <label
                      htmlFor="property"
                      className="ml-2 font-semibold text-gray-900"
                    >
                      Property
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {type === "property" ? (
              <div className="flex flex-row w-full gap-60">
                <div className="justify-start w-96">
                  <InputSelectField
                    label="Portfolio"
                    name="dropDownPortfolio"
                    id="dropDownPortfolio"
                    dataCy="dropDownPortfolio"
                    placeholder="Select a Portfolio"
                    value={selectedFolio}
                    onChange={(e) => {
                      setSelectedFolio(e.target.value);
                      handleFolioFilter(e);
                    }}
                    options={allFolioNames}
                    style="!w-full"
                    notImp
                  />
                </div>

                <div className="justify-end w-96">
                  <InputSelectField
                    label="Property Type"
                    name="propertyType"
                    id="propertyType"
                    dataCy="propertyType"
                    placeholder="Select a Property Type"
                    value={propertyTypes}
                    onChange={(e) => {
                      setPropertyTypes(e.target.value);
                      handlePropTypeFilter(e);
                    }}
                    options={["All", "Single Family", "Multi-Family"]}
                    notImp
                    style="!w-full"
                  />
                </div>
              </div>
            ) : null}

            {type === "portfolio" ? (
              <section name="Portfolios Table" className="w-full">
                <table id="PortfolioTable" className={style.maintenance_table}>
                  <thead className="bg-blue-28 text-sm text-gray-800 h-12">
                    <tr className="text-left">
                      <th className="pl-4">
                        <input
                          title="Select/UnSelect"
                          className="cursor-pointer w-5 h-5"
                          name={`selectPortfolios`}
                          data-cy="selectPortfolios"
                          type="checkbox"
                          checked={selectAllFolios}
                          onChange={(e) =>
                            handleAllSelectedFolios(e.target.checked)
                          }
                        />
                      </th>
                      <th>Portfoilo Name</th>
                      <th>Bank Status</th>
                      <th>Address</th>
                    </tr>
                  </thead>
                  {tableData1.length > 0 ? (
                    <>
                      <tbody>
                        {tableData1
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((rows, index) => (
                            <tr
                              key={index}
                              className="h-10 bg-transparent text-left border-l-4 border-gray-600 hover:border-blue-50 hover:bg-[#FAFAFA]"
                            >
                              <td
                                className="cursor-pointer pl-4 transition-all"
                                data-label="selectedPortfolios"
                              >
                                <input
                                  title="Select/UnSelect"
                                  className="cursor-pointer w-5 h-5"
                                  name={`selectTableData`}
                                  type="checkbox"
                                  data-cy="selectUnselectFolio"
                                  checked={selectedFolios.includes(rows.ID)}
                                  onChange={(e) =>
                                    handleSelectedFolio(e.target.checked, rows)
                                  }
                                />
                              </td>
                              <td
                                data-label="Portfolio Name"
                                className="cursor-pointer hover:scale-105 transition-all"
                              >
                                {rows.portfolioName}
                              </td>
                              <td
                                data-label="Bank Status"
                                className="cursor-pointer hover:scale-105 transition-all"
                              >
                                {rows.connectedAccountId === 0
                                  ? "Not Linked"
                                  : "Linked"}
                              </td>
                              <td
                                data-label="Address"
                                className="cursor-pointer hover:scale-105 transition-all"
                              >
                                {rows.businessAddress.address1 +
                                  " " +
                                  rows.businessAddress.address2 +
                                  " " +
                                  rows.businessAddress.city +
                                  " " +
                                  rows.businessAddress.state +
                                  " " +
                                  rows.businessAddress.country}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </>
                  ) : (
                    <tbody className="text-center">
                      <tr>
                        <td
                          data-label="properties"
                          colSpan={6}
                          className="text-blue-52"
                        >
                          No data available !
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
                <TablePagination
                  component="div"
                  count={tableData1.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <div className="flex justify-center items-center gap-16 mt-6 mb-4 w-full">
                  <ButtonGrayOutline
                    id="cancel"
                    btnName="cancel"
                    dataCy="cancel"
                    name="Cancel"
                    onClick={() => props.setTrigger(false)}
                    className="mt-6 justify-self-end"
                  />
                  <ButtonBlue
                    id="attachPortfolio"
                    btnName="attachPortfolio"
                    dataCy="attachPortfolio"
                    name="Add Portfolio"
                    onClick={(e) => attachPortfolio(e)}
                    className="mt-6 w-36"
                  />
                </div>
              </section>
            ) : null}

            {type === "property" ? (
              <section name="PropertyTable" className="w-full">
                <table id="PropertyTable" className={style.maintenance_table}>
                  <thead className="bg-blue-28 text-sm text-gray-800 h-12">
                    <tr className="text-left">
                      <th className="pl-4">
                        <input
                          title="Select/UnSelect"
                          className="cursor-pointer w-5 h-5"
                          id="selectAllPorperties"
                          name={`selectProperties`}
                          data-cy="selectAllPorperties"
                          type="checkbox"
                          checked={selectedAllProperties}
                          onChange={(e) =>
                            handleAllSelectedProperties(e.target.checked)
                          }
                        />
                      </th>
                      <th>Property Name</th>
                      <th>Property Type</th>
                      <th>Portfolio Name</th>
                      <th>Bank Status</th>
                      <th>Address</th>
                    </tr>
                  </thead>
                  {tableData2.length > 0 ? (
                    <>
                      <tbody>
                        {tableData2
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((rows, index) => (
                            <tr
                              key={index}
                              className="h-10 bg-transparent text-left border-l-4 border-gray-600 hover:border-blue-50 hover:bg-[#FAFAFA]"
                            >
                              <td
                                className="cursor-pointer pl-4 transition-all"
                                data-label="selectedProperties"
                              >
                                <input
                                  title="Select/UnSelect"
                                  className="cursor-pointer w-5 h-5"
                                  name={`selectTableData`}
                                  type="checkbox"
                                  id="selectUnselectProeprty"
                                  data-cy="selectUnselectProeprty"
                                  checked={selectedProperties.includes(rows.id)}
                                  onChange={(e) =>
                                    handleSelectedProperty(
                                      e.target.checked,
                                      rows
                                    )
                                  }
                                />
                              </td>

                              <td
                                data-label="Property Name"
                                className="cursor-pointer hover:scale-105 transition-all"
                              >
                                {rows.propertyName}
                              </td>
                              <td
                                data-label="Property Type"
                                className="cursor-pointer hover:scale-105 transition-all"
                              >
                                {rows.propertyType}
                              </td>
                              <td
                                data-label="Portfolio Name"
                                className="cursor-pointer hover:scale-105 transition-all"
                              >
                                {rows.portfolioName}
                              </td>
                              <td
                                data-label="Bank Status"
                                className="cursor-pointer hover:scale-105 transition-all"
                              >
                                {rows.connectedAccountId === 0
                                  ? "Not Linked"
                                  : "Linked"}
                              </td>
                              <td
                                data-label="Address"
                                className="cursor-pointer hover:scale-105 transition-all"
                              >
                                {rows.address1 +
                                  " " +
                                  rows.address2 +
                                  " " +
                                  rows.city +
                                  " " +
                                  rows.state +
                                  " " +
                                  rows.country}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </>
                  ) : (
                    <tbody className="text-center">
                      <tr>
                        <td
                          data-label="properties"
                          colSpan={6}
                          className="text-blue-52"
                        >
                          No data available !
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
                <TablePagination
                  component="div"
                  count={tableData2.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <div className="flex justify-center items-center gap-16 mt-6 mb-4 w-full">
                  <ButtonGrayOutline
                    id="cancel"
                    btnName="cancel"
                    dataCy="cancel"
                    name="Cancel"
                    onClick={() => props.setTrigger(false)}
                    className="mt-6 justify-self-end"
                  />
                  <ButtonBlue
                    id="addProperty"
                    btnName="addProperty"
                    dataCy="addProperty"
                    name="Add Property"
                    onClick={(e) => attachProperty(e)}
                    className="mt-6 !w-fit"
                  />
                </div>
              </section>
            ) : null}
          </form>
        </DialogContent>
      </Dialog>
    </>
  ) : null;
};

export default AttachFolioOrProp;
