import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { WebApimanager } from "../../WebApiManager";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { DetailsTile } from "../../Widgets/Forms/FormReviewTiles";
import { ButtonCustom } from "../../Widgets/Buttons";
import { BsPlusCircle } from "react-icons/bs";
import { TableComponent, Searchbar } from "../../Widgets/CommonWidgets";
import AttachFolioOrProp from "./attachFolioOrProp";
import { bankId } from "../../atoms";
import { useRecoilState, useRecoilValue } from "recoil";
import { InputSelectField } from "../../Widgets/Forms/InputFields";
import swal from "sweetalert";
import { Loader } from "../../Widgets/notificationFeedbacks";
import { SearchTableComponent } from "../../Widgets/Table/searchTableComponent";

const BankDashboard = () => {
  let webApi = new WebApimanager();
  let navigate = useNavigate();
  const tabs = [{ label: "PROPERTY", nickName: "property" }];
  const [activeInnerMenuItem, setActiveInnerMenuItem] = useState("property");
  const [tableData, setTableData] = useState([]);
  const [openFolioOrProp, setOpenFolioOrProp] = useState(false);
  const currentBankId = useRecoilValue(bankId);
  const [bankDetails, setBankDetails] = useState("");
  const [bankStatus, setBankStatus] = useState("");
  const [allDetails, setAllDetails] = useState([]);
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  useEffect(() => {
    setApiProcessing({
      loader: true,
      message: `Gathering Bank details...`,
    });
    getBankAccDetails();
    getPropertiesConnectedToBank();
  }, []);

  const getBankAccDetails = () => {
    setApiProcessing({
      loader: true,
      message: `Gathering Bank details...`,
    });
    webApi
      .get("/payment/stripe/connectAccount/" + currentBankId)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          //console.log("data", res.data)
          setBankDetails(res.data);
          // if(res.data.accountType === "Card"){
          //   viewCardDetails(true)
          // }
          if (res.data.isVerified === true) {
            setBankStatus("Verified");
          } else {
            setBankStatus("Not Verified");
          }
          setApiProcessing({
            loader: false,
            message: `Loading...`,
          });
        }
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Oops! Failed to get Bank Details, Please try again later.",
            "error"
          );
        }
      });
  };

  const getPropertiesConnectedToBank = () => {
    webApi
      .get("/properties/connectedAccount/" + currentBankId)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          //console.log("data", res.data)
          setAllDetails(res.data);
          setTableData(res.data);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Oops! Failed to get Portfolios and Properties, Please try again later.",
            "error"
          );
        }
      });
  };

  return (
    <>
      <Loader open={apiProcessing.loader} message={apiProcessing.message} />
      {openFolioOrProp && (
        <AttachFolioOrProp
          trigger={openFolioOrProp}
          setTrigger={setOpenFolioOrProp}
          bankAccId={currentBankId}
          getAllProps={() => getPropertiesConnectedToBank()}
        />
      )}
      <div className="flex justify-center items-center">
        <div className="flex flex-col h-full border w-11/12">
          <section
            name="Title and Options"
            className="flex justify-between items-center bg-blue-30 h-10"
          >
            <Link to="/accounting/bankInfo">
              <span
                className="flex gap-4"
                //onClick={navigate("/bankInfo")}
              >
                <BsFillArrowLeftCircleFill
                  id="Back to Properties Table"
                  name="Back to Properties Table"
                  data-cy="Back to Properties Table"
                  title="Back to Properties Table"
                  className="text-blue-52 w-6 h-6 ml-2"
                />
                <p className="font-semibold">Account Overview</p>
              </span>
            </Link>

            <div
              name="Property Options"
              className="flex text-gray-700 items-center gap-4 mr-4"
            >
              {/* <RiDeleteBin7Line
                onClick={() => deleteProperty()}
                className="w-5 h-5 hover:text-red-500 focus:text-red-500 cursor-pointer"
              /> */}

              {/* <span
                title="Edit Property"
                onClick={(e) => {
                  setcreatePropertypage(true);
                }}
                className={
                  "p-2 rounded flex justify-center items-center cursor-pointer hover:bg-white hover:text-blue-52 hover:shadow-lg " +
                  clickEffect
                }
              >
                <TiEdit className="w-5 h-5" />
              </span> */}
            </div>
          </section>

          <section name="propertyDetails" className="grid  m-6">
            <div className="grid gap-x-8 gap-y-4 grid-cols-2">
              <div className="font-semibold text-md">
                {bankDetails && bankDetails.accountType === "Card" ? (
                  <>Card Name:</>
                ) : (
                  <>Bank Name :</>
                )}
                <span className="text-gray-700 ml-1">
                  {bankDetails && bankDetails.name}
                </span>
              </div>

              <div className="font-semibold text-md">
                {bankDetails && bankDetails.accountType === "Card" ? (
                  <>Card Number :</>
                ) : (
                  <>Account Number :</>
                )}
                <span className="text-gray-700 ml-1">
                  {/* {bankDetails && "**** **** " + bankDetails.last4} */}
                  {bankDetails.last4 ? `**** **** ${bankDetails.last4}` : "-"}
                </span>
              </div>

              <div className="font-semibold text-md">
                {bankDetails && bankDetails.accountType === "Card" ? (
                  <>Card Type :</>
                ) : (
                  <>Routing Number :</>
                )}
                <span className="text-gray-700 ml-1 uppercase">
                  {bankDetails && bankDetails.routingNumber}
                </span>
              </div>

              <div className="font-semibold text-md">
                Account Status :
                <span className="text-gray-700 ml-1">{bankStatus}</span>
              </div>
            </div>
          </section>

          <section
            name="tabs"
            className="flex h-10 font-medium text-gray-600 cursor-pointer mt-4 pl-4 border-b-2 "
          >
            {tabs.map((tab, index) => (
              <p
                key={index}
                id={tab.nickName}
                name={tab.nickName}
                data-cy={tab.nickName}
                className={`hover:text-[#003C9A] focus:text-[#003C9A] pb-2 w-full border-b-2 text-start ${
                  activeInnerMenuItem === tab.nickName
                    ? `text-[#003C9A] border-blue-52 font-semibold`
                    : `text-gray-600 border-gray-600`
                }`}
                onClick={(e) => setActiveInnerMenuItem(tab.nickName)}
              >
                {tab.label}
              </p>
            ))}
          </section>
          <div
            name="Searchbar and Options"
            className={"flex justify-between items-center mt-4 mb-2 mx-4"}
          >
            <span className="font-semibold">
              {/* <DetailsTile
            label="Select:"
            className="mr-96"
            data={
              <InputSelectField
                // name="billType"
                // id="billType"
                placeholder="All"
                //options={utilityBillTypes}
                //value={billFilter}
                //onChange={handleUitilityBillFilter}
                style="flex-row"
                notImp={true}
              />
            }
            labelClassName="justify-self-end mr-[12px]"
          /> */}
            </span>
            <span className="flex items-center gap-4">
              <ButtonCustom
                id="addnew"
                btnName="addNew"
                dataCy="addNew"
                name={
                  <>
                    <BsPlusCircle className="w-4 h-4 mr-2" /> ADD FOLIO/PROPERTY
                  </>
                }
                onClick={() => {
                  setOpenFolioOrProp(true);
                }}
                className={`flex justify-center items-center rounded-lg h-8 mr-2 px-2 w-auto text-red-10 font-semibold text-sm bg-transparent hover:text-red-10 focus:text-red-10 border-red-10`}
              />

              <SearchTableComponent
                id="bankDashboardSearch"
                placeholder="Search..."
                data={allDetails}
                setTableData={setTableData}
                columns={[
                  { key: "propertyName", title: "Property Name" },
                  { key: "propertyType", title: "Property Type" },
                  { key: "noOfUnits", title: "Number Of Units" },
                  { key: "portfolioName", title: "Portfolio Name" },
                  {
                    key: "isActive",
                    title: "Property Status",
                    applyMethod: (row) => {
                      if (row.isActive === true) {
                        return "Active";
                      } else {
                        return "In Active";
                      }
                    },
                  },
                ]}
              />
            </span>
          </div>
          <TableComponent
            tableId="Folio/PropTable"
            data={tableData}
            columns={[
              { key: "propertyName", title: "Property Name" },
              { key: "propertyType", title: "Property Type" },
              { key: "noOfUnits", title: "Number Of Units" },
              { key: "portfolioName", title: "Portfolio Name" },
              {
                key: "isActive",
                title: "Property Status",
                applyMethod: (row) => {
                  if (row.isActive === true) {
                    return "Active";
                  } else {
                    return "In Active";
                  }
                },
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};

export default BankDashboard;
