import React, { useEffect, useState } from "react";
import { Searchbar, TableComponent } from "../../Widgets/CommonWidgets";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { portfolioTreeStore } from "../../atoms";
import { PhoneNumFormat } from "../../Widgets/Forms/FieldDependants";
import { ButtonIconSquare } from "../../Widgets/Buttons";
import { BsDownload } from "react-icons/bs";

import swal from "sweetalert";
import ExportCSV from "../../Widgets/ExportCSV";
import { DownloadVacateNoticeReportPdfAPI } from "./reportsAPI";
import { SearchTableComponent } from "../../Widgets/Table/searchTableComponent";

export const VacateNoticeReport = (props) => {
  let navigate = useNavigate();
  const [portfolioTree, SetPortfolioTree] = useRecoilState(portfolioTreeStore);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(props.data || []);
  }, [props.data]);

  const downloadPDF = () => {
    let requestData = {
      portfolioId: props.portfolio.ID,
    };

    DownloadVacateNoticeReportPdfAPI(requestData).then((res) => {
      window.open(res.data.url, "_blank");
    });
  };

  return (
    <>
      <div className="flex items-center justify-end gap-4 mb-2 mx-2">
        <ExportCSV
          data={tableData}
          keys={[
            {
              key: "portfolioName",
              title: "Portfolio Name",
            },
            {
              key: "address1",
              title: "Property Address/ Unit No.",
              applyMethod: (row) => {
                if (row.propertyType === "Single Family") {
                  return row.address1;
                } else return row.unitNumber;
              },
            },
            {
              key: "vacateID",
              title: "Vacate Notice ID",
            },
            {
              key: "tenantName",
              title: "Tenant Name",
            },
            {
              key: "phone_number",
              title: "Contact No.",
              applyMethod: (row) => PhoneNumFormat(row.phone_number),
            },
            {
              key: "tenant_email",
              title: "Email ID",
            },
            {
              key: "status",
              title: "Status",
            },
          ]}
          fileName="Vacate Notice Report.csv"
          className="bg-white"
        />
        <ButtonIconSquare
          id="Download"
          btnName="download"
          dataCy="download"
          title="Download PDF"
          onClick={downloadPDF}
          icon={<BsDownload className="w-5 h-5" />}
          className="hover:!bg-blue-50 hover:!text-white shadow-md bg-white"
        />
        <SearchTableComponent
          id="vacateNoticeSearch"
          placeholder="Search..."
          data={props.data}
          setTableData={setTableData}
          columns={[
            {
              key: "portfolioName",
              title: "Portfolio Name",
            },
            {
              key: "address1",
              title: "Property Address/ Unit No.",
              applyMethod: (row) => {
                if (row.propertyType === "Single Family") {
                  return row.address1;
                } else return row.unitNumber;
              },
            },
            {
              key: "vacateID",
              title: "Vacate Notice ID",
            },
            {
              key: "tenantName",
              title: "Tenant Name",
            },
            {
              key: "phone_number",
              title: "Contact No.",
              applyMethod: (row) => PhoneNumFormat(row.phone_number),
            },
            {
              key: "tenant_email",
              title: "Email ID",
            },
            {
              key: "status",
              title: "Status",
            },
          ]}
        />
      </div>
      <TableComponent
        tableId="delinquencyTable"
        className={"bg-white"}
        data={tableData}
        columns={[
          {
            key: "portfolioName",
            title: "Portfolio Name",
          },
          {
            key: "address1",
            title: "Property Address/ Unit No.",
            applyMethod: (row) => {
              if (row.propertyType === "Single Family") {
                return row.address1;
              } else return row.unitNumber;
            },
          },
          {
            key: "vacateID",
            title: "Vacate Notice ID",
            width: "120px",
          },
          {
            key: "tenantName",
            title: "Tenant Name",
            getCellStyles: (row) => {
              return { color: "blue", textDecoration: "underline" };
            },
            dataOnClick: (row) => {
              SetPortfolioTree({
                ...portfolioTree,
                tenant: {
                  unitID: row.unitId,
                  tenantCode: row.tenantCode,
                  tenantID: row.tenantId,
                  activeMenuItem: "delinquency",
                  activeDelinquencyTab: "vacateNotice",
                },
              });
              navigate("/tenants/tenantDetails");
            },
          },
          {
            key: "phone_number",
            title: "Contact No.",
            applyMethod: (row) => PhoneNumFormat(row.phone_number),
          },
          {
            key: "tenant_email",
            title: "Email ID",
          },
          {
            key: "status",
            title: "Status",
          },
        ]}
      />
    </>
  );
};
