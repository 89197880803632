import React, { useState, useCallback } from "react";
import { WebApimanager } from "../../WebApiManager";
import AddressComponent from "../../Widgets/Forms/AddressComponent";
import { TextReviewTile } from "../../Widgets/Forms/FormReviewTiles";
import {
  ButtonBlue,
  ButtonGrayOutline,
  ButtonIconSquare,
} from "../../Widgets/Buttons";
import InputField, {
  InputPhoneNumField,
  InputSelectField,
} from "../../Widgets/Forms/InputFields";
import SnackAlert from "../../Widgets/SnackbarAlert";
import { clientId, companyId, userData } from "../../atoms";
import { useRecoilValue } from "recoil";
import {
  Dialog,
  Stepper,
  StepLabel,
  Step,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import swal from "sweetalert";
import { useEffect } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
// import { einFormat } from "../../Widgets/Forms/FieldDependants";

const AddOwner = (props) => {
  let webApi = new WebApimanager();
  const clientIdVal = useRecoilValue(clientId);
  const companyIdVal = useRecoilValue(companyId);
  const userInfo = useRecoilValue(userData);
  const editForm = props.operation === "Edit" ? true : false;
  const [companyName, setCompanyName] = useState(
    editForm ? props.data.companyName : ""
  );
  // const [einSsn, setEinSsn] = useState(editForm ? einFormat(props.data.landlord.ein) : "")
  const [firstName, setFirstName] = useState(
    editForm ? props.data.landlord.firstName : ""
  );
  const [lastName, setLastname] = useState(
    editForm ? props.data.landlord.lastName : ""
  );
  const [email, setEmail] = useState(editForm ? props.data.landlord.email : "");
  const [phoneNum, setPhoneNum] = useState(
    editForm ? props.data.landlord.phoneNumber : ""
  );
  const [ownerRole, setOwnerRole] = useState(editForm ? props.data.role : "");
  const ownerRoleOptions = ["Managing Partner", "Partner", "Sole Owner"];
  const [completeAddress, setCompleteAddress] = useState({
    address1: editForm ? props.data.landlord.businessAddress.address1 : "",
    address2: editForm ? props.data.landlord.businessAddress.address2 : "",
    city: editForm ? props.data.landlord.businessAddress.city : "",
    state: editForm ? props.data.landlord.businessAddress.state : "",
    country: editForm ? props.data.landlord.businessAddress.country : "",
    zipCode: editForm ? props.data.landlord.businessAddress.zipCode : "",
  });
  const [formErrors, setFormErrors] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [apiProcessing, setApiProcessing] = useState(false);

  useEffect(() => {
    console.log(props.operation);
  }, []);

  const validate = () => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (activeStep === 0) {
      // if (!einSsn) {
      //   errors.einSsn = "SSN No is required!";
      // }
      if (!firstName) {
        errors.firstName = "First Name is required!";
      }
      if (!lastName) {
        errors.lastName = "Last Name is required!";
      }
      if (!email) {
        errors.email = "Email is required!";
      } else if (!emailRegex.test(email)) {
        errors.email = "Email is not valid!";
      }
      if (!phoneNum || phoneNum.length !== 12) {
        errors.phoneNum = "Contact number is not Valid!";
      }
      if (!ownerRole) {
        errors.ownerRole = "Owner Role is required!";
      }
    }

    if (activeStep === 1) {
      if (!completeAddress.address1) {
        errors.address1 = "Address 1 is required!";
      }
      if (!completeAddress.city) {
        errors.city = "City is required!";
      }
      if (!completeAddress.state) {
        errors.state = "State is required!";
      }
      if (!completeAddress.country) {
        errors.country = "Country is required!";
      }
      if (!completeAddress.zipCode) {
        errors.zipCode = "Zipcode is required!";
      } else if (completeAddress.zipCode.toString().length !== 5) {
        errors.zipCode = "Zipcode is not valid!";
      }
    }

    return errors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    //const numericValue = parseInt(einSsn.replace(/-/g, ''), 10);
    // setEinSsn(numericValue);
    if (editForm) {
      let data = {
        clientId: clientIdVal,
        phoneNumber: phoneNum,
        businessAddress: {
          address1: completeAddress.address1,
          address2: completeAddress.address2,
          city: completeAddress.city,
          state: completeAddress.state,
          country: completeAddress.country,
          zipCode: completeAddress.zipCode,
        },
      };
      webApi
        .put("/landlord/" + props.data.landlordID, data)
        .then((res) => {
          if (res.data.status) {
            swal("Success", res.data.message, "success");
            props.setTrigger(false);
            props.getOwnerData();
          } else {
            swal("Error", res.data.message, "error");
          }
          setApiProcessing({
            loader: false,
            message: `Loading...`,
          });
        })
        .catch((error) => {
          setApiProcessing({
            loader: false,
            message: `Loading...`,
          });
          if (error.customErrorMessage) {
            swal("Error", error.customErrorMessage, "error");
          } else {
            swal(
              "Error",
              `Oops! Failed to update Owner, Please try again later.`,
              "error"
            );
          }
        });
    } else {
      let data = {
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phoneNum,
        email: email,
        businessAddress: {
          address1: completeAddress.address1,
          address2: completeAddress.address2,
          city: completeAddress.city,
          state: completeAddress.state,
          country: completeAddress.country,
          zipCode: completeAddress.zipCode,
        },
        role: ownerRole,
        clientId: clientIdVal,
        //ein: numericValue,
        companyName: companyName,
      };
      webApi
        .post("/landlord", data)
        .then((res) => {
          if (res.data.errorCode) {
            swal("Error", res.data.errorMessage, "error");
          } else {
            swal("Success", res.data.message, "success");
            props.setTrigger(false);
            props.getOwnerData();
          }
          setApiProcessing({
            loader: false,
            message: `Loading...`,
          });
        })
        .catch((error) => {
          setApiProcessing({
            loader: false,
            message: `Loading...`,
          });
          if (error.customErrorMessage) {
            swal("Error", error.customErrorMessage, "error");
          } else {
            swal(
              "Error",
              `Oops! Failed to add Owner, Please try again later.`,
              "error"
            );
          }
        });
    }
  };

  const handleSteps = (event) => {
    event.preventDefault();

    const temp = validate();
    setFormErrors(temp);
    if (Object.keys(temp).length) {
      return;
    } else {
      setActiveStep((currentStep) => currentStep + 1);
    }
  };

  const handleBack = (event) => {
    event.preventDefault();
    setActiveStep((currentStep) => currentStep - 1);
  };

  const setNewAddress = useCallback(
    (newAddress) => {
      setCompleteAddress(newAddress);
    },
    [completeAddress]
  );

  // const handleEinSsnChange = (e) => {
  //   let value = e.target.value.replace(/\D/g, ''); // Remove all non-numeric characters
  //   if (value.length > 9) {
  //     value = value.slice(0, 9); // Limit to 9 characters
  //   }

  //   if (value.length > 2) {
  //     value = value.slice(0, 2) + '-' + value.slice(2); // Add the hyphen after the second digit
  //   }

  //   setEinSsn(value);
  // };

  return props.trigger ? (
    <>
      <Dialog
        open={true}
        aria-labelledby="Add Owner Form"
        fullWidth
        maxWidth="lg"
        //PaperProps={{ sx: { height: "80%" } }}
        //scroll="body"
        className="flex flex-col justify-center w-5/6 mx-auto"
      >
        <div className="flex justify-center items-center bg-blue-52 text-white font-semibold rounded-sm h-10 pr-2">
          <span className="flex-grow text-center">Add Owner</span>
          <ButtonIconSquare
            title="Close Owner Form"
            id="closeOwnerFrom"
            btnName="closeOwnerFrom"
            dataCy="closeOwnerFrom"
            onClick={(e) => props.setTrigger(false)}
            icon={<AiOutlineCloseCircle className="w-5 h-5" />}
            className="hover:!text-red-600"
          />
        </div>

        <Stepper activeStep={activeStep} alternativeLabel className="my-4">
          <Step>
            <StepLabel>Personal Information</StepLabel>
          </Step>
          <Step>
            <StepLabel>Address </StepLabel>
          </Step>
          <Step>
            <StepLabel>Review Details</StepLabel>
          </Step>
        </Stepper>

        <DialogContent dividers>
          <form className="flex flex-col justify-center items-center">
            {activeStep === 0 ? (
              <>
                <span className="border-b-2 border-blue-52 pb-1 mb-4 text-blue-52 w-5/6 md:gap-x-8 md:w-11/12">
                  Personal Information
                </span>
                <section className="grid grid-cols-2 items-start gap-x-14 gap-y-4 w-5/6 mb-4 md:gap-x-8 md:w-11/12">
                  <InputField
                    label="First Name"
                    name="firstName"
                    id="firstName"
                    dataCy="firstName"
                    type="text"
                    placeholder="Enter First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    errorText={formErrors.firstName}
                    style="!w-full"
                    readOnly={editForm}
                    disabled={editForm}
                  />

                  <InputField
                    label="Last name"
                    name="lastName"
                    id="lastName"
                    dataCy="lastName"
                    type="text"
                    placeholder="Enter Last Name"
                    value={lastName}
                    onChange={(e) => setLastname(e.target.value)}
                    errorText={formErrors.lastName}
                    style="!w-full"
                    readOnly={editForm}
                    disabled={editForm}
                  />

                  <InputField
                    label="Email ID"
                    name="email"
                    id="email"
                    dataCy="email"
                    type="text"
                    placeholder="Enter Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    errorText={formErrors.email}
                    style="!w-full"
                    readOnly={editForm}
                    disabled={editForm}
                  />

                  <InputPhoneNumField
                    label="Contact Number"
                    name="phoneNumber"
                    id="phoneNumber"
                    dataCy="phoneNumber"
                    placeholder="Enter Contact Number"
                    value={phoneNum}
                    setValue={setPhoneNum}
                    errorText={formErrors.phoneNum}
                    style="!w-full"
                  />

                  <InputField
                    label="Company Name"
                    name="companyName"
                    id="companyName"
                    dataCy="companyName"
                    type="text"
                    placeholder="Enter Company Name"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    notImp
                    style="!w-full"
                    readOnly={editForm}
                    disabled={editForm}
                  />

                  {/* <InputField
                    label="SSN No"
                    name="einSsn"
                    id="einSsn"
                    dataCy="einSsn"
                    type="text"
                    placeholder="SSN No"
                    value={einSsn}
                    onChange={(e) =>
                      handleEinSsnChange(e)
                    }
                    errorText={formErrors.einSsn}
                    style="!w-full"
                    maxLength={11}
                    readOnly={editForm}
                  /> */}

                  <div>
                    <InputSelectField
                      label="Ownership"
                      name="ownerRole"
                      id="ownerRole"
                      placeholder="Select an Role"
                      value={ownerRole}
                      onChange={(e) => {
                        setOwnerRole(e.target.value);
                      }}
                      options={ownerRoleOptions}
                      errorText={formErrors.ownerRole}
                      className=""
                      disabled={editForm}
                    />
                  </div>

                  <ButtonGrayOutline
                    name="Cancel"
                    id="cancel"
                    btnName="cancel"
                    dataCy="cancel"
                    onClick={() => props.setTrigger(false)}
                    className="mt-6 justify-self-end"
                  />
                  <ButtonBlue
                    name="Next"
                    id="next"
                    btnName="next"
                    dataCy="next"
                    onClick={(e) => handleSteps(e)}
                    className="mt-6"
                  />
                </section>
              </>
            ) : null}

            {activeStep === 1 ? (
              <>
                <span className="border-b-2 border-blue-52 pb-1 mb-4 text-blue-52 w-5/6 md:gap-x-8 md:w-11/12">
                  Address
                </span>
                <section className="grid grid-cols-2 gap-x-16 gap-y-6 w-5/6 mb-4 md:gap-x-8 md:w-11/12">
                  <AddressComponent
                    address={completeAddress}
                    setNewAddress={setNewAddress}
                    formErrors={formErrors}
                    style="!w-full"
                  />
                </section>
                <div className="flex justify-center items-center gap-16 mt-6 mb-4 w-3/4">
                  <ButtonGrayOutline
                    name="Back"
                    id="back"
                    btnName="back"
                    onClick={(e) => handleBack(e)}
                    className="mt-6 justify-self-end"
                  />
                  <ButtonBlue
                    name="Next"
                    id="next"
                    btnName="next"
                    onClick={(e) => handleSteps(e)}
                    className="mt-6"
                  />
                </div>
              </>
            ) : null}

            {activeStep === 2 ? (
              <>
                <span className="border-b-2 border-blue-52 pb-1 mb-4 text-blue-52 w-5/6 md:gap-x-8 md:w-11/12">
                  Review Details
                </span>
                <section className="grid grid-cols-4 gap-x-16 gap-y-6 w-5/6 justify-around items-center md:gap-x-8 md:w-11/12">
                  {/* <TextReviewTile title="SSN No" body={einSsn} /> */}
                  <TextReviewTile title="First Name" body={firstName} />
                  <TextReviewTile title="Last Name" body={lastName} />
                  <TextReviewTile title="Email ID" body={email} />
                  <TextReviewTile title="Contact No" body={phoneNum} />
                  <TextReviewTile title="Company Name" body={companyName} />
                  <TextReviewTile title="Ownership" body={ownerRole} />
                  <TextReviewTile
                    title="Address 1"
                    body={completeAddress.address1}
                  />
                  <TextReviewTile
                    title="Address 2"
                    body={completeAddress.address2}
                  />
                  <TextReviewTile title="City" body={completeAddress.city} />
                  <TextReviewTile title="State" body={completeAddress.state} />
                  <TextReviewTile
                    title="Country"
                    body={completeAddress.country}
                  />
                  <TextReviewTile
                    title="Zipcode"
                    body={completeAddress.zipCode}
                  />
                </section>

                <div className="flex justify-center items-center gap-16 mt-6 mb-4 w-3/4">
                  <ButtonGrayOutline
                    name="Back"
                    id="back"
                    btnName="back"
                    dataCy="back"
                    onClick={(e) => handleBack(e)}
                    hidden={apiProcessing}
                  />
                  <ButtonBlue
                    id="confirm"
                    btnName="confirm"
                    name="Confirm"
                    onClick={handleSubmit}
                  />
                </div>
              </>
            ) : null}
          </form>
        </DialogContent>
      </Dialog>
    </>
  ) : (
    ""
  );
};

export default AddOwner;
