import React, { useEffect, useState } from "react";
import ExportCSV from "../../Widgets/ExportCSV";
import { ButtonIconSquare } from "../../Widgets/Buttons";
import { BsDownload } from "react-icons/bs";
import { DownloadUnitDirectoryReportPdfAPI } from "./reportsAPI";
import {
  GetDenominationWithDollar,
  Searchbar,
  TableComponent,
} from "../../Widgets/CommonWidgets";
import { SearchTableComponent } from "../../Widgets/Table/searchTableComponent";

export const UnitsDirectoryReport = (props) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(props.data || []);
  }, [props.data]);

  const downloadPDF = () => {
    let requestData = {
      propertyId: props.property.ID,
    };

    DownloadUnitDirectoryReportPdfAPI(requestData).then((res) => {
      window.open(res.data.url, "_blank");
    });
  };

  const getFullAddress = (data) => {
    const { address1, address2, city, state, country, zipCode } = data.address;
    return `${address1}${address2 ? `, ${address2}` : ""}${
      city ? `, ${city}` : ""
    } ${state ? `, ${state}` : ""}${country ? `, ${country}` : ""}${
      zipCode ? `, ${zipCode}` : ""
    }`;
  };

  const getFullAddressCSV = (data) => {
    const { address1, address2, city, state, country, zipCode } = data.address;
    return `${address1}${address2 ? ` ${address2}` : ""}${
      city ? ` ${city}` : ""
    } ${state ? ` ${state}` : ""}${country ? ` ${country}` : ""}${
      zipCode ? ` ${zipCode}` : ""
    }`;
  };

  return (
    <>
      <div className="flex justify-end items-center gap-4 mx-2 my-4">
        <ExportCSV
          data={tableData}
          keys={[
            { key: "unitNumber", title: "Unit Number" },
            {
              key: "address.address1",
              title: "Address",
              applyMethod: (row) => getFullAddressCSV(row),
            },
            { key: "floorPlan.unitTypeName", title: "Floor Plans" },
            {
              key: "rent",
              title: "Rent",
              applyMethod: (row) => {
                return "$" + row.rent.toLocaleString();
              },
            },
            {
              key: "deposit",
              title: "Deposit",
              applyMethod: (row) => {
                return "$" + row.deposit.toLocaleString();
              },
            },
            { key: "sqFt", title: "Sqft" },
            // {
            //   key: "floorPlan.numberOfRooms",
            //   title: "No. of Rooms",
            //   width: "140px",
            // },
            {
              key: "floorPlan.numberOfBeds",
              title: "No. of Beds",
            },
            {
              key: "floorPlan.numberOfBaths",
              title: "No. of Baths",
            },
          ]}
          fileName="Unit Directory.csv"
          className={"bg-white"}
        />
        <ButtonIconSquare
          id="Download"
          btnName="download"
          dataCy="download"
          title="Download PDF"
          onClick={downloadPDF}
          icon={<BsDownload className="w-5 h-5" />}
          className="hover:!bg-blue-52 hover:!text-white shadow-md bg-white"
        />
        <SearchTableComponent
          id="unitDirectorySearchBar"
          placeholder="Search ..."
          data={props.data}
          setTableData={setTableData}
          columns={[
            { key: "unitNumber", title: "Unit Number", width: "120px" },
            {
              key: "address.address1",
              title: "Address",
              applyMethod: (row) => getFullAddress(row),
            },
            { key: "floorPlan.unitTypeName", title: "Floor Plans" },
            {
              key: "rent",
              title: "Rent",

              applyMethod: (row) => GetDenominationWithDollar(row.rent),
            },
            {
              key: "deposit",
              title: "Deposit",

              applyMethod: (row) => GetDenominationWithDollar(row.deposit),
            },
            { key: "sqFt", title: "Sqft", width: "100px" },
            {
              key: "numberOfBeds",
              title: "No. of Beds",
            },
            {
              key: "numberOfBaths",
              title: "No. of Baths",
            },
          ]}
        />
      </div>

      <TableComponent
        tableId="unitsTable"
        className="bg-white"
        data={tableData}
        columns={[
          { key: "unitNumber", title: "Unit Number", width: "130px" },
          {
            key: "address.address1",
            title: "Address",
            applyMethod: (row) => getFullAddress(row),
            width: "350px",
          },
          { key: "floorPlan.unitTypeName", title: "Floor Plans" },
          {
            key: "rent",
            title: "Rent",
            width: "100px",
            applyMethod: (row) => GetDenominationWithDollar(row.rent),
          },
          {
            key: "deposit",
            title: "Deposit",
            width: "100px",
            applyMethod: (row) => GetDenominationWithDollar(row.deposit),
          },
          { key: "sqFt", title: "Sqft", width: "100px" },
          {
            key: "numberOfBeds",
            title: "No. of Beds",
            width: "120px",
          },
          {
            key: "numberOfBaths",
            title: "No. of Baths",
            width: "100px",
          },
        ]}
        rowOnClick={() => {}}
        //initialSort={{ key: "unitNumber", direction: "descending" }}
      />
    </>
  );
};
