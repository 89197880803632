import React, { useState, useEffect } from "react";
import { WebApimanager } from "../../../WebApiManager";
import {
  ButtonBlue,
  ButtonGrayOutline,
  ButtonIconSquare,
} from "../../../Widgets/Buttons";
import { Dialog } from "@mui/material";
import InputField, {
  InputPhoneNumField,
  InputSelectField,
  InputMultiSelectField,
} from "../../../Widgets/Forms/InputFields";
import swal from "sweetalert";
import { AiOutlineCloseCircle } from "react-icons/ai";

const AddUtility = (props) => {
  let webApi = new WebApimanager();
  const editForm = props.operation === "Edit" ? true : false;
  const [formData, setFormData] = useState({
    serviceName: editForm ? props.editData.serviceName : "",
    serviceProvider: editForm ? props.editData.serviceProvider : "",
    emailId: editForm ? props.editData.email : "",
  });
  const [contactNo, setContactNo] = useState(
    editForm ? props.editData.phoneNumber : ""
  );
  const [formErrors, setFormErrors] = useState("");
  const [tags, setTags] = useState(editForm ? props.editData.tags : []);
  const [uitilityTags, setUtilityTags] = useState([]);
  const [serviceNames, setServiceNames] = useState([]);

  useEffect(() => {
    getUtilityTags();
    getServiceNames();
  }, []);

  const validate = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const errors = {};
    if (!formData.serviceName) {
      errors.serviceName = "Service Name is required!";
    }
    if (!formData.serviceProvider) {
      errors.serviceProvider = "Service Provider is required!";
    }
    if (!formData.emailId) {
      errors.emailId = "Email is required!";
    } else if (!emailRegex.test(formData.emailId)) {
      errors.emailId = "Email is not valid!";
    }
    if (!contactNo) {
      errors.contactNo = "Contact Number is required!";
    } else if (contactNo.length !== 12) {
      errors.contactNo = "Contact number is not valid!";
    }
    if (!tags.length) {
      errors.tags = "Tags is required!";
    }
    return errors;
  };

  const handleOnChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const getServiceNames = () => {
    webApi
      .get("/utility/get/serviceName")
      .then((res) => {
        if (res.data.errorCode) {
          console.log("error in service name", res.data.errorMessage, "error");
        } else {
          setServiceNames(res.data.serviceName);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Unable to get Service Names! Please try again later.",
            "error"
          );
        }
      });
  };

  const getUtilityTags = () => {
    webApi
      .get("/utility/get/tags")
      .then((res) => {
        if (res.data.errorCode) {
          console.log("error in uitlity tags", res.data.errorMessage, "error");
        } else {
          setUtilityTags(res.data.tags);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Unable to load Utility Tags! Please try again later.",
            "error"
          );
        }
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const temp = validate();
    setFormErrors(temp);
    if (Object.keys(temp).length) {
      return;
    } else {
      //console.log(props.editData)
      let data = {
        propertyId: props.PropertyId,
        serviceName: formData.serviceName,
        serviceProvider: formData.serviceProvider,
        phoneNumber: contactNo,
        email: formData.emailId,
        tags: tags,
      };
      if (editForm) {
        webApi
          .put("/utility/" + props.editData.ID, data)
          .then(async (res) => {
            if (res.data.errorCode) {
              swal("Failure", res.data.errorMessage, "error");
            } else {
              swal("Success", "Utility updated successfully", "success");
              props.setTrigger(false);
              props.getUtilitites();
            }
          })
          .catch((error) => {
            if (error.customErrorMessage) {
              swal("Error", error.customErrorMessage, "error");
            } else {
              swal(
                "Error",
                "Failed to Edit Utility Info! Please try again later.",
                "error"
              );
            }
          });
      } else {
        webApi
          .post("/utility", data)
          .then(async (res) => {
            if (res.data.errorCode) {
              swal("Failure", res.data.errorMessage, "error");
            } else {
              swal("Success", "Utility created successfully", "success");
              props.setTrigger(false);
              props.getUtilitites();
            }
          })
          .catch((error) => {
            if (error.customErrorMessage) {
              swal("Error", error.customErrorMessage, "error");
            } else {
              swal(
                "Error",
                "Failed to add Utility Info! Please try again later.",
                "error"
              );
            }
          });
      }
    }
  };

  return props.trigger ? (
    <>
      <Dialog
        open={true}
        aria-labelledby="Create Utility Form"
        fullWidth
        maxWidth="xs"
        PaperProps={{ sx: { height: "80%" } }}
        scroll="body"
        className="flex flex-col justify-center w-full"
      >
        <div className="flex justify-center items-center bg-blue-52 text-white font-semibold rounded-sm h-10 pr-2">
          <span className="flex-grow text-center">
            {editForm ? "Edit Utility" : "Add Utility"}
          </span>
          <ButtonIconSquare
            id="close"
            btnName="close"
            dataCy="close"
            title="Close"
            onClick={(e) => props.setTrigger(false)}
            icon={<AiOutlineCloseCircle className="w-5 h-5" />}
            className="hover:!text-red-600"
          />
        </div>

        <form className="flex flex-col justify-center items-center">
          <section className="flex flex-col gap-4 mt-2 w-88">
            <InputSelectField
              label="Service Name"
              name="serviceName"
              id="serviceName"
              dataCy="serviceName"
              placeholder="Select a Service Name"
              options={serviceNames}
              value={formData.serviceName}
              onChange={(e) => handleOnChange("serviceName", e.target.value)}
              errorText={formErrors.serviceName}
              style="!w-full"
            />

            <InputField
              label="Service Provider Name"
              name="serviceProvider"
              id="serviceProvider"
              dataCy="serviceProvider"
              type="text"
              placeholder="Enter Service Provider"
              value={formData.serviceProvider}
              onChange={(e) =>
                handleOnChange("serviceProvider", e.target.value)
              }
              errorText={formErrors.serviceProvider}
              style="!w-full"
            />

            <InputPhoneNumField
              label="Contact Number"
              name="contactNo"
              id="contactNo"
              dataCy="contactNo"
              placeholder="Enter Contact Number"
              value={contactNo}
              setValue={setContactNo}
              errorText={formErrors.contactNo}
              style="!w-full"
            />

            <InputField
              label="Email ID"
              name="emailId"
              id="emailId"
              dataCy="email"
              type="text"
              placeholder="abc@example.com"
              value={formData.emailId}
              onChange={(e) => handleOnChange("emailId", e.target.value)}
              errorText={formErrors.emailId}
              style="!w-full"
            />

            <InputMultiSelectField
              label="Tags"
              name="tags"
              id="tags"
              datacy="tags"
              placeholder="Select a Tag"
              options={uitilityTags}
              value={tags}
              setValue={setTags}
              errorText={formErrors.tags}
              style="!w-full"
            />

            <div className="flex justify-center items-center gap-16 mt-2 mb-2">
              <ButtonGrayOutline
                id="cancel"
                btnName="cancel"
                dataCy="cancel"
                name="Cancel"
                onClick={() => props.setTrigger(false)}
                className="mt-6 justify-self-end"
              />
              <ButtonBlue
                id="confirm"
                btnName="confirm"
                dataCy="confirm"
                name="Confirm"
                onClick={handleSubmit}
                className="mt-6"
              />
            </div>
          </section>
        </form>
      </Dialog>
    </>
  ) : null;
};

export default AddUtility;
