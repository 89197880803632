import React, { useEffect, useRef, useState } from "react";
import {
  GetDateInMMDDYYYY,
  Searchbar,
  TableComponent,
} from "../../Widgets/CommonWidgets";
import { ButtonIconCircle } from "../../Widgets/Buttons";
import { LiaClipboardListSolid } from "react-icons/lia";
import {
  GetCompleteAddress,
  GetFullAddressinTwoLines,
} from "../../Widgets/Forms/FieldDependants";
import { SearchTableComponent } from "../../Widgets/Table/searchTableComponent";

export const Prospects = (props) => {
  const searchBarRef = useRef(null);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    clearSearchField();
    setTableData(props.data);
  }, [props.data]);

  const clearSearchField = () => {
    if (searchBarRef.current) {
      searchBarRef.current.clearSearch();
    }
  };

  return (
    <>
      <div className="flex items-center justify-between gap-4 mb-2 mx-4">
        <span className="font-semibold">
          {tableData.length} Applications Received
        </span>
        <SearchTableComponent
          ref={searchBarRef}
          id="propertiesListingSearch"
          placeholder="Search..."
          data={props.data}
          setTableData={setTableData}
          columns={[
            {
              key: "prosId",
              title: "Prospect Id",
            },
            {
              key: "firstname",
              title: "Applicant Name",
              applyMethod: (row) => {
                return row.firstname + " " + row.lastname;
              },
            },
            {
              key: "CreatedAt",
              title: "Enquiry Date",
              applyMethod: (row) => GetDateInMMDDYYYY(row.CreatedAt),
            },
            {
              key: "address1",
              title: "Property Address",
              applyMethod: (row) => GetCompleteAddress(row),
            },
            {
              key: "portfolioName",
              title: "Portfolio Name",
            },
            {
              key: "status",
              title: "Application Status",
            },
          ]}
        />
      </div>
      <TableComponent
        tableId="propertiesListingTable"
        className={"bg-white"}
        data={tableData}
        rowOnClick={(row) => {}}
        columns={[
          {
            key: "prosId",
            title: "Prospect Id",
          },
          {
            key: "firstname	",
            title: "Applicant Name",
            applyMethod: (row) => {
              return row.firstname + " " + row.lastname;
            },
          },
          {
            key: "CreatedAt",
            title: "Enquiry Date",
            applyMethod: (row) => GetDateInMMDDYYYY(row.CreatedAt),
          },
          {
            key: "address1",
            title: "Property Address",
            applyMethod: (row) => GetCompleteAddress(row),
            getCellStyles: (row) => {
              return { color: "blue", textDecoration: "underline" };
            },
            dataOnClick: (row) => {
              props.handleCallBack(row);
            },
          },
          {
            key: "portfolioName",
            title: "Portfolio Name",
          },
          {
            key: "status",
            title: "Application Status",
          },
        ]}
        actionHeader={"Action Items"}
        actions={{
          customActions: (row) => (
            <ButtonIconCircle
              title={"View Report"}
              onClick={() => {
                window.open(row.reportlink, "_blank");
              }}
              icon={<LiaClipboardListSolid className="w-5 h-5" />}
              className="hover:!text-white hover:!bg-blue-53 border text-blue-52 border-blue-30 font-semibold"
            />
          ),
        }}
      />
    </>
  );
};
