import { WebApimanager } from "../../WebApiManager";

import swal from "sweetalert";
let webApi = new WebApimanager();
// API to PUT Partial Payment Status
export const UpdateCardPaymentStatusAPI = (portId, reqData) => {
  return webApi
    .put(`/paymentMethod/portfolio/${portId}`, reqData)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage); // Optional: You can throw an error to be caught later
      }
      return res; // Return the response on success
    })
    .catch((error) => {
    //   swal(
    //     "",
    //     "Unable to contact server. Please try again later.",
    //     "error"
    //   );
      throw error; // Throw error so it can be handled in the calling function
    });
};

export const UpdateBankPaymentStatusAPI = (portId, reqData) => {
    return webApi
      .put(`/paymentMethod/portfolio/${portId}`, reqData)
      .then((res) => {
        if (res.data.errorCode) {
          swal("", res.data.errorMessage, "error");
          throw new Error(res.data.errorMessage); // Optional: You can throw an error to be caught later
        }
        return res; // Return the response on success
      })
      .catch((error) => {
      //   swal(
      //     "",
      //     "Unable to contact server. Please try again later.",
      //     "error"
      //   );
        throw error; // Throw error so it can be handled in the calling function
      });
  };