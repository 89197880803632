import React, { useEffect, useState } from "react";
import { userData } from "../../atoms";

import {
  GetSubscriptionTypesAPI,
  BuySubscriptionAPI,
  ChangeSubscriptionPlanAPI,
} from "./subscriptionAPI";

import { clickEffect } from "../../Widgets/CommonWidgets";
import { ButtonCustom } from "../../Widgets/Buttons";

import { useRecoilState } from "recoil";
import { motion } from "framer-motion";
import { GiCheckMark } from "react-icons/gi";
import { GetProfileInfoByEmailAPI } from "../../CentralizedAPI/commonAPI";
import { useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Footer from "../../Widgets/Footer";

const SubscriptionPlans = () => {
  let navigate = useNavigate();
  let location = useLocation();
  let subChangeFlow = location?.state?.currentSubscription ? true : false;
  let switchSubRenewalPeriod =
    location?.state?.changeRenewalPeriodFrom &&
    location?.state?.updateCurrentSubscription
      ? true
      : false;

  const [userInfo, setUserInfo] = useRecoilState(userData);
  const [subscriptionPlans, setSubscriptionPlans] = useState(
    plans.filter((plan) => plan.billingCycle === "month")
  );

  useEffect(() => {
    //subscriptionPlansOptions(plans);
    getUpdatedSubscriptionInfo();
    getSubscriptionPlans();
  }, []);

  const subscriptionPlansOptions = (currentPlans) => {
    let switchRenewalPeriodFrom = location?.state?.changeRenewalPeriodFrom;
    let currentSubscription = location?.state?.updateCurrentSubscription;

    let updatedPlans = [];

    if (subChangeFlow) {
      updatedPlans = currentPlans
        .filter((plan) => plan.billingCycle === "month")
        .map((plan) => ({
          ...plan,
          isSubscribed: plan.subscriptionType === currentSubscription,
        }));
    } else if (switchRenewalPeriodFrom === "month") {
      updatedPlans = currentPlans.filter(
        (plan) =>
          plan.billingCycle === "year" &&
          plan.subscriptionType === currentSubscription
      );
    } else if (switchRenewalPeriodFrom === "year") {
      updatedPlans = currentPlans.filter(
        (plan) =>
          plan.billingCycle === "month" &&
          plan.subscriptionType === currentSubscription
      );
    } else {
      updatedPlans = currentPlans.filter(
        (plan) => plan.billingCycle === "month"
      );
    }

    setSubscriptionPlans(updatedPlans);
  };

  const getUpdatedSubscriptionInfo = (action) => {
    GetProfileInfoByEmailAPI(userInfo.email).then((res) => {
      setUserInfo(res.data);
      if (action !== "newUpdate") {
        if (
          res.data.isSubscribed &&
          !subChangeFlow &&
          !switchSubRenewalPeriod
        ) {
          navigate("/subscription/Details");
        } else if (
          res.data.isSubscribed === false &&
          res.data?.subscriptionStatus === "Cancelled" &&
          res.data.userRole === "Client Admin"
        ) {
          navigate("/subscription/retry");
        }
      }
    });
  };

  const getSubscriptionPlans = () => {
    GetSubscriptionTypesAPI().then((res) => {
      const updatedPlans = plans.map((plan) => {
        const matchingResponse = res.data.find(
          (item) =>
            item.subscriptionType === plan.subscriptionType &&
            item.billingCycle === plan.billingCycle
        );

        if (matchingResponse) {
          return {
            ...plan,
            ID: matchingResponse.id,
            CreatedAt: matchingResponse.CreatedAt,
            UpdatedAt: matchingResponse.UpdatedAt,
            DeletedAt: matchingResponse.DeletedAt,
            subscriptionType: matchingResponse.subscriptionType,
            billingCycle: matchingResponse.billingCycle,
            amount: matchingResponse.amount
              ? `$${matchingResponse.amount}.00`
              : plan.amount,
          };
        }

        // return plan;
      });

      subscriptionPlansOptions(updatedPlans);
    });
  };

  const handleSubscription = (plan) => {
    const requestData = {
      clientId: userInfo.businessAddress.ClientId,
      subscriptionTypeId: plan.ID,
    };

    if (subChangeFlow || switchSubRenewalPeriod) {
      requestData.billingCycle = plan.billingCycle;
      ChangeSubscriptionPlanAPI(requestData).then((res) => {
        if (res.data.status === true) {
          swal("", res.data.message, "success");
          navigate("/subscription/Details");
        }
      });
    } else {
      BuySubscriptionAPI(requestData).then((res) => {
        // getUpdatedSubscriptionInfo("newUpdate");
        setUserInfo({
          ...userInfo,
          isSubscribed: true,
        });
        window.location.href = res.data;
      });
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-white">
      {/* Curvy Logo Section */}
      <section className="relative bg-blue-52 h-44 text-white overflow-hidden">
        <div className="absolute inset-0 w-full">
          <svg
            viewBox="0 0 1440 320"
            className="absolute w-full h-full"
            preserveAspectRatio="none"
          >
            <path
              fill="#ffffff"
              fillOpacity="1"
              d="M0,320L60,288C120,256,240,192,360,160C480,128,600,128,720,144C840,160,960,192,1080,218.7C1200,245,1320,256,1380,256L1440,256V320H0V320Z"
            ></path>
          </svg>
        </div>
        <div className="relative grid grid-cols-2 h-full items-center z-10">
          <div className="ml-8 -mt-8">
            <motion.img
              id="yvooaLogo"
              className="w-48 h-14"
              src="logo/NEWLOGO.png"
              alt="Yvooa logo"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            />
          </div>
          <div className="flex justify-end mr-8">
            {(subChangeFlow || switchSubRenewalPeriod) && (
              <ButtonCustom
                id={"backButton"}
                name={"Cancel"}
                onClick={(e) => navigate("/subscription/Details")}
                className="px-6 py-2 w-fit border-2 border-white font-medium text-white"
              />
            )}
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <div className="flex flex-col items-center justify-center flex-grow pb-12">
        <h1 id="helperText1" className="text-4xl font-semibold mb-4 underline">
          Choose Your Plan
        </h1>
        <p id="helperText2" className="mb-12 text-lg text-gray-600">
          Choose from one of our Plans to begin your journey with YVOOA
        </p>

        <div
          className={`grid grid-cols-${
            switchSubRenewalPeriod ? 1 : 4
          } md:grid-cols-2 gap-6 w-full px-8 pb-6 justify-items-center`}
        >
          {subscriptionPlans.map((plan) => (
            <div
              key={plan.ID}
              className="pb-8 rounded-lg shadow-lg bg-white transform hover:scale-105 transition-transform border-2 flex flex-col justify-between w-88"
            >
              <div
                className={`${plan.bgColor} text-white rounded-bl-[3rem] rounded-br-full p-8`}
              >
                <h2
                  id={plan.subscriptionType + "Plan"}
                  className="text-2xl font-bold mb-4 underline"
                >
                  {plan.subscriptionType}
                </h2>
                <p
                  id={plan.subscriptionType + "PlanDescription"}
                  className="text-lg mb-4"
                >
                  {plan.description}
                </p>
              </div>

              <p
                id={plan.subscriptionType + "PlanPrice"}
                className="px-8 pt-8 text-4xl font-bold"
              >
                {plan.amount}{" "}
                <span className="text-lg">
                  {plan.billingCycle === "year" ? "/p.a" : "/mo"}
                </span>
              </p>

              <ul className="px-8 pt-4 space-y-2 flex-grow">
                {plan.features.map((feature, i) => (
                  <li
                    key={i}
                    id={plan.subscriptionType + "PlanFeatures"}
                    className="text-sm flex items-center"
                  >
                    <GiCheckMark className="text-green-500 w-5 h-5 mr-1" />
                    {feature}
                  </li>
                ))}
              </ul>

              <div className="flex justify-center mt-auto px-8 py-4">
                <ButtonCustom
                  id={plan.subscriptionType + "SubscribeButton"}
                  name={
                    plan.isSubscribed ? (
                      <span className="flex items-center gap-1">
                        Subscribed
                        <GiCheckMark
                          className={`w-5 h-5 mr-1 text-green-500`}
                        />
                      </span>
                    ) : (
                      "Subscribe"
                    )
                  }
                  onClick={(e) => handleSubscription(plan)}
                  disabled={plan.isSubscribed}
                  className={`px-6 py-2 w-fit border-2 border-gray-400 font-medium text-gray-400 ${
                    !plan.isSubscribed && `${clickEffect} ${plan.planClassName}`
                  }`}
                />
              </div>
            </div>
          ))}
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default SubscriptionPlans;

const plans = [
  {
    ID: 1,
    subscriptionType: "FREE",
    amount: "$0.00",
    billingCycle: "month",
    description:
      "For individuals trying out the platform for a limited period of time.",
    features: ["Limited projects", "1 team member", "Basic support"],
    bgColor: "bg-[#38B37E]",
    planClassName:
      "hover:border-[#38B37E] hover:bg-[#38B37E] hover:text-white focus:text-white",
  },
  {
    ID: 2,
    subscriptionType: "BASIC",
    amount: "$27.00",
    billingCycle: "month",
    description:
      "For small teams trying out the platform for an unlimited period of time",
    features: ["Unlimited projects", "1 team member", "Basic support"],
    bgColor: "bg-[#FFAF09]",
    planClassName:
      "hover:border-[#FFAF09] hover:bg-[#FFAF09] hover:text-white focus:text-white",
  },
  {
    ID: 3,
    subscriptionType: "PREMIUM",
    amount: "$59.00",
    billingCycle: "month",
    description: "For larger teams needing more power and support.",
    features: [
      "Limited projects",
      "10 team members",
      "Priority support",
      "Advanced analytics",
    ],
    bgColor: "bg-[#FF6522]",
    planClassName:
      "hover:border-[#FF6522] hover:bg-[#FF6522] hover:text-white focus:text-white",
  },
  {
    ID: 4,
    subscriptionType: "ADVANCED",
    amount: "$97.00",
    billingCycle: "month",
    description:
      "For enterprise teams with full customization and scalability.",
    features: [
      "Limited projects",
      "Unlimited team members",
      "Dedicated support",
      "Custom integrations",
      "Advanced analytics",
    ],
    bgColor: "bg-blue-52",
    planClassName:
      "hover:border-blue-52 hover:bg-blue-52 hover:text-white focus:text-white",
  },
  {
    ID: 5,
    subscriptionType: "FREE",
    amount: "$0.00",
    billingCycle: "year",
    description:
      "For individuals trying out the platform for a limited period of time.",
    features: ["Limited projects", "1 team member", "Basic support"],
    bgColor: "bg-[#38B37E]",
    planClassName:
      "hover:border-[#38B37E] hover:bg-[#38B37E] hover:text-white focus:text-white",
  },
  {
    ID: 6,
    subscriptionType: "BASIC",
    amount: "$265.00",
    billingCycle: "year",
    description:
      "For small teams trying out the platform for an unlimited period of time",
    features: ["Unlimited projects", "1 team member", "Basic support"],
    bgColor: "bg-[#FFAF09]",
    planClassName:
      "hover:border-[#FFAF09] hover:bg-[#FFAF09] hover:text-white focus:text-white",
  },
  {
    ID: 7,
    subscriptionType: "PREMIUM",
    amount: "$325.00",
    billingCycle: "year",
    description: "For larger teams needing more power and support.",
    features: [
      "Limited projects",
      "10 team members",
      "Priority support",
      "Advanced analytics",
    ],
    bgColor: "bg-[#FF6522]",
    planClassName:
      "hover:border-[#FF6522] hover:bg-[#FF6522] hover:text-white focus:text-white",
  },
  {
    ID: 8,
    subscriptionType: "ADVANCED",
    amount: "$375.00",
    billingCycle: "year",
    description:
      "For enterprise teams with full customization and scalability.",
    features: [
      "Limited projects",
      "Unlimited team members",
      "Dedicated support",
      "Custom integrations",
      "Advanced analytics",
    ],
    bgColor: "bg-blue-52",
    planClassName:
      "hover:border-blue-52 hover:bg-blue-52 hover:text-white focus:text-white",
  },
];
