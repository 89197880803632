import { Dialog, DialogContent } from "@mui/material";
import React from "react";
import {
  ButtonBlue,
  ButtonGrayOutline,
  ButtonIconSquare,
} from "../../Widgets/Buttons";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { InputDateField, InputTextArea } from "../../Widgets/Forms/InputFields";
import { useState } from "react";
import { PiWarningOctagon } from "react-icons/pi";
import { useEffect } from "react";
import { MultiDetailsTile } from "../../Widgets/Forms/FormReviewTiles";
import { DateFormatForAPI } from "../../Widgets/CommonWidgets";
import { CancelSubscriptionAPI } from "./subscriptionAPI";

import swal from "sweetalert";

export const CancelSubscription = (props) => {
  const [cancelType, setCancelType] = useState("immediate");
  const [cancelDate, setCancelDate] = useState("");
  const [cancelReason, setCancelReason] = useState("");
  const [formErrors, setFormErrors] = useState({});

  const validate = () => {
    const errors = {};

    if (!cancelType) {
      errors.cancelType = "Cancellation type is required!";
    }
    if (cancelType === "schedule" && !cancelDate) {
      errors.cancelDate = "Cancellation Date is required!";
    }

    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return false;
    else return true;
  };

  const handleSubmit = () => {
    if (!validate()) return;
    const requestData = {
      clientId: props.userId,
      cancelAt:
        cancelType === "schedule" ? cancelDate.toString().split("T")[0] : "",
    };

    CancelSubscriptionAPI(requestData).then((res) => {
      if (res.data.status === true) {
        swal("", res.data.message, "success");
        props.handleCallBack();
        props.setTrigger(false);
      }
    });
  };

  const handleRadioChange = (e) => {
    if (e.target.value === "immediate") {
      setCancelDate("");
    }
    setCancelType(e.target.value);
  };

  return (
    props.trigger && (
      <>
        <Dialog
          open={true}
          aria-labelledby="cancelSubscriptionForm"
          fullWidth
          maxWidth="xs"
          PaperProps={{ sx: { height: "80%" } }}
          //scroll="body"
          className="flex flex-col justify-center w-full"
        >
          <div className="flex justify-center items-center bg-blue-52 text-white font-semibold rounded-sm h-10 pr-2">
            <span className="flex-grow text-center">Cancel Subscription</span>
            <ButtonIconSquare
              id="close"
              btnName="close"
              dataCy="close"
              title="Close"
              onClick={(e) => props.setTrigger(false)}
              icon={<AiOutlineCloseCircle className="w-5 h-5" />}
              className="hover:!text-red-600"
            />
          </div>

          <DialogContent dividers>
            <section className="flex flex-col items-center justify-center gap-4 mt-2 mx-auto px-4">
              <PiWarningOctagon className="text-blue-53" size={50} />
              <p className="text-center">
                Cancelling this subscription will stop all services at the end
                of the billing cycle.
              </p>

              <p className="text-3xl font-bold text-gray-700">
                {"$" + props.subscription.amount}
                {props.renewalPeriod !== "month" ? " /p.a" : " /mo"}
              </p>

              <MultiDetailsTile
                details={{
                  "Current Plan": props.subscription.subscriptionType,
                  "Product Type": props.subscription.productType,
                  "Subscription Start": props.subscription.subscriptionStart,
                  "Renewal Period": props.subscription.renewalPeriod,
                }}
                labelClassName="text-gray-500 mb-2"
              />

              {/* <div className="">
                <h2 className="text-lg mb-2">Cancellation Type</h2>

                <div className="flex items-center space-x-4">
                  <label className="flex items-center space-x-2">
                    <input
                      type="radio"
                      name="cancellationType"
                      value="immediate"
                      onChange={handleRadioChange}
                      checked={cancelType === "immediate"}
                      className="text-blue-600 border-gray-300 focus:ring-blue-500"
                    />
                    <span>Immediate Cancellation</span>
                  </label>

                  <label className="flex items-center space-x-2">
                    <input
                      type="radio"
                      name="cancellationType"
                      value="schedule"
                      onChange={handleRadioChange}
                      checked={cancelType === "schedule"}
                      className="text-blue-600 border-gray-300 focus:ring-blue-500"
                    />
                    <span>Schedule Cancel</span>
                  </label>
                </div>

                <p className="text-sm text-red-10 mt-1">
                  {formErrors.cancelType}
                </p>
              </div> */}

              {/* <InputDateField
                label="Date of Cancellation"
                name="dateOfCancellation"
                id="dateOfCancellation"
                dataCy="dateOfCancellation"
                notImp
                disabled={cancelType !== "schedule"}
                minDate={new Date()}
                value={cancelDate}
                onChange={(value) => setCancelDate(value)}
                errorText={formErrors.cancelDate}
                style="!w-full"
              /> */}

              <InputTextArea
                label="Reason For Cancellation"
                name="reasonForCancellation"
                id="reasonForCancellation"
                dataCy="reasonForCancellation"
                notImp
                type="text"
                placeholder="Add a reason (Optional)..."
                value={cancelReason}
                onChange={(e) => setCancelReason(e.target.value)}
                style="!w-full"
                className="rounded-lg"
              />

              <div className="flex justify-center items-center gap-16">
                <ButtonGrayOutline
                  id="cancel"
                  btnName="cancel"
                  dataCy="cancel"
                  name="Cancel"
                  onClick={() => props.setTrigger(false)}
                  className="mt-6 justify-self-end"
                />
                <ButtonBlue
                  id="confirm"
                  btnName="confirm"
                  dataCy="confirm"
                  name="Confirm"
                  onClick={handleSubmit}
                  className="mt-6"
                />
              </div>
            </section>
          </DialogContent>
        </Dialog>
      </>
    )
  );
};
