import React, { useState, useEffect } from "react";
import { WebApimanager } from "../../../WebApiManager";
import {
  ButtonBlue,
  ButtonGrayOutline,
  ButtonIconSquare,
} from "../../../Widgets/Buttons";
import { Dialog } from "@mui/material";
import InputField, {
  InputMultiSelectField,
} from "../../../Widgets/Forms/InputFields";
import swal from "sweetalert";
import { AiOutlineCloseCircle } from "react-icons/ai";

const AddAmenities = (props) => {
  let webApi = new WebApimanager();
  const editForm = props.operation === "Edit" ? true : false;
  const [amenityName, setAmenityName] = useState(
    editForm ? props.editData.amenityName : ""
  );
  const [formErrors, setFormErrors] = useState("");
  const [amenityTags, setAmenityTags] = useState([]);
  const [tags, setTags] = useState(editForm ? props.editData.tags : []);

  useEffect(() => {
    getAmenityTags();
  }, []);

  const validate = () => {
    const errors = {};
    if (!amenityName) {
      errors.amenityName = "Amenity Name is required!";
    }
    if (!tags.length) {
      errors.tags = "Tag is required!";
    }
    return errors;
  };

  const getAmenityTags = () => {
    webApi
      .get("/amenity/get/tags")
      .then((res) => {
        if (res.data.errorCode) {
          console.log("Error", res.data.errorMessage, "error");
        } else {
          setAmenityTags(res.data.tags);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Unable to get Amenities tags! Please try again later.",
            "error"
          );
        }
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const temp = validate();
    setFormErrors(temp);
    if (Object.keys(temp).length) {
      return;
    } else {
      let data = {
        propertyId: props.PropertyId,
        amenityName: amenityName,
        tags: tags,
      };
      if (editForm) {
        webApi
          .put("/amenity/" + props.editData.ID, data)
          .then(async (res) => {
            if (res.data.errorCode) {
              swal("Error", res.data.errorMessage, "error");
            } else {
              swal("Success", "Amenity updated successfully", "success");
              props.setTrigger(false);
              props.getAmenities();
            }
          })
          .catch((error) => {
            if (error.customErrorMessage) {
              swal("Error", error.customErrorMessage, "error");
            } else {
              swal(
                "Error",
                "Failed to Edit Amenity Info! Please try again later.",
                "error"
              );
            }
          });
      } else {
        webApi
          .post("/amenity", data)
          .then(async (res) => {
            if (res.data.errorCode) {
              swal("Error", res.data.errorMessage, "error");
            } else {
              swal("Success", "Amenity created successfully", "success");
              props.setTrigger(false);
              props.getAmenities();
            }
          })
          .catch((error) => {
            if (error.customErrorMessage) {
              swal("Error", error.customErrorMessage, "error");
            } else {
              swal(
                "Error",
                "Failed to Add Amenity Info! Please try again later.",
                "error"
              );
            }
          });
      }
    }
  };

  return props.trigger ? (
    <>
      <Dialog
        open={true}
        aria-labelledby="Create Amenity Form"
        fullWidth
        maxWidth="xs"
        PaperProps={{ sx: { height: "80%" } }}
        scroll="body"
        className="flex flex-col justify-center w-full"
      >
        <div className="flex justify-center items-center bg-blue-52 text-white font-semibold rounded-sm h-10 pr-2">
          <span className="flex-grow text-center">
            {" "}
            {editForm ? "Edit Amenity" : "Add Amenity"}
          </span>
          <ButtonIconSquare
            id="close"
            btnName="close"
            dataCy="close"
            title="Close"
            onClick={(e) => props.setTrigger(false)}
            icon={<AiOutlineCloseCircle className="w-5 h-5" />}
            className="hover:!text-red-600"
          />
        </div>

        <form className="flex flex-col justify-center items-center">
          <section className="flex flex-col gap-4 mt-2 w-88">
            <InputField
              label="Amenity Name"
              name="amenityName"
              id="amenityName"
              dataCy="amenityName"
              type="text"
              placeholder="Enter Amenity Name"
              value={amenityName}
              onChange={(e) => setAmenityName(e.target.value)}
              errorText={formErrors.amenityName}
              style="!w-full"
            />

            <InputMultiSelectField
              label="Tags"
              name="tags"
              id="tags"
              dataCy="tags"
              placeholder="Select a Tag"
              options={amenityTags}
              value={tags}
              setValue={setTags}
              errorText={formErrors.tags}
              style="!w-full"
            />
            <div className="flex justify-center items-center gap-16 mt-2 mb-2">
              <ButtonGrayOutline
                id="cancel"
                btnName="cancel"
                dataCy="cancel"
                name="Cancel"
                onClick={() => props.setTrigger(false)}
                className="mt-6 justify-self-end"
              />
              <ButtonBlue
                id="confirm"
                btnName="confirm"
                dataCy="confirm"
                name="Confirm"
                onClick={handleSubmit}
                className="mt-6"
              />
            </div>
          </section>
        </form>
      </Dialog>
    </>
  ) : null;
};

export default AddAmenities;
