import React, { useEffect, useState } from "react";
import { DownloadRentRollReportPdfAPI } from "./reportsAPI";
import { IconInCircle } from "../../Widgets/Icons";
import { FiHome } from "react-icons/fi";
import { MultiDetailsTile } from "../../Widgets/Forms/FormReviewTiles";
import { GetFullAddressinTwoLines } from "../../Widgets/Forms/FieldDependants";
import {
  GetDateInMMDDYYYY,
  GetDenominationWithDollar,
  Searchbar,
  TableComponent,
} from "../../Widgets/CommonWidgets";
import ExportCSV from "../../Widgets/ExportCSV";
import { ButtonIconSquare } from "../../Widgets/Buttons";
import { BsDownload } from "react-icons/bs";
import { SearchTableComponent } from "../../Widgets/Table/searchTableComponent";

export const RentRollsReport = (props) => {
  const [tableData, setTableData] = useState([]);
  const [rentalToatalData, setRentalToatalData] = useState({});

  useEffect(() => {
    setTableData(props.data.renatlReport || []);
    setRentalToatalData(props.data.renatlCounts || {});
  }, [props.data.renatlReport, props.data.renatlCounts]);

  const downloadPDF = () => {
    let requestData = {
      propertyId: props.property.ID,
    };
    DownloadRentRollReportPdfAPI(requestData).then((res) => {
      window.open(res.data.url, "_blank");
    });
  };

  const showTotalInfo = (title, data) => {
    return (
      <>
        <span className="flex font-semibold items-center">
          {title} :<p className="font-normal">&nbsp;{data}</p>
        </span>
      </>
    );
  };

  return (
    <>
      <section
        name="Header and SearchBar"
        className="flex justify-between items-center mb-8 mx-6"
      >
        <span className="flex items-center gap-4">
          <IconInCircle
            icon={<FiHome className="w-8 h-8 text-blue-52" />}
            radius="3.5rem"
            className="shadow-xl"
            color="#BFE3ED"
          />

          <MultiDetailsTile
            details={{
              "Property Address :": GetFullAddressinTwoLines(
                props.property.address
              ),
              "Property Type :": props.property.propertyType,
              "As of :": GetDateInMMDDYYYY(new Date()),
            }}
          />
        </span>
        <div className="flex flex-row gap-2">
          <ExportCSV
            data={tableData}
            keys={[
              { key: "unitNumber", title: "Unit No." },
              {
                key: "numberOfBeds",
                title: "BD/BA",
                applyMethod: (row) => {
                  return row.numberOfBeds + "/" + row.numberOfBaths;
                },
              },
              {
                key: "name",
                title: "Tenant Name",
              },
              {
                key: "unitStatus",
                title: "Status",
              },
              { key: "sqFt", title: "Sqft" },
              {
                key: "rent",
                title: "Rent",
                applyMethod: (row) => GetDenominationWithDollar(row.rent),
              },
              {
                key: "deposit",
                title: "Deposit",
                applyMethod: (row) => GetDenominationWithDollar(row.deposit),
              },
              {
                key: "leaseStartDate",
                title: "Lease From",
                applyMethod: (row) => GetDateInMMDDYYYY(row.leaseStartDate),
              },
              {
                key: "leaseEndDate",
                title: "Lease To",
                applyMethod: (row) => GetDateInMMDDYYYY(row.leaseEndDate),
              },
              {
                key: "moveInDate",
                title: "Move In",
                applyMethod: (row) => GetDateInMMDDYYYY(row.moveInDate),
              },
              {
                key: "moveOutDate",
                title: "Move Out",
                applyMethod: (row) => GetDateInMMDDYYYY(row.moveOutDate),
              },
              {
                key: "pastDue",
                title: "Past Due",
                applyMethod: (row) => GetDenominationWithDollar(row.pastDue),
              },
              { key: "lateCount", title: "Late Count" },
            ]}
            fileName="Rent Roll.csv"
            className={"bg-white"}
          />
          <ButtonIconSquare
            id="Download"
            btnName="download"
            dataCy="download"
            title="Download PDF"
            onClick={downloadPDF}
            icon={<BsDownload className="w-5 h-5" />}
            className="hover:!bg-blue-50 hover:!text-white shadow-md bg-white"
          />
          <SearchTableComponent
            id="rentRollSearch"
            placeholder="Search..."
            data={props.data.renatlReport}
            setTableData={setTableData}
            columns={[
              { key: "unitNumber", title: "Unit No." },
              {
                key: "numberOfBeds",
                title: "BD/BA",
                applyMethod: (row) => {
                  return row.numberOfBeds + "/" + row.numberOfBaths;
                },
              },
              {
                key: "name",
                title: "Tenant Name",
              },
              {
                key: "unitStatus",
                title: "Status",
              },
              { key: "sqFt", title: "Sqft" },
              {
                key: "rent",
                title: "Rent",

                applyMethod: (row) => GetDenominationWithDollar(row.rent),
              },
              {
                key: "deposit",
                title: "Deposit",

                applyMethod: (row) => GetDenominationWithDollar(row.deposit),
              },
              {
                key: "leaseStartDate",
                title: "Lease From",
                applyMethod: (row) => GetDateInMMDDYYYY(row.leaseStartDate),
              },
              {
                key: "leaseEndDate",
                title: "Lease To",
                applyMethod: (row) => GetDateInMMDDYYYY(row.leaseEndDate),
              },
              {
                key: "moveInDate",
                title: "Move In",

                applyMethod: (row) => GetDateInMMDDYYYY(row.moveInDate),
              },
              {
                key: "moveOutDate",
                title: "Move Out",

                applyMethod: (row) => GetDateInMMDDYYYY(row.moveOutDate),
              },
              {
                key: "pastDue",
                title: "Past Due",

                applyMethod: (row) => GetDenominationWithDollar(row.pastDue),
              },
              { key: "lateCount", title: "Late Count" },
            ]}
          />
        </div>
      </section>

      <section>
        <TableComponent
          tableId="rentRollTable"
          className={"bg-white"}
          data={tableData}
          columns={[
            { key: "unitNumber", title: "Unit No.", width: "100px" },
            {
              key: "numberOfBeds",
              title: "BD/BA",
              applyMethod: (row) => {
                return row.numberOfBeds + "/" + row.numberOfBaths;
              },
              width: "60px",
            },
            {
              key: "name",
              title: "Tenant Name",
              width: "130px",
            },
            {
              key: "unitStatus",
              title: "Status",
              width: "100px",
            },
            { key: "sqFt", title: "Sqft", width: "80px" },
            {
              key: "rent",
              title: "Rent",
              width: "80px",
              applyMethod: (row) => GetDenominationWithDollar(row.rent),
            },
            {
              key: "deposit",
              title: "Deposit",
              width: "100px",
              applyMethod: (row) => GetDenominationWithDollar(row.deposit),
            },
            {
              key: "leaseStartDate",
              title: "Lease From",
              applyMethod: (row) => GetDateInMMDDYYYY(row.leaseStartDate),
              width: "120px",
            },
            {
              key: "leaseEndDate",
              title: "Lease To",
              applyMethod: (row) => GetDateInMMDDYYYY(row.leaseEndDate),
              width: "100px",
            },
            {
              key: "moveInDate",
              title: "Move In",
              width: "100px",
              applyMethod: (row) => GetDateInMMDDYYYY(row.moveInDate),
            },
            {
              key: "moveOutDate",
              title: "Move Out",
              width: "100px",
              applyMethod: (row) => GetDateInMMDDYYYY(row.moveOutDate),
            },
            {
              key: "pastDue",
              title: "Past Due",
              width: "100px",
              applyMethod: (row) => GetDenominationWithDollar(row.pastDue),
            },
            { key: "lateCount", title: "Late Count", width: "120px" },
          ]}
          rowOnClick={() => {}}
          initialSort={{ key: "leaseStartDate", direction: "descending" }}
        />

        <div
          name="Totals row"
          className="flex justify-between rounded bg-white shadow-md p-2 border border-blue-53"
        >
          <span className="text-normal text-gray-600 uppercase font-bold">
            Totals
          </span>
          {showTotalInfo(
            "Units",
            (rentalToatalData && rentalToatalData.totalUnits) || "-"
          )}
          {showTotalInfo(
            "Occupied",
            (rentalToatalData && rentalToatalData.percentageOccupied + "%") ||
              "-"
          )}
          {showTotalInfo(
            "Total Rent",
            rentalToatalData &&
              GetDenominationWithDollar(rentalToatalData.totalRent)
          )}
          {showTotalInfo(
            "Deposit",
            rentalToatalData &&
              GetDenominationWithDollar(rentalToatalData.totalDeposit)
          )}
          {showTotalInfo(
            "Past Due",
            rentalToatalData &&
              GetDenominationWithDollar(rentalToatalData.totalPastDue)
          )}
          {showTotalInfo(
            "Late Count",
            (rentalToatalData && rentalToatalData.totalLateCount) || "-"
          )}
        </div>
      </section>
    </>
  );
};
