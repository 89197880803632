import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { clientId, companyId, portfolioTreeStore } from "../../atoms";
import { useRecoilValue, useRecoilState } from "recoil";
import { WebApimanager } from "../../WebApiManager";
import { InputObjectSelectField } from "../../Widgets/Forms/InputFields";
import {
  TableComponent,
  GetDateInMMDDYYYY,
  Searchbar,
} from "../../Widgets/CommonWidgets";

import swal from "sweetalert";
import { IconInCircle, InfoIcon } from "../../Widgets/Icons";
import { AiOutlineHome } from "react-icons/ai";
import { Loader } from "../../Widgets/notificationFeedbacks";
import {
  GetCompleteAddress,
  GetFullAddressinTwoLines,
  PhoneNumFormat,
} from "../../Widgets/Forms/FieldDependants";
import { SearchTableComponent } from "../../Widgets/Table/searchTableComponent";

const TenantDashboard = () => {
  let webApi = new WebApimanager();
  let navigate = useNavigate();
  const clientIdVal = useRecoilValue(clientId);
  const companyIdVal = useRecoilValue(companyId);
  const [portfolioTree, SetPortfolioTree] = useRecoilState(portfolioTreeStore);

  const [selectedPortfolioID, setSelectedPortfolioID] = useState("");
  const [selectedPropertyId, setSelectedPropertyId] = useState("");

  const [allPortfolios, setAllPortfolios] = useState([]);
  const [allPropertiesMap, setAllPropertiesMap] = useState(new Map());
  const [properties, setProperties] = useState([]);

  const [allTenants, setAllTenants] = useState([]);
  const [allPropertyTenantsMap, setAllPropertyTenantsMap] = useState(new Map());
  const [tenantsTableData, setTenantsTableData] = useState([]);

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  useEffect(() => {
    setApiProcessing({
      loader: true,
      message: `Gathering Tenants...`,
    });
    getAllTenants();
    getPortfolios();
  }, []);

  const getPortfolios = () => {
    webApi
      .get("/portfolios/client/" + clientIdVal)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          const ports =
            res.data.length > 1
              ? res.data.sort((a, b) =>
                  a.portfolioName > b.portfolioName ? 1 : -1
                )
              : res.data;
          setAllPortfolios(ports);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to get Portfolios, Please try again later.`,
            "error"
          );
        }
      });
  };

  const getAllTenants = () => {
    setApiProcessing({
      loader: true,
      message: `Gathering Tenants...`,
    });
    webApi
      .get(`/reports/tenantDirectory/client/${clientIdVal}`)
      .then(async (res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setTenantsTableData(res.data);
          setAllTenants(res.data);
        }
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to get All Tenants, Please try again later.`,
            "error"
          );
        }
      });
  };

  const getTenantsByPropertyId = (propertyId) => {
    setApiProcessing({
      loader: true,
      message: `Gathering Tenants...`,
    });
    setSelectedPropertyId(propertyId);
    if (allPropertyTenantsMap.has(propertyId)) {
      setTenantsTableData(allPropertyTenantsMap.get(propertyId));
      setApiProcessing({
        loader: false,
        message: `Loading...`,
      });
      return;
    }
    setTenantsTableData([]);
    webApi
      .get(`/reports/tenantDirectory/property/${propertyId}`)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setTenantsTableData(res.data);
          setAllPropertyTenantsMap(
            allPropertyTenantsMap.set(propertyId, res.data)
          );
        }
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to get Tenants for Selected Property, Please try again later.`,
            "error"
          );
        }
      });
  };

  const getProperties = (portfolioId) => {
    setApiProcessing({
      loader: true,
      message: `Getting Properties...`,
    });
    setSelectedPortfolioID(portfolioId);
    if (allPropertiesMap.has(portfolioId)) {
      setProperties(allPropertiesMap.get(portfolioId));
      setApiProcessing({
        loader: false,
        message: `Loading...`,
      });
      return;
    }
    setProperties([]);
    webApi
      .get("/properties/portfolio/" + portfolioId)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          const acsendingData =
            res.data.length > 1
              ? res.data.sort((a, b) =>
                  a.propertyName > b.propertyName ? 1 : -1
                )
              : res.data;
          setProperties(acsendingData);
          setAllPropertiesMap(allPropertiesMap.set(portfolioId, acsendingData));
        }
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to get Properties, Please try again later.`,
            "error"
          );
        }
      });
  };

  const handleReset = (e) => {
    e.preventDefault();
    setSelectedPortfolioID("");
    setSelectedPropertyId("");
    setProperties([]);
    setTenantsTableData(allTenants);
  };

  return (
    <>
      <Loader open={apiProcessing.loader} message={apiProcessing.message} />

      <div className="w-5/6 m-auto">
        <span className="flex justify-center items-center mb-12 bg-blue-52 text-white font-semibold text-center rounded h-10">
          Tenant Directory
          <InfoIcon message="A centralized place for accessing and managing tenant records, leases, and payments." />
        </span>

        <section name="Header and Select Fields" className="flex flex-col mb-6">
          <div className="flex justify-start items-center mb-6">
            <IconInCircle
              icon={<AiOutlineHome className="w-9 h-9 text-[#2C88AE]" />}
              radius="4rem"
              className="shadow-xl mx-6"
            />
            <span>
              <p className="font-semibold">
                View All Your Tenant Information In One Place!
              </p>
              <p className="text-gray-400/100">
                Use the User Directory to track user details, profiles and much
                more!
              </p>
            </span>
          </div>

          <div className="flex justify-between items-end mb-6 mx-6">
            <div className="flex items-end gap-4 mr-2">
              <InputObjectSelectField
                label="Select Portfolio"
                name="portfolio"
                id="portfolio"
                dataCy="portfolio"
                placeholder="Select a Portfolio"
                value={selectedPortfolioID}
                onChange={(e) => getProperties(parseInt(e.target.value))}
                options={allPortfolios}
                optionValue="ID"
                optionName="portfolioName"
                style="rounded-md !w-fit"
              />

              <InputObjectSelectField
                label="Select Property"
                name="property"
                id="property"
                dataCy="proeprty"
                placeholder="Select a Property"
                value={selectedPropertyId}
                onChange={(e) =>
                  getTenantsByPropertyId(parseInt(e.target.value))
                }
                options={properties}
                optionValue="ID"
                optionName="propertyName"
                style="rounded-md !w-fit"
              />
              <button
                id="resetFilter"
                name="resetFilter"
                data-cy="resetFilter"
                onClick={handleReset}
                className="underline text-blue-52 cursor-pointer items-end active:scale-95 transform transition duration-100"
              >
                Reset
              </button>
            </div>
            <SearchTableComponent
              id="tenantdashboardSearchBar"
              placeholder="Search Tenants Table..."
              data={
                selectedPropertyId
                  ? allPropertyTenantsMap.get(selectedPropertyId)
                  : allTenants
              }
              setTableData={setTenantsTableData}
              columns={[
                { key: "name", title: "Tenant Name" },
                {
                  key: "email",
                  title: "Email",
                },
                {
                  key: "phoneNumber",
                  title: "Contact No.",
                  applyMethod: (row) => PhoneNumFormat(row.phoneNumber, true),
                },
                {
                  key: "address1",
                  title: "Address",
                  applyMethod: (row) => GetCompleteAddress(row),
                },
                { key: "unitNumber", title: "Unit Number", width: "150px" },
                {
                  key: "rent",
                  title: "Rent",
                  width: "80px",
                  applyMethod: (row) => {
                    return "$" + row.rent.toLocaleString();
                  },
                },
                {
                  key: "deposit",
                  title: "Deposit",

                  applyMethod: (row) => {
                    return "$" + row.deposit.toLocaleString();
                  },
                },
                { key: "status", title: "Status" },
                {
                  key: "leaseStartDate",
                  title: "Lease From",
                  applyMethod: (row) => GetDateInMMDDYYYY(row.leaseStartDate),
                },
                {
                  key: "leaseEndDate",
                  title: "Lease To",
                  applyMethod: (row) => GetDateInMMDDYYYY(row.leaseEndDate),
                },
                {
                  key: "moveInDate",
                  title: "Move In",

                  applyMethod: (row) => GetDateInMMDDYYYY(row.moveInDate),
                },
                {
                  key: "moveOutDate",
                  title: "Move Out",
                  applyMethod: (row) => GetDateInMMDDYYYY(row.moveOutDate),
                },
              ]}
            />
          </div>
        </section>

        <section name="tenantsTable">
          <TableComponent
            tableId="Tenants Table data"
            data={tenantsTableData}
            columns={[
              //{ key: "residentCode", title: "Resident Code", width: "150px" },
              { key: "name", title: "Tenant Name", width: "150px" },
              {
                key: "email",
                title: "Email",
                width: "180px",
              },
              {
                key: "phoneNumber",
                title: "Contact No.",
                applyMethod: (row) => PhoneNumFormat(row.phoneNumber, true),
                //width: "120px",
              },
              {
                key: "address1",
                title: "Address",
                applyMethod: (row) => GetCompleteAddress(row),
                width: "200px",
              },
              { key: "unitNumber", title: "Unit Number", width: "150px" },
              {
                key: "rent",
                title: "Rent",
                width: "80px",
                applyMethod: (row) => {
                  return "$" + row.rent.toLocaleString();
                },
              },
              {
                key: "deposit",
                title: "Deposit",
                width: "100px",
                applyMethod: (row) => {
                  return "$" + row.deposit.toLocaleString();
                },
              },
              { key: "status", title: "Status", width: "100px" },
              {
                key: "leaseStartDate",
                title: "Lease From",
                applyMethod: (row) => GetDateInMMDDYYYY(row.leaseStartDate),
                width: "120px",
              },
              {
                key: "leaseEndDate",
                title: "Lease To",
                applyMethod: (row) => GetDateInMMDDYYYY(row.leaseEndDate),
                width: "100px",
              },
              {
                key: "moveInDate",
                title: "Move In",
                width: "100px",
                applyMethod: (row) => GetDateInMMDDYYYY(row.moveInDate),
              },
              {
                key: "moveOutDate",
                title: "Move Out",
                width: "120px",
                applyMethod: (row) => GetDateInMMDDYYYY(row.moveOutDate),
              },
            ]}
            rowOnClick={(row) => {
              SetPortfolioTree({
                ...portfolioTree,
                tenant: row,
              });
              navigate("/tenants/tenantDetails");
            }}
            initialSort={{ key: "leaseStartDate", direction: "descending" }}
          />
        </section>
      </div>
    </>
  );
};

export default TenantDashboard;
