import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";

// Main Menu Flags

export const PortfoliosMenu = () => {
  return useFeatureFlag("Portfolio_Menu");
};

export const MaintenanceMenu = () => {
  return useFeatureFlag("Maintenance_Menu");
};

export const ReportsMenu = () => {
  return useFeatureFlag("Reports_Menu");
};

export const DocumentsMenu = () => {
  return useFeatureFlag("Documents_Menu");
};

export const ImportMenu = () => {
  return useFeatureFlag("Import_Menu");
};

//Directories Menu Options
export const DirectoriesMenu = () => {
  return useFeatureFlag("Directories_Menu");
};

export const UsersDirectoryMenu = () => {
  return useFeatureFlag("Users_Directory_Menu");
};

export const OwnershipDirectoryMenu = () => {
  return useFeatureFlag("Ownership_Directory_Menu");
};

export const TenantDirectoryMenu = () => {
  return useFeatureFlag("Tenant_Directory_Menu");
};

export const VendorDirectoryMenu = () => {
  return useFeatureFlag("Vendor_Directory_Menu");
};

export const NotificationsMenu = () => {
  return useFeatureFlag("Notifications_Menu");
};

//Accounting Menu Options
export const AccountingMenu = () => {
  return useFeatureFlag("Accounting_Menu");
};

export const ChartOfAccountsMenu = () => {
  return useFeatureFlag("Chart_of_Accounts_Menu");
};

export const BankInfoMenu = () => {
  return useFeatureFlag("Bank_Info_Menu");
};

export const ConnectedBankMenu = () => {
  return useFeatureFlag("Connected_Bank_Menu");
};

export const ProspectsMenu = () => {
  return useFeatureFlag("Prospects_Menu");
};
