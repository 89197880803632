import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CreateProperty from "./createProperty";
import { WebApimanager } from "../../WebApiManager";
import {
  BsThreeDotsVertical,
  BsPlusCircle,
  BsFillArrowLeftCircleFill,
} from "react-icons/bs";
import { RiDeleteBin7Line } from "react-icons/ri";
import { portfolioTreeStore, userData } from "../../atoms";
import { useRecoilState, useRecoilValue } from "recoil";
import swal from "sweetalert";
import { ButtonBlueOutline } from "../../Widgets/Buttons";
import { GetDateInMMDDYYYY, clickEffect } from "../../Widgets/CommonWidgets";
import { TiEdit } from "react-icons/ti";
import {
  TableComponent,
  Searchbar,
  Dropdown,
} from "../../Widgets/CommonWidgets";
import { TextReviewTile } from "../../Widgets/Forms/FormReviewTiles";
import CreatePortfolio from "../Portfolios/createPortfolio";
import { SnackAlert } from "../../Widgets/notificationFeedbacks";
import { Loader } from "../../Widgets/notificationFeedbacks";
import { PhoneNumFormat } from "../../Widgets/Forms/FieldDependants";
import { AddressViewTile } from "../../Widgets/Forms/FormReviewTiles";
import { UpdateCardPaymentStatusAPI } from "./propertyApi";
import { SearchTableComponent } from "../../Widgets/Table/searchTableComponent";
import { GetPortfolioDetailsByPortfolioId } from "./propertiesAPI";
import ToggleButton from "../../Widgets/Buttons/toggleButton";

const PropertyTable = () => {
  let webApi = new WebApimanager();
  let navigate = useNavigate();

  const userInfo = useRecoilValue(userData);
  const isClientAdmin = userInfo.userRole === "Client Admin" ? true : false;
  const [portfolioTree, setPortfolioTree] = useRecoilState(portfolioTreeStore);

  const [openPortfolioForm, setOpenPortfolioForm] = useState(false);
  const [createPropertypage, setcreatePropertypage] = useState(false);
  const [properties, setproperties] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [bankDetails, setBankDetails] = useState("");
  const [editPropertyData, setEditPropertyData] = useState("");
  const [formOperation, setFormOperation] = useState("");
  const [snackAlert, setSnackAlert] = useState({
    open: false,
    type: "info",
    message: "",
  });

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  useEffect(() => {
    setApiProcessing({
      loader: true,
      message: `Gathering Properties...`,
    });
    getProperties();
    if (portfolioTree.portfolio.connectedAccountId !== 0) {
      getConnectedBankAccountDetails(
        portfolioTree.portfolio.connectedAccountId
      );
    }
  }, [isClientAdmin]);

  const getProperties = () => {
    setApiProcessing({
      loader: true,
      message: `Gathering Properties...`,
    });
    webApi
      .get("/properties/portfolio/" + portfolioTree.portfolio.ID)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setproperties(res.data);
          setTableData(res.data);
        }
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
      })
      .catch((error) => {
        setApiProcessing({
          loader: false,
          message: `Loading...`,
        });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Oops, Unable to get Properties. Please try again later.",
            "error"
          );
        }
      });
  };

  const getUpdatedPortfolioDetails = () => {
    GetPortfolioDetailsByPortfolioId(portfolioTree.portfolio.ID).then((res) => {
      setPortfolioTree({
        ...portfolioTree,
        portfolio: res.data,
      });
    });
  };

  const deleteProperty = (property) => {
    swal({
      title: "Are you sure?",
      text: `You want to delete the property ${property.propertyName}!`,
      icon: "warning",
      buttons: ["Cancel", "Yes, delete it!"],
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        setApiProcessing({
          loader: true,
          message: `Deleting Property ${property.propertyName} ...!`,
        });
        webApi
          .delete("/properties/" + property.ID)
          .then((res) => {
            if (res.data.errorCode !== 200) {
              swal("Error", res.data.errorMessage, "error");
            } else {
              getProperties();
              swal("Success", res.data.errorMessage, "success");
            }
            setApiProcessing({
              loader: false,
              message: `Loading...`,
            });
          })
          .catch((error) => {
            setApiProcessing({
              loader: false,
              message: `Loading...`,
            });
            if (error.customErrorMessage) {
              swal("Error", error.customErrorMessage, "error");
            } else {
              swal(
                "Error",
                `Failed to Delete Property ${property.propertyName}, Please try again later.`,
                "error"
              );
            }
          });
      } else {
        return;
      }
    });
  };

  const GotoPropertyDetails = (property) => {
    setPortfolioTree({
      ...portfolioTree,
      property: property,
    });
    navigate("/portfolio/propertyDetails");
  };

  const portfolioTableActions = {
    editTitle: "Edit Property",
    editOnClick: (row) => {
      setEditPropertyData(row);
      setFormOperation("Edit");
      setcreatePropertypage(true);
    },
    deleteTitle: "Delete Property",
    deleteOnClick: (row) => deleteProperty(row),
  };

  const getFullAddress = (data) => {
    const { address1, address2, city, state, country, zipCode } =
      data.businessAddress;
    return `${address1}${address2 ? `, ${address2}` : ""}${
      city ? `, ${city}` : ""
    } ${state ? `, ${state}` : ""}${country ? `, ${country}` : ""}${
      zipCode ? `, ${zipCode}` : ""
    }`;
  };

  const getConnectedBankAccountDetails = (id) => {
    webApi
      .get("/payment/stripe/connectAccount/" + id)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setBankDetails(res.data);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Oops! Failed to get Bank Details, Please try again later.",
            "error"
          );
        }
      });
  };

  const updateCardPaymentStatus = (drag) => {
    const reqData = { isAvailable: drag, paymentType: "Card" };
    UpdateCardPaymentStatusAPI(portfolioTree.portfolio.ID, reqData).then(
      (res) => {
        if (res.data.status === true) {
          getUpdatedPortfolioDetails();
          swal("", res.data.message, "success");
        }
      }
    );
  };

  const updateBankPaymentStatus = (drag) => {
    const reqData = { isAvailable: drag, paymentType: "Bank Transfer" };
    UpdateCardPaymentStatusAPI(portfolioTree.portfolio.ID, reqData).then(
      (res) => {
        if (res.data.status === true) {
          getUpdatedPortfolioDetails();
          swal("", res.data.message, "success");
        }
      }
    );
  };

  return (
    <>
      {openPortfolioForm && (
        <CreatePortfolio
          trigger={openPortfolioForm}
          setTrigger={setOpenPortfolioForm}
          operation={"Edit"}
          portfolio={portfolioTree.portfolio}
          getAllPortfolios={(portfolioData) =>
            setPortfolioTree({
              ...portfolioTree,
              portfolio: portfolioData,
            })
          }
        />
      )}

      {createPropertypage && (
        <CreateProperty
          trigger={createPropertypage}
          setTrigger={setcreatePropertypage}
          portfolio={portfolioTree.portfolio}
          //portfolioType={}
          operation={formOperation}
          property={editPropertyData}
          getAllProperties={(prop) => getProperties()}
        />
      )}

      <SnackAlert
        open={snackAlert.open}
        type={snackAlert.type}
        message={snackAlert.message}
        handleSnackbar={snackAlert}
        handleClose={() => setSnackAlert({ ...snackAlert, open: false })}
      />

      <Loader open={apiProcessing.loader} message={apiProcessing.message} />

      <div className="w-5/6 m-auto">
        <section
          name="Property Table and Options"
          className="flex justify-between items-center bg-blue-30 h-10 mb-4"
        >
          <span className="flex gap-4">
            <BsFillArrowLeftCircleFill
              title="Back to Previous page"
              onClick={() => {
                setPortfolioTree({
                  ...portfolioTree,
                  property: {},
                });
                navigate("/portfolio");
              }}
              className="text-blue-52 w-6 h-6 ml-2 cursor-pointer"
            />
            <p id="portfolioNameTitle" className="font-semibold">
              {portfolioTree.portfolio.portfolioName}
            </p>
          </span>
          <span className="flex justify-end items-center text-gray-700 gap-4 mr-4">
            {/* <RiDeleteBin7Line
              title="Delete Portfolio"
              onClick={() => deletePortfolio()}
              className="w-5 h-5 xs:mr-3 cursor-pointer hover:text-red-500 focus:text-red-500"
            /> */}

            <span
              title="Edit Portfolio"
              id="editPortfolio"
              onClick={(e) => {
                setOpenPortfolioForm(true);
              }}
              className={
                "p-2 rounded flex justify-center items-center cursor-pointer hover:bg-white hover:text-blue-52 hover:shadow-lg " +
                clickEffect
              }
            >
              <TiEdit className="w-5 h-5" />
            </span>
          </span>
        </section>

        <div className="grid grid-cols-3 justify-center items-center ml-6 gap-4">
          <TextReviewTile
            id="portfolioTypeDetail"
            title="Portfolio Type"
            body={portfolioTree.portfolio.portfolioType}
          />
          <TextReviewTile
            id="ownershipDetail"
            title="Ownership"
            body={portfolioTree.portfolio.ownership}
          />
          <TextReviewTile
            id="ownerEmailIdDetail"
            title="Owner Email Id"
            body={portfolioTree.portfolio.email}
          />
          <TextReviewTile
            id="contactNumberDetail"
            title="Contact Number"
            body={PhoneNumFormat(portfolioTree.portfolio.phoneNumber, true)}
          />
          <AddressViewTile
            id="addressDetail"
            title="Address"
            body={
              portfolioTree.portfolio.businessAddress.address1 +
              ", " +
              portfolioTree.portfolio.businessAddress.address2
            }
            body2={
              portfolioTree.portfolio.businessAddress.city +
              ", " +
              portfolioTree.portfolio.businessAddress.state +
              ", " +
              portfolioTree.portfolio.businessAddress.zipCode
            }
          />
          <TextReviewTile
            id="propertyManagerDetail"
            title="Property Manager"
            body={
              portfolioTree.portfolio.assignedPropertyManager
                .propertyManagerName || "-"
            }
          />

          {isClientAdmin && (
            <>
              <TextReviewTile
                id="accountTypeDetail"
                title={
                  bankDetails.accountType === "Card" ? (
                    <>Card Type</>
                  ) : (
                    <>Bank Name</>
                  )
                }
                body={bankDetails.name}
              />
              <TextReviewTile
                id="accountNumberDetail"
                title={
                  bankDetails.accountType === "Card" ? (
                    <>Card Number</>
                  ) : (
                    <>Account Number</>
                  )
                }
                body={
                  bankDetails.last4 ? `**** **** ${bankDetails.last4}` : "-"
                }
              />
            </>
          )}

          <TextReviewTile
            id="activePaymentMethods"
            title="Payment Methods"
            body={
              portfolioTree.portfolio.isCardPaymentAvailable &&
              portfolioTree.portfolio.isBankPaymentAvailable
                ? "Credit Card, Bank Transfer"
                : portfolioTree.portfolio.isCardPaymentAvailable
                ? "Credit Card"
                : portfolioTree.portfolio.isBankPaymentAvailable
                ? "Bank Transfer"
                : "Offline Payments"
            }
          />
        </div>

        <section
          name="Title and Options"
          className="flex justify-between items-center mt-4 px-4 bg-blue-30 h-10"
        >
          <p id="propertiesHeader" className="font-semibold">
            Properties ({properties.length})
          </p>
        </section>

        <div
          name="Search Bar"
          className={"flex justify-between items-center my-4 mx-4"}
        >
          <SearchTableComponent
            id="propertySearchBar"
            placeholder="Search ..."
            data={properties}
            setTableData={setTableData}
            columns={[
              { key: "propertyName", title: "Property Name" },
              {
                key: "propertyType",
                title: "Property Type",
              },
              {
                key: "status",
                title: "Property Status",
              },
              {
                key: "purchaseDate",
                title: "Purchase Date",
                applyMethod: (row) => GetDateInMMDDYYYY(row.purchaseDate),
              },
              {
                key: "onboardDate",
                title: "Onboard Date",
                applyMethod: (row) => GetDateInMMDDYYYY(row.onboardDate),
              },
              { key: "yearBuilt", title: "Year Built" },
            ]}
          />

          <div className="flex justify-center items-center">
            <span className="flex items-center gap-1 mr-4">
              Card Payment:{" "}
              <ToggleButton
                isActive={
                  portfolioTree &&
                  portfolioTree.portfolio.isCardPaymentAvailable
                }
                onToggle={(value) => updateCardPaymentStatus(value)}
                offLabel="Off"
                onLabel="On"
              />
            </span>
            <span className="flex items-center gap-1 mr-4 ml-4">
              Bank Transfer:{" "}
              <ToggleButton
                isActive={
                  portfolioTree &&
                  portfolioTree.portfolio.isBankPaymentAvailable
                }
                onToggle={(value) => updateBankPaymentStatus(value)}
                offLabel="Off"
                onLabel="On"
              />
            </span>
            <ButtonBlueOutline
              id="addNewProperty"
              name={
                <>
                  <BsPlusCircle className="w-5 h-5 mr-2" /> Add New
                </>
              }
              onClick={(e) => {
                setcreatePropertypage(true);
                setFormOperation("New");
              }}
              className={`flex justify-center items-center ml-4 px-2 w-fit h-10 rounded-md`}
            />
          </div>
        </div>

        <TableComponent
          tableId="PropertyTable"
          data={tableData}
          columns={[
            { key: "propertyName", title: "Property Name" },
            {
              key: "propertyType",
              title: "Property Type",
            },
            {
              key: "status",
              title: "Property Status",
            },
            {
              key: "purchaseDate",
              title: "Purchase Date",
              applyMethod: (row) => GetDateInMMDDYYYY(row.purchaseDate),
            },
            {
              key: "onboardDate",
              title: "Onboard Date",
              applyMethod: (row) => GetDateInMMDDYYYY(row.onboardDate),
            },
            { key: "yearBuilt", title: "Year Built" },
          ]}
          actionHeader="Actions"
          actions={portfolioTableActions}
          rowOnClick={GotoPropertyDetails}
          initialSort={{ key: "purchaseDate", direction: "descending" }}
        />
      </div>
    </>
  );
};

export default PropertyTable;
