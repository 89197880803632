//Commom Imports
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { WebApimanager } from "../../../WebApiManager";
import { useRecoilState, useRecoilValue } from "recoil";
import { portfolioTreeStore, userData } from "../../../atoms";
import swal from "sweetalert";

//Pages Import
import CreateVacateNotice from "./createVacateNotice";

//Reusable Components
import { UploadNewFile } from "../../Documents/documents";
import { DetailsTile } from "../../../Widgets/Forms/FormReviewTiles";
import { IconInCircle } from "../../../Widgets/Icons";
import {
  GetDateInMMDDYYYY,
  GetTimeYearToMin,
  clickEffect,
} from "../../../Widgets/CommonWidgets";
import {
  ButtonCustom,
  ButtonIconSquare,
  ButtonWhiteOutline,
  ButtonGrayOutline,
  ButtonBlue,
} from "../../../Widgets/Buttons";
import { InputTextArea } from "../../../Widgets/Forms/InputFields";
import { FileDisplay } from "../../../Widgets/Forms/FormReviewTiles";
import { CommentsWidget } from "../../../Widgets/Comments";
import StatusUpdateDialog from "../../../Widgets/DialogBox/statusUpdateDialog";

//External Imports
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { IoDocumentTextOutline } from "react-icons/io5";
import { TiEdit } from "react-icons/ti";
import { FiPaperclip } from "react-icons/fi";

const VacateNoticeDetails = () => {
  //Common Variables
  let navigate = useNavigate();
  let webApi = new WebApimanager();
  let location = useLocation();
  let vacateNotice = location.state.vacateNotice;
  const userInfo = useRecoilValue(userData);
  const [portfolioTree, SetPortfolioTree] = useRecoilState(portfolioTreeStore);

  //Vacate Notice Details
  const [vacateNoticeDetails, setVacateNoticeDetails] = useState({});

  //Vacate Notice Form
  const [openVacateNoticeForm, setOpenVacateNoticeForm] = useState(false);

  //Vacate Notice Status
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);

  //Comments
  const [allComments, setAllComments] = useState([]);

  //Activity
  const [activities, setActivities] = useState([]);

  //Files
  const [allDocuments, setAllDocuments] = useState([]);
  const [openDocUploadForm, setOpenDocUploadForm] = useState(false);

  useEffect(() => {
    getVacateNoticeDetails();
  }, []);

  //API to get Lease Details
  const getVacateNoticeDetails = () => {
    webApi
      .get(`/vacateNotice/${vacateNotice.id}`, { loader: true })
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setVacateNoticeDetails(res.data.vacateDetail);
          setAllDocuments(res.data.documents);
          setAllComments(res.data.comments);
          setActivities(res.data.activity);
        }
      })
      .catch((error) => {
        swal(
          "Error",
          "Oops, unable to get Lease Details! Please try again later.",
          "error"
        );
      });
  };

  //Comments API's
  const addComment = (comment) => {
    let data = {
      vacateID: vacateNoticeDetails.id,
      creatorID: userInfo.businessAddress.ClientId,
      creatorRole: userInfo.userRole,
      creatorName: userInfo.firstName + userInfo.lastName,
      comment: comment,
    };

    webApi
      .post("/vacateNotice/comment", data, { loader: true })
      .then(async (res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setAllComments([...allComments, res.data]);
        }
      })
      .catch((error) => {
        swal(
          "Error",
          `Oops! Failed to add Comment, Please try again later.`,
          "error"
        );
      });
  };

  //Vacate Notice API's
  const handleVacateNoticeDocuments = (file) => {
    webApi
      .post(
        `/vacateNotice/upload/${vacateNotice.id}`,
        {
          fileName: file.name,
        },
        { loader: true }
      )
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          uploadDocuments(res.data, file);
        }
      })
      .catch((error) => {
        swal(
          "Error",
          `Failed to upload file ${file.name}. Please try again later.`,
          "error"
        );
      });
  };

  const uploadDocuments = (filePath, file) => {
    const data = {
      objectKey: `${filePath}`,
      metadata: {
        filename: file.name,
        mediatype: file.type,
      },
    };

    webApi
      .uploadFile(data.objectKey, data.metadata, file)
      .then((res) => {
        if (res.status === 200) {
          getVacateNoticeDetails();
          swal(
            "Success",
            `${file.name} has been successfully uploaded.`,
            "success"
          );
        }
      })
      .catch((error) => {
        swal(
          "Error",
          `Failed to upload file ${file.name}. Please try again later.`,
          "error"
        );
      });
  };

  //Vacate Notice Document Download
  const handleDocDownload = (file) => {
    window.location.href = file.presignURL;
  };

  //Vacate Notice Document Delete
  const handleDocDelete = (file) => {
    swal({
      title: "Are you sure?",
      text: `You intend to delete the file ${file.fileName}!`,
      icon: "warning",
      buttons: ["Cancel", "Delete!"],
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        let data = {
          objectKeys: [file.path],
          activity: "Vacate Notice",
          id: vacateNotice.id,
        };
        webApi
          .delete(`/documents/delete`, data, { loader: true })
          .then((res) => {
            if (res.data[file.fileName] === "Document Deleted successfully") {
              getVacateNoticeDetails();
              swal(
                "Success",
                `Document ${file.fileName} Deleted successfully`,
                "success"
              );
            } else {
              swal("Failure", res.data[file.fileName], "error");
            }
          })
          .catch((error) => {
            swal(
              "Error",
              `Failed to Delete file named ${file.fileName}, Please try again later!`,
              "error"
            );
          });
      } else {
        return;
      }
    });
  };

  //Vacate Notice Status Update
  const handleCancelStatus = (extendedData) => {
    webApi
      .put(
        `/vacateNotice/${vacateNotice.id}`,
        {
          creatorID: userInfo.businessAddress.ClientId,
          creatorRole: userInfo.userRole,
          createdBy: userInfo.firstName + " " + userInfo.lastName,
          status: "Cancelled",
          notes: extendedData.textAreaValue,
        },
        { loader: true }
      )
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          getVacateNoticeDetails();
          swal("", `Status Updated Successfully!`, "success");
        }
      });
  };

  return (
    <>
      {openVacateNoticeForm && (
        <CreateVacateNotice
          trigger={openVacateNoticeForm}
          setTrigger={setOpenVacateNoticeForm}
          vacateNotice={vacateNoticeDetails}
          operation={"Edit"}
          leaseDetails={location.state.leaseDetails}
          getAllVacateNotices={(data) => getVacateNoticeDetails()}
        />
      )}

      <UploadNewFile
        trigger={openDocUploadForm}
        setTrigger={setOpenDocUploadForm}
        title="Upload Document"
        fieldName="Upload Document"
        accept=".pdf"
        placeholder="Attach .pdf format only"
        handleUpload={(file) => handleVacateNoticeDocuments(file)}
      />

      <StatusUpdateDialog
        open={statusDialogOpen}
        onClose={() => setStatusDialogOpen(false)}
        textAreaRequired={true}
        title="Vacate Notice Status Update"
        message="Do you want to change status for this Notice?"
        confirmButtonText="Confirm"
        cancelButtonText="Cancel"
        extendData={vacateNoticeDetails}
        onConfirm={handleCancelStatus}
        onCancel={() => setStatusDialogOpen(false)}
        textAreaTitle={"Description"}
        textAreaPlaceholder="Enter here..."
      />

      <div className={"w-5/6 m-auto"}>
        <section className="h-12 bg-[#3D72BF] flex justify-between items-center text-white mb-12 px-2">
          <span className="flex items-center gap-2">
            <BsFillArrowLeftCircleFill
              id="backToPrevious"
              name="backToPrevious"
              data-cy="backToPrevious"
              title="Back to Previous Page"
              onClick={(e) => {
                SetPortfolioTree({
                  ...portfolioTree,
                  tenant: {
                    ...portfolioTree.tenant,
                    activeMenuItem: "delinquency",
                    activeDelinquencyTab: "vacateNotice",
                  },
                });
                navigate("/tenants/tenantDetails");
              }}
              className="w-6 h-6 cursor-pointer"
            />
            {vacateNoticeDetails.vacateID}
          </span>

          <span className="flex items-center gap-2">
            {vacateNoticeDetails &&
              vacateNoticeDetails.status !== "Cancelled" && (
                <ButtonWhiteOutline
                  btnName="cancelButton"
                  dataCy="cancelButton"
                  id="cancelButton"
                  name="Cancel"
                  onClick={(e) => setStatusDialogOpen(true)}
                  className="h-fit px-2 py-1"
                />
              )}

            {vacateNoticeDetails &&
              vacateNoticeDetails.status !== "Accepted" &&
              vacateNoticeDetails.status !== "Cancelled" && (
                <ButtonIconSquare
                  title="Edit"
                  onClick={(e) => setOpenVacateNoticeForm(true)}
                  icon={<TiEdit className="w-5 h-5" />}
                  className="hover:!text-blue-52"
                />
              )}
          </span>
        </section>

        <div className="grid grid-cols-2 gap-[10%] mb-12">
          <section className="flex flex-col justify-start">
            <div name="Info" className="flex justify-start items-center gap-2">
              <IconInCircle
                icon={
                  <IoDocumentTextOutline className="w-7 h-7 text-blue-52" />
                }
                radius="3rem"
                color="#CEDBEF"
              />
              <span className="gap-1">
                <p className="text-blue-52 font-semibold">Vacate Notice</p>
              </span>
            </div>

            <div name="Documents" className="mt-8  w-full">
              <span className="flex justify-start items-center border-b-2 border-[#42AACB] mb-4">
                Documents
              </span>

              {allDocuments.map((file, index) => (
                <FileDisplay
                  key={index + file.fileName}
                  file={file}
                  fileNameKey="fileName"
                  fileUrlKey="presignURL"
                  onDelete={handleDocDelete}
                  onDownload={handleDocDownload}
                />
              ))}

              {!allDocuments.length && (
                <p className="text-center">No Documents!</p>
              )}

              <span className="flex justify-center">
                {!allDocuments.length && (
                  <ButtonCustom
                    btnName="addDocuments"
                    dataCy="addDocuments"
                    id="addDocuments"
                    name={
                      <>
                        <FiPaperclip className="mr-1" /> Attach
                      </>
                    }
                    onClick={(e) => setOpenDocUploadForm(true)}
                    className="flex justify-center items-center mt-2 rounded-md h-10 px-2 text-[#3D72BF] border border-[#3D72BF] font-semibold hover:bg-[#3D72BF] hover:text-white focus:bg-[#3D72BF] focus:text-white"
                  />
                )}
              </span>
            </div>
            <div name="Comments" className="mt-8">
              <CommentsWidget
                borderClassName="border-[#42AACB]"
                CircleIconColor={"#0A875C"}
                comments={allComments}
                handleSend={addComment}
                userRole={userInfo.userRole}
                commentRoleKey="creatorRole"
                commenterName="creatorName"
                commentKey="comment"
                inputPlaceHolder="Add Comment"
              />
            </div>
          </section>

          <section className="flex flex-col justify-start">
            <div
              name="Details"
              className="border box-border h-fit px-6 overflow-hidden"
            >
              <DetailsTile
                label="Notice Status:"
                data={vacateNoticeDetails.status}
                className="mb-4 border-b-2 my-4 pb-2"
                dataClassName={`text-sm py-2 px-8 rounded text-white font-semibold  w-fit ${
                  vacateNoticeDetails.status === "Cancelled"
                    ? "bg-[#D10D22]"
                    : "bg-[#0A875C]"
                }`}
              />

              <DetailsTile
                label="Created By"
                data={vacateNoticeDetails.createdBy}
                className="mb-4"
                labelClassName="text-gray-500/100"
              />
              <DetailsTile
                label=" Date Issued"
                data={GetDateInMMDDYYYY(vacateNoticeDetails.dateIssued)}
                className="mb-4"
                labelClassName="text-gray-500/100"
              />

              <DetailsTile
                label="Move Out Date"
                data={GetDateInMMDDYYYY(vacateNoticeDetails.moveOutDate)}
                className="mb-4"
                labelClassName="text-gray-500/100"
              />

              <DetailsTile
                label="Reason"
                data={vacateNoticeDetails.reasonForVacate}
                className="mb-4"
                labelClassName="text-gray-500/100"
              />

              <DetailsTile
                label="Days to Respond"
                data={vacateNoticeDetails.daysOfResponse}
                className="mb-4"
                labelClassName="text-gray-500/100"
              />
            </div>

            <div
              name="Activity"
              className="border box-border mt-6 h-fit px-6 overflow-hidden"
            >
              <span className="flex justify-Start items-center py-2">
                <p className="font-semibold">Vacate Notice Activity</p>
              </span>
              <VacateNoticeActivityWidget data={activities} />
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default VacateNoticeDetails;

// View Lease Status
const VacateNoticeActivityWidget = (props) => {
  const [allActivities, setAllActivities] = useState([]);

  useEffect(() => {
    setAllActivities(props.data);
  }, [props.data]);

  const Tile = (props) => {
    return (
      <>
        <span hidden={props.hidden} className="flex items-center gap-1">
          <p className="text-gray-500 font-semibold">{props.title || "-"}</p>
          <p className="">{props.body || "-"}</p>
        </span>
      </>
    );
  };

  return (
    <>
      <div className="overflow-y-auto overscroll-contain max-h-96 w-full p-1">
        {allActivities.length > 0 ? (
          allActivities.map((item, index) => (
            <React.Fragment key={index}>
              <div className="gap-1 border-t-2 pt-2">
                <span className="flex justify-end">
                  <p className="font-medium text-gray-500">
                    {GetTimeYearToMin(item.CreatedAt) || "-"}
                  </p>
                </span>

                <Tile
                  title={item.creatorRole + ":"}
                  body={item.creatorName || "-"}
                />
                <Tile title="Status :" body={item.note || "-"} />

                {/* <p className="text-gray-500 pb-2">{item.note || "-"}</p> */}
              </div>
            </React.Fragment>
          ))
        ) : (
          <div className="border-t-2 mt-2 text-gray-500">No Data!</div>
        )}
      </div>
    </>
  );
};
