import React from "react";
import PropTypes from "prop-types";

const ToggleButton = ({
  isActive,
  onToggle,
  offLabel = "Off",
  onLabel = "On",
  className = "",
}) => {
  return (
    <div className={`flex items-center gap-2 ${className}`}>
      <p className="text-sm font-semibold">{offLabel}</p>
      <button
        onClick={(e) => {
          e.stopPropagation();
          onToggle(!isActive);
        }}
        className={`${
          isActive ? "bg-green-500" : "bg-gray-300"
        } w-12 h-6 rounded-full transition-all duration-300 focus:outline-none`}
      >
        <div
          className={`${
            isActive ? "transform translate-x-full" : "transform translate-x-0"
          } w-6 h-6 bg-white rounded-full shadow-md transition-transform duration-300`}
        />
      </button>
      <p className="text-sm font-semibold">{onLabel}</p>
    </div>
  );
};

ToggleButton.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  offLabel: PropTypes.string,
  onLabel: PropTypes.string,
  className: PropTypes.string,
};

export default ToggleButton;
