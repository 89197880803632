//Common Imports
import React, { useEffect, useState } from "react";
import { WebApimanager } from "../../../WebApiManager";
import { userData } from "../../../atoms";
import { useRecoilValue } from "recoil";
import swal from "sweetalert";

//Reusable Imports
import {
  ButtonBlue,
  ButtonGrayOutline,
  ButtonIconSquare,
} from "../../../Widgets/Buttons";
import InputField, {
  InputDateField,
  InputFileField,
  InputSelectField,
  InputTextArea,
} from "../../../Widgets/Forms/InputFields";
import { DateFormatForAPI } from "../../../Widgets/CommonWidgets";

//External Imports
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Dialog, DialogContent } from "@mui/material";
import dayjs from "dayjs";

const CreateVacateNotice = (props) => {
  let webApi = new WebApimanager();
  const userInfo = useRecoilValue(userData);
  const editForm = props.operation === "Edit" ? true : false;
  const leaseDetails = props.leaseDetails;
  const vacateNotice = editForm ? props.vacateNotice : {};

  //Form Variables
  const [reasonForVacating, setReasonForVavating] = useState(
    editForm ? vacateNotice.reasonForVacate : ""
  );
  const [moveOutDate, setMoveOutDate] = useState(
    editForm ? vacateNotice.moveOutDate : ""
  );
  const [daysToRespond, setDaysToRespond] = useState(
    editForm ? vacateNotice.daysOfResponse : ""
  );
  const [notes, setNotes] = useState(editForm ? vacateNotice.notes : "");
  const [attachFile, setAttachFile] = useState();
  const [fileUploadPath, setFileUploadPath] = useState("");
  const [formErrors, setFormErrors] = useState("");
  const InputFieldCustomProps = {
    min: 0,
  };

  //Form Validate Function
  const validate = () => {
    const errors = {};

    if (!reasonForVacating.trim()) {
      errors.reasonForVacating = "Reason For Vacating is required!";
    }
    if (!moveOutDate.trim()) {
      errors.moveOutDate = "Move Out Date is required!";
    } else if (
      new Date(moveOutDate) < getMoveOutMinDate() ||
      new Date(moveOutDate) > getMoveOutMaxDate()
    ) {
      errors.moveOutDate =
        "Move-out date must be between 30 and 180 days before the expiry date!";
    }
    if (!daysToRespond && vacateNotice.createdByRole !== "Tenant") {
      errors.daysToRespond = "Days To Respond is required!";
    }

    return errors;
  };

  //Form Submit Function
  const handleConfirm = (e) => {
    e.preventDefault();
    const temp = validate();
    setFormErrors(temp);
    if (Object.keys(temp).length) {
      return;
    } else {
      handleCreateEvictionNotice();
    }
  };

  //Create Vacate Notice API
  const handleCreateEvictionNotice = () => {
    let requestData = {
      creatorID: userInfo.ID,
      creatorRole: userInfo.userRole,
      createdBy: userInfo.firstName + userInfo.lastName,
      reasonForVacate: reasonForVacating,
      moveOutDate: DateFormatForAPI(moveOutDate),
      notes: notes,
      daysOfResponse: daysToRespond,
    };

    if (!editForm) {
      requestData.leaseID = leaseDetails.ID;
      requestData.fileName = attachFile && attachFile.name;
    }

    if (editForm) {
      webApi
        .put(`/vacateNotice/${vacateNotice.id}`, requestData, { loader: true })
        .then((res) => {
          if (res.data.errorCode) {
            swal("Error", res.data.errorMessage, "error");
          } else {
            props.getAllVacateNotices();
            swal("", `Notice edited Successfully!`, "success");
            props.setTrigger(false);
          }
        });
    } else {
      webApi
        .post("/vacateNotice", requestData, { loader: true })
        .then((res) => {
          if (res.data.errorCode) {
            swal("Error", res.data.errorMessage, "error");
          } else {
            if (attachFile) {
              setFileUploadPath(res.data.fileName);
              handleDocUpload(res.data.fileName, res.data.ID);
            } else {
              props.getAllVacateNotices();
              swal("", `Vacate Notice has been sent Successfully!`, "success");
              props.setTrigger(false);
            }
          }
        });
    }
  };

  //File Upload
  const handleDocUpload = (path, vacateNoticeID) => {
    const metaData = {
      filename: attachFile.name,
      mediatype: attachFile.type,
    };
    webApi
      .uploadFile(path, metaData, attachFile)
      .then((res) => {
        handleVacateNoticeMail(vacateNoticeID);
        props.getAllVacateNotices();
      })
      .catch((error) => {
        swal({
          title: "Info?",
          text: "Failed to Upload the Attached File!!",
          icon: "warning",
          buttons: ["Cancel", "Try Again"],
          dangerMode: false,
        }).then((confirmed) => {
          if (confirmed) {
            handleDocUpload(fileUploadPath);
          } else {
            props.getAllVacateNotices();
            props.setTrigger(false);
          }
        });
      });
  };

  const handleVacateNoticeMail = (VacateNoticeID) => {
    webApi
      .post(`/vacateNotice/sendEmail/${VacateNoticeID}`, { loader: true })
      .then((res) => {
        if (res.data.errorCode) {
          throw Error;
        } else if (res.data.status === true) {
          swal("", `Vacate Notice has been sent Successfully!`, "success");
          props.setTrigger(false);
        }
      })
      .catch((error) => {
        swal(
          "",
          `The Vacate Notice was created successfully, but the email notification for the Vacate Notice could not be sent.`,
          "success"
        );
        props.setTrigger(false);
      });
  };

  //Form Field Dependancies
  const getMoveOutMinDate = () => {
    const leaseEndDate = new Date(leaseDetails.leaseEndDate);
    const minDate = new Date(leaseEndDate);
    minDate.setDate(leaseEndDate.getDate() - 180);

    const today = new Date();
    // Reset time portion to 00:00:00 to compare dates only
    today.setHours(0, 0, 0, 0);

    return minDate;
  };

  const getMoveOutMaxDate = () => {
    const leaseEndDate = new Date(leaseDetails.leaseEndDate);
    const maxDate = new Date(leaseEndDate);
    maxDate.setDate(leaseEndDate.getDate() - 30);

    const today = new Date();
    // Reset time portion to 00:00:00 to compare dates only
    today.setHours(0, 0, 0, 0);

    return maxDate;
  };

  return (
    props.trigger && (
      <>
        <Dialog
          open={true}
          aria-labelledby="Add VacateNotice Form"
          fullWidth
          maxWidth="xs"
          //PaperProps={{ sx: { height: "100%" } }}
          className="flex flex-col justify-center w-full"
        >
          <div className="flex justify-center items-center bg-blue-52 text-white font-semibold rounded-sm h-10 pr-2">
            <span className="flex-grow text-center">
              {" "}
              {editForm ? "Edit Vacate Notice" : "Create Vacate Notice"}
            </span>
            <ButtonIconSquare
              id="close"
              btnName="close"
              dataCy="close"
              title="Close"
              onClick={(e) => props.setTrigger(false)}
              icon={<AiOutlineCloseCircle className="w-5 h-5" />}
              className="hover:!text-red-600"
            />
          </div>

          <DialogContent dividers>
            <div className="flex flex-col items-center justify-center gap-3 mx-auto">
              <InputSelectField
                label="Reason for Vacating"
                name="reasonForVacating"
                id="reasonForVacating"
                dataCy="reasonForVacating"
                placeholder="Choose a Reason for Vacating"
                disabled={editForm ? true : false}
                value={reasonForVacating}
                onChange={(e) => {
                  setReasonForVavating(e.target.value);
                }}
                options={["Lease Expiry", "Lease Violations", "Other"]}
                errorText={formErrors.reasonForVacating}
                style="!w-full"
              />

              <InputDateField
                label="Move Out Date"
                name="moveOutDate"
                id="moveOutDate"
                btnName="moveOutDate"
                dataCy="moveOutDate"
                minDate={getMoveOutMinDate()}
                maxDate={getMoveOutMaxDate()}
                value={moveOutDate}
                onChange={(value) => setMoveOutDate(value)}
                style="!w-full"
                hintText="Set Move-out dates between 30 and 180 days before the expiry date."
                errorText={formErrors.moveOutDate}
              />

              {vacateNotice.createdByRole !== "Tenant" && (
                <InputField
                  label="Days To Respond"
                  name="daysToRespond"
                  id="daysToRespond"
                  dataCy="daysToRespond"
                  type="text"
                  customProps={InputFieldCustomProps}
                  placeholder="Enter Days to Respond"
                  value={daysToRespond}
                  onChange={(e) => {
                    let value = "";
                    if (e.target.value.replace(/[^\d]/g, "")) {
                      value = parseInt(e.target.value.replace(/[^\d]/g, ""));
                    }
                    setDaysToRespond(value);
                  }}
                  errorText={formErrors.daysToRespond}
                  style="!w-full"
                />
              )}

              {!editForm && (
                <InputFileField
                  label="Attach File"
                  name="document"
                  id="document"
                  dataCy="document"
                  accept=".pdf"
                  maxSizeinMB={10}
                  notImp
                  placeholder="Attach .pdf format only"
                  value={attachFile}
                  setValue={setAttachFile}
                  hintText="*File Size should be less than 10MB"
                  style="!w-full"
                  errorText={formErrors.attachFile}
                />
              )}

              <InputTextArea
                label="Note"
                name="notes"
                id="notes"
                dataCy="notes"
                type="text"
                notImp
                disabled={editForm ? true : false}
                placeholder="Enter Notes"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                errorText={formErrors.notes}
                style="!w-full"
              />
            </div>

            <div className="flex justify-center items-center gap-16 my-6">
              <ButtonGrayOutline
                name="Cancel"
                btnName="cancel"
                dataCy="cancel"
                onClick={() => props.setTrigger(false)}
                className="mt-6 justify-self-end"
              />
              <ButtonBlue
                name="Confirm"
                btnName="Confirm"
                dataCy="Confirm"
                onClick={(e) => handleConfirm(e)}
                className="mt-6"
              />
            </div>
          </DialogContent>
        </Dialog>
      </>
    )
  );
};

export default CreateVacateNotice;
