import React, { useState, useEffect } from "react";
import { WebApimanager } from "../../../WebApiManager";
import {
  ButtonBlue,
  ButtonGrayOutline,
  ButtonIconSquare,
} from "../../../Widgets/Buttons";
import { Dialog, DialogContent, DialogActions } from "@mui/material";
import InputField, {
  InputSelectField,
  InputDateField,
  InputDollarsField,
  InputObjectSelectField,
} from "../../../Widgets/Forms/InputFields";
import swal from "sweetalert";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { DateFormatForAPI } from "../../../Widgets/CommonWidgets";

const AddUtilityCharges = (props) => {
  let webApi = new WebApimanager();
  const isSingleFamily = props.isSingleFamily;
  const editForm = props.operation === "Edit" ? true : false;
  const editImport = props.operation === "Import" ? true : false;
  const [formErrors, setFormErrors] = useState("");

  const [allUnits, setAllUnits] = useState(props.allUnits);

  const [selectedUnit, setSelectedUnit] = useState(
    editForm ? props.editData.unitID : ""
  );
  const [selectedUnitNumber, setSelectedUnitNumber] = useState("");
  const [utilityBillTypes, setUtilityBillTypes] = useState([]);
  const [selectedBillType, setSelectedBillType] = useState(
    editForm || editImport ? props.editData.billType : ""
  );
  const [serviceFrom, setServiceFrom] = useState(
    editForm || editImport ? props.editData.serviceFrom : ""
  );
  const [serviceTo, setServiceTo] = useState(
    editForm || editImport ? props.editData.serviceTo : ""
  );
  const [priorReading, setPriorReading] = useState(
    editForm || editImport ? props.editData.priorReading : ""
  );
  const [currentReading, setCurrentReading] = useState(
    editForm || editImport ? props.editData.currentReading : ""
  );
  const [totalConsumption, setTotalConsumption] = useState(
    editForm || editImport ? props.editData.totalConsumption : ""
  );
  const [perUnitPrice, setPerUnitPrice] = useState(
    editForm || editImport ? props.editData.perUnitPrice : ""
  );
  const [totalCharges, setTotalCharges] = useState(
    editForm || editImport ? props.editData.totalCharge : ""
  );

  useEffect(() => {
    if (editImport) getAllUnits();
    if (isSingleFamily && props.allUnits.length) {
      setSelectedUnit(props.allUnits[0].ID);
      setSelectedUnitNumber(props.allUnits[0].unitNumber);
    }
    getUtilityBillTypes();
  }, []);

  const validate = () => {
    const errors = {};
    if (!selectedUnit) {
      errors.selectedUnit = "Unit Number is required!";
    }
    if (!selectedBillType.trim()) {
      errors.selectedBillType = "Bill Type is required!";
    }
    if (!serviceFrom.trim()) {
      errors.serviceFrom = "Service From is required!";
    }
    if (!serviceTo.trim()) {
      errors.serviceTo = "Service To is required!";
    } else if (new Date(serviceTo) <= new Date(serviceFrom)) {
      errors.serviceTo =
        "Service To date should be later than the Service From date!";
    }
    if (!priorReading) {
      errors.priorReading = "Prior Reading is required!";
    }
    if (!currentReading) {
      errors.currentReading = "Current Reading is required!";
    } else if (currentReading < priorReading) {
      errors.currentReading =
        "Current Reading should be greater than prior reading!";
    }
    if (totalConsumption < 0) {
      errors.totalConsumption =
        "Please Enter valid Current and Prior readings!";
    }
    if (!perUnitPrice) {
      errors.perUnitPrice = "Per Unit Price is required!";
    }
    if (totalCharges < 0) {
      errors.totalCharges = "Please Enter valid Unit Price!";
    }
    return errors;
  };

  const getAllUnits = () => {
    webApi
      .get("/units/property/" + props.propertyId)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setAllUnits(res.data);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops!, Unable to get Units! Please try again later.`,
            "error"
          );
        }
      });
  };

  const getUtilityBillTypes = () => {
    webApi
      .get("/unitUtilityBill/billTypes")
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setUtilityBillTypes(res.data.billType);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Unable to get Bill Types! Please try again later.",
            "error"
          );
        }
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const temp = validate();
    setFormErrors(temp);
    if (Object.keys(temp).length) {
      return;
    } else {
      let data = {
        unitID: selectedUnit,
        billType: selectedBillType,
        serviceFrom: DateFormatForAPI(serviceFrom),
        serviceTo: DateFormatForAPI(serviceTo),
        priorReading: priorReading,
        currentReading: currentReading,
        totalConsumption: totalConsumption,
        perUnitPrice: parseInt(perUnitPrice),
        totalCharge: totalCharges,
      };

      if (editForm) {
        webApi
          .put("/unitUtilityBill/" + props.editData.ID, data)
          .then(async (res) => {
            if (res.data.errorCode) {
              swal("Failure", res.data.errorMessage, "error");
            } else {
              props.getBills();
              swal("Success", "Utility Bill Updated successfully", "success");
              props.setTrigger(false);
            }
          })
          .catch((error) => {
            if (error.customErrorMessage) {
              swal("Error", error.customErrorMessage, "error");
            } else {
              swal(
                "Error",
                "Failed to Update Utility Bill! Please try again later.",
                "error"
              );
            }
          });
      } else {
        webApi
          .post("/unitUtilityBill", data)
          .then(async (res) => {
            if (res.data.errorCode) {
              swal("Failure", res.data.errorMessage, "error");
            } else {
              props.getBills(res.data);
              swal("Success", "Utility Bill Created successfully", "success");
              props.setTrigger(false);
            }
          })
          .catch((error) => {
            if (error.customErrorMessage) {
              swal("Error", error.customErrorMessage, "error");
            } else {
              swal(
                "Error",
                "Failed to Add Utility Bill! Please try again later.",
                "error"
              );
            }
          });
      }
    }
  };

  const getTotalConsumption = (prior, current) => {
    if (prior && current) {
      setTotalConsumption(current - prior);
    }
  };

  const getTotalCharge = (totalCons, price) => {
    // if(totalCons && price){
    setTotalCharges(totalCons * price);
    //}
  };

  return props.trigger ? (
    <>
      <Dialog
        open={true}
        aria-labelledby="Create Amenity Form"
        fullWidth
        maxWidth="xs"
        PaperProps={{ sx: { height: "80%" } }}
        //scroll="body"
        className="flex flex-col justify-center w-full"
      >
        <div className="flex justify-center items-center bg-blue-52 text-white font-semibold rounded-sm h-10 pr-2">
          <span className="flex-grow text-center">
            {editForm ? "Edit " : "Add "}Charge
          </span>
          <ButtonIconSquare
            id="close"
            btnName="close"
            dataCy="close"
            title="Close"
            onClick={(e) => props.setTrigger(false)}
            icon={<AiOutlineCloseCircle className="w-5 h-5" />}
            className="hover:!text-red-600"
          />
        </div>

        <DialogContent dividers>
          <section className="flex flex-col items-center justify-center gap-4 mt-2 w-88 mx-auto">
            {isSingleFamily ? (
              <InputField
                label="Property Name"
                name="unitNumber"
                id="unitNumber"
                dataCy="unitNumber"
                placeholder="Unit Number"
                value={selectedUnitNumber}
                readOnly
                onChange={(e) => {}}
                style="!w-full"
                className="!bg-gray-50"
                errorText={formErrors.selectedUnit}
              />
            ) : (
              <InputObjectSelectField
                label="Unit Number"
                name="unit"
                id="unit"
                dataCy="unit"
                placeholder="Choose a Unit"
                value={selectedUnit}
                onChange={(e) => setSelectedUnit(parseInt(e.target.value))}
                options={allUnits}
                optionValue="ID"
                optionName="unitNumber"
                style="!w-full"
                errorText={formErrors.selectedUnit}
              />
            )}

            <InputSelectField
              label="Bill Type"
              name="billType"
              id="billType"
              dataCy="billType"
              placeholder="Select a Bill Type"
              options={utilityBillTypes}
              value={selectedBillType}
              onChange={(e) => setSelectedBillType(e.target.value)}
              errorText={formErrors.selectedBillType}
              style="!w-full"
            />

            <InputDateField
              label="Service From"
              name="serviceFrom"
              id="serviceFrom"
              dataCy="serviceFrom"
              value={serviceFrom}
              onChange={(value) => setServiceFrom(value)}
              errorText={formErrors.serviceFrom}
              style="!w-full"
            />

            <InputDateField
              label="Service To"
              name="serviceTo"
              id="serviceTo"
              dataCy="servoceTo"
              value={serviceTo}
              onChange={(value) => setServiceTo(value)}
              errorText={formErrors.serviceTo}
              style="!w-full"
            />

            <InputField
              label="Prior Reading"
              name="priorReading"
              id="priorReading"
              type="number"
              dataCy="priorReading"
              placeholder="Enter Prior Reading"
              value={priorReading}
              //onChange={(e) => setPriorReading(parseInt(e.target.value))}
              onChange={(e) => {
                setPriorReading(parseInt(e.target.value));
                getTotalConsumption(e.target.value, currentReading);
              }}
              errorText={formErrors.priorReading}
              style="!w-full"
            />

            <InputField
              label="Current Reading"
              name="currentReading"
              id="currentReading"
              dataCy="currentReading"
              type="number"
              placeholder="Enter Current Reading"
              value={currentReading}
              // onChange={(e) => }
              onChange={(e) => {
                setCurrentReading(parseInt(e.target.value));
                getTotalConsumption(priorReading, e.target.value);
              }}
              errorText={formErrors.currentReading}
              style="!w-full"
            />

            <InputField
              label="Total Consumption(Gal)"
              name="totalConsumption"
              id="totalConsumption"
              dataCy="totalConsumption"
              type="number"
              placeholder="Enter Total Consumption"
              value={totalConsumption}
              //onChange={(e) => setTotalConsumption(parseInt(e.target.value))}
              onChange={(e) => {
                setTotalConsumption(parseInt(e.target.value));
                getTotalCharge(e.target.value, perUnitPrice);
              }}
              errorText={formErrors.totalConsumption}
              style="!w-full"
              readOnly
            />

            <InputDollarsField
              label="Per Unit Price"
              name="perUnitPrice"
              id="perUnitPrice"
              dataCy="perUnitPrice"
              placeholder="Enter Per Unit Price"
              value={perUnitPrice}
              setValue={(value) => {
                setPerUnitPrice(value);
                getTotalCharge(totalConsumption, value);
              }}
              //onChange={(e) => setPerUnitPrice(parseInt(e.target.value))}
              // onChange={(e) => {
              //   setPerUnitPrice(parseInt(e.target.value));
              //   getTotalCharge(totalConsumption, e.target.value);
              // }}
              errorText={formErrors.perUnitPrice}
              style="!w-full"
              type="number"
            />

            {/* <InputDollarsField
              label="Total Charges"
              name="totalCharges"
              id="totalCharges"
              placeholder="Enter Total Charges"
              value={totalCharges}
              onChange={(e) => setTotalCharges(parseInt(e.target.value))}
              errorText={formErrors.totalCharges}
              style="!w-full"
              type="number"
              readOnly
            /> */}

            <label
              htmlFor="totalCharges"
              className="w-full text-gray-700 text-sm font-semibold"
            >
              Total Charges
              <span className="text-red-600">*</span>
              <span className="flex mt-1 items-center">
                <span className="bg-gray-50 border rounded text-gray-900 text-sm focus:border-blue-52 w-fit p-2">
                  $
                </span>
                <input
                  className={` shadow border appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none`}
                  name="totalCharges"
                  id="totalCharges"
                  data-cy="totalCharges"
                  type="number"
                  min={0}
                  placeholder="Enter Total Charges"
                  value={totalCharges}
                  onChange={(e) => setTotalCharges(parseInt(e.target.value))}
                  readOnly={true}
                />
              </span>
              <span className="text-red-10 text-sm">
                {formErrors.totalCharges}
              </span>
            </label>

            <div className="flex justify-center items-center gap-16">
              <ButtonGrayOutline
                id="cancel"
                btnName="cancel"
                dataCy="cancel"
                name="Cancel"
                onClick={() => props.setTrigger(false)}
                className="mt-6 justify-self-end"
              />
              <ButtonBlue
                id="confirm"
                btnName="confirm"
                dataCy="confirm"
                name="Confirm"
                onClick={handleSubmit}
                className="mt-6"
              />
            </div>
          </section>
        </DialogContent>
      </Dialog>
    </>
  ) : null;
};

export default AddUtilityCharges;
