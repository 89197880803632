import { WebApimanager } from "../../WebApiManager";
import swal from "sweetalert";

let webApi = new WebApimanager();

// API to Get Prospects Data
export const GetPortfolioDetailsByPortfolioId = (portfolioId) => {
  return webApi
    .get(`/portfolios/${portfolioId}`)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage);
      }
      return res;
    })
    .catch((error) => {
      swal(
        "",
        "Oops! unable to get portfolio details at the moment. Please try again later.",
        "error"
      );
      throw error;
    });
};

// API to Get Prospects Data
export const GetProspectsByPropertyIdAPI = (propertyId) => {
  return webApi
    .get(`/prospects/properties/${propertyId}`)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage);
      }
      return res;
    })
    .catch((error) => {
      swal(
        "",
        "Oops! Prospects Data couldn't be downloaded at the moment. Please try again later.",
        "error"
      );
      throw error;
    });
};

// API to Add Property to add Single Family to  Listing
export const AddSingleFamilyToListingAPI = (propertyId, requestData) => {
  return webApi
    .put(`/singleFamily/properties/${propertyId}`, requestData)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage);
      }
      return res;
    })
    .catch((error) => {
      // swal(
      //   "",
      //   "Oops! Failed to List Property at the moment. Please try again later.",
      //   "error"
      // );
      throw error;
    });
};

// API to Add Property to add Multi Family to  Listing
export const AddMultiFamilyToListingAPI = (propertyId, requestData) => {
  return webApi
    .put(`/multiFamily/properties/${propertyId}`, requestData)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage);
      }
      return res;
    })
    .catch((error) => {
      // swal(
      //   "",
      //   "Oops! Failed to List Property at the moment. Please try again later.",
      //   "error"
      // );
      throw error;
    });
};

// API to Get Prospects Data
export const GenerateLinkForPropertyAPI = (propertyId) => {
  return webApi
    .get(`/publishLink/property/${propertyId}`)
    .then((res) => {
      if (res.data.errorCode) {
        swal("", res.data.errorMessage, "error");
        throw new Error(res.data.errorMessage);
      }
      return res;
    })
    .catch((error) => {
      swal(
        "",
        "Oops! Link Couldn't be generated at the moment. Please try again later.",
        "error"
      );
      throw error;
    });
};
