import React, { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { isAuthenticated, userData } from "../../atoms";

const PrivateRoute = ({ child }) => {
  const userAuthenticated = useRecoilValue(isAuthenticated);
  const userInfo = useRecoilValue(userData);
  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    if (userInfo.surveyStatus === false) {
      navigate("/survey");
    } else if (
      userInfo?.isSubscribed === false &&
      userInfo?.subscriptionStatus === "" &&
      userInfo?.userRole === "Client Admin"
    ) {
      navigate("/subscription");
    } else if (
      userInfo?.isSubscribed === false &&
      userInfo?.subscriptionStatus === "Cancelled" &&
      userInfo?.userRole === "Client Admin"
    ) {
      navigate("/subscription/retry");
    }

  }, [userInfo, location.pathname]);

  // If the user is authenticated, render the child route
  // Otherwise, redirect to login page
  return userAuthenticated ? child : <Navigate to="/login" replace={true}/>
};

export default PrivateRoute;

