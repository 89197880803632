import React, { useState, useEffect, useRef } from "react";
import {
  userData,
  accessToken,
  idToken,
  refreshToken,
  portfolioTreeStore,
  profileImageUrl,
  navigatedNotif,
  navigatedNotifIndex,
} from "../../atoms";
import { WebApimanager } from "../../WebApiManager";
import {
  GetDateInMMDDYYYY,
  Dropdown,
  clickEffect,
} from "../../Widgets/CommonWidgets";
import { useRecoilValue, useResetRecoilState, useRecoilState } from "recoil";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";
import { GiHamburgerMenu } from "react-icons/gi";
import { BiCube } from "react-icons/bi";
import { BsBriefcase, BsChat } from "react-icons/bs";
import { FiHome, FiUsers, FiSearch, FiAlertOctagon } from "react-icons/fi";
import { MdMailOutline } from "react-icons/md";
import {
  TbFileSettings,
  TbFileDownload,
  TbClipboardText,
} from "react-icons/tb";
import swal from "sweetalert";
import { AiOutlineRise, AiOutlineBank } from "react-icons/ai";
import { RiPriceTag2Line, RiShieldUserLine } from "react-icons/ri";
import { IoMdNotifications } from "react-icons/io";
import { CgFileDocument } from "react-icons/cg";
import style from "./index.module.css";
import IdleTimeoutContainer from "../../Utility/IdleTimeoutContainer";
import { FaFileInvoiceDollar } from "react-icons/fa";
import {
  TbCircleArrowLeftFilled,
  TbCircleArrowRightFilled,
} from "react-icons/tb";
import { ButtonIconSquare } from "../../Widgets/Buttons";

//Feature Flags
import {
  PortfoliosMenu,
  MaintenanceMenu,
  ReportsMenu,
  DocumentsMenu,
  ImportMenu,
  DirectoriesMenu,
  UsersDirectoryMenu,
  OwnershipDirectoryMenu,
  TenantDirectoryMenu,
  VendorDirectoryMenu,
  NotificationsMenu,
  AccountingMenu,
  ChartOfAccountsMenu,
  BankInfoMenu,
  ConnectedBankMenu,
  ProspectsMenu,
} from "../../Utility/FeatureFlags/featureFlags";

const Navbar = () => {
  let webApi = new WebApimanager();
  const userInfo = useRecoilValue(userData);
  const userReset = useResetRecoilState(userData);
  const profileImage = useRecoilValue(profileImageUrl);
  const resetAccessToken = useResetRecoilState(accessToken);
  const resetIdToken = useResetRecoilState(idToken);
  const resetRefreshToken = useResetRecoilState(refreshToken);
  const resetPortfolioTree = useResetRecoilState(portfolioTreeStore);
  let navigate = useNavigate();
  const location = useLocation();
  const iconsCenter = "flex flex-col items-center ";
  const [isClientAdmin, setIsClientAdmin] = useState(false);
  const [showTableOptions, setShowTableOptions] = useState(false);
  const [notif, setNotif] = useState(false);
  const [notificationsData, setNotificationsData] = useState([]);
  const notificationsRef = useRef(null);
  const profileRef = useRef(null);
  const [notifOpen, setNotifOpen] = useState(false); // State for notifications dropdown
  const [profileOpen, setProfileOpen] = useState(false); // State for profile dropdown
  const [unRead, setUnRead] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [isMdScreen, setIsMdScreen] = useState(false);
  const [notifData, setNotifData] = useRecoilState(navigatedNotif);
  const [navNotifIndex, setNavnotifIndex] = useRecoilState(navigatedNotifIndex);
  const menuItems = [
    {
      accessor: "home",
      id: "home",
      label: "Home",
      path: "/dashboard",
      icon: <BiCube className="w-6 h-6" />,
    },
    {
      accessor: "portfolio",
      id: "portfolio",
      label: "Portfolio",
      path: "/portfolio",
      icon: <BsBriefcase className="w-6 h-6" />,
      hide: !PortfoliosMenu(),
    },
    {
      accessor: "maintenance",
      id: "maintenance",
      label: "Maintenance",
      path: "/maintenance",
      icon: <TbFileSettings className="w-6 h-6" />,
      hide: !MaintenanceMenu(),
    },
    // {
    //   accessor: "reports",
    //   id: "reports",
    //   label: "Reports",
    //   path: "/reports",
    //   icon: <CgFileDocument className="w-6 h-6" />,
    //   hide: !ReportsMenu(),
    // },
    {
      accessor: "reports",
      id: "reports",
      label: "Reports",
      path: "/report",
      icon: <CgFileDocument className="w-6 h-6" />,
      hide: !ReportsMenu(),
    },
    {
      accessor: "documents",
      id: "documents",
      label: "Documents",
      path: "/documents",
      icon: <TbClipboardText className="w-6 h-6" />,
      hide: !DocumentsMenu(),
    },
    {
      accessor: "import",
      id: "import",
      label: "Import",
      path: "/importCSV",
      icon: <TbFileDownload className="w-6 h-6" />,
      hide: !ImportMenu(),
    },
    {
      accessor: "directories",
      id: "directories",
      label: "Directories",
      path: "/directories",
      icon: <FiUsers className="w-6 h-6" />,
      //hide: !isClientAdmin,
      hide: !DirectoriesMenu(),
      children: [
        {
          name: "Users Directory",
          id: "usersDirectory",
          optionName: "usersDirectory",
          path: "/users",
          onClick: () => navigate("/directories/users"),
          hide: !UsersDirectoryMenu(),
          //hide: !isClientAdmin, //test this
        },
        {
          name: "Ownership Directory",
          id: "ownershipDirectory",
          optionName: "ownershipDirectory",
          path: "/owners",
          onClick: () => navigate("/directories/owners"),
          hide: !OwnershipDirectoryMenu(),
        },
        {
          name: "Tenant Directory",
          id: "tenantDirectory",
          optionName: "tenantDirectory",
          path: "/tenants",
          onClick: () => navigate("/directories/tenants"),
          hide: !TenantDirectoryMenu(),
        },
        {
          name: "Vendor Directory",
          id: "vendorDirectory",
          optionName: "vendorDirectory",
          path: "/vendor",
          onClick: () => navigate("/directories/vendor"),
          hide: !VendorDirectoryMenu(),
        },
      ],
    },
    {
      accessor: "notifications",
      id: "notifications",
      label: "Notifications",
      path: "/notifications",
      icon: <MdMailOutline className="w-6 h-6" />,
      hide: !NotificationsMenu(),
    },
    {
      accessor: "accounting",
      id: "accounting",
      label: "Accounting",
      path: "/accounting",
      icon: <FaFileInvoiceDollar className="w-6 h-6" />,
      //hide: HideAccountsMenu(),
      hide: !AccountingMenu(),
      children: [
        {
          name: "Chart of Accounts",
          id: "ChartOfAccounts",
          optionName: "ChartOfAccounts",
          path: "/chartOfAccounts",
          onClick: () => navigate("/accounting/chartOfAccounts"),
          hide: !ChartOfAccountsMenu(),
        },
        {
          name: "Bank Info",
          id: "bankInfo",
          optionName: "bankInfo",
          path: "/bankInfo",
          onClick: () => navigate("/accounting/bankInfo"),
          hide: !BankInfoMenu(),
          //hide: !isClientAdmin,
        },
        {
          name: "Connected Bank",
          id: "banking",
          optionName: "banking",
          path: "/banking",
          onClick: () => navigate("/accounting/banking"),
          hide: !ConnectedBankMenu(),
          //hide: !isClientAdmin,
        },
      ],
    },
    {
      accessor: "prospects",
      id: "prospects",
      label: "Prospects",
      path: "/prospects",
      icon: <RiPriceTag2Line className="w-6 h-6" />,
      hide: !ProspectsMenu(),
    },
  ];

  useEffect(() => {
    if (userInfo.userRole === "Client Admin") {
      setIsClientAdmin(true);
    }
    handleResize();
    getNotifications();
    const handleClickOutside = (event) => {
      if (
        notificationsRef.current &&
        !notificationsRef.current.contains(event.target)
      ) {
        setNotifOpen(false);
      }
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setProfileOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [userInfo]);

  // Toggle notifications dropdown
  const toggleNotifications = () => {
    setNotifOpen(!notifOpen);
    if (profileOpen) {
      setProfileOpen(false);
    }
  };

  // Toggle profile dropdown
  const toggleProfile = () => {
    setProfileOpen(!profileOpen);
    if (notifOpen) {
      setNotifOpen(false);
    }
  };

  const handleResize = () => {
    setIsMdScreen(window.innerWidth >= 768 && window.innerWidth <= 1200);
  };

  const getNotifications = () => {
    webApi
      .get("/notification/client/" + userInfo.ID + "&received")
      .then((res) => {
        if (res.data.errorCode) {
          //console.log("Failure", res.data.errorMessage, "error");
        } else {
          //adding latest 10 notifs
          const latestNotifications = res.data.slice(0, 10);
          setNotificationsData(latestNotifications);
          const hasUnreadNotification = latestNotifications.some(
            (item) => !item.isRead
          );

          if (hasUnreadNotification) {
            setUnRead(true);
          } else {
            setUnRead(false);
          }
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Unable to get the Notifications, Please try again later.`,
            "error"
          );
        }
      });
  };

  const [notifInside, setNotifInside] = useState(
    Array(notificationsData.length).fill(false)
  );

  const handleNotificationClick = (rows, index) => {
    setNotifData(rows);
    navigate("/notifications");
    setNavnotifIndex(index);
    setNotifOpen(false);
    setNotifInside((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
    if (rows.isRead === false) {
      updateNotoficationStatus(rows.ID);
    }
  };

  const handleViewAllNotifs = () => {
    setNotifOpen(false);
    navigate("/notifications");
  };

  const updateNotoficationStatus = (ID) => {
    webApi
      .put("/notification/isRead/" + ID + "&" + true)
      .then((res) => {
        if (res.data.errorCode) {
          //console.log("Failure", res.data.errorMessage, "error");
        } else {
          //console.log("success in updatenotif");
          getNotifications();
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          //console.log("Notification Is Read API Failed");
        }
      });
  };

  const handleLogout = async () => {
    await Auth.signOut().then(() => {
      userReset();
      resetAccessToken();
      resetIdToken();
      resetRefreshToken();
      resetPortfolioTree();
      //sessionStorage.clear();
      sessionStorage.removeItem("recoil-persist");
      navigate("/login", { replace: true });
    });
  };

  const gotToProfile = () => {
    navigate("/myprofile");
  };

  const gotToSubscription = () => {
    navigate("/subscription/Details");
  };

  const handleLinkClick = (value, event) => {
    if (value === "notifications") {
      setNotifData("");
    }
    // Additional logic if needed
  };

  return (
    <>
      <IdleTimeoutContainer handleLogout={handleLogout} />
      <nav className="flex flex-col h-[10%] items-center fixed w-full z-10 bg-white">
        <section className="grid grid-cols-6 items-center h-auto my-2 w-full">
          {/* logo Part */}
          <div
            className={iconsCenter + " cursor-pointer col-span-1"}
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            <img
              id="Yvooa Logo"
              className="h-3/4 w-3/4"
              src="/DASHLOGO.png"
              alt="Yvooa Logo"
            />
          </div>

          {/* Menu items */}
          <section className="flex justify-evenly items-center col-span-4">
            {menuItems.map((menuItem, index) => {
              let isActive = location.pathname.includes(menuItem.path);

              const handleMouseEnter = (index) => {
                setHoveredItem(index);
              };

              const handleMouseLeave = () => {
                setHoveredItem(null);
              };

              return (
                // Check if show property exists and its value is true
                !menuItem.hide && (
                  <>
                    {menuItem.children ? (
                      <NavbarDropdown
                        iconsCenter={iconsCenter}
                        hoveredItem={hoveredItem}
                        menuItem={menuItem}
                        options={menuItem.children}
                        index={index}
                        selectorActive={isActive}
                        isMdScreen={isMdScreen}
                        handleMouseEnter={handleMouseEnter}
                        handleMouseLeave={handleMouseLeave}
                      />
                    ) : (
                      <Link
                        key={index}
                        label={menuItem.accessor}
                        to={menuItem.path}
                        name={menuItem.accessor}
                        className={`hover:text-blue-52  ${iconsCenter} ${
                          isActive
                            ? "text-blue-52 focus:text-blue-52"
                            : "text-gray-600"
                        }`}
                        id={menuItem.id}
                        // activeClassName="text-blue-52" // Add multiple classes here
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                        onClick={(e) => handleLinkClick(menuItem.accessor, e)}
                      >
                        {menuItem.icon}
                        {isMdScreen &&
                          (isActive || hoveredItem === index) &&
                          menuItem.label}
                        {!isMdScreen && menuItem.label}
                      </Link>
                    )}
                  </>
                )
              );
            })}
          </section>

          {/* profile */}
          <div className="flex justify-center items-center col-span-1 cursor-pointer gap-4 pr-auto">
            <div ref={notificationsRef} className="text-blue-52">
              <div className="relative inline-block">
                <IoMdNotifications
                  onClick={toggleNotifications}
                  className="w-6 h-6"
                />
                <span
                  className={`absolute top-1 right-1 inline-block w-2 h-2 transform translate-x-1/2 -translate-y-1/2 rounded-full ${
                    unRead === true ? "bg-red-600" : ""
                  }`}
                ></span>
              </div>
              {notifOpen && (
                <>
                  <div className="absolute right-20 z-10 mt-2 w-64 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-h-64 overflow-auto">
                    {notificationsData.length > 0 ? (
                      notificationsData.map((rows, index) => (
                        <div className="flex-col" role="none" key={index}>
                          <div className="flex items-center">
                            <span
                              className={`h-2 w-2 rounded-full m-2 ${
                                rows.isRead === true
                                  ? "bg-gray-500"
                                  : "bg-red-500"
                              }`}
                            ></span>
                            <p
                              className="text-gray-700 block px-4 py-2 hover:bg-gray-300 w-52"
                              id={`menu-item-${index}`}
                              onClick={() =>
                                handleNotificationClick(rows, index)
                              }
                            >
                              {rows.notificationType}{" "}
                              <span>({GetDateInMMDDYYYY(rows.CreatedAt)})</span>
                            </p>
                            <svg
                              className={`-mr-1 h-5 w-5 arrow transform ${
                                notifInside[index] ? "rotate-180" : ""
                              }`}
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                              onClick={() =>
                                handleNotificationClick(rows, index)
                              }
                            >
                              <path
                                fillRule="evenodd"
                                d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </div>
                          {notifInside[index] && (
                            <div className="py-1 bg-slate-100" role="none">
                              <p className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-300">
                                {rows.message}
                              </p>
                            </div>
                          )}
                        </div>
                      ))
                    ) : (
                      <>
                        <div className="flex-col" role="none">
                          <div className="flex items-center">
                            <p className="text-gray-700 block px-4 py-2 hover:bg-gray-300 w-52">
                              No Notifications Available!
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                    <p
                      className="flex underline text-blue-52 justify-end cursor-pointer"
                      onClick={handleViewAllNotifs}
                    >
                      View All
                    </p>
                  </div>
                </>
              )}
            </div>
            <div
              ref={profileRef}
              name="Profile"
              className="flex justify-center items-center cursor-pointer relative"
              onClick={toggleProfile}
            >
              <img
                id="home-prof-img"
                className="w-10 h-10 rounded-full border border-gray-100 shadow-sm mr-2 bg-gray-400"
                src={
                  profileImage ||
                  "https://w7.pngwing.com/pngs/981/645/png-transparent-default-profile-united-states-computer-icons-desktop-free-high-quality-person-icon-miscellaneous-silhouette-symbol-thumbnail.png"
                }
                alt=""
              />
              <div className="flex flex-col md:hidden">
                {userInfo && (
                  <p id="userName" className="font-bold">
                    {userInfo["firstName"]}
                  </p>
                )}
                <p
                  id="userRole"
                  title={userInfo["userRole"]}
                  className="font-light overflow-hidden overflow-ellipsis whitespace-nowrap"
                >
                  {userInfo && userInfo["userRole"] === "Client Admin"
                    ? "CA"
                    : "PM"}
                </p>
              </div>

              {profileOpen && (
                <>
                  <div className="absolute right-6 top-12 z-10 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="flex-col" role="none">
                      <p
                        className="text-gray-700 px-4 py-2  hover:bg-gray-300 w-52"
                        id="viewProfile"
                        name="viewProfile"
                        onClick={gotToProfile}
                      >
                        View Profile
                      </p>
                      {isClientAdmin && (
                        <p
                          className="text-gray-700 px-4 py-2  hover:bg-gray-300 w-52"
                          id="goToSubscriptionDetails"
                          name="goToSubscriptionDetails"
                          onClick={gotToSubscription}
                        >
                          Subscription
                        </p>
                      )}
                      <p
                        className="text-gray-700 px-4 py-2  hover:bg-gray-300 w-52"
                        id="logoutBtn"
                        name="logoutBtn"
                        onClick={handleLogout}
                      >
                        Logout
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </section>

        {/* Alerts */}
        <AlertsBar data={notificationsData} />
      </nav>
    </>
  );
};

export default Navbar;

//Custom Hamburger Dropdown
const NavbarDropdown = ({
  options,
  menuItem,
  selectorActive,
  iconsCenter,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const location = useLocation();
  const [keepOpen, setKeepOpen] = useState(selectorActive);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div
      className="relative inline-flex justify-center items-center text-left cursor-pointer"
      onMouseEnter={() => {
        setIsOpen(true);
      }}
      onMouseLeave={() => {
        setIsOpen(false);
      }}
    >
      <div
        key={props.index}
        id={menuItem.id}
        label={menuItem.accessor}
        name={menuItem.accessor}
        className={`hover:text-blue-52 focus:text-blue-52 rounded-md ${iconsCenter} 
        ${selectorActive ? "text-blue-52" : "text-gray-600"} ${
          isOpen && "bg-gray-100"
        } `}
        onMouseEnter={() => props.handleMouseEnter(props.index)}
        onMouseLeave={() => props.handleMouseLeave()}
        onClick={() => setIsOpen(!isOpen)}
      >
        {menuItem.icon}
        {props.isMdScreen &&
          (selectorActive || props.hoveredItem === props.index || isOpen) &&
          menuItem.label}
        {!props.isMdScreen && menuItem.label}
      </div>
      {(isOpen || props.hoveredItem === props.index) && options ? (
        <div
          ref={dropdownRef}
          className="origin-top-right absolute left-4 top-12 z-10 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
        >
          {options.map((option) => (
            <React.Fragment key={option.name}>
              {(() => {
                const isActive = location.pathname.includes(option.path);
                return (
                  !option.hide && (
                    <button
                      className={`block w-full text-left px-4 py-2 hover:text-blue-52 focus:text-blue-52 ${
                        isActive ? "text-blue-52" : "text-gray-600"
                      }`}
                      onClick={() => {
                        option.onClick(option.name);
                        setIsOpen(false);
                      }}
                      id={option.id}
                      name={option.optionName}
                    >
                      {option.name}
                    </button>
                  )
                );
              })()}
            </React.Fragment>
          ))}
        </div>
      ) : null}
    </div>
  );
};

//Custom Alerts Bar
const AlertsBar = ({ data }) => {
  let navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevClick = () => {
    currentIndex === 0
      ? setCurrentIndex(data.length - 1)
      : setCurrentIndex(currentIndex - 1);
  };

  const handleNextClick = () => {
    currentIndex + 1 === data.length
      ? setCurrentIndex(0)
      : setCurrentIndex(currentIndex + 1);
  };

  return (
    <section className=" w-full px-4 h-auto text-white bg-[#389DAD] flex items-center justify-between border-t-2 border-blue-52">
      {data.length > 0 && (
        <>
          <ButtonIconSquare
            title="Left"
            onClick={handlePrevClick}
            icon={<TbCircleArrowLeftFilled className="w-4 h-4" />}
            className="hover:!text-blue-52"
          />
          <div
            className="flex items-center px-4 overflow-hidden clickEffect cursor-pointer"
            onClick={(e) => navigate("/notifications")}
          >
            <FiAlertOctagon
              className="w-4 h-4 text-amber-400 mx-1"
              style={{ flexShrink: 0 }}
            />
            <span className="font-semibold mx-1 overflow-hidden whitespace-nowrap overflow-ellipsis">
              {data[currentIndex].notificationType}:{" "}
              {data[currentIndex].message}
            </span>
          </div>
          <ButtonIconSquare
            title="Right"
            onClick={handleNextClick}
            icon={<TbCircleArrowRightFilled className="w-4 h-4" />}
            className="hover:!text-blue-52"
          />
        </>
      )}
    </section>
  );
};
