import React, { useState, useCallback } from "react";
import { WebApimanager } from "../../WebApiManager";
import AddressComponent from "../../Widgets/Forms/AddressComponent";
import {
  TextReviewTile,
  FileReviewTile,
} from "../../Widgets/Forms/FormReviewTiles";
import {
  ButtonBlue,
  ButtonGrayOutline,
  ButtonIconSquare,
} from "../../Widgets/Buttons";
import SnackAlert from "../../Widgets/SnackbarAlert";
import InputField, {
  InputSelectField,
  InputObjectSelectField,
  InputDollarsField,
  InputFileField,
} from "../../Widgets/Forms/InputFields";
import { Dialog, DialogContent, Stepper, StepLabel, Step } from "@mui/material";
import { DollarPrefix } from "../../Widgets/Forms/FieldDependants";
import swal from "sweetalert";
import { useEffect } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";

const CreateUnit = (props) => {
  let webApi = new WebApimanager();
  const editForm = props.operation === "Edit" ? true : false;
  const editImport = props.operation === "Import" ? true : false;
  const propertyData = props.Property;
  //const allFloorPlans = props.allFloorPlans;
  const [allFloorPlans, setAllFloorPlans] = useState(props.allFloorPlans || []);
  const unitData = props.unitData || "";

  const [formData, setFormData] = useState({
    selectedFloorPlanId: editForm || editImport ? unitData.unitTypeId : "",
    selectedFloorPlanName: "",
    unitNumber: editForm || editImport ? unitData.unitNumber : "",
    sqFt: editForm || editImport ? unitData.sqFt : "",
    unitStatus: editForm || editImport ? unitData.unitStatus : "",
    numberOfBeds: editForm || editImport ? unitData.numberOfBeds : "",
    numberOfBaths: editForm || editImport ? unitData.numberOfBaths : "",
    // numberOfRooms: editForm || editImport ? unitData.numberOfRooms : "",
    rent: editForm || editImport ? unitData.rent : "",
    deposit: editForm || editImport ? unitData.deposit : "",
    isPetsAllowed: editForm || editImport ? unitData.isPetsAllowed : "",
    petDeposit: editForm || editImport ? unitData.petDeposit : "",
  });

  const [completeAddress, setCompleteAddress] = useState({
    address1: propertyData.address.address1,
    address2: propertyData.address.address2,
    city: propertyData.address.city,
    state: propertyData.address.state,
    country: propertyData.address.country,
    zipCode: propertyData.address.zipCode,
  });

  const [unitImage, setUnitImage] = useState("");

  const [allUnitStatus, setAllUnitStatus] = useState([]);
  const [formErrors, setFormErrors] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [apiProcessing, setApiProcessing] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState("error");
  const [snackbarMessage, setSnackbarMessage] = useState("New Unit Created");

  const CustomProps = {
    min: 0,
  };

  useEffect(() => {
    webApi
      .get("/units/list/status")
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setAllUnitStatus(res.data.status);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to Load Unit Status Options, Please try again later.`,
            "error"
          );
        }
      });
  }, []);

  const validate = () => {
    const errors = {};
    if (activeStep === 0) {
      if (!formData.unitNumber) {
        errors.unitNumber = "Unit Number is required!";
      }
      // if (!editImport && !formData.selectedFloorPlanId) {
      //   errors.selectedFloorPlanId = "Floor Plan is required!";
      // }
      // if (!formData.unitStatus) {
      //   errors.unitStatus = "Unit Status is required!"; //Changes for New Unit Flow
      // }
      // if (!formData.numberOfRooms) {
      //   errors.numberOfRooms = "No. Of Rooms is required!";
      // }
      if (!formData.numberOfBeds) {
        errors.numberOfBeds = "No. Of Beds is required!";
      }
      if (!formData.numberOfBaths) {
        errors.numberOfBaths = "No. Of Bathrooms is required!";
      }
      if (!formData.sqFt) {
        errors.sqFt = "Area is required!";
      } else if (!Number.isInteger(formData.sqFt)) {
        errors.sqFt = "Value should be an integer!";
      }

      if (!formData.rent) {
        errors.rent = "Rent Amount is required!";
      }
      if (!formData.deposit) {
        errors.deposit = "Deposit Amount is required!";
      }
      if (formData.isPetsAllowed === "") {
        errors.isPetsAllowed = "required!";
      }
      if (formData.isPetsAllowed && !formData.petDeposit) {
        errors.petDeposit = "Pet Deposit Amount is required!";
      }
    } else if (activeStep === 1) {
      if (!completeAddress.address1) {
        errors.address1 = "Address 1 is required!";
      }
      if (!completeAddress.city) {
        errors.city = "City is required!";
      }
      if (!completeAddress.state) {
        errors.state = "State is required!";
      }
      if (!completeAddress.country) {
        errors.country = "Country is required!";
      }
      if (!completeAddress.zipCode) {
        errors.zipCode = "Zipcode is required!";
      }
    }

    return errors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (apiProcessing) {
      handleSnackbar("warning", "Operation in Progress..!");
    } else {
      //setApiProcessing(true);
      let data = {
        unitTypeId: formData.selectedFloorPlanId,
        propertyId: propertyData.ID,
        unitNumber: formData.unitNumber,
        sqFt: formData.sqFt,
        //unitStatus: formData.unitStatus,   //Changes for New Unit Flow
        numberOfBeds: formData.numberOfBeds,
        numberOfBaths: formData.numberOfBaths,
        // numberOfRooms: formData.numberOfRooms,
        rent: formData.rent,
        deposit: formData.deposit,
        address: {
          address1: completeAddress.address1,
          address2: completeAddress.address2,
          city: completeAddress.city,
          state: completeAddress.state,
          country: completeAddress.country,
          zipCode: completeAddress.zipCode,
        },
        isPetsAllowed: formData.isPetsAllowed,
        petDeposit: formData.petDeposit,
        //image: unitImage.name,
      };

      if (editForm) {
        webApi
          .put(`/units/${unitData.ID}`, data)
          .then(async (res) => handleApiResponse(res))
          .catch((error) => {
            if (error.customErrorMessage) {
              swal("Error", error.customErrorMessage, "error");
            } else {
              swal(
                "Error",
                `Oops! Failed to Update Unit ${data.unitNumber}, Please try again later.`,
                "error"
              );
            }
          });
      } else {
        webApi
          .post("/units", data)
          .then(async (res) => handleApiResponse(res))
          .catch((error) => {
            if (error.customErrorMessage) {
              swal("Error", error.customErrorMessage, "error");
            } else {
              swal(
                "Error",
                `Oops! Failed to Create Unit ${data.unitNumber}, Please try again later.`,
                "error"
              );
            }
          });
      }
    }
  };

  const handleApiResponse = async (res) => {
    if (res.data.errorCode) {
      swal("Failure", res.data.errorMessage, "error");
      setApiProcessing(false);
    } else {
      if (unitImage) {
        const metaData = {
          filename: unitImage.name,
          mediatype: unitImage.type,
        };
        webApi
          .uploadFile(res.data.image, metaData, unitImage)
          .then((res) => {})
          .catch((error) => {
            if (error.customErrorMessage) {
              swal("Error", error.customErrorMessage, "error");
            } else {
              swal(
                "Error",
                "Failed to Upload Unit Image! Please try again later.",
                "error"
              );
            }
          });
      }
      if (editImport) {
        props.getUnits(res.data);
      } else {
        props.getUnits();
      }
      swal(
        "Success",
        editForm ? "Successfully Updated Unit" : "Successfully Created a Unit",
        "success"
      );
      setApiProcessing(false);
      props.setUnitFormPage(false);
    }
  };

  const FillFloorPlanDependentFields = (planID) => {
    for (let i = 0; i < allFloorPlans.length; i++) {
      if (planID === allFloorPlans[i].ID) {
        setFormData({
          ...formData,
          selectedFloorPlanId: allFloorPlans[i].ID,
          // numberOfRooms: allFloorPlans[i].numberOfRooms,
          numberOfBeds: allFloorPlans[i].numberOfBeds,
          numberOfBaths: allFloorPlans[i].numberOfBaths,
          selectedFloorPlanName: allFloorPlans[i].unitTypeName,
        });
        return;
      }
    }
  };

  const handleSteps = (event) => {
    event.preventDefault();
    const temp = validate();
    setFormErrors(temp);
    if (Object.keys(temp).length) {
      return;
    } else {
      setActiveStep((currentStep) => currentStep + 1);
    }
  };

  const handleOnChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleBack = (event) => {
    event.preventDefault();
    setActiveStep((currentStep) => currentStep - 1);
  };

  const setNewAddress = useCallback(
    (newAddress) => {
      setCompleteAddress(newAddress);
    },
    [completeAddress]
  );

  const handleSnackbar = (type, message) => {
    setOpenSnackbar(true);
    setSnackbarType(type);
    setSnackbarMessage(message);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return props.openUnitFormPage ? (
    <>
      <Dialog
        open={true}
        aria-labelledby="Create Unit Form"
        fullWidth
        maxWidth="lg"
        PaperProps={{ sx: { height: "80%" } }}
        //scroll="body"
        className="flex flex-col justify-center w-full mx-auto"
      >
        <SnackAlert
          open={openSnackbar}
          close={handleSnackbarClose}
          type={snackbarType}
          message={snackbarMessage}
        />

        <div className="flex justify-center items-center bg-blue-52 text-white font-semibold rounded-sm h-10 pr-2">
          <span className="flex-grow text-center">
            {" "}
            {editForm ? "Edit Unit" : "Add Unit"}
          </span>
          <ButtonIconSquare
            id="closeAddUnitForm"
            btnName="closeAddUnitForm"
            dataCy="closeAddUnitForm"
            title="Close"
            onClick={(e) => props.setUnitFormPage(false)}
            icon={<AiOutlineCloseCircle className="w-5 h-5" />}
            className="hover:!text-red-600"
          />
        </div>

        <Stepper activeStep={activeStep} alternativeLabel className="my-4">
          <Step>
            <StepLabel>Unit Details</StepLabel>
          </Step>
          <Step>
            <StepLabel>Unit Address</StepLabel>
          </Step>
          <Step>
            <StepLabel>Confirm Details</StepLabel>
          </Step>
        </Stepper>

        <DialogContent dividers>
          <form className="flex flex-col justify-center items-center">
            {activeStep === 0 ? (
              <>
                <span className="border-b-2 border-blue-52 pb-1 mb-4 text-blue-52 w-4/6 md:gap-x-8 md:w-11/12">
                  Unit Details
                </span>
                <section className="grid grid-cols-2 gap-x-16 gap-y-6 w-4/6 mb-4 md:gap-x-8 md:w-11/12">
                  <InputField
                    label="Unit Number"
                    name="unitNumber"
                    id="unitNumber"
                    dataCy="unitNumber"
                    type="text"
                    placeholder="Enter Unit Number"
                    value={formData.unitNumber}
                    onChange={(e) =>
                      handleOnChange("unitNumber", e.target.value)
                    }
                    errorText={formErrors.unitNumber}
                    style="!w-full"
                  />

                  {!editImport && (
                    <InputObjectSelectField
                      label="Floor Plan"
                      name="selectedFloorPlanId"
                      id="selectedFloorPlanId"
                      dataCy="selectedFloorPlanId"
                      notImp
                      placeholder="Select a Floor Plan"
                      value={formData.selectedFloorPlanId}
                      onChange={(e) => {
                        handleOnChange(
                          "selectedFloorPlanId",
                          parseInt(e.target.value)
                        );
                        FillFloorPlanDependentFields(parseInt(e.target.value));
                      }}
                      options={allFloorPlans}
                      optionValue="ID"
                      optionName="unitTypeName"
                      style="!w-full"
                      errorText={formErrors.selectedFloorPlanId}
                    />
                  )}

                  {/* <InputSelectField
                    label="Unit Status"
                    name="unitStatus"
                    id="unitStatus"
                    placeholder="Choose a Unit Status"
                    options={allUnitStatus}
                    value={formData.unitStatus}
                    onChange={(e) =>
                      handleOnChange("unitStatus", e.target.value)
                    }
                    errorText={formErrors.unitStatus}
                    style="!w-full"
                  /> */}

                  <InputField
                    label="Area(Sq.ft)"
                    name="sqFt"
                    id="sqFt"
                    dataCy="sqFt"
                    type="text"
                    placeholder="Enter Area in Sq.ft"
                    //CustomProps={CustomProps}
                    value={formData.sqFt}
                    onChange={(e) => {
                      let value = "";
                      if (e.target.value.replace(/[^\d]/g, "")) {
                        value = parseInt(e.target.value.replace(/[^\d]/g, ""));
                      }
                      handleOnChange("sqFt", value);
                    }}
                    errorText={formErrors.sqFt}
                    style="!w-full"
                  />

                  <InputDollarsField
                    label="Monthly Rent"
                    name="rent"
                    id="rent"
                    dataCy="rent"
                    placeholder="Enter Rent Amount"
                    value={formData.rent}
                    setValue={(value) => handleOnChange("rent", value)}
                    errorText={formErrors.rent}
                    style="!w-full"
                  />

                  <InputDollarsField
                    label="Minimum Security Deposit"
                    name="deposit"
                    id="deposit"
                    dataCy="deposit"
                    placeholder="Enter Deposit Amount"
                    value={formData.deposit}
                    setValue={(value) => handleOnChange("deposit", value)}
                    errorText={formErrors.deposit}
                    style="!w-full"
                  />

                  {/* <InputField
                    label="No. Of Rooms"
                    name="numberOfRooms"
                    id="numberOfRooms"
                    dataCy="numberOfRooms"
                    type="text"
                    placeholder="Enter No. of Rooms"
                    //disabled={editImport ? false : true}
                    value={formData.numberOfRooms}
                    onChange={(e) => {
                      let value = "";
                      if (e.target.value.replace(/[^\d]/g, "")) {
                        value = parseInt(e.target.value.replace(/[^\d]/g, ""));
                      }

                      handleOnChange("numberOfRooms", value);
                    }}
                    errorText={formErrors.numberOfRooms}
                    // hintText={editImport ? "" : "Non Editable"}
                    // className={editImport ? "" : "bg-gray-50"}
                    style="!w-full"
                  /> */}

                  <InputField
                    label="No. Of Beds"
                    name="numberOfBeds"
                    id="numberOfBeds"
                    dataCy="numberOfBeds"
                    type="text"
                    //disabled={editImport ? false : true}
                    placeholder="Enter No. of Beds"
                    value={formData.numberOfBeds}
                    onChange={(e) => {
                      let value = "";
                      if (e.target.value.replace(/[^\d]/g, "")) {
                        value = parseInt(e.target.value.replace(/[^\d]/g, ""));
                      }
                      handleOnChange("numberOfBeds", value);
                    }}
                    errorText={formErrors.numberOfBeds}
                    // hintText={editImport ? "" : "Non Editable"}
                    // className={editImport ? "" : "bg-gray-50"}
                    style="!w-full"
                  />

                  <InputField
                    label="No. Of Bathrooms"
                    name="numberOfBaths"
                    id="numberOfBaths"
                    dataCy="numberOfBaths"
                    type="text"
                    //disabled={editImport ? false : true}
                    placeholder="Enter No. of Bathrooms"
                    value={formData.numberOfBaths}
                    onChange={(e) => {
                      let value = "";
                      if (e.target.value.replace(/[^\d]/g, "")) {
                        value = parseInt(e.target.value.replace(/[^\d]/g, ""));
                      }
                      handleOnChange("numberOfBaths", value);
                    }}
                    errorText={formErrors.numberOfBaths}
                    // hintText={editImport ? "" : "Non Editable"}
                    // className={editImport ? "" : "bg-gray-50"}
                    style="!w-full"
                  />

                  <InputObjectSelectField
                    label="Pets Allowed"
                    name="isPetsAllowed"
                    id="isPetsAllowed"
                    dataCy="isPetsAllowed"
                    placeholder="Choose a value"
                    value={formData.isPetsAllowed.toString()}
                    onChange={(e) => {
                      const petsAllowed = JSON.parse(e.target.value);
                      if (petsAllowed) {
                        handleOnChange("isPetsAllowed", petsAllowed);
                      } else {
                        setFormData({
                          ...formData,
                          isPetsAllowed: petsAllowed,
                          petDeposit: "",
                        });
                      }
                    }}
                    options={[
                      { name: "Yes", value: "true" },
                      { name: "No", value: "false" },
                    ]}
                    optionValue="value"
                    optionName="name"
                    style="!w-full"
                    errorText={formErrors.isPetsAllowed}
                  />

                  {formData.isPetsAllowed && (
                    <InputDollarsField
                      label="Pet Deposit"
                      name="petDeposit"
                      id="petDeposit"
                      dataCy="petDeposit"
                      placeholder="Enter Pet Deposit Amount"
                      value={formData.petDeposit}
                      setValue={(value) => handleOnChange("petDeposit", value)}
                      errorText={formErrors.petDeposit}
                      style="!w-full"
                    />
                  )}
                </section>
                <div className="flex justify-center items-center gap-16 mt-6 mb-4 w-3/4">
                  <ButtonGrayOutline
                    id="cancel"
                    btnName="cancel"
                    dataCy="cancel"
                    name="Cancel"
                    onClick={() => props.setUnitFormPage(false)}
                    className="mt-6 justify-self-end"
                  />
                  <ButtonBlue
                    id="next"
                    btnName="next"
                    dataCy="next"
                    name="Next"
                    onClick={(e) => handleSteps(e)}
                    className="mt-6"
                  />
                </div>
              </>
            ) : null}

            {activeStep === 1 ? (
              <>
                <span className="border-b-2 border-blue-52 pb-1 mb-4 text-blue-52 w-4/6 md:gap-x-8 md:w-11/12">
                  Unit Address
                </span>
                <section className="grid grid-cols-2 gap-x-16 gap-y-6 w-4/6 mb-4 md:gap-x-8 md:w-11/12">
                  <AddressComponent
                    address={completeAddress}
                    setNewAddress={setNewAddress}
                    formErrors={formErrors}
                    style="!w-full"
                  />
                  <ButtonGrayOutline
                    id="back"
                    btnName="back"
                    dataCy="back"
                    name="Back"
                    onClick={(e) => handleBack(e)}
                    className="mt-6 justify-self-end"
                  />
                  <ButtonBlue
                    id="next"
                    btnName="next"
                    dataCy="next"
                    name="Next"
                    onClick={(e) => handleSteps(e)}
                    className="mt-6"
                  />
                </section>
              </>
            ) : null}

            {activeStep === 2 ? (
              <>
                <span className="border-b-2 border-blue-52 pb-1 mb-4 text-blue-52 w-3/4 md:gap-x-8 md:w-11/12">
                  Review Unit Details
                </span>
                <div className="grid grid-cols-3 gap-x-16 gap-y-6 w-3/4 justify-around items-center md:gap-x-8 md:w-11/12">
                  <TextReviewTile title="Unit No." body={formData.unitNumber} />
                  <TextReviewTile
                    title="Floor Plan"
                    hidden={editImport}
                    body={formData.selectedFloorPlanName}
                  />
                  {/* <TextReviewTile
                    title="Unit Status"
                    body={formData.unitStatus}
                  /> */}
                  <TextReviewTile title="Area(Sq.ft)" body={formData.sqFt} />
                  <TextReviewTile
                    title="Monthly Rent"
                    body={"$" + formData.rent.toLocaleString()}
                  />
                  <TextReviewTile
                    title="Minimum Security Deposit"
                    body={"$" + formData.deposit.toLocaleString()}
                  />
                  {/* <TextReviewTile
                    title="No. of Rooms"
                    body={formData.numberOfRooms}
                  /> */}
                  <TextReviewTile
                    title="No. of Beds"
                    body={formData.numberOfBeds}
                  />
                  <TextReviewTile
                    title="No. of Bathrooms"
                    body={formData.numberOfBaths}
                  />
                  {/* <FileReviewTile title="Unit Image" body={unitImage.name} /> */}
                  <TextReviewTile
                    title="Pets Allowed"
                    body={formData.isPetsAllowed ? "Yes" : "No"}
                  />
                  <TextReviewTile
                    title="Pet Deposit"
                    body={"$" + formData.petDeposit.toLocaleString()}
                    hidden={!formData.isPetsAllowed}
                  />
                </div>
                <span className="border-b-2 border-blue-52 pb-1 mb-4 text-blue-52 w-3/4 mt-6 md:gap-x-8 md:w-11/12">
                  Review Unit Address Details
                </span>
                <div className="grid grid-cols-3 gap-x-16 gap-y-6 w-3/4 justify-around items-center md:gap-x-8 md:w-11/12">
                  <TextReviewTile
                    title="Address 1"
                    body={completeAddress.address1}
                  />
                  <TextReviewTile
                    title="Address 2"
                    body={completeAddress.address2}
                  />
                  <TextReviewTile title="City" body={completeAddress.city} />
                  <TextReviewTile title="State" body={completeAddress.state} />
                  <TextReviewTile
                    title="Country"
                    body={completeAddress.country}
                  />
                  <TextReviewTile
                    title="Zipcode"
                    body={completeAddress.zipCode}
                  />
                </div>
                <div className="flex justify-center items-center gap-16 mt-8 mb-4 w-3/4">
                  <ButtonGrayOutline
                    id="back"
                    btnName="back"
                    dataCy="back"
                    name="Back"
                    onClick={(e) => handleBack(e)}
                    hidden={apiProcessing}
                  />
                  <ButtonBlue
                    name="Confirm"
                    id="confirm"
                    btnName="confirm"
                    dataCy="confirm"
                    onClick={handleSubmit}
                  />
                </div>
              </>
            ) : null}
          </form>
        </DialogContent>
      </Dialog>
    </>
  ) : null;
};

export default CreateUnit;
