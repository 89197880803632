import React, { useEffect } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { ButtonBlue } from "../../Widgets/Buttons";
import swal from "sweetalert";
import { hideLoader, showLoader } from "../loaderProvider";

export default function StripePaymentGatewayForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const returnUrl = `${window.location.origin}/subscription/Details`;

  // console.log("returnUrl", returnUrl);

  const paymentElementOptions = {
    layout: "tabs",
  };

  useEffect(() => {
    if (!stripe) return;

    if (!props.CS) return;
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    showLoader();
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    stripe
      .confirmPayment({
        elements,
        confirmParams: {
          // Return URL where the customer should be redirected after the PaymentIntent is confirmed.
          return_url: returnUrl,
        },
        redirect: "if_required",
      })
      .then(function (result) {
        hideLoader();
        if (result.error) {
          swal("", result.error.message, "error");
        } else if (
          result.paymentIntent.status === "succeeded" ||
          result.paymentIntent.status === "processing"
        ) {
          swal("", "Payment Method added successfully", "success");
          props.handleConfirm(result);
        }
      });
    hideLoader();
  };

  return (
    <>
      <form id="payment-form" className="mx-auto">
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        {/* <button disabled={isLoading || !stripe || !elements} onClick={(e) => handleSubmit(e)}>
      <span id="button-text">
        {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
      </span>
    </button> */}
        <div className="flex justify-center items-center mt-2">
          <ButtonBlue
            id="payNowButton"
            name="Pay now"
            onClick={(e) => handleSubmit(e)}
            className="mt-3 px-2 py-1 !w-40"
            disabled={!stripe || !elements}
          />
        </div>
        {/* Show any error or success messages */}
        {/* {message && <div id="payment-message">{message}</div>} */}
      </form>
    </>
  );
}
