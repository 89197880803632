import React, { useCallback, useEffect, useState } from "react";

import { motion } from "framer-motion"; // Framer for animations
import { clickEffect } from "../../Widgets/CommonWidgets";
import swal from "sweetalert";
import { useRecoilValue } from "recoil";
import { userData } from "../../atoms";
import { AiOutlineMenu } from "react-icons/ai";
import {
  InputObjectSelectField,
  InputSelectField,
} from "../../Widgets/Forms/InputFields";
import { ButtonCustom } from "../../Widgets/Buttons";
import {
  GetPortfoliosByUserIdAPI,
  GetPropertiesByPortfolioIdAPI,
} from "../../CentralizedAPI/commonAPI";
import {
  GetListedPropertiesByClientIdAPI,
  GetProspectsByClientIdAPI,
  GetProspectsByPropertyIdAPI,
} from "./prospectsAPI";
import { PropertiesListing } from "./propertiesListing";
import { Prospects } from "./prospects";

const ProspectDashboard = () => {
  const userInfo = useRecoilValue(userData);
  const [activeMenuItem, setActiveMenuItem] = useState("");
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [reportTitle, setReportTitle] = useState("");

  // Consolidated Filter Variables
  const [filters, setFilters] = useState({
    portfolioName: "",
    propertyName: "",
    propertyStatus: "",
    availPeriod: "",
    prosAppStatus: "",
  });

  const [portfolioOptions, setPortfolioOptions] = useState([]);
  const [propertyOptions, setPropertyOptions] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [showTable, setShowTable] = useState(false);

  const menuItems = [
    {
      name: "Prospect Statistics",
      accessor: "prospectStatitics",
      onClick: () => swal("", "Our team is working on it!", "info"),
    },
    {
      name: "Listings",
      accessor: "listings",
      onClick: () => {
        handleFilterReset();
        getListedPropsByClientId();
        setActiveMenuItem("listings");
      },
    },
    {
      name: "Prospects",
      accessor: "prospects",
      onClick: () => {
        handleFilterReset();
        getPropspectsByClientID();
        setActiveMenuItem("prospects");
      },
    },
    {
      name: "Tours",
      accessor: "tours",
      onClick: () => swal("", "Our team is working on it!", "info"),
    },
  ];

  useEffect(() => {
    setActiveMenuItem("listings");
    getListedPropsByClientId();
    getPortfolios();
  }, []);

  // API calls
  const getListedPropsByClientId = () => {
    GetListedPropertiesByClientIdAPI(userInfo.ID).then((res) => {
      setReportData(res.data);
      setFilteredData(res.data);
      setReportTitle("Listed Properties");
      setShowTable(true);
    });
  };

  const getPropspectsByClientID = () => {
    GetProspectsByClientIdAPI(userInfo.ID).then((res) => {
      setReportData(res.data);
      setFilteredData(res.data);
      setReportTitle("All Prospects");
      setShowTable(true);
    });
  };

  const getPortfolios = () => {
    GetPortfoliosByUserIdAPI(userInfo.ID).then((res) => {
      const sortedData =
        res.data.length > 1
          ? res.data.sort((a, b) =>
              a.portfolioName > b.portfolioName ? 1 : -1
            )
          : res.data;
      setPortfolioOptions(sortedData);
    });
  };

  const getProperties = (portfolioId) => {
    GetPropertiesByPortfolioIdAPI(portfolioId).then((res) => {
      setPropertyOptions(
        res.data.length > 1
          ? res.data.sort((a, b) => (a.propertyName > b.propertyName ? 1 : -1))
          : res.data
      );
    });
  };

  const getPropspectsByPropertyId = (propertyId) => {
    GetProspectsByPropertyIdAPI(propertyId).then((res) => {
      setReportData(res.data);
      setFilteredData(res.data);
      setReportTitle(`Prospects for Property ID: ${propertyId}`);
      setShowTable(true);
    });
  };

  // Consolidated filtering logic
  const filterData = useCallback(() => {
    let filtered = reportData;

    if (filters.portfolioName) {
      filtered = filtered.filter((item) => {
        const portfolioField =
          activeMenuItem === "listings" ? item.portfolio : item.portfolioName;

        return portfolioField === filters.portfolioName;
      });

      if (activeMenuItem === "prospects") {
        const selectedPortfolio = portfolioOptions.filter(
          (item) => item.portfolioName === filters.portfolioName
        );
        if (selectedPortfolio) getProperties(selectedPortfolio[0].ID);
      }
    }

    if (filters.propertyName) {
      filtered = filtered.filter(
        (item) => item.propertyName === filters.propertyName
      );
    }
    if (filters.propertyStatus) {
      filtered = filtered.filter(
        (item) => item.status === filters.propertyStatus
      );
    }
    if (filters.prosAppStatus) {
      filtered = filtered.filter(
        (item) => item.status === filters.prosAppStatus
      );
    }

    // Filter by availability period (Weekly, Monthly, Quarterly, Annual)
    if (filters.availPeriod) {
      const currentDate = new Date();
      const oneDay = 1000 * 60 * 60 * 24;
      const oneMonth = 30 * oneDay;
      const threeMonths = 3 * oneMonth;
      const oneYear = 365 * oneDay;

      filtered = filtered.filter((item) => {
        const createdAt = new Date(item.CreatedAt || item.listedDate);
        const timeDiff = currentDate - createdAt;
        switch (filters.availPeriod) {
          case "Weekly":
            return timeDiff <= 7 * oneDay;
          case "Monthly":
            return timeDiff <= oneMonth;
          case "Quarterly":
            return timeDiff <= threeMonths;
          case "Annual":
            return timeDiff <= oneYear;
          default:
            return true;
        }
      });
    }

    setFilteredData(filtered);
  }, [reportData, filters]);

  useEffect(() => {
    filterData(); // Re-run filtering logic whenever filters or reportData changes
  }, [filters, reportData, filterData]);

  const handleFilterChange = (filterName, value) => {
    if (filterName === "portfolioName") {
      setPropertyOptions([]);
    }
    setFilters((prevFilters) => ({ ...prevFilters, [filterName]: value }));
  };

  const handleFilterReset = (method) => {
    setFilters({
      portfolioName: "",
      propertyName: "",
      propertyStatus: "",
      availPeriod: "",
      prosAppStatus: "",
    });
    setPropertyOptions([]);
    setFilteredData([]);
    setReportData([]);

    if (method === "hard") {
      if (activeMenuItem === "prospects") {
        getPropspectsByClientID();
      } else if (activeMenuItem === "listings") {
        getListedPropsByClientId();
      }
    }
  };

  const handleListingsPropAddressClick = (row) => {
    handleFilterReset();
    getPropspectsByPropertyId(row.propertyId);
    getPortfolios();
    getProperties(row.portfolioId);
    setReportTitle(`${row.propertyName} Prospects`);
    setActiveMenuItem("prospects");

    handlePortfolioSelect(row.portfolio);
    handlePropertySelect(row.propertyName);
  };

  const handleProspectsAddressClick = (row) => {
    setReportTitle(`${row.propertyName} Prospects`);
    handlePortfolioSelect(row.portfolioName);
    handlePropertySelect(row.propertyName);
  };

  const handlePortfolioSelect = (portfolioName) => {
    setFilters((prevFilters) => ({ ...prevFilters, portfolioName }));
  };

  const handlePropertySelect = (propertyName) => {
    setFilters((prevFilters) => ({ ...prevFilters, propertyName }));
  };

  const NavigationChip = ({ name, accessor, onClick, id }) => (
    <span
      id={id}
      key={id}
      className={`p-2 border rounded-full w-40 text-center text-gray-500 border-gray-500 hover:bg-gray-100 ${
        activeMenuItem === accessor ? `bg-gray-200` : ""
      } ${clickEffect}`}
      onClick={onClick}
    >
      {name}
    </span>
  );

  // Filter Fields
  const portfolioSelectField = () => (
    <InputObjectSelectField
      name="portfolioName"
      id="selectPortfolio"
      notImp
      placeholder="Select Portfolio"
      value={filters.portfolioName}
      onChange={(e) => handleFilterChange("portfolioName", e.target.value)}
      options={portfolioOptions}
      optionValue="portfolioName"
      optionName="portfolioName"
      className="w-full bg-white"
    />
  );

  const propertySelectField = () => (
    <InputObjectSelectField
      name="propertyName"
      id="propertySelect"
      notImp
      placeholder="Select Property"
      value={filters.propertyName}
      onChange={(e) => handleFilterChange("propertyName", e.target.value)}
      options={propertyOptions}
      optionValue="propertyName"
      optionName="propertyName"
      className="w-full bg-white"
    />
  );

  const propertyStatusField = () => (
    <InputSelectField
      name="propertyStatus"
      id="propertyStatus"
      notImp
      placeholder="Select Property Status"
      value={filters.propertyStatus}
      onChange={(e) => handleFilterChange("propertyStatus", e.target.value)}
      options={["Vacant (Ready to Rent)", "Vacant Not Ready to Rent"]}
      className="w-full bg-white"
    />
  );

  const availabilityPeriodField = () => (
    <InputSelectField
      name="availPeriod"
      id="availabilityPeriod"
      notImp
      placeholder="Select Availability Period"
      value={filters.availPeriod}
      onChange={(e) => handleFilterChange("availPeriod", e.target.value)}
      options={["Weekly", "Monthly", "Quarterly", "Annual"]}
      className="w-full bg-white"
    />
  );

  const prospectsApplicationStatusField = () => (
    <InputSelectField
      name="prosAppStatus"
      id="prosAppStatus"
      notImp
      placeholder="Select Application Status"
      value={filters.prosAppStatus}
      onChange={(e) => handleFilterChange("prosAppStatus", e.target.value)}
      options={["completed", "Incomplete"]}
      className="w-full bg-white"
    />
  );

  const resetButton = () => (
    <ButtonCustom
      id="clearAll"
      name="Reset"
      onClick={(e) => handleFilterReset("hard")}
      className="px-2 py-1 text-center w-24 bg-transparent rounded text-gray-600 underline hover:bg-gray-600 hover:text-white !shadow-none"
    />
  );

  return (
    <div className="mx-10">
      <section id="navigationItems" className="flex items-center gap-4">
        {menuItems.map((menu) => (
          <NavigationChip
            id={menu.accessor}
            name={menu.name}
            accessor={menu.accessor}
            onClick={menu.onClick}
          />
        ))}
      </section>

      <div className="m-auto flex items-start mt-10">
        <motion.section
          animate={{ width: isCollapsed ? "80px" : "26%" }} // Collapse or expand
          transition={{ duration: 0.3 }}
          className="bg-gray-100 py-6 px-4 overflow-hidden h-full"
        >
          {/* Report Builder Header */}
          <div
            className="flex items-center mb-4 cursor-pointer bg-blue-100 border-2 border-blue-30 rounded-lg p-2"
            onClick={(e) => setIsCollapsed(!isCollapsed)}
          >
            <motion.div
              animate={{ rotate: isCollapsed ? 90 : 0 }} // Rotate icon when collapsed
              transition={{ duration: 0.3 }}
            >
              <AiOutlineMenu className="mr-2 text-blue-52 text-2xl" />
            </motion.div>
            {!isCollapsed && (
              <h2 className="text-gray-400 font-light  flex flex-col">
                Company Name:
                <span className="font-semibold text-black">
                  {userInfo.companyName}
                </span>
              </h2>
            )}
          </div>

          {!isCollapsed && (
            <>
              <div className="space-y-2">
                {activeMenuItem === "listings" && (
                  <span>
                    <p className="bg-slate-400 p-2 rounded-md">
                      Listed Properties
                    </p>
                    <p className="p-2 text-gray-400">Archive</p>
                  </span>
                )}
                {activeMenuItem === "prospects" && (
                  <span>
                    <p className="bg-slate-400 p-2 rounded-md">
                      Prospects Enquiry
                    </p>
                    <p className="p-2 text-gray-400">Prospects Archive</p>
                  </span>
                )}
              </div>

              {activeMenuItem && (
                <>
                  <label className="block text-sm mb-2 mt-10 border-b-2">
                    Filter
                  </label>
                  <section className="space-y-2 pb-2">
                    {activeMenuItem === "listings" && (
                      <>
                        {portfolioSelectField()}
                        {propertyStatusField()}
                        {availabilityPeriodField()}
                      </>
                    )}
                    {activeMenuItem === "prospects" && (
                      <>
                        {portfolioSelectField()}
                        {propertySelectField()}
                        {prospectsApplicationStatusField()}
                        {availabilityPeriodField()}
                      </>
                    )}

                    <div className="flex justify-end items-center gap-4 !my-6">
                      {resetButton()}
                    </div>
                  </section>
                </>
              )}
            </>
          )}
        </motion.section>

        {/* Main Content Area */}
        <div
          className={`h-screen bg-gray-100 ml-6 ${
            isCollapsed ? "w-11/12" : "w-3/4"
          } `}
        >
          <span
            id="portfolioPageTitle"
            className={`flex justify-start items-center px-6 bg-[#678398] text-white font-semibold rounded h-10`}
          >
            {reportTitle}
          </span>

          <section className="border py-4 h-full rounded overflow-x-auto">
            {!showTable && (
              <p className="text-gray-500 text-center py-auto">No Data!</p>
            )}
            {
              <>
                {activeMenuItem === "listings" && (
                  <PropertiesListing
                    data={filteredData}
                    handleCallBack={handleListingsPropAddressClick}
                  />
                )}

                {activeMenuItem === "prospects" && (
                  <Prospects
                    data={filteredData}
                    handleCallBack={handleProspectsAddressClick}
                  />
                )}
              </>
            }
          </section>
        </div>
      </div>
    </div>
  );
};

export default ProspectDashboard;
