import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { clientId, companyId, userData } from "../../atoms";
import { useRecoilValue } from "recoil";
import { WebApimanager } from "../../WebApiManager";
import {
  InputObjectSelectField,
  InputFileField,
} from "../../Widgets/Forms/InputFields";
import {
  ButtonCustom,
  ButtonGrayOutline,
  ButtonYellowOutline,
  ButtonBlue,
  ButtonDelete,
  ButtonBlueOutline,
} from "../../Widgets/Buttons";
import {
  TableComponent,
  Gallery,
  GetDateInMMDDYYYY,
  Searchbar,
  clickEffect,
} from "../../Widgets/CommonWidgets";
import { Loader } from "../../Widgets/notificationFeedbacks";
import { DetailsTile } from "../../Widgets/Forms/FormReviewTiles";
import { TbClipboardText, TbFileUpload } from "react-icons/tb";
import { IconInCircle, InfoIcon } from "../../Widgets/Icons";
import {
  AiOutlineSearch,
  AiOutlineCloseCircle,
  AiOutlinePlus,
} from "react-icons/ai";
import { BsPlusCircle } from "react-icons/bs";
import { FcFolder } from "react-icons/fc";
import { Dialog, Stepper, StepLabel, Step } from "@mui/material";
import { TiDelete } from "react-icons/ti";
import swal from "sweetalert";
import { Document, Page, pdfjs } from "react-pdf";
import style from "./index.module.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.js',
//   import.meta.url,
// ).toString();

const Documents = () => {
  let webApi = new WebApimanager();
  let navigate = useNavigate();
  const clientIdVal = useRecoilValue(clientId);
  const companyIdVal = useRecoilValue(companyId);
  const clientData = useRecoilValue(userData);

  const [selectedPortfolioID, setSelectedPortfolioID] = useState("");
  const [allPortfolios, setAllPortfolios] = useState([]);
  const [selectedPropertyId, setSelectedPropertyId] = useState("");
  const [allPropertiesMap, setAllPropertiesMap] = useState(new Map());
  const [properties, setProperties] = useState([]);

  const [portfolioMenuActive, setPortfolioMenuActive] = useState(false);

  const [currentPrefix, setCurrentPrefix] = useState("");
  const [allFolders, setAllFolders] = useState([]);
  const [files, setFiles] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [currentFolders, setCurrentFolders] = useState([]);

  const [searchString, setSearchString] = useState("");

  const [openFolderForm, setOpenFolderForm] = useState(false);
  const [openFileUploadForm, setOpenFileUploadForm] = useState(false);

  const [openPdf, setOpenPdf] = useState(false);
  const [openGallery, setOpenGallery] = useState(false);
  const [fileLink, setFileLink] = useState("");
  const [videoLink, setVideoLink] = useState("");

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  useEffect(() => {
    setApiProcessing({
      loader: true,
      message: `Gathering Documents and Folders...`,
    });
    getPortfolios();
    getDocumentsByPrefix(`${clientData.companyID}/`);
    setPortfolioMenuActive(false);
  }, []);

  const getPortfolios = () => {
    setApiProcessing({
      loader: true,
      message: `Fetching all Portfolios...`,
    });
    webApi
      .get("/portfolios/client/" + clientIdVal)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setAllPortfolios(
            res.data.length > 1
              ? res.data.sort((a, b) =>
                  a.portfolioName > b.portfolioName ? 1 : -1
                )
              : res.data
          );
        }
        setApiProcessing({ loader: false, message: `` });
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to get Portfolios, Please try again later.`,
            "error"
          );
        }
      });
  };

  const getProperties = (portfolioId, openingView) => {
    setSelectedPortfolioID(portfolioId);
    setApiProcessing({
      loader: true,
      message: `Fetching Properties of selected Portfolio...`,
    });
    setSelectedPropertyId("");
    if (allPropertiesMap.has(portfolioId)) {
      const props = allPropertiesMap.get(portfolioId);
      setProperties(props);
      if (openingView && props.length > 1) {
        getDocumentsByPropertyId(props[0].ID);
      }
      setApiProcessing({ loader: false, message: `` });
      return;
    }
    setProperties([]);
    webApi
      .get("/properties/portfolio/" + portfolioId)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          const acsendingData =
            res.data.length > 1
              ? res.data.sort((a, b) =>
                  a.propertyName > b.propertyName ? 1 : -1
                )
              : res.data;
          setProperties(acsendingData);
          setAllPropertiesMap(allPropertiesMap.set(portfolioId, acsendingData));
          if (openingView && res.data.length > 1) {
            getDocumentsByPropertyId(res.data[0].ID);
          }
        }
        setApiProcessing({ loader: false, message: `` });
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Oops! Failed to get Properties, Please try again later.`,
            "error"
          );
        }
      });
  };

  const getDocumentsByPortfolioId = (portfolioId) => {
    setApiProcessing({
      loader: true,
      message: `Gathering Docs and Folders...`,
    });
    setSelectedPortfolioID(portfolioId);
    webApi
      .get(`/documents/portfolio/${portfolioId}`)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setCurrentPrefix(res.data.prefix);
          setAllFolders(res.data.folders);
          setCurrentFolders(res.data.folders);
          if (res.data.files.length) {
            const transformedFiles = res.data.files.map((fileName) => ({
              file: fileName,
            }));
            setTableData(transformedFiles);
            setFiles(transformedFiles);
          } else {
            setFiles([]);
            setTableData([]);
          }
        }
        setApiProcessing({ loader: false, message: `` });
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Failed to upload the file. Please try again later.",
            "error"
          );
        }
      });
  };

  const getDocumentsByPropertyId = (propertyId) => {
    setApiProcessing({
      loader: true,
      message: `Gathering Docs and Folders...`,
    });
    setSelectedPropertyId(propertyId);
    webApi
      .get(`/documents/property/${propertyId}`)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setCurrentPrefix(res.data.prefix);
          setAllFolders(res.data.folders);
          setCurrentFolders(res.data.folders);
          if (res.data.files.length) {
            const transformedFiles = res.data.files.map((fileName) => ({
              file: fileName,
            }));
            setTableData(transformedFiles);
            setFiles(transformedFiles);
          } else {
            setFiles([]);
            setTableData([]);
          }
        }
        setApiProcessing({ loader: false, message: `` });
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Failed to upload the file. Please try again later.",
            "error"
          );
        }
      });
  };

  const getDocumentsByPrefix = (newprefix) => {
    setApiProcessing({
      loader: true,
      message: `Gathering Documents and Folders...`,
    });
    const data = {
      prefix: newprefix,
    };
    if (clientData.userRole !== "Client Admin") {
      data.role = clientData.userRole;
      data.roleId = clientData.ID;
    }
    webApi
      .post("/documents/prefix", data)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setCurrentPrefix(res.data.prefix);
          const sortedFolders = res.data.folders.sort();
          setAllFolders(sortedFolders);
          setCurrentFolders(sortedFolders);
          if (res.data.files.length) {
            const transformedFiles = res.data.files.map((fileName) => ({
              file: fileName,
            }));
            setTableData(transformedFiles);
            setFiles(transformedFiles);
          } else {
            setFiles([]);
            setTableData([]);
          }
        }
        setApiProcessing({ loader: false, message: `` });
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            "Oops, something went wrong! Please try again later.",
            "error"
          );
        }
      });
  };

  const createFolder = (folderName) => {
    setApiProcessing({
      loader: true,
      message: `Creating Folder...`,
    });
    const data = {
      prefix: currentPrefix,
    };
    webApi
      .post(`/documents/newFolder/${folderName}`, data)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          setCurrentPrefix(res.data.prefix);
          setAllFolders(res.data.folders);
          setCurrentFolders(res.data.folders);
          if (res.data.files.length) {
            const transformedFiles = res.data.files.map((fileName) => ({
              file: fileName,
            }));
            setTableData(transformedFiles);
            setFiles(transformedFiles);
          } else {
            setFiles([]);
            setTableData([]);
          }
        }
        setApiProcessing({ loader: false, message: `` });
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Failed to Create Folder ${folderName}. Please try again later.`,
            "error"
          );
        }
      });
  };

  const getFileLink = (fileName, action) => {
    setApiProcessing({
      loader: true,
      message: `Getting File...`,
    });
    setFileLink("");
    setVideoLink("");
    const data = {
      objectKey: `${currentPrefix}${fileName}`,
    };
    webApi
      .post(`/documents/download`, data)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          if (action === "view") {
            if (
              res.data.metadata.filename.split(".").pop().toLowerCase() ===
              "pdf"
            ) {
              setFileLink(res.data.objectKey);
              setOpenPdf(true);
            } else if (
              ["jpg", "jpeg", "png"].includes(
                res.data.metadata.filename.split(".").pop().toLowerCase()
              )
            ) {
              setFileLink(res.data.objectKey);
              setOpenGallery(true);
            } else {
              setVideoLink(res.data.objectKey);
              setOpenGallery(true);
            }
            // const newWindow = window.open(res.data.objectKey, "_blank");
            // if (newWindow) {
            //   newWindow.focus();
            // }
          } else if (action === "download") {
            window.location.href = res.data.objectKey;
          }
        }
        setApiProcessing({ loader: false, message: `` });
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else
          swal(
            "Error",
            "Oops, Unable to get the File! Please try again later.",
            "error"
          );
      });
  };

  const handleFileUpload = (file) => {
    setApiProcessing({
      loader: true,
      message: `Uploading File...`,
    });
    const data = {
      objectKey: `${currentPrefix}${file.name}`,
      metadata: {
        filename: file.name,
        mediatype: file.type,
      },
    };

    webApi
      .uploadFile(data.objectKey, data.metadata, file)
      .then((res) => {
        getDocumentsByPrefix(`${currentPrefix}`);
        setApiProcessing({ loader: false, message: `` });
        swal("Success", "File Upload is successful", "success");
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Failed to upload file ${file.name}. Please try again later.`,
            "error"
          );
        }
      });
  };

  const handleSearch = (event) => {
    const currentSearchString = event.target.value;
    setSearchString(currentSearchString);

    if (currentSearchString === "") {
      setCurrentFolders(allFolders);
      setTableData(files);
    } else {
      const filteredFolders = allFolders.filter((folder) =>
        folder.toLowerCase().includes(currentSearchString.toLowerCase())
      );

      setCurrentFolders(filteredFolders);

      const filteredFiles = files.filter((singleFile) =>
        singleFile.file
          .toLowerCase()
          .includes(currentSearchString.toLowerCase())
      );

      setTableData(filteredFiles);
    }
  };

  const handleSearchClear = () => {
    setSearchString("");
    setCurrentFolders(allFolders);
    setTableData(files);
  };

  const filesTableActions = {
    downloadTitle: "Download",
    downloadOnclick: (row) => {
      getFileLink(row.file, "download");
    },
    mailTitle: "Mail",
    mailOnclick: (row) => {
      swal(
        "Info",
        "Mail Option isn't available at the moment, Our Developers are working on it.",
        "info"
      );
    },
  };

  const handleClickonOrganizationDocuments = (e) => {
    e.preventDefault();
    setCurrentPrefix("");
    setAllFolders([]);
    setCurrentFolders([]);
    setFiles([]);
    setTableData([]);
    getDocumentsByPrefix(`${clientData.companyID}/`);
    setSelectedPortfolioID("");
    setSelectedPropertyId("");
    setPortfolioMenuActive(false);
  };

  const handleClickonPortfolioDocuments = (e) => {
    e.preventDefault();
    setCurrentPrefix("");
    setSelectedPortfolioID("");
    setSelectedPropertyId("");
    setProperties([]);
    setAllFolders([]);
    setCurrentFolders([]);
    setFiles([]);
    setTableData([]);
    // if (allPortfolios.length) {
    //   getProperties(allPortfolios[0].ID, true);
    // }
    setPortfolioMenuActive(true);
  };

  const handlePrefixClick = (index) => {
    const clickedFolders = currentPrefix.split("/").slice(0, index + 1);
    const updatedPrefix = clickedFolders.join("/");
    getDocumentsByPrefix(`${updatedPrefix}/`);
    if (index === 1) {
      setSelectedPropertyId("");
    }
  };
  return (
    <>
      <CreateNewFolder
        trigger={openFolderForm}
        setTrigger={setOpenFolderForm}
        handleCreate={(folderName) => createFolder(folderName)}
      />

      <UploadNewFile
        trigger={openFileUploadForm}
        setTrigger={setOpenFileUploadForm}
        title="Upload New File"
        accept=".png, .jpg, .jpeg, .pdf"
        handleUpload={(file) => handleFileUpload(file)}
      />

      <Loader open={apiProcessing.loader} message={apiProcessing.message} />

      {openPdf && (
        <PDFViewer pdfUrl={fileLink} onClose={(e) => setOpenPdf(false)} />
      )}

      {openGallery && (
        <Gallery
          images={[fileLink]}
          videos={[videoLink]}
          openGallery={openGallery}
          setOpenGallery={setOpenGallery}
        />
      )}

      <div className="w-5/6 m-auto">
        <div
          name="Header Menu"
          className="grid grid-cols-2 justify-center items-center mb-8 font-semibold text-center"
        >
          <span
            onClick={handleClickonOrganizationDocuments}
            className={`cursor-pointer flex justify-center items-center h-10 rounded-l-lg ${
              !portfolioMenuActive
                ? "bg-blue-500 text-white"
                : "bg-gray-300 text-gray-500 font-medium"
            }`}
          >
            Organizational Documents
            <InfoIcon
              message="Use the Organization Documents System to track documents, leases, forms, and much more!"
              left="27%"
            />
          </span>

          <span
            onClick={handleClickonPortfolioDocuments}
            className={`cursor-pointer flex justify-center items-center h-10 rounded-r-lg ${
              portfolioMenuActive
                ? "bg-blue-500 text-white"
                : "bg-gray-300 text-gray-500 font-medium"
            }`}
          >
            Portfolio Documents
          </span>
        </div>

        <section name="Header and Select Fields" className="flex flex-col mb-6">
          <div className="flex justify-start items-center mb-6">
            <IconInCircle
              icon={<TbClipboardText className="w-9 h-9 text-[#2C88AE]" />}
              radius="4rem"
              className="shadow-xl mx-6"
            />
            <span>
              <p className="font-semibold">View All Your Documents!</p>
              {!portfolioMenuActive && (
                <p className="text-gray-400/100">
                  Use the Organization Documents System to track documents,
                  leases, forms and much more!
                </p>
              )}
              {portfolioMenuActive && (
                <p className="text-gray-400/100">
                  Use the Portfolio Documents System to track documents, leases,
                  forms and much more!
                </p>
              )}
            </span>
          </div>

          {/* {!portfolioMenuActive && (
            <div className="flex justify-between items-center mb-6 mx-6">
              <InputObjectSelectField
                label="Select Category"
                name="category"
                id="category"
                notImp
                disabled
                placeholder="All"
                value={""}
                onChange={(e) => {}}
                options={[]}
                optionValue="name"
                optionName="name"
                style=""
              />

              <ButtonGrayOutline
                id="advancedSearch"
                title="Advanced Search"
                name={
                  <span className="flex">
                    <AiOutlineSearch
                      size={25}
                      className="cursor-pointer mr-[4px]"
                    />
                    Advanced Search
                  </span>
                }
                onClick={() => {}}
                className="!w-fit bg-gray-50 h-9"
              />
            </div>
          )} */}

          {portfolioMenuActive && (
            <div className="flex justify-between items-end mb-6 mx-6">
              <div className="flex items-center gap-4">
                <InputObjectSelectField
                  label="Select Portfolio"
                  name="portfolio"
                  id="portfolio"
                  dataCy="portfolio"
                  placeholder="Select a Portfolio"
                  value={selectedPortfolioID}
                  onChange={(e) => {
                    getDocumentsByPortfolioId(parseInt(e.target.value));
                    getProperties(parseInt(e.target.value), false);
                  }}
                  options={allPortfolios}
                  optionValue="ID"
                  optionName="portfolioName"
                  style=""
                />

                <InputObjectSelectField
                  label="Select Property"
                  name="property"
                  id="property"
                  dataCy="property"
                  placeholder="Select a property"
                  value={selectedPropertyId}
                  onChange={(e) =>
                    getDocumentsByPropertyId(parseInt(e.target.value))
                  }
                  options={properties}
                  optionValue="ID"
                  optionName="propertyName"
                  style="rounded-md"
                />
              </div>

              {/* <ButtonGrayOutline
                id="advancedSearch"
                title="Advanced Search"
                name={
                  <span className="flex">
                    <AiOutlineSearch
                      size={25}
                      className="cursor-pointer mr-[4px]"
                    />
                    Advanced Search
                  </span>
                }
                onClick={() => {}}
                className="!w-fit bg-gray-50"
              /> */}
            </div>
          )}
        </section>

        <section name="Documents and Folders">
          <div name="Prefix listing" className="overflow-x-auto">
            <div className="flex items-center mb-2 mt-2 ml-4">
              <div
                className="flex"
                style={{
                  maxWidth: "100%",
                  overflowX: "auto",
                  whiteSpace: "nowrap", // Add this line
                }}
              >
                <span className="text-gray-500">Documents</span>
                {currentPrefix.split("/").map((folder, index, array) => (
                  <React.Fragment key={index}>
                    {index !== array.length - 1 && (
                      <span className="text-gray-500 ml-1 mr-1">
                        &nbsp;&gt;&nbsp;
                      </span>
                    )}
                    <span
                      className={
                        "cursor-pointer " +
                        (index === 0 && portfolioMenuActive
                          ? "text-gray-500"
                          : "text-blue-500 " + clickEffect)
                      }
                      id={index}
                      name={index}
                      data-cy={index}
                      onClick={() => {
                        if (!(index === 0 && portfolioMenuActive)) {
                          handlePrefixClick(index);
                        }
                      }}
                      title={folder.charAt(0).toUpperCase() + folder.slice(1)}
                    >
                      {index >= 2 && index <= array.length - 4
                        ? "..."
                        : folder.charAt(0).toUpperCase() + folder.slice(1)}
                    </span>
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className="border-b-2 border-gray-400"></div>
          </div>

          <div className="flex justify-end items-center mb-4 mt-4 gap-4 mr-4">
            <div
              name="Folders and Files Search"
              className={"flex justify-end items-center w-80"}
            >
              <input
                className={
                  " border shadow rounded-2xl w-full focus:outline-none pr-[30px] py-2 px-3"
                }
                placeholder={"Search Files, Folders ..."}
                value={searchString}
                onChange={handleSearch}
              />
              {searchString === "" && (
                <AiOutlineSearch
                  size={25}
                  className="cursor-pointer absolute mr-[4px] text-blue-52"
                />
              )}
              {searchString !== "" && (
                <TiDelete
                  size={25}
                  className="cursor-pointer absolute mr-[4px] text-red-500"
                  onClick={handleSearchClear}
                />
              )}
            </div>

            {/* <span
              title="Create Folder"
              onClick={(e) => setOpenFolderForm(true)}
              className={
                "p-2 shadow-lg rounded flex justify-center items-center cursor-pointer hover:bg-yellow-300 hover:text-white " +
                clickEffect
              }
            >
              <AiOutlinePlus className="w-5 h-5" />
            </span> */}

            <ButtonBlueOutline
              id="addNewFolder"
              btnName="addNewFolder"
              dataCy="addNewFolder"
              title="Create Folder"
              name={
                <>
                  <BsPlusCircle className="w-5 h-5 mr-2" /> Add New
                </>
              }
              onClick={(e) => {
                if (portfolioMenuActive && !selectedPortfolioID) {
                  swal(
                    "Info",
                    "Please Select a portfolio to add a Folder!",
                    "warning"
                  );
                } else {
                  setOpenFolderForm(true);
                }
              }}
              className={`flex justify-center items-center px-2 w-fit h-10 rounded-md`}
            />
          </div>

          <div className="max-h-[400px] overflow-y-scroll">
            <div className="grid grid-cols-3 gap-4 mb-8 rounded">
              {currentFolders.length > 0 &&
                currentFolders.sort().map((folder, index) => (
                  <div
                    key={folder + index}
                    className={
                      "flex items-center cursor-pointer h-10 p-2 border rounded-md w-auto max-w-[300px] overflow-x-scroll overflow-y-hidden focus:outline-none shadow-lg bg-gray-50 " +
                      clickEffect
                    }
                    onClick={(e) =>
                      getDocumentsByPrefix(`${currentPrefix}${folder}/`)
                    }
                    title={folder.charAt(0).toUpperCase() + folder.slice(1)}
                  >
                    <FcFolder className="w-5 h-5 mr-2" />
                    <span className="text-blue-500 overflow-hidden whitespace-nowrap overflow-ellipsis">
                      {folder.charAt(0).toUpperCase() + folder.slice(1)}
                    </span>
                  </div>
                ))}
            </div>
          </div>

          <TableComponent
            tableId="Files table"
            data={tableData}
            const
            columns={[
              {
                key: "icon",
                title: "",
                applyMethod: (row) => (
                  <IconInCircle
                    icon={
                      <TbClipboardText
                        title={row.file}
                        className="w-5 h-5 text-blue-52"
                      />
                    }
                    radius="2rem"
                    className="shadow-xl"
                    color="#D8F3F7"
                  />
                ),
                width: "80px",
              },

              { key: "file", title: "Name", width: "80%" },
            ]}
            rowOnClick={(row) => {
              getFileLink(row.file, "view");
            }}
            actionHeader={
              <span
                title="Upload File"
                onClick={(e) => {
                  if (portfolioMenuActive && !selectedPortfolioID) {
                    swal(
                      "Info",
                      "Please Select a portfolio to add a File!",
                      "warning"
                    );
                  } else {
                    setOpenFileUploadForm(true);
                  }
                }}
                className={
                  "p-1 rounded flex justify-center items-center cursor-pointer hover:text-white hover:bg-[#228B22] hover:shadow" +
                  clickEffect
                }
              >
                <TbFileUpload className="w-6 h-6" />
              </span>
            }
            actions={filesTableActions}
            // initialSort={{ key: "file", direction: "descending" }}
          />
        </section>
      </div>
    </>
  );
};

export default Documents;

export const CreateNewFolder = (props) => {
  const [folderName, setFolderName] = useState("");

  const handleCreate = (e) => {
    e.preventDefault();
    if (!folderName.trim()) {
      swal("Warning", "Folder name is required!", "warning");
    } else {
      props.handleCreate(folderName);
      setFolderName("");
      props.setTrigger(false);
    }
  };

  return (
    <>
      <Dialog
        open={props.trigger}
        aria-labelledby="Create New Folder"
        fullWidth
        maxWidth="sm"
        PaperProps={{ sx: { height: "60%" } }}
        scroll="body"
        className="flex flex-col justify-center w-full"
      >
        <span className="flex justify-center items-center mb-10 bg-blue-52 text-white font-semibold text-center rounded-sm h-10">
          Create New Folder
        </span>

        <section className="flex flex-col justify-center items-center">
          <div className="flex items-center cursor-pointer h-10 p-2 border rounded-md w-[300px] overflow-x-scroll overflow-y-hidden focus:outline-none shadow-lg bg-gray-50">
            <FcFolder className="w-5 h-5 mr-2" />
            <input
              className={
                props.className + "  rounded-2xl w-full focus:outline-none px-3"
              }
              id="enterFolderName"
              name="enterFolderName"
              data-cy="enterFolderName"
              placeholder="Enter Folder Name"
              value={folderName}
              onChange={(e) =>
                setFolderName(e.target.value.replace(/[^\w\s]/gi, ""))
              }
            />
          </div>

          <div className="flex gap-4 items-center">
            <ButtonGrayOutline
              id="cancel"
              btnName="cancel"
              dataCy="cancel"
              name="Cancel"
              onClick={(e) => {
                setFolderName("");
                props.setTrigger(false);
              }}
              className="mt-6 justify-self-end"
            />
            <ButtonBlue
              id="create"
              btnName="create"
              dataCy="create"
              name="Create"
              onClick={(e) => handleCreate(e)}
              className="mt-6"
            />
          </div>
        </section>
      </Dialog>
    </>
  );
};

export const UploadNewFile = (props) => {
  const [uploadFile, setUploadFile] = useState(props.multiple ? [] : "");

  const upload = (e) => {
    e.preventDefault();
    if (!uploadFile || (props.multiple && !uploadFile.length)) {
      swal(
        "Warning",
        props.warningMessage || `Please Select a File to Upload!`,
        "warning"
      );
    } else {
      props.handleUpload(uploadFile);
      setUploadFile("");
      props.setTrigger(false);
    }
  };

  return (
    <>
      <Dialog
        open={props.trigger}
        aria-labelledby="Upload New File"
        fullWidth
        maxWidth="sm"
        PaperProps={{ sx: { height: "fit-content" } }}
        scroll="body"
        className="flex flex-col justify-center w-full"
      >
        <span className="flex justify-center items-center mb-10 bg-blue-52 text-white font-semibold text-center rounded-sm h-10">
          {props.title}
        </span>

        <section className="flex flex-col justify-center items-center">
          <InputFileField
            label={props.fieldName || "Select File"}
            name="uploadFile"
            id="uploadFile"
            dataCy="uploadFile"
            multiple={props.multiple ? true : false}
            //accept=".png, .jpg, .jpeg, .pdf"
            maxSizeinMB={10}
            accept={props.accept}
            placeholder={props.placeholder || "Select File"}
            value={uploadFile}
            setValue={setUploadFile}
            style=" w-80"
            hintText={props.hintText || "*File Size should be less than 10MB"}
          />

          <div className="flex gap-4 items-center mb-8">
            <ButtonGrayOutline
              id="cancel"
              btnName="cancel"
              dataCy="cancel"
              name="Cancel"
              onClick={(e) => {
                setUploadFile(props.multiple ? [] : "");
                props.setTrigger(false);
              }}
              className="mt-6 justify-self-end"
            />
            <ButtonBlue
              id="upload"
              btnName="upload"
              dataCy="upload"
              name={props.confirmButtonName || "Upload"}
              onClick={(e) => upload(e)}
              className="mt-6"
            />
          </div>
        </section>
      </Dialog>
    </>
  );
};

export const PDFViewer = ({ pdfUrl, onClose }) => {
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div className={style.pdfContainer}>
      <button className={style.btnClose} onClick={onClose}>
        Close
      </button>
      <div>
        <Document file={pdfUrl} onLoadSuccess={handleDocumentLoadSuccess}>
          <Page pageNumber={currentPage} />
        </Document>
      </div>
      <div className=" mt-2 text-white">
        <button
          disabled={currentPage <= 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          Previous
        </button>
        <span>
          {currentPage} of {numPages}
        </span>
        <button
          disabled={currentPage >= numPages}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          Next
        </button>
      </div>
    </div>
  );
};
