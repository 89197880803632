import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { TiEdit } from "react-icons/ti";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import {
  TableComponent,
  Searchbar,
  GetDateInMMDDYYYY,
} from "../../Widgets/CommonWidgets";
import style from "./index.module.css";
import { vendorId } from "../../atoms";
import { useRecoilValue } from "recoil";
import { WebApimanager } from "../../WebApiManager";
import AddVendor from "./addVendor";
import swal from "sweetalert";
import { PhoneNumFormat } from "../../Widgets/Forms/FieldDependants";
import { DetailsTile } from "../../Widgets/Forms/FormReviewTiles";
import { SearchTableComponent } from "../../Widgets/Table/searchTableComponent";

const VendorDetails = (props) => {
  let webApi = new WebApimanager();
  let navigate = useNavigate();
  const [activeMenuItem, setActiveMenuItem] = useState("workOrders");
  const currentVendorId = useRecoilValue(vendorId);
  const [fullVendorDetails, setFullVendorDetails] = useState("");
  const [tableData, setTableData] = useState("");
  const [allMaintenances, setAllMaintenances] = useState("");
  const [formOperation, setFormOperation] = useState("");
  const [addVendorForm, setAddVendorForm] = useState(false);
  const [editVendorData, setEditVendorData] = useState("");
  const tabs = [
    { label: "WORKORDERS", nickName: "workOrders" },
    // { label: "STATISTICS", nickName: "statistics" },
    // { label: "INVOICES", nickName: "invoices" },
    // { label: "PORTFOLIOS", nickName: "portfoilios" },
    // { label: "DOCUMENTS", nickName: "documents" },
  ];

  useEffect(() => {
    getVendorData();
    getMaintenancesOfVendor();
  }, []);

  const getVendorData = () => {
    webApi
      .get("/vendor/" + currentVendorId)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setFullVendorDetails(res.data);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage)
          swal("Error", error.customErrorMessage, "error");
        else
          swal(
            "Error",
            `Oops! Unable to get Vendor Details, Please try again later.`,
            "error"
          );
      });
  };

  const getMaintenancesOfVendor = () => {
    webApi
      .get("/vendor/" + currentVendorId + "/maintenanceTickets")
      .then((res) => {
        if (res.data.errorCode) {
          swal("Error", res.data.errorMessage, "error");
        } else {
          setTableData(res.data);
          setAllMaintenances(res.data);
        }
      })
      .catch((error) => {
        if (error.customErrorMessage)
          swal("Error", error.customErrorMessage, "error");
        else
          swal(
            "Error",
            `Oops! Unable to get Maintenance work orders of Selected Vendor, Please try again later.`,
            "error"
          );
      });
  };

  return (
    <>
      {addVendorForm ? (
        <AddVendor
          openAddVendor={addVendorForm}
          setOpenVendor={setAddVendorForm}
          operation={formOperation}
          data={editVendorData}
          getVendorData={() => getVendorData()}
        />
      ) : null}
      <div className="flex justify-center items-center">
        <div className="flex flex-col h-full border w-11/12">
          <section
            name="Title and Options"
            className="flex justify-between items-center bg-blue-30 h-10"
          >
            <span className="flex gap-4">
              <BsFillArrowLeftCircleFill
                title="Back to Vendor Dashboard"
                onClick={() => navigate("/vendor")}
                className="text-blue-52 w-6 h-6 ml-2 cursor-pointer"
              />
              <p className="font-semibold">
                {fullVendorDetails &&
                  fullVendorDetails.companyName.toUpperCase()}{" "}
                Overview
                {/* {fullVendorDetails && fullVendorDetails.companyName.toUpperCase() + " "+ fullVendorDetails.address1 + ", "
                + fullVendorDetails.address2+ ", " + fullVendorDetails.city+ ", " + fullVendorDetails.state+ ", " + fullVendorDetails.zipCode} */}
              </p>
            </span>

            <div name="editVendor" className="flex text-gray-700">
              <TiEdit
                id="editButton"
                name="editButton"
                data-cy="editButton"
                className="w-5 h-5 mr-4 hover:text-red-500 focus:text-red-500 cursor-pointer"
                onClick={() => {
                  setAddVendorForm(true);
                  setFormOperation("Edit");
                  setEditVendorData(fullVendorDetails);
                }}
              />
            </div>
          </section>

          <section
            name="vendorDetails"
            className="flex justify-around items-start mx-4 my-6 gap-4 md:overflow-x-auto"
          >
            <div className="w-96 h-52 border flex flex-col justify-center items-center bg-gray-50">
              {fullVendorDetails && fullVendorDetails.image !== "" ? (
                <img
                  id="vendorImage"
                  src={fullVendorDetails.image}
                  alt="Vendor Type Photo"
                  className="w-full h-full object-cover cursor-pointer"
                />
              ) : (
                <>
                  <p className="text-gray-600 text-center">
                    No Image to Display
                  </p>
                </>
              )}
            </div>

            <div className="grid grid-cols-2 gap-4">
              <span className="flex flex-col gap-2">
                <DetailsTile
                  label="Vendor Name"
                  data={
                    fullVendorDetails &&
                    fullVendorDetails.firstName +
                      " " +
                      fullVendorDetails.lastName
                  }
                  labelClassName="text-gray-500"
                />

                <DetailsTile
                  label="First Name"
                  data={fullVendorDetails && fullVendorDetails.firstName}
                  labelClassName="text-gray-500"
                />

                <DetailsTile
                  label="Last Name"
                  data={fullVendorDetails && fullVendorDetails.lastName}
                  labelClassName="text-gray-500"
                />

                <DetailsTile
                  label="
                  Vendor Type"
                  data={fullVendorDetails && fullVendorDetails.vendorType}
                  labelClassName="text-gray-500"
                />

                <DetailsTile
                  label="
                  Email ID"
                  data={fullVendorDetails && fullVendorDetails.email}
                  labelClassName="text-gray-500"
                  className="break-words"
                />

                <DetailsTile
                  label="Contact Number"
                  data={
                    fullVendorDetails &&
                    PhoneNumFormat(fullVendorDetails.contactNo, true)
                  }
                  labelClassName="text-gray-500"
                />

                <DetailsTile
                  label="Area Covered"
                  data={
                    `$` + fullVendorDetails && fullVendorDetails.areaCovered
                  }
                  labelClassName="text-gray-500"
                />
              </span>

              <span className="flex flex-col gap-2">
                <DetailsTile
                  label="Street Address 1"
                  data={fullVendorDetails && fullVendorDetails.address1}
                  labelClassName="text-gray-500"
                />
                <DetailsTile
                  label="Street Address 2"
                  data={
                    fullVendorDetails && fullVendorDetails.address2 !== ""
                      ? fullVendorDetails.address2
                      : `-`
                  }
                  labelClassName="text-gray-500"
                />
                <DetailsTile
                  label="City"
                  data={fullVendorDetails && fullVendorDetails.city}
                  labelClassName="text-gray-500"
                />
                <DetailsTile
                  label="State"
                  data={fullVendorDetails && fullVendorDetails.state}
                  labelClassName="text-gray-500"
                />
                <DetailsTile
                  label="Country"
                  data={fullVendorDetails && fullVendorDetails.country}
                  labelClassName="text-gray-500"
                />
                <DetailsTile
                  label="ZIP Code"
                  data={fullVendorDetails && fullVendorDetails.zipCode}
                  labelClassName="text-gray-500"
                />
              </span>
            </div>
          </section>

          <section
            name="tabs"
            className="flex justify-around items-center h-10 font-medium text-gray-600 cursor-pointer"
          >
            {tabs.map((tab, index) => (
              <p
                key={index}
                id={tab.nickName}
                data-cy={tab.nickName}
                className={`hover:text-[#003C9A] focus:text-[#003C9A] pb-2 border-b-2 w-full text-center ${
                  activeMenuItem === tab.nickName
                    ? `text-[#003C9A] border-blue-52 font-semibold`
                    : `text-gray-600 border-gray-600`
                }`}
                onClick={(e) => setActiveMenuItem(tab.nickName)}
              >
                {tab.label}
              </p>
            ))}
          </section>

          {activeMenuItem === "workOrders" && (
            <section name="Units Table">
              <div className="flex justify-between items-center gap-4 m-4">
                <p className="font-semibold text-gray-600">
                  Work Orders({tableData.length})
                </p>

                <SearchTableComponent
                  styles=""
                  placeholder="Search..."
                  data={allMaintenances}
                  setTableData={setTableData}
                  columns={[
                    {
                      key: "propertyId",
                      title: "Property ID",
                      applyMethod: (row) => row.unitDetails.propertyId,
                    },
                    { key: "description", title: "Description" },
                    { key: "woid", title: "Work Order ID" },
                    { key: "taskCreatedRoleName", title: "Created By" },
                    {
                      key: "CreatedAt",
                      title: "Created On",
                      applyMethod: (row) => GetDateInMMDDYYYY(row.CreatedAt),
                    },
                    {
                      key: "UpdatedAt",
                      title: "Closed On",
                      applyMethod: (row) => {
                        if (row.status.toLowerCase() === "closed") {
                          return GetDateInMMDDYYYY(row.UpdatedAt);
                        } else {
                          return "-";
                        }
                      },
                    },
                    { key: "status", title: "Status" },
                  ]}
                />
              </div>

              <TableComponent
                tableId="VendorsMaintenanceTable"
                data={tableData}
                columns={[
                  {
                    key: "propertyId",
                    title: "Property ID",
                    applyMethod: (row) => row.unitDetails.propertyId,
                  },
                  { key: "description", title: "Description" },
                  { key: "woid", title: "Work Order ID" },
                  { key: "taskCreatedRoleName", title: "Created By" },
                  {
                    key: "CreatedAt",
                    title: "Created On",
                    applyMethod: (row) => GetDateInMMDDYYYY(row.CreatedAt),
                  },
                  {
                    key: "UpdatedAt",
                    title: "Closed On",
                    applyMethod: (row) => {
                      if (row.status.toLowerCase() === "closed") {
                        return GetDateInMMDDYYYY(row.UpdatedAt);
                      } else {
                        return "-";
                      }
                    },
                  },
                  { key: "status", title: "Status" },
                ]}
                rowOnClick={(row) =>
                  navigate("/maintenance/maintenanceDetails", {
                    state: { ticketId: row.ID },
                  })
                }
              />
            </section>
          )}
        </div>
      </div>
    </>
  );
};

export default VendorDetails;
