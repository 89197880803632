import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { WebApimanager } from "../../../WebApiManager";

// Reusable Components
import { IconInCircle } from "../../../Widgets/Icons";
import {
  TextReviewTile,
  DetailsTile,
  DetailsTileAttached,
  FileDisplay,
  FileReviewTile,
} from "../../../Widgets/Forms/FormReviewTiles";
import {
  InputSelectField,
  InputTextArea,
} from "../../../Widgets/Forms/InputFields";
import { GetDateInMMDDYYYY } from "../../../Widgets/CommonWidgets";
import {
  ButtonBlueOutline,
  ButtonCustom,
  ButtonGrayOutline,
  ButtonBlue,
} from "../../../Widgets/Buttons";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import { PDFViewer, UploadNewFile } from "../../Documents/documents";
import UpdateEvictionProcess from "./updateEvictionProcess";
import {
  GetFullAddressinTwoLines,
  GetCompleteAddress,
} from "../../../Widgets/Forms/FieldDependants";

//External Imports
import swal from "sweetalert";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
} from "@mui/material";
// import { makeStyles } from "@mui/material/styles";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { PiWarningOctagonLight } from "react-icons/pi";
import { CiCirclePlus } from "react-icons/ci";
import { IoIosArrowDown } from "react-icons/io";
import { FiPaperclip } from "react-icons/fi";
import { PiWarningOctagon } from "react-icons/pi";
import { portfolioTreeStore } from "../../../atoms";
import { useRecoilState } from "recoil";

const EvictionDetails = () => {
  //Routines
  let webApi = new WebApimanager();
  let location = useLocation();
  let navigate = useNavigate();
  const [portfolioTree, SetPortfolioTree] = useRecoilState(portfolioTreeStore);
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: ``,
  });

  //Component Specific
  const [evictionFiledDetails, setEvictionFiledData] = useState(
    location.state.data
  );
  const [evictionOverview, setEvictionOverView] = useState({});
  const [evictionProcessDetails, setEvicPocessDetails] = useState([]);
  const [openDocUploadForm, setOpenDocUploadForm] = useState(false);
  const [filesURL, setFilesURL] = useState([]);

  //Update Eviction
  const [openEvicUpdateForm, setOpenEvicUpdateForm] = useState(false);
  const [currentEvicActivity, setCurrentEvicActivity] = useState("");
  const [openEvicCloseForm, setOpenEvicCloseForm] = useState(false);

  //PDf Viewer
  const [openPdf, setOpenPdf] = useState(false);
  const [pdfFile, setPdfFile] = useState("");

  useEffect(() => {
    getEvictionDetails(
      evictionFiledDetails.tenantCode,
      evictionFiledDetails.evictionId
    );
  }, []);

  //Eviction Details API
  const getEvictionDetails = (tenantCode, evictionId) => {
    setApiProcessing({
      loader: true,
      message: `Gathering Eviction Details...`,
    });
    webApi
      .get(
        `/eviction/evictionDetails/tenantCode/${tenantCode}/evictionId/${evictionId}`
      )
      .then(async (res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
          setApiProcessing({ loader: false, message: `` });
        } else {
          setEvictionFiledData({
            ...res.data.evictionFiled,
          });
          setEvictionOverView({
            status: res.data.status,
            activityUpdateDate: res.data.activityUpdateDate,
            evictionDate: res.data.evictionDate,
          });
          setEvicPocessDetails([
            res.data.evictionFiled,
            ...res.data.evictionUpdateActivities,
          ]);
        }
        setApiProcessing({ loader: false, message: `` });
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
        if (error.customErrorMessage)
          swal("Error", error.customErrorMessage, "error");
        else
          swal(
            "Error",
            "Oops! Unable to get the Tenant Details, Please try again later.",
            "error"
          );
      });
  };

  //Upload Documents API's
  const handleEvictionDocuments = (files) => {
    setApiProcessing({ loader: true, message: `Preparing Files to Upload...` });

    let docNames = [];

    for (var i = 0; i < files.length; i++) {
      docNames.push(files[i].name);
    }

    let data = {
      tenantCode: evictionFiledDetails.tenantCode,
      evictionId: evictionFiledDetails.evictionId,
      activity: currentEvicActivity,
      documents: docNames,
    };

    webApi
      .post(`/eviction/uploadDocuments`, data)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
          setApiProcessing({ loader: false, message: `` });
        } else {
          let failedtoUploadCount = 0;
          let successUploadCount = 0;
          setApiProcessing({ loader: true, message: `Uploading Files...` });
          const uploadPromises = files.map(async (file, i) => {
            const metaData = {
              filename: file.name,
              mediatype: file.type,
            };

            return webApi
              .uploadFile(res.data.path[i], metaData, file)
              .then((res) => successUploadCount++)
              .catch((error) => {
                failedtoUploadCount++;
                // if (error.customErrorMessage) {
                //   swal("Error", error.customErrorMessage, "error");
                // } else {
                //   swal(
                //     "Error",
                //     `Failed to upload ${file.name}. Please try again later.`,
                //     "error"
                //   );
                // }
              });
          });

          Promise.all(uploadPromises)
            .then((results) => {
              if (failedtoUploadCount !== files.length) {
                getEvictionDetails(
                  evictionFiledDetails.tenantCode,
                  evictionFiledDetails.evictionId
                );

                failedtoUploadCount
                  ? swal(
                      "Info",
                      `Successfully Uploaded ${
                        successUploadCount / files.length
                      } Files!`,
                      "success"
                    )
                  : swal(
                      "Success",
                      "Files  Upload has been successful!",
                      "success"
                    );
              } else {
                swal(
                  "Failure",
                  `Failed to Upload ${failedtoUploadCount} Files, Please try again Later!`,
                  "error"
                );
              }

              setApiProcessing({ loader: false, message: `` });
            })
            .catch((error) => {
              setApiProcessing({ loader: false, message: `` });
            });
        }
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Failed to upload files, Please try again later!`,
            "error"
          );
        }
      });
  };

  //Document Operations

  const handleDocDelete = (file) => {
    swal({
      title: "Are you sure?",
      text: `You intend to delete the file ${file.fileName}!`,
      icon: "warning",
      buttons: ["Cancel", "Delete!"],
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        let data = { objectKeys: [file.prefix] };
        webApi
          .delete(`/documents/delete`, data)
          .then((res) => {
            if (res.data[file.fileName] === "Document Deleted successfully") {
              getEvictionDetails(
                evictionFiledDetails.tenantCode,
                evictionFiledDetails.evictionId
              );
              swal(
                "Success",
                `Document ${file.fileName} Deleted successfully`,
                "success"
              );
            } else {
              swal("Failure", res.data[file.fileName], "error");
            }
          })
          .catch((error) => {
            if (error.customErrorMessage) {
              swal("Error", error.customErrorMessage, "error");
            } else {
              swal(
                "Error",
                `Failed to Delete file named ${file.fileName}, Please try again later!`,
                "error"
              );
            }
          });
      } else {
        return;
      }
    });
  };

  const handleDocDownload = (file) => {
    window.location.href = file.presignedURL;
  };

  //Eviction Status API
  const handleEvictionCloseUpdate = (request) => {
    setApiProcessing({
      loader: true,
      message: `Updating Status...`,
    });
    webApi
      .post(`/eviction/updateStatus`, request)
      .then((res) => {
        if (res.data.errorCode) {
          swal("Failure", res.data.errorMessage, "error");
        } else {
          swal(`Success`, `Successfully Updated the Status.`, "success");
          getEvictionDetails(
            evictionFiledDetails.tenantCode,
            evictionFiledDetails.evictionId
          );
        }
        setApiProcessing({ loader: false, message: `` });
      })
      .catch((error) => {
        setApiProcessing({ loader: false, message: `` });
        if (error.customErrorMessage) {
          swal("Error", error.customErrorMessage, "error");
        } else {
          swal(
            "Error",
            `Failed to update the Status., Please try again later!`,
            "error"
          );
        }
      });
  };

  //show writ of Possession Document
  const showWritOfPossession = (props) => {
    setPdfFile(props.url);
    setOpenPdf(true);
  };

  return (
    <>
      <Loader open={apiProcessing.loader} message={apiProcessing.message} />

      {openEvicUpdateForm && (
        <UpdateEvictionProcess
          tenantCode={evictionFiledDetails.tenantCode}
          evictionID={evictionFiledDetails.evictionId}
          trigger={openEvicUpdateForm}
          setTrigger={setOpenEvicUpdateForm}
          handleUpdateEvictionCallback={(data) =>
            getEvictionDetails(
              evictionFiledDetails.tenantCode,
              evictionFiledDetails.evictionId
            )
          }
        />
      )}

      {openEvicCloseForm && (
        <EvictionCloseForm
          trigger={openEvicCloseForm}
          setTrigger={setOpenEvicCloseForm}
          tenantCode={evictionFiledDetails.tenantCode}
          evictionID={evictionFiledDetails.evictionId}
          handleEvictionClose={handleEvictionCloseUpdate}
        />
      )}

      <UploadNewFile
        trigger={openDocUploadForm}
        setTrigger={setOpenDocUploadForm}
        title="Attach Files"
        multiple
        accept=".pdf, .png, .jpg"
        placeholder="Attach .pdf .png .jpg format only"
        handleUpload={(files) => handleEvictionDocuments(files)}
      />

      {openPdf && (
        <PDFViewer
          pdfUrl={pdfFile} // Pass the file URL to PDFViewer if available
          onClose={(e) => setOpenPdf(false)}
        />
      )}

      <div className="w-11/12 m-auto border">
        <section
          name="Eviction details page title"
          className="flex justify-start items-center bg-blue-52 h-10 mb-4 rounded px-2"
        >
          <BsFillArrowLeftCircleFill
            id="backToPrevious"
            name="backToPrevious"
            data-cy="backToPrevious"
            title="Back to Previous page"
            onClick={(e) => {
              SetPortfolioTree({
                ...portfolioTree,
                tenant: {
                  ...portfolioTree.tenant,
                  activeMenuItem: "delinquency",
                  activeDelinquencyTab: "evictionNotice",
                },
              });
              navigate("/tenants/tenantDetails");
            }}
            className="text-white w-6 h-6 cursor-pointer"
          />

          <span className="flex font-normal items-center text-white pl-2">
            Eviction ID: &nbsp; {evictionFiledDetails.evictionId}
          </span>
        </section>

        <section
          name="Eviction Details"
          className="flex justify-between items-start mb-8 mx-6"
        >
          <span className="flex items-center gap-4">
            <IconInCircle
              icon={<PiWarningOctagonLight className="w-8 h-8 text-blue-52" />}
              radius="3.5rem"
              className="shadow-xl"
              color="#BFE3ED"
            />
            <span>
              <DetailsTile
                label="Status :"
                data={evictionOverview.status}
                labelClassName="justify-self-start text-gray-500"
                dataClassName="justify-self-start"
              />
              <DetailsTile
                label="Activity Update Date :"
                data={GetDateInMMDDYYYY(evictionOverview.activityUpdateDate)}
                labelClassName="justify-self-start text-gray-500"
                dataClassName="justify-self-start"
              />
              <DetailsTile
                label="Eviction Date :"
                data={GetDateInMMDDYYYY(evictionOverview.evictionDate)}
                labelClassName="justify-self-start text-gray-500"
                dataClassName="justify-self-start"
              />
            </span>
          </span>
          <span className="flex flex-col items-end">
            {evictionOverview.status !== "Tenant Evicted" ? (
              <InputSelectField
                label="Status :"
                name="evictionStatus"
                id="evictionStatus"
                dataCy="evictionStatus"
                placeholder="Select a Status"
                options={[evictionOverview.status, "Tenant Evicted"]}
                value={evictionOverview.status}
                onChange={(e) => setOpenEvicCloseForm(true)}
                className="bg-[#0a875c] !text-white !w-fit"
                style="flex gap-2 items-center !w-fit"
                notImp
              />
            ) : (
              <DetailsTileAttached
                label="Status :"
                data={evictionOverview.status}
                dataClassName="text-sm py-2 px-4 rounded text-white font-semibold bg-red-10 w-fit mr-2"
              />
            )}
            {evictionOverview.status !== "Tenant Evicted" && (
              <ButtonBlueOutline
                btnName="addUpdate"
                dataCy="addUpdate"
                id="addUpdate"
                name={
                  <>
                    <CiCirclePlus className="mr-1 w-5 h-5" /> Add Update
                  </>
                }
                onClick={(e) => setOpenEvicUpdateForm(true)}
                className="flex justify-center items-center mt-2 rounded-md h-10 px-2 font-semibold !w-fit mr-2"
              />
            )}
          </span>
        </section>

        <div className="font-semibold p-2 bg-[#D5EAF0] w-full">
          Eviction Process
        </div>

        {evictionProcessDetails.map((item, index) => (
          <React.Fragment key={item.ID}>
            <Accordion>
              <AccordionSummary
                expandIcon={<IoIosArrowDown />}
                id={item.ID + index}
              >
                <div className="font-semibold">{item.activity}</div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="grid grid-cols-4 gap-x-4 gap-y-4">
                  {item.activity === "Eviction Filed" &&
                    GetEvictionFiledTiles(item)}

                  {item.activity === "Tenant Response" &&
                    GetTenantResponseTiles(item)}

                  {item.activity === "Court Hearing" &&
                    GetCourtHearingTiles(item)}

                  {item.activity === "Judgement" && GetJudgementTiles(item)}

                  {item.activity === "Writ of Possession" &&
                    GetWritofPossessionTiles(item)}

                  {item.activity === "Sheriff Eviction" && (
                    <GetSheriffEvictionTiles
                      item={item}
                      showWritOfPossession={showWritOfPossession}
                    />
                  )}

                  {item.activity === "Tenant Evicted" &&
                    GetTenantEvictedTiles(item)}
                </div>

                {item.activity !== "Tenant Evicted" && (
                  <div name="Documents" className="mt-8 w-full">
                    <span className="flex justify-start items-center border-b-2 border-[#42AACB] mb-4">
                      Documents
                    </span>
                    {item.documents?.map((file, fileIndex) => (
                      <React.Fragment>
                        <FileDisplay
                          file={file}
                          fileNameKey="fileName"
                          fileUrlKey="presignedURL"
                          onDelete={handleDocDelete}
                          onDownload={handleDocDownload}
                        />
                      </React.Fragment>
                    ))}
                    <span className="flex justify-center">
                      <ButtonCustom
                        btnName="addDocuments"
                        dataCy="addDocuments"
                        id="addDocuments"
                        name={
                          <>
                            <FiPaperclip className="mr-1" /> Attach Files
                          </>
                        }
                        onClick={(e) => {
                          setOpenDocUploadForm(true);
                          setCurrentEvicActivity(item.activity);
                        }}
                        className="flex justify-center items-center mt-2 rounded-md h-10 px-2 text-[#3D72BF] border border-[#3D72BF] font-semibold hover:bg-[#3D72BF] hover:text-white focus:bg-[#3D72BF] focus:text-white"
                      />
                    </span>
                  </div>
                )}
              </AccordionDetails>
            </Accordion>
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default EvictionDetails;

//Accordian
// const useStyles = makeStyles((theme) => ({
//   summaryRoot: {
//     backgroundColor: "#D9D9D9", //  background color
//     color: "#FF0000",
//     // Add any other styles if needed for the summary
//   },
// }));

//Eviction Process Tiles
const GetEvictionFiledTiles = (item) => (
  <>
    <TextReviewTile title="Tenant Name" body={item.tenantName} />

    <TextReviewTile
      title="Property Address"
      body={GetFullAddressinTwoLines(item.propertyaddress)}
    />

    <TextReviewTile title="Court Filed In" body={item.courtFiledIn} />

    <TextReviewTile title="Case Id" body={item.caseId} />
    <TextReviewTile
      title="Date Filed"
      body={GetDateInMMDDYYYY(item.dateFiled)}
    />
    <TextReviewTile title="Activity" body={item.activity} />

    <TextReviewTile
      title="Activity Update Date"
      body={GetDateInMMDDYYYY(item.activityUpdateDate)}
    />

    <TextReviewTile
      title="Last Day to Answer"
      body={GetDateInMMDDYYYY(item.lastDateToAnswer)}
    />

    <TextReviewTile title="Description" body={item.description} />
  </>
);

const GetTenantResponseTiles = (item) => (
  <>
    <TextReviewTile title="Activity" body={item.activity} />

    <TextReviewTile
      title="Activity Update Date"
      body={GetDateInMMDDYYYY(item.activityUpdateDate)}
    />

    <TextReviewTile title="Tenant Response" body={item.tenantResponse} />

    <TextReviewTile title="Court Date" body={GetDateInMMDDYYYY(item.date)} />
  </>
);

const GetCourtHearingTiles = (item) => (
  <>
    <TextReviewTile title="Activity" body={item.activity} />

    <TextReviewTile
      title="Activity Update Date"
      body={GetDateInMMDDYYYY(item.activityUpdateDate)}
    />

    <TextReviewTile title="Tenant Response" body={item.tenantResponse} />

    <TextReviewTile title="Court Date" body={GetDateInMMDDYYYY(item.date)} />
  </>
);

const GetJudgementTiles = (item) => (
  <>
    <TextReviewTile title="Activity" body={item.activity} />

    <TextReviewTile
      title="Activity Update Date"
      body={GetDateInMMDDYYYY(item.activityUpdateDate)}
    />

    <TextReviewTile
      title="Settlement Date"
      body={GetDateInMMDDYYYY(item.date)}
    />

    <TextReviewTile title="Description" body={item.description} />
  </>
);

const GetWritofPossessionTiles = (item) => (
  <>
    <TextReviewTile title="Activity" body={item.activity} />

    <TextReviewTile
      title="Activity Update Date"
      body={GetDateInMMDDYYYY(item.activityUpdateDate)}
    />

    <TextReviewTile title="Date Issued" body={GetDateInMMDDYYYY(item.date)} />

    <TextReviewTile title="Description" body={item.description} />
  </>
);

const GetSheriffEvictionTiles = ({ item, showWritOfPossession }) => {
  return (
    <>
      <TextReviewTile title="Activity" body={item.activity} />

      <TextReviewTile
        title="Activity Update Date"
        body={GetDateInMMDDYYYY(item.activityUpdateDate)}
      />

      <TextReviewTile
        title="Eviction Date"
        body={GetDateInMMDDYYYY(item.date)}
      />

      <FileReviewTile
        title="Attach Writ of Possession"
        body={item.writOfPossession && item.writOfPossession.fileName}
        url={item.writOfPossession && item.writOfPossession.presignedURL}
        onClick={(props) => showWritOfPossession(props)}
        dataClassName="cursor-pointer"
      />

      <TextReviewTile title="Description" body={item.description} />
    </>
  );
};

const GetTenantEvictedTiles = (item) => (
  <>
    <TextReviewTile title="Activity" body={item.activity} />

    <TextReviewTile title="Description" body={item.description} />
  </>
);

//Terminate or Cancel Lease
const EvictionCloseForm = (props) => {
  const [note, setNote] = useState("");

  const confirm = (e) => {
    e.preventDefault();
    if (!note) {
      swal("Warning", `Please Enter the Description!`, "warning");
    } else {
      const request = {
        tenantCode: props.tenantCode,
        evictionId: props.evictionID,
        status: "Tenant Evicted",
        description: note,
      };
      props.handleEvictionClose(request);
      setNote("");
      props.setTrigger(false);
    }
  };

  return (
    <>
      <Dialog
        open={props.trigger}
        aria-labelledby="Lease Termination"
        fullWidth
        maxWidth="sm"
        PaperProps={{ sx: { height: "auto" } }}
        scroll="body"
        className="mx-auto w-full rounded"
      >
        <span className="flex justify-center items-center mb-6 bg-blue-52 text-white font-semibold text-center rounded-sm h-10">
          Eviction Status Update
        </span>

        <section className="flex flex-col justify-center items-center">
          <PiWarningOctagon className="mx-auto w-12 h-12 text-blue-53" />
          <p className="mx-auto">
            Do you want to change the status for this eviction?
          </p>

          <InputTextArea
            label="Description"
            name="Description"
            id="Description"
            dataCy="Description"
            type="text"
            placeholder="Enter description here..."
            value={note}
            onChange={(e) => setNote(e.target.value)}
            //errorText={errors.note}
            style="!mt-4 !mb-6"
            className="h-16 rounded-lg"
          />

          <div className="flex gap-8 items-center mb-4">
            <ButtonGrayOutline
              id="cancel"
              btnName="cancel"
              dataCy="cancel"
              name="Cancel"
              onClick={(e) => {
                setNote("");
                props.setTrigger(false);
              }}
              className="justify-self-end"
            />
            <ButtonBlue
              id="confirm"
              btnName="confirm"
              dataCy="confirm"
              name={"Confirm"}
              onClick={(e) => confirm(e)}
              className=""
            />
          </div>
        </section>
      </Dialog>
    </>
  );
};
