import { useNavigate } from "react-router-dom";
import { userData } from "../../atoms";

import { ButtonCustom } from "../../Widgets/Buttons";

import { BsFillCheckCircleFill } from "react-icons/bs";
import { useRecoilState } from "recoil";
import { useEffect } from "react";
import { GetProfileInfoByEmailAPI } from "../../CentralizedAPI/commonAPI";
import { motion } from "framer-motion";

const SubscriptionSuccess = () => {
  let navigate = useNavigate();
  const [userInfo, setUserInfo] = useRecoilState(userData);

  useEffect(() => {
    getUpdatedSubscriptionInfo();
  }, []);

  const getUpdatedSubscriptionInfo = () => {
    GetProfileInfoByEmailAPI(userInfo.email).then((res) => {
      setUserInfo(res.data);
      if (!res.data.isSubscribed && res.data.userRole === "Client Admin") {
        navigate("/subscription/retry");
      }
    });
  };

  const navigateToSubscriptionDetails = () => {
    navigate("/subscription/Details");
  };

  return (
    <div className="bg-slate-200 h-screen">
      <section className="relative bg-blue-52 h-44 text-white overflow-hidden">
        <div className="absolute inset-0 w-full">
          <svg
            viewBox="0 0 1440 320"
            className="absolute w-full h-full"
            preserveAspectRatio="none"
          >
            <path
              fill="#E2E8F0"
              fillOpacity="1"
              d="M0,320L60,288C120,256,240,192,360,160C480,128,600,128,720,144C840,160,960,192,1080,218.7C1200,245,1320,256,1380,256L1440,256V320H0V320Z"
            ></path>
          </svg>
        </div>
        <div className="relative grid grid-cols-2 h-full items-center z-10">
          <div className="ml-8 -mt-8">
            <motion.img
              id="yvooaLogo"
              className="w-48 h-14"
              src="/logo/NEWLOGO.png"
              alt="Yvooa logo"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            />
          </div>
        </div>
      </section>

      <div className="flex justify-center bg-white w-fit h-64 px-4 py-6 rounded-xl mx-auto border-2">
        <div className="flex flex-col w-auto items-center justify-center">
          <BsFillCheckCircleFill className="w-14 h-12 text-green-600 mb-4" />
          <p className="text-black font-semibold text-center mb-4">
            Subscription successful! Thanks for joining us.
          </p>
          {userInfo.isSubscribed && (
            <ButtonCustom
              id="subscriptionDetailsButton"
              name="View Subscription"
              onClick={navigateToSubscriptionDetails}
              className="px-6 py-2 w-fit font-bold border-2 border-[#38B37E] text-gray-400 hover:bg-[#38B37E] hover:text-white focus:bg-[#38B37E] focus:text-white"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionSuccess;
